<template>
  <div class="supplier">
    <div class="item dataList">
      <a-row>
        <a-col :span="24">
          <a-row class="select-form">
            <a-col :span="24">
              <a-form layout="inline" :form="searchSupplierForm">
                <a-form-item>
                  <a-input
                      size="large"
                      placeholder="公司名称"
                      style="width: 120px"
                      v-model.trim="queryParam.name"
                      allowClear>
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-select v-model="queryParam.status"
                            :getPopupContainer="trigger => trigger.parentNode"
                            allowClear
                            style="width: 110px"
                            size="large"
                            placeholder="审核状态"
                            @change="handleSupplierStateSelectChange">
                    <a-select-option :key="0"
                                     value="0">
                      未审核
                    </a-select-option>
                    <a-select-option :key="1"
                                     value="1">
                      审核通过
                    </a-select-option>
                    <a-select-option :key="2"
                                     value="2">
                      审核驳回
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item>
                  <a-col :span="8">
                    <a-button
                        size="large"
                        type="primary"
                        @click="handleSearch">查询
                    </a-button>
                  </a-col>
                  <a-col :span="8" offset="8">
                    <a-button size="large" @click="resetSearchForm">重置</a-button>
                  </a-col>
                </a-form-item>
              </a-form>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <a-table bordered
             rowKey="id"
             :pagination="false"
             :data-source="dataSource"
             :columns="columns"
             :customRow="customRow"
             :row-class-name="setRowClassName"
             style="width: 100%;">
      <template slot="status" slot-scope="text">
        <div>
          <span v-if="text==0">未审核</span>
          <span v-else-if="text==1" style="color: limegreen">审核通过</span>
          <span v-else style="color: red">审核驳回</span>
        </div>
      </template>
      <template slot="detail" slot-scope="text,record">
        <a-tag   style="color: #2db7f5;font-size: 12px" @click="handleCheck(record)">
          公司详细信息，点击查看>>>
        </a-tag >
      </template>
      <template slot="createTime" slot-scope="text">
        {{getFormatDate(text)}}
      </template>
      <template slot="isLogin" slot-scope="text">
        <span v-if="text==0" style="color: silver">未登录</span>
        <span v-else style="color: limegreen">已登录</span>
      </template>
      <span slot="action" slot-scope="text,record">
         <a-tooltip placement="top" title="审核" autoAdjustOverflow>
            <a-button :disabled="record.status!=0" type="primary" size="small" icon="check-circle" @click="handleCheck(record)"/>
         </a-tooltip>
      </span>
    </a-table>
    <pageination  style="float: right"
                  :paginationList="paginationList"
                  @paging="paging">
    </pageination>
    <div class="check">
      <supplier-detail :check-visible="checkVisible" :supplier-info="editParam" @changeCheckVisible="changeCheckVisible"></supplier-detail>
    </div>
  </div>
</template>

<script>
import Pageination from "@/components/pageination";
import {dateFormat} from "@/utils/common";
import SupplierDetail from "@/views/supplier/supplierDetail";
export default {
  name: "index",
  components: {SupplierDetail, Pageination},
  created() {
    let {size,current} = this.paginationList;
    let query = {size,current,...this.queryParam};
    this.getSupplierList(query);
  },
  data(){
    return {
      selectedRowId:undefined,
      searchSupplierForm:this.$form.createForm(this, {name: 'supplier_search_form'}),
      paginationList:{
        size:10,
        current:1,
        total:0,
        pageSizeOptions: ['10', '20', '30', '40', '50','100'],
      },
      queryParam:{
        name:undefined,
        status:undefined,
      },
      dataSource:[],
      columns:[
        {
          title: '公司名称',
          dataIndex: 'name',
          key:'name',
          width: '120px',
          align: 'center',
        },
        {
          title: '负责人',
          dataIndex: 'leaderName',
          key:'leaderName',
          width: '70px',
          align: 'center',
        },
        {
          title: '负责人电话',
          dataIndex: 'leaderPhone',
          key:'leaderPhone',
          width: '100px',
          align: 'center',
        },
        {
          title: '注册时间',
          dataIndex: 'createTime',
          key:'createTime',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'createTime' },
        },
        {
          title: '公司电话',
          dataIndex: 'phone',
          key:'phone',
          width: '100px',
          align: 'center',
        },
        {
          title: '登录状态',
          dataIndex: 'isLogin',
          key:'isLogin',
          width: '80px',
          align: 'center',
          scopedSlots: { customRender: 'isLogin' },
        },
        {
          title: '审核状态',
          dataIndex: 'status',
          key:'status',
          width: '80px',
          align: 'center',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '公司详情',
          dataIndex: 'detail',
          key:'detail',
          width: '20px',
          align: 'center',
          scopedSlots: { customRender: 'detail' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          key:'action',
          width: '10px',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      isClicked:false,
      checkVisible:false,
      editParam: {
        supplierQualification:{},
        supplierVO:{},
      },
    }
  },
  methods:{
    customRow(record){
      return {
        on:{
          click:(event)=>{
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    getSupplierList(params){
      this.$api.supplier.supplier4Admin(params).then(res=>{
        if(res.code===200){
          this.dataSource = res.data.records;
          this.paginationList.total = res.data.total;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    handleSupplierStateSelectChange(value) {
      this.queryParam.status = value;
    },
    async handleSearch() {
      let {size,current} = this.paginationList;
      let query = {size,current,...this.queryParam};
      this.getSupplierList(query);
    },
    handleCheck(record){
      const params = {id:record.id};
      this.$api.supplier.detail(params).then(res=>{
        if(res.code===200){
          this.$store.state.supplierInfo = res.data;
          let item = JSON.parse(JSON.stringify(this.$store.getters.supplierInfo));
          this.editParam = item;
          this.checkVisible = true;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      });

    },
    changeCheckVisible(value){
      this.checkVisible = value;
    },
    resetSearchForm() {
      this.queryParam = {
        name: undefined,
        status: undefined,
      }
      let size = 10, current = 1;
      let query = {size, current, ...this.queryParam};
      this.getSupplierList(query);
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
    paging(parameter) {
      let supplierQuery = {
        current:parameter.current,
        size:parameter.size,
        status:this.queryParam.status,
        name:this.queryParam.name,
      };
      this.getSupplierList(supplierQuery);
    },
  }
}
</script>

<style lang="scss" scoped>
.supplier {
  .item {
  }
  .dataList {
    .select-form {
      margin: 0 0 10px 0;
    }
    ::v-deep .clickRowStyle{
      background-color:#e6f7ff
    }
  }
}

</style>