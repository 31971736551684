<template>
  <div class="purchase">
    <div class="item dataList">
      <a-row>
        <a-col :span="24">
          <a-row class="select-form">
            <a-col :span="24">
              <a-form layout="inline" :form="searchForm">
                <a-form-item>
                  <a-input
                      size="large"
                      placeholder="请输入采购单批次号"
                      v-model.trim="queryParam.purchaseOrderTaskCode"
                      clearable
                  >
                  </a-input>
                </a-form-item>
                <a-form-item v-if="userRoles.indexOf('2')!==-1">
                  <restaurant-selector ref="restaurantSelector"
                                       :current-value="supplierBindResChooseOption"
                                       @restaurantSelectChange="restaurantSelectChange"/>
                </a-form-item>
                <a-form-item>
                  <goods-selector ref="goodsSelector"
                                  :current-value="goodsChooseOption"
                                  :company-id="this.queryParam.companyId"
                                  @goodsSelectChange="goodsSelectChange"></goods-selector>
                </a-form-item>
                <a-form-item>
                  <a-select v-model="taskStatus"
                            :getPopupContainer="trigger => trigger.parentNode"
                            allowClear
                            style="width: 110px"
                            size="large"
                            placeholder="采购状态"
                            @change="taskStatusChange">
                    <a-select-option :key="0"
                                     value="0">
                      未开始
                    </a-select-option>
                    <a-select-option :key="1"
                                     value="1">
                      采购中
                    </a-select-option>
                    <a-select-option :key="2"
                                     value="2">
                      已完成
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item>
                  <a-select v-model="delStatus"
                            :getPopupContainer="trigger => trigger.parentNode"
                            allowClear
                            style="width: 110px"
                            size="large"
                            placeholder="删除状态"
                            @change="delStatusChange">
                    <a-select-option :key="0"
                                     value="0">
                      未删除
                    </a-select-option>
                    <a-select-option :key="1"
                                     value="1">
                      已删除
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item>
                  <a-col :span="8">
                    <a-button
                        size="large"
                        type="primary"
                        @click="searchSmallPurchaseTaskList">查询
                    </a-button>
                  </a-col>
                  <a-col :span="8" offset="8">
                    <a-button size="large" @click="resetSearchSmallPurchaseForm">重置</a-button>
                  </a-col>
                </a-form-item>
              </a-form>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-table rowKey="id"
               :pagination="false"
               :columns="columns"
               :data-source="dataSource"
               :customRow="customRow"
               :row-class-name="setRowClassName"
               style="width: 100%;"
               bordered
      >
        <template slot="empty">
          <a-empty description="暂无数据"></a-empty>
        </template>
        <template slot="detailList" slot-scope="text,record">
          <a-tag style="color: #2db7f5;font-size: 12px" @click="getSmallTaskDetail(record.id)">
            订单详细信息，点击查看>>>
          </a-tag>
        </template>
        <template slot="totalPrice" slot-scope="text">
          <span style="color: red">
            ￥{{getFormatMoney(text,true)}}
          </span>
        </template>
        <template slot="createTime" slot-scope="text">
          {{getFormatDate(text)}}
        </template>
        <template slot="status" slot-scope="text">
          <span v-if="text==0" style="color: silver">未采购</span>
          <span v-if="text==1" style="color:#F29F2A">采购中</span>
          <span v-if="text==2" style="color:limegreen">已完成</span>
        </template>
      </a-table>
      <pageination style="float: right"
                   :paginationList="paginationList"
                   @paging="paging">
      </pageination>
      <div>
        <small-task-detail :visible="smallTaskDetailVisible"
                           :small-task-detail="smallTaskDetail"
                           @changeSmallTaskDetail="changeSmallTaskDetail"
                           @changeSmallTaskDetailVisible="changeSmallTaskDetailVisible"/>
      </div>
    </div>
  </div>
</template>

<script>
import Pageination from "@/components/pageination";
import {dateFormat, formatMoney} from "@/utils/common";
import SmallTaskDetail from "@/views/purchase/smallTaskDetail";
import GoodsSelector from "@/components/goodsSelector";
import RestaurantSelector from "@/components/restaurantSelector";
import SupplierSelector from "@/components/supplierSelector";
import CompanySelector from "@/components/companySelector";
export default {
  name: "SmallPurchase",
  inject: ['reload'],
  components: {
    SmallTaskDetail, Pageination,SupplierSelector, RestaurantSelector, CompanySelector, GoodsSelector,
  },
  created() {
    let queryParam = {
      current: this.paginationList.current,
      size: this.paginationList.size,
      ...this.queryParam,
    }
    this.searchSmallPurchaseTaskList(queryParam);
  },
  data() {
    return {
      userType:this.$store.getters.userInfo.staffType,
      userRoles:this.$store.getters.userInfo.roleIds,
      searchForm: this.$form.createForm(this, {name: 'search_form'}),
      goodsChooseOption:{key:'',label:''},
      supplierBindResChooseOption:{key:'',label:''},
      taskStatus:undefined,
      delStatus:undefined,
      queryParam: {
        purchaseOrderTaskCode: '',
        productId: '',
        restaurantId: '',
        startTime: '',
        endTime: '',
        status: '',
        delStatus: '',
      },
      smallTaskDetail:undefined,
      smallTaskDetailVisible:false,
      paginationList: {
        size: 10,
        current: 1,
        total: 0,
        pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
      },
      dataSource: [],
      columns: [
        {
          title: '采购总价',
          dataIndex: 'totalPrice',
          key: 'totalPrice',
          width: '70px',
          align: 'center',
          scopedSlots: {customRender: 'totalPrice'},
        },

        {
          title: '采购单状态',
          dataIndex: 'status',
          key: 'status',
          width: '70px',
          align: 'center',
          scopedSlots: {customRender: 'status'},
        },
        {
          title: '批次号',
          dataIndex: 'code',
          key: 'code',
          width: '120px',
          align: 'center',
        },
        {
          title: '采购门店',
          dataIndex: 'restaurantName',
          key: 'restaurantName',
          width: '100px',
          align: 'center',
        },
        {
          title: '发布人',
          dataIndex: 'publisherName',
          key: 'publisherName',
          width: '70px',
          align: 'center',
        },
        {
          title: '采购单明细',
          dataIndex: 'detailList',
          key: 'detailList',
          width: '70px',
          align: 'center',
          scopedSlots: {customRender: 'detailList'},
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
          width: '60px',
          align: 'center',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: '100px',
          align: 'center',
          scopedSlots: {customRender: 'createTime'},
        },
      ],
    }
  },
  methods: {
    customRow(record) {
      return {
        on: {
          click: (event) => {
            //console.log("点击了：",record.id)
            this.selectedRowId = record.id;
          },
        },
      };
    },
    goodsSelectChange(value){
      //获取下拉选择框中的数据
      this.goodsChooseOption = value;
      this.queryParam.productId = value.key;
    },
    taskStatusChange(value) {
      this.queryParam.status = value;
      this.taskStatus = value;
    },
    delStatusChange(value) {
      this.queryParam.delStatus = value;
      this.delStatus = value;
    },
    restaurantSelectChange(value){
      this.supplierBindResChooseOption = value;
      this.queryParam.restaurantId = value.key;
    },
    getSmallPurchaseTaskList(params) {
      this.$api.smallPurchaseTask.purchaseTaskCompOrResList(params).then(res => {
        if (res.code === 200) {
          console.log("结果数据：",res.data);
          this.dataSource = res.data.records;
          // this.dataSource.forEach(e => {
          //
          // })
          this.paginationList.total = res.data.total;
        } else {
          if (res.msg) {
            this.$message.error(res.msg);
          }
        }
      })
    },
    searchSmallPurchaseTaskList() {
      let queryParam = {
        current: this.paginationList.current,
        size: this.paginationList.size,
        ...this.queryParam,
      }
      //console.log(queryParam);
      //TODO 查询零星采购列表
      this.getSmallPurchaseTaskList(queryParam);
    },
    resetSearchSmallPurchaseForm() {
      this.$refs.goodsSelector.value = undefined;
      this.orderStatus = undefined;
      this.delStatus = undefined;
      this.taskStatus = undefined;
      this.supplierBindResChooseOption = {key:'',label:''};
      this.goodsChooseOption = {key:'',label:''};
      this.queryParam = {
        purchaseOrderTaskCode: '',
        productId: '',
        restaurantId: '',
        companyId: '',
        productName: '',
        startTime: '',
        endTime: '',
        status: '',
        delStatus:'',
      }
      let params = {
        current: this.paginationList.current,
        size: this.paginationList.size,
        ...this.queryParam,
      }
      this.getSmallPurchaseTaskList(params);
    },
    getSmallTaskDetail(key){
      const param = {id:key,delStatus:''};
      this.$api.smallPurchaseTask.detail(param).then(res=>{
        if(res.code===200&&res.success){
          console.log("采购单详细信息:",res.data);
          this.smallTaskDetail = res.data;
          this.smallTaskDetailVisible = true;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      });
    },
    changeSmallTaskDetail(value){
      this.smallTaskDetail = value;
    },
    changeSmallTaskDetailVisible(value){
      this.smallTaskDetailVisible = value;
    },
    setRowClassName(record) {
      if (record.id === this.selectedRowId) {
        return 'clickRowStyle'
      }
    },
    payWayChange(value) {
      this.queryParam.payWay = value;
    },
    //拼装分页参数
    paging(parameter) {
      //console.log("paging获取到的参数",parameter)
      let queryParam = {
        current: parameter.current,
        size: parameter.size,
        ...this.queryParam,
      };
      this.getSmallPurchaseTaskList(queryParam);
    },
    getFormatDate(text) {
      const result = dateFormat(text, 'YYYY-MM-DD HH:mm:ss');
      return result;
    },
    getFormatMoney(money, isCarryBit) {
      const result = formatMoney(money, 2, ".", ",", isCarryBit);
      return result;
    },
  },
}
</script>

<style lang="scss" scoped>

.purchase {
  .item {
  }

  .add-purchase-btn {
    margin: 0 0 10px 0;
  }

  .dataList {
    .select-form {
      margin: 0 0 10px 0;
    }

    ::v-deep .clickRowStyle {
      background-color: #e6f7ff
    }
  }

}
</style>