<template>
  <div class="add-category">
    <a-modal title="添加商品"
             :visible="addVisible"
             :confirm-loading="confirmLoading"
             okText="确定"
             cancelText="取消"
             @ok="handleOk"
             @cancel="handleCancelSubmit"
             width="500px">
      <a-form class="add-goods-form" :form="addGoodsForm" label-width="100px">
        <a-row justify="start">
          <div style="display: flex;flex-direction: row;">
            <div>
              <a-form-item label="商品名称" :label-col="{span:6}" :wrapper-col="{ span:12}">
                <a-input
                    v-decorator="['name',rules.name]"
                    placeholder="请输入名称"
                    type="text"
                    size="large"
                    :key="0"
                    allowClear>
                </a-input>
              </a-form-item>
              <a-form-item label="所属分类" :label-col="{span:6}" :wrapper-col="{ span:12}">
                <category-selector ref="categorySelector"
                                   placeholder="请选择分类!"
                                   :current-value="selectOption"
                                   @categorySelectChange="categorySelectChange"></category-selector>
              </a-form-item>
              <a-form-item label="单位" :label-col="{span:4}" :wrapper-col="{ span:12,offset:2}">
                <a-input
                    v-decorator="['unit',rules.unit]"
                    placeholder="请输入单位"
                    size="large"
                    type="text"
                    :key="1"
                    allowClear>
                </a-input>
              </a-form-item>
            </div>
            <div>
              <a-col :span="24">
                <a-form-item class="upload-form-item" label="商品图片">
                  <a-upload v-decorator="['mainPicture',rules.mainPicture]"
                            class="avatar-uploader"
                            action="/auth/upLoad"
                            :headers=headers
                            name="file"
                            :data=this.uploadParams
                            :fileList="fileList"
                            list-type="picture-card"
                            :before-upload="handleBeforeUpload"
                            :remove="handleRemovePicture"
                            @preview="handlePreview"
                            @change="handleChange">
                    <div v-if="!this.addParam.mainPicture">
                      <a-icon type='plus'/>
                      <div class="ant-upload-text">
                        上传图片
                      </div>
                    </div>
                  </a-upload>
                  <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                    <img alt="商品图片" style="width: 100%" :src="addParam.mainPicture"/>
                  </a-modal>
                </a-form-item>
              </a-col>
            </div>
          </div>
          <a-divider style="padding: 0px;margin: 0px"/>
          <a-col :span="24">

            <a-form-item label="描述" :wrapper-col="{span:24}">
              <a-textarea v-decorator="['description',rules.description]"
                          placeholder="商品描述"
                          rows="4"
                          allowClear/>
            </a-form-item>
            <a-form-item label="备注" :wrapper-col="{span:24}">
              <a-textarea v-decorator="['remark',rules.remark]"
                          placeholder="备注"
                          rows="4"
                          allowClear/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import Modal from "ant-design-vue/lib/modal/Modal";
import CategorySelector from "@/components/categorySelector";

export default {
  name: "addGoods",
  components: {CategorySelector},
  props: {
    addVisible: {
      type: Boolean,
    },
  },
  inject: ["reload"],
  data() {
    return {
      customVisible: this.addVisible,
      confirmLoading: false,
      addGoodsForm: this.$form.createForm(this, {name: 'add_category_form'}),
      addParam: {
        name: undefined,
        categoryId: undefined,
        mainPicture: undefined,
        unit: undefined,
        description: undefined,
        remark: undefined,
      },
      rules: {
        name: {
          rules: [
            {required: true, message: '请输入商品名称!'},
          ]
        },
        // category:{
        //   rules:[
        //     {required: true, message: '请选择分类!'},
        //   ],
        // },
        mainPicture: {
          rules: [
            {required: false, message: '上传商品图片'}
          ]
        },
        unit: {
          rules: [
            {required: true, message: '单位'},
          ]
        },
        description: {
          rules: [
            {required: false, message: '描述'},
          ]
        },
        remark: {
          rules: [
            {required: false, message: '备注'},
          ]
        },
      },
      headers: {
        contentType: 'multipart/form-data'
      },
      previewVisible: false,
      fileList: [],
      selectOption: {key: undefined, label: undefined},
    }
  },
  methods: {
    handleOk() {
      this.confirmLoading = true;
      if (!this.addParam.categoryId) {
        this.$message.error("商品分类未选择");
        this.confirmLoading = false;
        return;
      }
      if(!this.addParam.mainPicture){
        this.$message.error("商品图片未上传");
        this.confirmLoading = false;
        return;
      }
      this.addGoodsForm.validateFields((err, values) => {
        if (!err) {
          this.addParam.name = values.name;
          this.addParam.unit = values.unit;
          this.addParam.description = values.description;
          this.addParam.remark = values.remark;
          this.$api.goods.addGoods(this.addParam).then(res => {
            if (res.code === 200 && res.success) {
              this.$message.success("添加成功！");
              this.confirmLoading = false;
              this.reload();
            } else {
              if (res.msg) {
                this.$message.error(res.msg);
                this.confirmLoading = false;
              }
            }
          });
        }
      });
    },
    categorySelectChange(value) {
      this.selectOption = value;
      this.addParam.categoryId = value.key;
    },
    //上传文件相关
    uploadParams() {
      let d = {
        companyName: this.$store.getters.userInfo.companyName,
        userType: this.$store.getters.userInfo.staffType,
      }
      return d;
    },
    handleBeforeUpload(file) {
      return new Promise((resolve, reject) => {
        const isJPG = file.type === 'image/jpg';
        const isJPEG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isGIF = file.type === 'image/gif';
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!(isJPG || isJPEG || isPNG || isGIF)) {
          Modal.error({
            title: '只能上传JPG、JPEG、GIF、PNG格式的图片',
          });
          return reject(false);
        } else if (!isLt10M) {
          Modal.error({
            title: '图片大小不能超过10M',
          });
          return reject(false);
        }
        return resolve(true);
      })
    },
    handleRemovePicture() {
      //console.log("remove1:",this.addGoodsForm.getFieldValue('mainPicture'));
      this.addParam.mainPicture = '';
    },
    handleChange(info) {
      this.fileList = info.fileList;
      //console.log(info);
      const status = info.file.status;
      if (status !== 'uploading') {
        setTimeout(() => {
        }, 10000);
        //console.log(info)
      }
      if (status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
      if (status === 'done') {
        if (info.file.response.data) {
          this.addParam.mainPicture = info.file.response.data;
          this.$message.success(`${info.file.name} 上传成功`);
        } else {
          this.$message.error(`${info.file.name} ${info.file.response.msg}`);
        }
      }
      if (status === 'removed') {
        this.addParam.mainPicture = '';
      }
    },
    async handlePreview(file) {
      if (file.response.data) {
        this.addParam.mainPicture = file.response.data;
        this.previewVisible = true;
      } else {
        this.$message.error("图片上传失败不能预览！")
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handleCancelSubmit() {
      this.fileList = [];
      this.selectOption = {key: '', label: undefined},
          this.addParam.mainPicture = '';
      this.addGoodsForm.resetFields();
      //console.log(this.addGoodsForm.getFieldValue('mainPicture'))
      this.customVisible = false;
      this.$emit("changeAddVisible", this.customVisible);
    }
  }
}
</script>

<style lang="scss" scoped>
.add-category {
  padding: 0 50px 50px;

  .add-goods-form {
    padding: 10px 10px 0 0;
    border-top: 1px solid #eee !important;
    border-bottom: 1px solid #eee !important;

    .editable-row-operations a {
      margin-right: 8px;
    }

    .upload-form-item {
    }

    .avatar-uploader > .ant-upload {
      width: 128px;
      height: 128px;
    }

    .ant-upload-select-picture-card i {
      font-size: 32px;
      color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }

    ::v-deep .ant-table-body > tr > td {
      padding: 10px;
    }

    ::v-deep .ant-table-thead > tr > th {
      padding: 10px;
    }
  }
}
</style>