<template>
  <div>

  </div>
</template>

<script>
export default {
  name: "role-permission",
  props:{
    currentRole:{
      type:Object,
      default:null,
    },
    currentRolePermissions:{
      type:Array,
    },
  },
  created() {

  },
  data(){
    return {
      sourceData:undefined,
      treeData:undefined,
      tempTreeData:undefined,
      disabledStatus:false,
      defaultExpandedKeys:[],
      checkedKeys:[],
      allCheckedKeys:[],
    }
  },
  methods:{
    getTreeNode(subItem){
      if(subItem) {
        return subItem.map(item => {
          const result = {
            key: item.id,
            pid:item.pid,
            level:item.level,
            title: item.name,
            value:item.permissionValue,
            children: item.subList,
          }
          if (result.children) {
            result.children = this.getTreeNode(result.children);
          }
          return result;
        });
      }else{
        return null;
      }
    },
    setDisabled(selectedData){
      if (selectedData) {
        selectedData.forEach(e=>{
          e.disabled = true;
          const children = e.children;
          if(children){
            this.setDisabled(children);
          }
        })
      }
    },
    getTreeData(){
      const sourceData = this.sourceData;
      this.treeData = [];
      if(sourceData) {
        this.treeData = sourceData.map(e => {
          return {
            key:  e.id,
            pid:e.pid,
            level:e.level,
            title: e.name,
            value:e.permissionValue,
            children: this.getTreeNode(e.subList),
          }
        });
      }
    },
    getCurrentRolePermissions(currentRoleId){
      if(currentRoleId){
        this.$api.permission.getRolePermissions(currentRoleId).then(res=>{
          if(res.code===200&&res.success){
            const permissions = res.data;
            this.checkedKeys = permissions.map(item=>item.permissionId);
            this.defaultExpandedKeys = this.checkedKeys;
            if(currentRoleId<=7){
              if (this.checkedKeys.length > 0) {
                const selectData = this.treeData.filter(source =>
                    this.checkedKeys.find(i => i === source.key) !== undefined);
                this.setDisabled(selectData);
              }
            }
          }
        })
      }else{
        this.getTreeData();
      }
    }
  },
  watch:{
    treeData(newValue){
      if(newValue&&this.currentRolePermissions){
        this.checkedKeys = this.currentRolePermissions;
        this.defaultExpandedKeys = this.checkedKeys;
       if(this.currentRole.id<7){
         if (this.checkedKeys.length > 0) {
           const selectData = this.treeData.filter(source =>
               this.checkedKeys.find(i => i === source.key) !== undefined);
           this.setDisabled(selectData);
         }
       }
      }
    }
  },
}
</script>

<style scoped>

</style>