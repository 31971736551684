<template>
  <div class="role">
    <div class="item dataList">
      <a-row>
        <a-col :span="24">
          <a-row class="select-form">
            <a-col :span="24">
              <a-form layout="inline" :form="searchForm">
                <a-form-item>
                  <a-input
                      size="large"
                      placeholder="角色名称"
                      style="width: 120px"
                      v-model.trim="queryParam.roleName"
                      allowClear>
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-input
                      size="large"
                      placeholder="角色编码"
                      style="width: 120px"
                      v-model.trim="queryParam.roleCode"
                      allowClear>
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-select v-model="queryParam.status"
                            :getPopupContainer="trigger => trigger.parentNode"
                            allowClear
                            style="width: 110px"
                            size="large"
                            placeholder="启用状态"
                            @change="handleRoleEnableStateSelectChange">
                    <a-select-option :key="0"
                                     value="0">
                      启用
                    </a-select-option>
                    <a-select-option :key="1"
                                     value="1">
                      禁用
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item>
                  <a-col :span="8">
                    <a-button
                        size="large"
                        type="primary"
                        @click="handleSearch">查询
                    </a-button>
                  </a-col>
                  <a-col :span="8" offset="8">
                    <a-button size="large" @click="resetSearchForm">重置</a-button>
                  </a-col>
                </a-form-item>
              </a-form>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="24">
          <div class="add-role-btn">
            <a-button size="large"
                      type="primary"
                      @click="showAddModal">添加角色</a-button>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-table bordered
             rowKey="id"
             :pagination="false"
             :data-source="dataSource"
             :columns="columns"
             :customRow="customRow"
             :row-class-name="setRowClassName"
             style="width: 100%;">
        <template slot="status" slot-scope="text">
          <div>
            <span v-if="text==0" style="color: limegreen">启用</span>
            <span v-else style="color: red">禁用</span>
          </div>
        </template>
        <template slot="action" slot-scope="text,record">
          <div v-if="record.id>8">
            <a-tooltip placement="top" title="权限配置" autoAdjustOverflow>
              <a-button :key="record.id" type="info" size="small" icon="user"
                        @click="rolePermission(record)"/>
            </a-tooltip>
            <a-divider type="vertical"/>
            <a-tooltip placement="top" title="编辑" autoAdjustOverflow>
              <a-button type="primary" size="small" icon="edit" @click="handleEdit(record)"/>
            </a-tooltip>
            <a-divider type="vertical"/>
            <a-popconfirm title="确定删除?"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="() => onDelete(record.id)">
              <a-icon slot="icon" type="question-circle-o" style="color: red"/>
              <a-button type="danger" size="small" icon="delete"/>
            </a-popconfirm>
          </div>
          <div v-else>
            <a-tooltip placement="top" title="权限配置" autoAdjustOverflow>
              <a-button :key="record.id" type="info" size="small" icon="user"
                        @click="rolePermission(record)"/>
            </a-tooltip>
          </div>
        </template>
    </a-table>
    <pageination  style="float: right"
                  :paginationList="paginationList"
                  @paging="paging">
    </pageination>
    <div class="add">
      <add-role :add-visible="addVisible" @changeAddVisible="changeAddVisible"></add-role>
    </div>
    <div class="edit">
      <role-detail ref="roleDetail"
                   :edit-visible="editVisible" :role-info="editParam"
                   @changeRoleName="changeRoleName"
                   @changeDes = "changeDes"
                   @changeStatus ="changeStatus"
                   @changeEditVisible="changeEditVisible"></role-detail>
    </div>
    <div class="rolePermission">
      <a-drawer :visible="rolePermissionVisible"
                @close="rolePermissionDrawerClose"
                width="350px">
        <template slot="title">
          <div>
            <span style="font-weight: bolder;font-size: 20px">角色权限配置</span>
            <a-button size="small" style="margin-right: 20px;float: right" type="primary" @click="handleChangePermission">确定</a-button>
            <a-button size="small" style="margin-right: 8px;float: right" @click="rolePermissionDrawerClose">取消</a-button>
          </div>
        </template>
        <div>
          <a-tree
              v-model="checkedKeys"
              :expandedKeys.sync="defaultExpandedKeys"
              :tree-data="treeData"
              :disabled="disabledStatus"
              @check="checkedChange"
              checkable
          />
        </div>
      </a-drawer>
    </div>
  </div>

</template>

<script>
import RoleDetail from "@/views/role/roleDetail";
import AddRole from "@/views/role/addRole";
import Pageination from "@/components/pageination";

export default {
  name: "index",
  components: {
    RoleDetail,
    AddRole,
    Pageination,
  },
  inject:["reload"],
  created() {
    let {size,current} = this.paginationList;
    let query = {size,current,...this.queryParam};
    this.getRoleList(query);
  },
  data(){
    return {
      selectedRowId:undefined,
      searchForm: this.$form.createForm(this, {name: 'role_search_form'}),
      paginationList:{
        size:10,
        current:1,
        total:0,
        pageSizeOptions: ['10', '20', '30', '40', '50','100'],
      },
      sourceRoleIds:[1,2,3,4,5,6,7],
      queryParam:{
        roleName:undefined,
        roleCode:undefined,
        status:undefined,
      },
      editParam:{
        id:undefined,
        roleName:undefined,
        roleCode:undefined,
        status:undefined,
        describes:undefined,
      },
      dataSource:[],
      columns:[
        {
          title: '角色名称',
          dataIndex: 'roleName',
          key:'roleName',
          width: '70px',
          align: 'center',
        },
        {
          title: '角色编码',
          dataIndex: 'roleCode',
          key:'roleCode',
          width: '70px',
          align: 'center',
        },
        {
          title: '描述',
          dataIndex: 'describes',
          key:'describes',
          width: '70px',
          align: 'center',
        },
        {
          title: '启用状态',
          dataIndex: 'status',
          key:'status',
          width: '70px',
          align: 'center',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          key:'action',
          width: '70px',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },

      ],
      addVisible:false,
      editVisible:false,

      rolePermissionVisible:false,
      disabledStatus:false,
      treeData:undefined,
      tempTreeData:undefined,
      defaultExpandedKeys:[],
      checkedKeys:[],
      allCheckedKeys:[],
      selectedPermissions:undefined,
      currentRole:undefined,
      currentRolePermissions:undefined,

    }
  },
  methods: {
    customRow(record){
      return {
        on:{
          click:(event)=>{
            // console.log(event);
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    checkedChange(selectedKeys,info){
      this.selectedPermissions = selectedKeys.concat(info.halfCheckedKeys);
    },
    getTreeData(){
      const sourceData = this.sourceData;
      this.treeData = [];
      if(sourceData) {
        this.treeData = sourceData.map(e => {
          return {
            key:  e.id,
            pid:e.pid,
            level:e.level,
            title: e.name,
            value:e.permissionValue,
            children: this.getTreeNode(e.subList),
          }
        });
      }
    },
    getTreeNode(subItem){
      if(subItem) {
        return subItem.map(item => {
          const result = {
            key: item.id,
            pid:item.pid,
            level:item.level,
            title: item.name,
            value:item.permissionValue,
            children: item.subList,
          }
          if (result.children) {
            result.children = this.getTreeNode(result.children);
          }
          return result;
        });
      }else{
        return null;
      }
    },
    setDisabled(selectedData){
      if (selectedData) {
        selectedData.forEach(e=>{
          e.disabled = true;
          const children = e.children;
          if(children){
            this.setDisabled(children);
          }
        })
      }
    },
    rolePermission(record){
      const permissionInfo = this.$store.getters.permissionInfo;
      this.currentRole = record;
      if(permissionInfo){
        this.treeData = JSON.parse(JSON.stringify(permissionInfo));
        const currentRoleId = record.id;
        this.getSelectedRolePermissions(currentRoleId);
      }else{
        this.$api.permission.permissionTree().then(res=>{
          if(res.code===200&&res.success){
            this.sourceData = res.data;
            this.getTreeData();
            this.cachePermissionInfo(this.treeData);
            const currentRoleId = record.id;
            this.getSelectedRolePermissions(currentRoleId);
          }
        })
      }
    },
    getSelectedRolePermissions(currentRoleId){
      this.$api.permission.getRolePermissions(currentRoleId).then(res=>{
        if(res.code===200&&res.success){
          const permissions = res.data;
          if(permissions.length>0){
            this.checkedKeys = permissions.map(item=>item.permissionId);
            this.defaultExpandedKeys = this.checkedKeys;
            if(currentRoleId<=8){
              if (this.checkedKeys.length > 0) {
                const selectData = this.treeData.filter(source =>
                    this.checkedKeys.find(i => i === source.key) !== undefined);
                this.setDisabled(selectData);
              }
            }
          }
        }
        this.rolePermissionVisible = true;
      })
    },
    cachePermissionInfo(permissionInfo){
      this.tempTreeData = JSON.parse(JSON.stringify(permissionInfo));
      this.$store.commit("setPermissionInfo",this.tempTreeData);
    },
    handleChangePermission(){
      const params = {
        roleId:this.currentRole.id,
        ids:this.selectedPermissions,
      }
      this.$api.permission.updateRolePermissions(params).then(res=>{
        if(res.code===200&res.success){
          this.$message.success("操作成功！");
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    rolePermissionDrawerClose(){
      this.treeData = this.tempTreeData;
      this.checkedKeys = [];
      this.defaultExpandedKeys = [];
      this.selectedPermissions = [];
      this.currentRolePermissions = [];
      this.currentRole = undefined;
      this.rolePermissionVisible = false;

    },

    handleRoleEnableStateSelectChange(value) {
      this.queryParam.status = value;
    },
    async handleSearch() {
      let {size,current} = this.paginationList;
      let query = {size,current,...this.queryParam};
      this.getRoleList(query);
    },
    resetSearchForm(){
      this.queryParam = {
        roleName: undefined,
        roleCode: undefined,
        status: undefined,
      }
      let size = 10,current = 1;
      let query = {size,current,...this.queryParam};
      this.getRoleList(query);
    },
    getRoleList(params){
      this.$api.role.selectByCondition(params).then(res=>{
        if(res.code===200){
          this.dataSource = res.data.records;
          this.paginationList.total = res.data.total;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })

    },
    handleEdit(record){
      this.$store.state.roleInfo = record;
      let item = JSON.parse(JSON.stringify(this.$store.getters.roleInfo));
      this.editParam = item;
      this.editVisible = true;
    },
    async onDelete(id){
      let delIds = {
        ids:id,
      };
      const res = await this.$api.role.removeById(delIds);
      if(res.code===200){
        this.$message.success("删除成功！")
        this.reload();
      }else{
        if(res.msg){
          this.$message.error(res.msg);
        }
      }
    },
    showAddModal(){
      this.addVisible = true;
    },
    showEditModal(){
      this.editVisible = true;
    },
    changeAddVisible(value){
      this.addVisible = value;
    },
    changeEditVisible(value){
      this.editVisible = value;
    },
    changeRoleName(value){
      this.editParam.roleName = value;
    },
    changeStatus(value) {
      this.editParam.status = value;
    },
    changeDes(value){
      this.editParam.describes = value;
    },
    paging(parameter){
      let size = parameter.size;
      let current = parameter.current;
      let query = {size,current,...this.queryParam};
      this.getRoleList(query);
    },
  }
}
</script>

<style lang="scss" scoped>
.role{
  .item{
  }
  .add-role-btn{
    margin: 0 0 10px 0;
  }
  .dataList{
    .select-form{
      margin: 0 0 10px 0;
    }
  }
  ::v-deep .clickRowStyle{
    background-color:#e6f7ff
  }

}
.add{

}
</style>