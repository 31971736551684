<template>
  <div class="add-category">
    <a-modal title="添加分类"
             :visible="addVisible"
             :confirm-loading="confirmLoading"
             okText="确定"
             cancelText="取消"
             @ok="handleOk"
             @cancel="handleCancelSubmit"
             width="400px">
      <a-form class="add-goods-form" :form="addCategoryForm" label-width="100px">
        <a-form-item label="分类名称">
          <a-row>
            <a-col :span="24">
              <a-input
                  v-decorator="['name',rules.name]"
                  type="text"
                  key="0"
                  allowClear>
              </a-input>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item class="upload-form-item" label="分类图片">
          <a-upload v-decorator="['picture',rules.picture]"
                    class="avatar-uploader"
                    action="/auth/upLoad"
                    :headers=headers
                    name="file"
                    :data=this.uploadParams
                    :fileList="fileList"
                    list-type="picture-card"
                    :before-upload="handleBeforeUpload"
                    :remove="handleRemovePicture"
                    @preview="handlePreview"
                    @change="handleChange">
            <div v-if="!this.queryParam.picture">
              <a-icon type='plus' />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="分类图片" style="width: 100%" :src="queryParam.picture"/>
          </a-modal>
        </a-form-item>
        <a-form-item label="描述">
          <a-textarea v-decorator="['remark',rules.remark]"
                      placeholder="备注"
                      rows="4"
                      allowClear/>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import Modal from "ant-design-vue/lib/modal/Modal";

export default {
  name: "addCategory",
  props:{
    addVisible:{
      type:Boolean,
    },
  },
  inject:["reload"],
  data(){
    return {
      customVisible:this.addVisible,
      confirmLoading:false,
      addCategoryForm:this.$form.createForm(this, {name: 'add_category_form'}),
      queryParam:{
        name:'',
        picture:null,
        remark:'',
      },
      rules:{
        name: {
          rules: [
            {required: true, message: '请输入分类名称!'},
          ]
        },
        picture:{
          rules: [
            {required: false, message: '上传分类图片'}
          ]
        },
        remark: {
          rules:[
            {required: false, message: '角色描述'},
          ]
        },
      },
      headers:{
        contentType:'multipart/form-data'
      },
      previewVisible:false,
      fileList:[],
    }
  },
  methods:{
    handleOk(){
      this.confirmLoading = true;
      this.addCategoryForm.validateFields((err, values) => {
        if (!err) {
          this.queryParam.name = values.name;
          this.queryParam.remark = values.remark;
          this.$api.category.addOrUpdate(this.queryParam).then(res=>{
            if(res.code===200&&res.success){
              this.$message.success("添加成功！");
              this.confirmLoading = false;
              this.reload();
            }else {
              this.confirmLoading = false;
            }
          });
        }
      });
    },
    //上传文件相关
    uploadParams(){
      let d = {
        companyName:this.$store.getters.userInfo.companyName,
        userType: this.$store.getters.userInfo.staffType,
      }
      return d;
    },
    handleBeforeUpload(file){
      return new Promise((resolve,reject)=>{
        const isJPG = file.type === 'image/jpg';
        const isJPEG = file.type=== 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isGIF = file.type === 'image/gif';
        const isLt10M = file.size/1024/1024 <10;
        if(!(isJPG||isJPEG||isPNG||isGIF)){
          Modal.error({
            title:'只能上传JPG、JPEG、GIF、PNG格式的图片',
          });
          return reject(false);
        }else if(!isLt10M){
          Modal.error({
            title:'图片大小不能超过10M',
          });
          return reject(false);
        }
        return resolve(true);
      })
    },
    handleRemovePicture(){
      //console.log("remove1:",this.addCategoryForm.getFieldValue('picture'));
      this.queryParam.picture = '';
    },
    handleChange(info){
      this.fileList = info.fileList;
      //console.log(info);
      const status = info.file.status;
      if (status!== 'uploading') {
        setTimeout(()=>{

        },10000);
        //console.log(info)
      }
      if (status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
      if (status === 'done') {
        if (info.file.response.data) {
          this.queryParam.picture = info.file.response.data;
          this.$message.success(`${info.file.name} 上传成功`);
        } else {
          this.$message.error(`${info.file.name} ${info.file.response.msg}`);
        }
      }
      if(status ==='removed'){
        this.queryParam.picture = '';
      }
    },
    async handlePreview(file) {
      if(file.response.data){
        this.queryParam.picture  =  file.response.data;
        this.previewVisible = true;
      }else{
        this.$message.error("图片上传失败不能预览！")
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handleCancelSubmit(){
     this.fileList = [];
     this.queryParam.picture = '';
      this.addCategoryForm.resetFields();
      //console.log(this.addCategoryForm.getFieldValue('picture'))
      this.customVisible = false;
      this.$emit("changeAddVisible",this.customVisible);
    }
  }
}
</script>

<style lang="scss" scoped>
.add-category {
  .add-goods-form {
    padding: 10px 10px 0 0;
    border-top: 1px solid #eee !important;
    border-bottom: 1px solid #eee !important;
    .editable-row-operations a {
      margin-right: 8px;
    }
    .upload-form-item{
    }
    .avatar-uploader > .ant-upload {
      width: 128px;
      height: 128px;
    }
    .ant-upload-select-picture-card i {
      font-size: 32px;
      color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
    ::v-deep .ant-table-body > tr > td {
      padding: 10px;
    }

    ::v-deep .ant-table-thead > tr > th {
      padding: 10px;
    }
  }
}
</style>