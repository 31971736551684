<template>
  <div class="add_storage">
    <a-drawer
        title="新增入库记录"
        :width="520"
        :visible="addVisible"
        :body-style="{ paddingBottom: '80px',fontFamily: '楷体, serif' }"
        :header-style="{fontFamily: '楷体, serif'}"
        @close="handleClose">
      <a-form class="add-goods-form" :form="addStorageForm" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="商品名称" name="name">
              <goods-selector ref="goodsSelector"
                              :current-value="goodsChooseOption"
                              :company-id="addParam.companyId"
                              @goodsSelectChange="goodsSelectChange"></goods-selector>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="入库数量" name="storageNum">
              <a-input-number
                  v-model="addParam.storageNum"
                  key="1"
                  :min="0" :max="99999"
                  :parser="value=>value.replace(/^[0]+[1-9]*/g,'')"
                  allowClear>
              </a-input-number>
              当前值：{{ addParam.storageNum }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="供应商" name="supplier">
              <supplier-selector ref="supplierSelector"
                                 :current-value="bindSupplierChooseOption"
                                 @supplierSelectChange="supplierSelectChange"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="单位" name="unit">
              <span v-if=addParam.unit>{{ addParam.unit }}</span>
              <span v-else style="margin-left: 3px">--</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="入库备注" name="storageRemark">
              <a-textarea
                  v-model="addParam.storageRemark"
                  :rows="4"
                  placeholder="请输入备注信息"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div
          :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
      >
        <a-button style="margin-right: 8px" type="primary" @click="handleOk">确定</a-button>
        <a-button @click="handleClose">取消</a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import GoodsSelector from "@/components/goodsSelector";
import SupplierSelector from "@/components/supplierSelector";

export default {
  name: "addStorage",
  components: {GoodsSelector, SupplierSelector},
  props: {
    addVisible: {
      type: Boolean,
    },
  },
  inject: ["reload"],
  data() {
    return {
      customVisible: this.addVisible,
      addParam: {
        productId: undefined,
        unit: undefined,
        storageNum: 0,
        supplierId: undefined,
        storageRemark: undefined,
      },
      headers: {
        contentType: 'multipart/form-data'
      },
      addStorageForm: this.$form.createForm(this, {name: 'add_storage_form'}),
      goodsChooseOption: {key: '', label: ''},
      bindSupplierChooseOption: {key: '', label: ''},
    }
  },
  methods: {
    handleOk() {
      if(!this.addParam.supplierId){
        this.$message.error("供应商未选择");
        return;
      }
      this.$api.storageOrder.add(this.addParam).then(res => {
        if (res.code === 200 && res.success) {
          this.$message.success("添加成功！");
          this.reload();
        } else {
          if (res.msg) {
            this.$message.error(res.msg);
          }
        }
      });
    },
    handleClose() {
      this.addParam = {
        productId: undefined,
        unit: undefined,
        storageNum: 0,
        supplierId: undefined,
        storageRemark: undefined,
      };
      this.customVisible = false;
      this.goodsChooseOption={key: '', label: ''};
      this.bindSupplierChooseOption={key: '', label: ''};
      this.$emit("changeAddVisible", this.customVisible);
    },
    goodsSelectChange(value) {
      this.goodsChooseOption = value;
      this.addParam.productId = value.key;
      let param = {
        id: value.key,
      }
      this.$api.goods.goodsDetail(param).then(res => {
        if (res.code === 200 && res.success) {
          const goods = res.data;
          this.addParam.unit = goods.unit;
          //console.log("单位：",target);
        }
      })
    },
    supplierSelectChange(value) {
      this.bindSupplierChooseOption = value;
      this.addParam.supplierId = value.key;
    },
  }
}
</script>

<style lang="scss" scoped>
.add_storage{
  font-family: 楷体, serif;
}
</style>