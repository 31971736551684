<template>
  <div>
    <a-modal title="添加门店"
             :visible="addVisible"
             :confirm-loading="confirmLoading"
             okText="确定"
             cancelText="取消"
             @ok="handleOk"
             @cancel="handleCancel"
             width="350px">
      <a-form class="add-restaurant-form"
              :form="addResForm"
              label-width="100px">
        <a-row>
          <a-form-item label="门店名称" :label-col="{span:8}" :wrapper-col="{ span:16}">
            <a-input
                v-decorator="['name',rules.name]"
                type="text"
                key="0"
                allowClear>
            </a-input>
          </a-form-item>
          <a-form-item label="门店地址" :label-col="{span:8}" :wrapper-col="{ span:16}">
            <a-cascader
                :field-names="{label:'name',value:'name',children:'children'}"

                v-decorator="['residence',rules.residence]"
                placeholder="请选择省市区"
                :options="residences"
            />
          </a-form-item>

          <a-form-item label="详细地址" :label-col="{span:8}" :wrapper-col="{ span:16}">
            <a-input
                v-decorator="['street',rules.street]"
                type="text"
                key="1"
                allowClear>
            </a-input>
          </a-form-item>
          <a-form-item label="联系方式" :label-col="{span:8}" :wrapper-col="{ span:16}">
            <a-input
                v-decorator="['phone',rules.phone]"
                style="width: 100%"
                :maxLength="11"
                addon-before="+86"
                allowClear
            >
            </a-input>
          </a-form-item>
          <a-form-item label="所属公司" :label-col="{span:8}" :wrapper-col="{ span:16}">
            <span>{{ companyName }}</span>
          </a-form-item>
          <a-form-item label="介绍">
            <a-textarea v-decorator="['introduce',rules.introduce]"
                        placeholder="门店介绍"
                        rows="4"
                        allowClear/>
          </a-form-item>
          <a-form-item class="upload-form-item" label="门店主图">
            <a-upload v-decorator="['headPicture',rules.headPicture]"
                      class="avatar-uploader"
                      action="/auth/upLoad"
                      :headers=headers
                      name="file"
                      :data=this.uploadParams
                      :fileList="fileList"
                      list-type="picture-card"
                      :before-upload="handleBeforeUpload"
                      :remove="handleRemovePicture"
                      :disabled="loading"
                      @preview="handlePreview"
                      @change="handleChange">
              <div v-if="!this.addParam.headPicture">
                <a-icon type='plus' />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelHeadPic">
              <img alt="门店主图" style="width: 100%" :src="addParam.headPicture"/>
            </a-modal>
          </a-form-item>

          <a-form-item class="upload-form-item" label="门店详情图片">
            <a-upload v-decorator="['detailPictures',rules.detailPictures]"
                      class="avatar-uploader"
                      action="/auth/upLoad"
                      :headers=headers
                      name="file"
                      :data=this.uploadParams
                      :fileList="fileListSub"
                      list-type="picture-card"
                      :disabled="loadingSub"
                      :before-upload="handleBeforeUpload"
                      :remove="handleRemovePictureSub"
                      @preview="handlePreviewSub"
                      @change="handleChangeSub">
              <div v-if="this.fileListSub.length<3">
                <a-icon type='plus' />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisibleSub" :footer="null" @cancel="handleCancelDetailPic">
              <img alt="门店详情图片" style="width: 100%" :src="addParam.detailPictures"/>
            </a-modal>
          </a-form-item>
          <a-form-item label="备注">
            <a-textarea v-decorator="['remark',rules.remark]"
                        placeholder="备注信息"
                        rows="4"
                        allowClear/>
          </a-form-item>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import Modal from "ant-design-vue/lib/modal/Modal";
import {provinceInfo} from "@/config/provinceInfo";
const residences = provinceInfo;
export default {
  name: "addRes",
  props:{
    addVisible:{
      type:Boolean,
    },
  },
  inject:["reload"],
  data(){
    return {
      customVisible:this.addVisible,
      confirmLoading:false,
      addResForm:this.$form.createForm(this, {name: 'add_restaurant_form'}),
      companyName:this.$store.getters.userInfo.companyName,
      addParam:{
        headPicture:null,
        detailPictures:null,
      },
      companyId:this.$store.getters.userInfo.companyId,
      rules:{
        name: {
          rules: [
            {required: true, message: '请输入门店名称!'},
          ]
        },
        residence:{
          initialValue:['云南省','昆明市','五华区'],
          rules:[
            { type: 'array', required: true, message: '请选择省市区' }
          ]
        },
        street: {
          rules: [
            {required: true, message: '请输入具体街道地址!'},
          ]
        },
        phone: {
          rules: [
            {required: true, message: '请输入联系方式!'},
          ]
        },
        introduce:{
          rules: [
            {required: false, message: '请输入门店介绍!'},
          ]
        },
        headPicture:{
          rules: [
            {required: false, message: '上传门店主图'}
          ]
        },
        detailPictures:{
          rules: [
            {required: false, message: '上传门店详情图片'}
          ]
        },
        remark: {
          rules:[
            {required: false, message: '填写备注信息'},
          ]
        },
      },
      residences,
      headers:{
        contentType:'multipart/form-data'
      },
      previewVisible:false,
      previewVisibleSub:false,
      loading:false,
      loadingSub:false,
      fileList:[],
      fileListSub:[],

    }
  },
  methods:{
    uploadParams(){
      let d = {
        companyName:this.companyName,
        userType: this.$store.getters.userInfo.staffType,
      }
      return d;
    },
    handleBeforeUpload(file){
      return new Promise((resolve,reject)=>{
        const isJPG = file.type === 'image/jpg';
        const isJPEG = file.type=== 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isGIF = file.type === 'image/gif';
        const isLt10M = file.size/1024/1024 <10;
        if(!(isJPG||isJPEG||isPNG||isGIF)){
          Modal.error({
            title:'只能上传JPG、JPEG、GIF、PNG格式的图片',
          });
          return reject(false);
        }else if(!isLt10M){
          Modal.error({
            title:'图片大小不能超过10M',
          });
          return reject(false);
        }
        return resolve(true);
      })
    },
    handleRemovePicture(){
      this.addParam.headPicture = null;
    },
    handleChange(info){
      this.loading = true;
      this.fileList = info.fileList;
      const status = info.file.status;
      if (status!== 'uploading') {
        this.loading = false;
        setTimeout(()=>{
        },10000);
      }
      if (status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
      if (status === 'done') {
        if (info.file.response.data) {
          this.addParam.headPicture = info.file.response.data;
          this.$message.success(`${info.file.name} 上传成功`);
        } else {
          this.$message.error(`${info.file.name} ${info.file.response.msg}`);
        }
      }
      if(status ==='removed'){
        this.addParam.headPicture = null;
      }
    },
    async handlePreview(file) {
      if(file.response.data){
        this.addParam.headPicture  =  file.response.data;
        this.previewVisible = true;
      }else{
        this.$message.error("图片上传失败不能预览！")
      }
    },

    handleRemovePictureSub(){
      this.addParam.detailPictures = null;
    },
    handleChangeSub(info){
      this.loadingSub = true;
      this.fileListSub = info.fileList;
      const status = info.file.status;
      if (status!== 'uploading') {
        this.loadingSub = false;
        setTimeout(()=>{

        },10000);
      }
      if (status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
      if (status === 'done') {
        if (info.file.response.data) {
          this.$message.success(`${info.file.name} 上传成功`);
        } else {
          this.$message.error(`${info.file.name} ${info.file.response.msg}`);
        }
      }
      if(status ==='removed'){
        this.addParam.detailPictures = null;
      }
    },
    async handlePreviewSub(file) {
      if(file.response.data){
        this.addParam.detailPictures  =  file.response.data;
        this.previewVisibleSub = true;
      }else{
        this.$message.error("图片上传失败不能预览！")
      }
    },
    handleOk(){
      this.confirmLoading = true;
      this.addResForm.validateFields((err, values) => {
        if (!err) {
          const residenceInfo = values.residence;
          const detailAdd = residenceInfo[0].concat("-")
              .concat(residenceInfo[1]).concat("-")
              .concat(residenceInfo[2]).concat("-")
              .concat(values.street);
          const detailPics =this.fileListSub.map(pic=>{
            if(pic.response.data){
              return pic.response.data;
            }
          }).join(",");
          this.addParam.detailPictures = detailPics;
          const params = {
            name:values.name,
            address:detailAdd,
            phone:values.phone,
            companyId: this.companyId,
            introduce:values.introduce,
            remark:values.remark,
            headPicture:this.addParam.headPicture,
            detailPictures:detailPics,
          }
          this.$api.restaurant.addRes(params).then(res => {
            if (res.code === 200 && res.success) {
              this.$message.success("添加成功！");
              this.confirmLoading = false;
              this.reload();
            } else {
              if (res.msg) {
                this.$message.error(res.msg);
              }
            }
          });
        }
      });
      this.confirmLoading = false;
    },
    handleCancel(){
      this.previewVisibleSub = false;
      this.fileList = [];
      this.fileListSub = [];
      this.addParam.headPicture = null;
      this.addParam.detailPictures = null;
      this.addResForm.resetFields();
      this.companyId = this.$store.getters.userInfo.companyId;
      this.customVisible = false;
      this.$emit("changeAddVisible",this.customVisible);
    },
    handleCancelHeadPic(){
      this.previewVisible = false;
    },
    handleCancelDetailPic(){
      this.previewVisibleSub = false;
    },


  },
}
</script>

<style scoped>

</style>