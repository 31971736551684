<template>
  <div class="category">
    <div class="item dataList">
      <a-row>
        <a-col :span="24">
          <a-row class="select-form">
            <a-col :span="24">
              <a-form layout="inline"  :form="this.categoryForm">
                <a-form-item>
                  <a-input
                      size="large"
                      placeholder="分类名称"
                      style="width: 120px"
                      v-model.trim="queryParam.name"
                      allowClear>
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-col :span="8">
                    <a-button
                        size="large"
                        type="primary"
                        @click="handleSearch">查询
                    </a-button>
                  </a-col>
                </a-form-item>
              </a-form>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="24">
          <div class="add-category-btn">
            <a-button size="large"
                      type="primary"
                      @click="showAddModal">添加分类</a-button>
          </div>
        </a-col>
      </a-row>
    </div>
    <a-table bordered
             rowKey="id"
             :pagination="false"
             :columns="columns"
             :data-source="dataSource"
             :customRow="customRow"
             :row-class-name="setRowClassName"
             style="width: 100%;">
      <template slot="picture" slot-scope="text">
        <div v-if="text">
          <div :style="{ marginLeft: `${buttonWidth}px`, whiteSpace: 'nowrap' }">
            <a-popover title="分类图片" placement="top">
              <template slot="content">
                <img :src="text" height="256px" width="256px"/>
              </template>
              <span style="color: #1890ff">分类图片</span>
            </a-popover>
          </div>
        </div>
      </template>
      <span slot="action" slot-scope="text,record">
         <a-tooltip placement="top" title="编辑" autoAdjustOverflow>
            <a-button type="primary" size="small" icon="edit" @click="handleEdit(record)"/>
         </a-tooltip>
            <a-divider type="vertical"/>
            <a-popconfirm title="确定删除?"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="() => onDelete(record.id)">
              <a-icon slot="icon" type="question-circle-o" style="color: red"/>
              <a-button type="danger" size="small" icon="delete"/>
            </a-popconfirm>
        </span>
    </a-table>
    <pageination  style="float: right"
                  :paginationList="paginationList"
                  @paging="paging">
    </pageination>
    <div class="add">
      <add-category :add-visible="addVisible" @changeAddVisible="changeAddVisible"></add-category>
    </div>
    <div class="edit">
      <edit-category ref="editCategory"
                   :edit-visible="editVisible" :category-info="editParam"
                   @changeCategoryName="changeCategoryName"
                   @changeRemark = "changeRemark"
                   @changePic ="changePicture"
                   @changeEditVisible="changeEditVisible">

      </edit-category>
    </div>
  </div>
</template>

<script>
import AddCategory from "@/views/product/addCategory";
import EditCategory from "@/views/product/editCategory";
import Pageination from "@/components/pageination";
export default {
  name: "category",
  components: {Pageination, EditCategory, AddCategory,},
  created() {
    let {size,current} = this.paginationList;
    let query = {size,current,...this.queryParam};
    this.getCategoryList(query);
  },
  data(){
    return{
      selectedRowId:undefined,
      categoryForm: this.$form.createForm(this, {name: 'category_form'}),
      dataSource:[],
      buttonWidth: 70,
      columns:[
        {
          title: '分类名称',
          dataIndex: 'name',
          key:'name',
          width: '70px',
          align: 'center',
        },
        {
          title: '图片',
          dataIndex: 'picture',
          key:'picture',
          width: '70px',
          align: 'center',
          scopedSlots: { customRender: 'picture' },
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key:'remark',
          width: '70px',
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'action',
          key:'action',
          width: '70px',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      queryParam:{
        name:undefined,
      },
      paginationList:{
        size:10,
        current:1,
        total:0,
        pageSizeOptions: ['10', '20', '30', '40', '50','100'],
      },
      addVisible:false,
      editParam:{
        id: undefined,
        name: undefined,
        picture: undefined,
        remark: undefined,
      },
      editVisible:false,
    }
  },
  methods:{
    customRow(record){
      return {
        on:{
          click:(event)=>{
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    changeAddVisible(value){
      this.addVisible = value;
    },
    async handleSearch() {
      let {size,current} = this.paginationList;
      let query = {size,current,...this.queryParam};
      this.getCategoryList(query);
    },
    showAddModal(){
      this.addVisible = true;
    },
    async onDelete(id){
      let delIds = {
        ids:id,
      };
      const res = await this.$api.category.removeById(delIds);
      if(res.code===200){
        this.$message.success("删除成功！")
        this.reload();
      }else{
        if(res.msg){
          this.$message.error(res.msg);
        }
      }
    },
    handleEdit(record){
      this.$store.state.categoryInfo = record;
      let item = JSON.parse(JSON.stringify(this.$store.getters.categoryInfo));
      this.editParam = item;
      this.editVisible = true;
    },
    getCategoryList(params) {
      this.$api.category.categoryList(params).then(res => {
        if (res.code === 200) {
          this.dataSource = res.data.records;
          this.paginationList.total = res.data.total;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    paging(parameter){
      let size = parameter.size;
      let current = parameter.current;
      let query = {size,current,...this.queryParam};
      this.getCategoryList(query);
    },
    changeCategoryName(value){
      this.editParam.name = value;
    },
    changeRemark(value){
      this.editParam.remark = value;
    },
    changePicture(value){
      this.editParam.picture = value;
    },
    changeFileList(value){
      this.fileList = value;
    },
    changeEditVisible(value){
      this.editVisible = value;
    },
  }
}
</script>

<style lang="scss" scoped>
.category{
  .item{
  }
  .add-category-btn{
    margin: 0 0 10px 0;
  }
  .dataList{
    .select-form{
      margin: 0 0 10px 0;
    }
  }

}
.add{
  ::v-deep .clickRowStyle{
    background-color:#e6f7ff
  }
}
</style>