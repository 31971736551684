<template>
  <div class="restaurant">
    <div class="item dataList">
      <a-row>
        <a-col :span="24">
          <a-row class="select-form">
            <a-form layout="inline" :form="searchResForm">
              <a-form-item>
                <a-input
                    size="large"
                    placeholder="门店名称"
                    style="width: 180px"
                    v-model.trim="queryParam.name"
                    allowClear>
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-col :span="8">
                  <a-button
                      size="large"
                      type="primary"
                      @click="handleSearch">查询
                  </a-button>
                </a-col>
                <a-col :span="8" offset="8">
                  <a-button size="large" @click="resetSearchResForm">重置</a-button>
                </a-col>
              </a-form-item>
            </a-form>
          </a-row>
        </a-col>
        <a-col :span="24">
          <div class="add-restaurant-btn">
            <a-button size="large"
                      type="primary"
                      @click="showAddModal">添加门店</a-button>
          </div>
        </a-col>
      </a-row>
      <a-table bordered
               rowKey="id"
               :pagination="false"
               :data-source="dataSource"
               :customRow="customRow"
               :row-class-name="setRowClassName"
               :columns="columns"
               style="width: 100%;">
        <template slot="createTime" slot-scope="text">
          {{getFormatDate(text)}}
        </template>
        <template slot="headPicture" slot-scope="text">
          <a-popover title="门店主图" placement="top">
            <template slot="content">
              <img :src="text" height="256px" width="256px"/>
            </template>
            <span style="color: #1890ff">门店主图</span>
          </a-popover>
        </template>
        <template slot="detailPictures" slot-scope="text">
          <a-popover  title="详情图片" placement="top">
            <template slot="content">
              <img v-for=" (item,index) in text.split(',')" :key="index" :src="item" height="256px" width="256px"/>
            </template>
            <span style="color: #1890ff">详情图片</span>
          </a-popover>
        </template>
        <span slot="action" slot-scope="text,record">
           <a-tooltip placement="top" title="编辑" autoAdjustOverflow>
            <a-button type="primary" size="small" icon="edit" @click="handleEdit(record)"/>
           </a-tooltip>
            <a-divider type="vertical"/>
            <a-popconfirm title="确定删除?"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="() => onDelete(record.id)">
              <a-icon slot="icon" type="question-circle-o" style="color: red"/>
              <a-button type="danger" size="small" icon="delete"/>
            </a-popconfirm>
        </span>
      </a-table>
      <pageination  style="float: right"
                    :paginationList="paginationList"
                    @paging="paging">
      </pageination>
      <div>
        <add-res :add-visible="addVisible" @changeAddVisible="changeAddVisible"></add-res>
      </div>
      <div>
        <edit-res ref="editRes"
                  :edit-visible="editVisible" :res-info="editParam"
                  :residence="residence" :street="street"
                  @changeResName="changeResName"
                  @changeResResidence = "changeResResidence"
                  @changeResStreet = "changeResStreet"
                  @changeResPhone="changeResPhone"
                  @changeHeadPic="changeHeadPicture"
                  @changeDetailPic="changeDetailPicture"
                  @changeResIntroducet="changeResIntroduce"
                  @changeRemark="changeRemark"
                  @changeEditVisible="changeEditVisible">

        </edit-res>
      </div>
    </div>
  </div>
</template>

<script>
import {dateFormat} from "@/utils/common";
import AddRes from "@/views/restaurant/addRes";
import Pageination from "@/components/pageination";
import EditRes from "@/views/restaurant/editRes";

export default {
  name: "index",
  components: {EditRes, Pageination, AddRes},
  inject:["reload"],
  data(){
    return{
      selectedRowId:undefined,
      searchResForm: this.$form.createForm(this, {name: 'search_restaurant_form'}),
      addVisible:false,
      editVisible:false,
      chooseOption:{key:'',label:undefined},
      queryParam:{
        name:undefined,
      },
      mainPicList:[],
      subPicList:[],
      editParam:{
        id: undefined,
        name: undefined,
        phone:undefined,
        address:undefined,
        headPicture: undefined,
        detailPictures:undefined,
        introduce:undefined,
        remark: undefined,
      },
      residence:undefined,
      street:undefined,
      paginationList:{
        size:10,
        current:1,
        total:0,
        pageSizeOptions: ['10', '20', '30', '40', '50','100'],
      },
      dataSource:[],
      columns:[
        {
          title: '门店名称',
          dataIndex: 'name',
          key:'name',
          width: '120px',
          align: 'center',
        },
        {
          title: '门店地址',
          dataIndex: 'address',
          key:'address',
          width: '140px',
          align: 'center',
          scopedSlots:{customRender:'address'},
        },
        {
          title: '主图',
          dataIndex: 'headPicture',
          key:'headPicture',
          width: '80px',
          align: 'center',
          scopedSlots: { customRender: 'headPicture' },
        },
        {
          title: '详情图片',
          dataIndex: 'detailPictures',
          key:'detailPictures',
          width: '80px',
          align: 'center',
          scopedSlots: { customRender: 'detailPictures' },
        },
        {
          title: '介绍',
          dataIndex: 'introduce',
          key:'introduce',
          width: '140px',
          align: 'center',
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key:'remark',
          width: '140px',
          align: 'center',
        },
        {
          title: '联系电话',
          dataIndex: 'phone',
          key:'phone',
          width: '100px',
          align: 'center',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key:'createTime',
          width: '120px',
          align: 'center',
          scopedSlots: { customRender: 'createTime' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          key:'action',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  created() {
    let resQuery = {
      current:this.paginationList.current,
      size:this.paginationList.size,
      name:this.queryParam.name,
    };
    this.getRestaurantList(resQuery);
  },
  methods:{
    customRow(record){
      return {
        on:{
          click:(event)=>{
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    showAddModal(){
      this.addVisible = true;
    },
    changeAddVisible(value){
      this.addVisible = value;
    },
    async handleSearch() {
      let staffQuery = {
        current: this.paginationList.current,
        size: this.paginationList.size,
        name: this.queryParam.name,
      };
      this.getRestaurantList(staffQuery);
    },
    paging(parameter) {
      let staffQuery = {
        current:parameter.current,
        size:parameter.size,
        name:this.queryParam.name,
      };
      this.getRestaurantList(staffQuery);
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
    resetSearchResForm(){
      this.queryParam = {
          name:undefined,
      }
      let resQuery = {
        current:1,
        size:10,
        name:this.queryParam.name,
      };
      this.getRestaurantList(resQuery);
    },
    getRestaurantList(queryParams){
      this.$api.restaurant.selectByCondition(queryParams).then(res=>{
        if(res.code===200){
          this.dataSource = res.data.records;
          this.paginationList.total = res.data.total;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    onDelete(key) {
      let delIds = {
        ids:key,
      };
      this.$api.restaurant.removeById(delIds).then(res=>{
        if(res.code===200){
          this.$message.success("删除成功！")
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    changeEditVisible(value){
      this.editVisible = value;
    },
    changeResName(value){
      this.editParam.name = value;
    },
    changeResResidence(value){
      this.residence = value;
    },
    changeResStreet(value){
      this.street = value;
    },
    changeResPhone(value){
      this.editParam.phone = value;
    },
    changeHeadPicture(value){
      this.editParam.headPicture = value;
    },
    changeDetailPicture(value){
      this.editParam.detailPictures = value;
    },
    changeResIntroduce(value){
      this.editParam.introduce = value;
    },
    changeRemark(value){
      this.editParam.remark = value;
    },

    handleEdit(record){
      this.$store.state.restaurantInfo = record;
      let item = JSON.parse(JSON.stringify(this.$store.getters.restaurantInfo));
      this.editParam = item;
      const address = item.address;
      const addressArr = address.split("-");
      const residence = addressArr.slice(0,3);
      const street = addressArr.slice(3)[0];
      this.residence = residence;
      this.street = street;
      this.editVisible = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.restaurant{
  ::v-deep .ant-table-thead > tr > th{
    padding: 8px;
  }
  ::v-deep .ant-table-tbody > tr > td{
    padding: 8px;
  }
  .item {
  }
  .add-restaurant-btn{
    margin: 0 0 10px 0;
  }
  .dataList{
    .select-form{
      margin: 0px 0px 10px 0px;
    }
    ::v-deep .clickRowStyle{
      background-color:#e6f7ff
    }
  }
}
</style>