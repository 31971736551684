<template>
  <div class="edit-category">
    <a-modal title="分类编辑"
             :visible="editVisible"
             :confirm-loading="confirmLoading"
             okText="确定" cancelText="取消"
             @ok="handleOk"
             @cancel="handleCancel"
             width="400px">
      <a-form class="edit-category-form"
              :form="editCategoryForm"
              label-width="100px">
        <a-form-item label="分类名称">
          <a-row>
            <a-col :span="24">
              <a-input ref="categoryName"
                       :value ="categoryInfo.name"
                       type="text"
                       @change="$emit('changeCategoryName',$event.target.value)"
                       allowClear>
              </a-input>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item class="upload-form-item" label="分类图片">
          <a-upload action="/auth/upLoad"
                    class="avatar-uploader"
                    :headers=headers
                    name="file"
                    :data=this.uploadParams
                    :fileList="fileList"
                    list-type="picture-card"
                    :before-upload="handleBeforeUpload"
                    :remove="handleRemovePicture"
                    @preview="handlePreview"
                    @change="handleChange">
            <div v-if="!this.editParam.picture">
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="分类图片" style="width: 100%" :src="editParam.picture"/>
          </a-modal>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea ref="desc"
                      :value="categoryInfo.remark"
                      placeholder="填写备注"
                      rows="4"
                      @change="$emit('changeRemark',$event.target.value)"
                      allowClear/>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import Modal from "ant-design-vue/lib/modal/Modal";

export default {
  name: "editCategory",
  props:{
    editVisible:{
      type:Boolean,
    },
    categoryInfo:{
      type:Object,
    },
  },
  inject:["reload"],
  data(){
    return {
      customVisible:this.editVisible,
      confirmLoading:false,
      editCategoryForm:this.$form.createForm(this,{name: 'edit_category_form'}),
      editParam:{
        id: undefined,
        name: undefined,
        picture: undefined,
        remark: undefined,
      },

      headers:{
        contentType:'multipart/form-data'
      },
      previewVisible:false,
      fileList:[],
      loading:false,
    }
  },
  methods:{
    handleOk(){
      this.confirmLoading = true;
      const {id,name,remark} = this.categoryInfo;
      this.editParam.id = id;
      this.editParam.name = name;
      this.editParam.remark = remark;
      //console.log(this.categoryInfo,this.editParam);
      if(!this.editParam.name){
        this.$message.error("分类名称不能为空！");
        return;
      }
      //console.log(this.editParam);
      this.$api.category.addOrUpdate(this.editParam).then(res=>{
        if(res.code===200&&res.success){
          this.$message.success("编辑成功!!");
          this.confirmLoading = false;
          this.customVisible = false;
          this.$emit("changeEditVisible",this.customVisible);
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
          this.confirmLoading = false;
        }
      });
    },
    //上传文件相关
    uploadParams(){
      let d = {
        companyName:this.$store.getters.userInfo.companyName,
        userType: this.$store.getters.userInfo.staffType,
      }
      return d;
    },
    handleBeforeUpload(file){
      return new Promise((resolve,reject)=>{
        const isJPG = file.type === 'image/jpg';
        const isJPEG = file.type=== 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isGIF = file.type === 'image/gif';
        const isLt10M = file.size/1024/1024 <10;
        if(!(isJPG||isJPEG||isPNG||isGIF)){
          Modal.error({
            title:'只能上传JPG、JPEG、GIF、PNG格式的图片',
          });
          return reject(false);
        }else if(!isLt10M){
          Modal.error({
            title:'图片大小不能超过10M',
          });
          return reject(false);
        }
        return resolve(true);
      })
    },
    handleRemovePicture(){
      this.editParam.picture = undefined;
    },
    handleChange(info){
      this.loading = true;
      this.fileList = info.fileList;
      if (info.file.status !== 'uploading') {
        //console.log(info)
      }
      if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
      if (info.file.status === 'done') {
        if (info.file.response.data) {
          this.editParam.picture = info.file.response.data;
          this.$message.success(`${info.file.name} 上传成功`);
        } else {
          this.$message.error(`${info.file.name} ${info.file.response.msg}`);
        }
      }
      this.loading = false;
    },
    async handlePreview(file) {
      if(file.response.data){
        this.editParam.picture  =  file.response.data;
        this.previewVisible = true;
      }else{
        this.$message.error("图片上传失败不能预览！")
      }
    },
    changeStatus(value){
      this.$emit('changeStatus',value)
    },
    handleCancel(){
      const item = this.$store.getters.categoryInfo;
      this.fileList = [];
      this.editParam.picture = undefined;
      //console.log("item:",item);
      this.$emit('changeCategoryName',item.name);
      this.$emit('changePicture',item.picture);
      this.$emit('changeRemark',item.remark);
      this.customVisible = false;
      this.$emit("changeEditVisible",this.customVisible);
    },

  },
}
</script>

<style lang="scss" scoped>
.edit-category{

  .upload-form-item{
    .avatar-uploader > .ant-upload{
      width: 128px;
      height: 128px;
    }
    .ant-upload-select-picture-card i {
      font-size: 32px;
      color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
}
</style>