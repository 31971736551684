<template>
  <div>
    <a-modal title="采购任务单"
             :visible="visible"
             :hidden="taskDetailHidden"
             :confirm-loading="confirmLoading"
             okText="确定"
             cancelText="取消"
             @ok="handleOk"
             @cancel="handleCancel"
             :okButtonProps="okButtonStyle"
             width="790px">
      <a-row>
        <a-col :span="12">
          <label>批次号：</label>
          <span style="color: #92989B">{{ taskCode}}</span>
        </a-col>
        <a-col :span="12">
          <label>采购方：</label>
          <span style="color: #92989B">{{ companyName}}({{resName}})</span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="12">
          <label>发布时间：</label>
          <span style="color: #92989B">{{ createDate}}</span>

        </a-col>
        <a-col :span="12">
          <label>发布人：</label>
          <span style="color: #92989B">{{createPerson}}</span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="12">
          <label>交货时间：</label>
          <span style="color: #92989B">{{ deliveryTime}}</span>

        </a-col>
        <a-col :span="12">
          <label>交货方式：</label>
          <span v-if="deliveryWay===0" style="color: #92989B">送货上门</span>
          <span v-if="deliveryWay===1" style="color: #92989B">自提</span>
        </a-col>
      </a-row>
      <br>
      <a-form class="add-purchase-form"
              :form="addPurchaseForm"
              label-width="100px">
        <a-row>
          <a-col :span="24">
            <a-form-item>
              <span>商品列表</span>
              <div>
                <a-button v-if="isShowCompAction"
                          style="margin-bottom: 8px;"
                          type="primary"
                          :disabled="this.bidStatus!==0"
                          @click="handleAdd">
                  添加
                </a-button>
                <a-table bordered
                         :loading="searchLoading"
                         rowKey="id"
                         :data-source="dataSource"
                         :columns="columns"
                         :customRow="customRow"
                         :row-class-name="setRowClassName"
                         style="width: 100%;">
                  <template slot="productName" slot-scope="text,record">
                    <div v-if="record.editable"  style="margin: -5px 0">
                      <goods-selector ref="goodsSelector"
                                      :current-value="goodsChooseOption"
                                      @goodsSelectChange="goodsSelectChange"></goods-selector>
                    </div>
                    <template v-else>
                      {{text}}
                    </template>
                  </template>
                  <template slot="num" slot-scope="text,record">
                    <div>
                      <a-input
                          v-if="record.editable"
                          style="margin: -5px 0"
                          :value="text"
                          @change="e => handleChange(e.target.value, record.key, 'num')"/>
                      <template v-else>
                        <span style="color:#2db7f5;font-size: 20px">{{text}}</span>
                      </template>
                    </div>
                  </template>
                  <template slot="stockNum" slot-scope="text">
                    <span style="color:#2db7f5;font-size: 20px">{{text}}</span>
                  </template>
                  <template slot="status" slot-scope="text">
                    <span v-if="text==0" style="color: silver">未开始</span>
                    <span v-else-if="text==1" style="color: black">未报价</span>
                    <span v-else-if="text==2" style="color: #108ee9">报价中</span>
                    <span v-else-if="text==3" style="color: red">报价结束</span>
                    <span v-else-if="text==4" style="color: limegreen">已完成</span>
                  </template>
                  <template slot="action" slot-scope="text, record">
                    <div v-if="isShowCompAction">
                        <span v-if="record.editable">
                          <a @click="() => save(record.key)">保存</a>
                           <a-divider type="vertical"/>
                          <a-popconfirm title="确定取消?"
                                        ok-text="确定"
                                        cancel-text="取消"
                                        @confirm="() => cancel(record.key)">
                          <a>取消</a>
                          </a-popconfirm>
                       </span>
                        <span v-else>
                          <a :disabled="editingKey !== ''||record.status>0"
                             @click="() => edit(record)">修改</a>
                       </span>
                        <a-divider type="vertical"/>
                        <span v-if="record.editable&&delState">
                            <a :disabled="editingKey !== ''||record.status>0">删除</a>
                       </span>
                        <span v-else>
                           <a-popconfirm
                               v-if="dataSource.length"
                               :disabled="editingKey !== ''||record.status>0"
                               title="确定删除?"
                               ok-text="确定"
                               cancel-text="取消"
                               @confirm="() => onDelete(record)">
                             <a-icon slot="icon" type="question-circle-o" style="color: red"/>
                             <a :disabled="editingKey !== ''||record.status>0">删除</a>
                           </a-popconfirm>
                       </span>
                    </div>
                    <div v-if="userType===1">
                      <a-tooltip placement="top" title="报价" autoAdjustOverflow>
                        <a-button type="primary" size="small" icon="file-add"
                                  @click="bidPrice(record)"
                                  :disabled="record.status===0||record.status>2||record.stockNum==0"></a-button>
                      </a-tooltip>
                    </div>
                  </template>
                </a-table>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <div class="bid-price">
      <add-bid-price :visible="bidPriceVisible"
                     :purchase-order-detail="bidOrderTaskDetail"
                     @changeBidOrderTaskDetail="changeBidOrderTaskDetail"
                     @changeBidPriceVisible="changeBidPriceVisible"
                     @changeTaskDetailHidden="changeTaskDetailHidden"/>
    </div>
  </div>
</template>

<script>
import {dateFormat} from "@/utils/common";
import GoodsSelector from "@/components/goodsSelector";
import AddBidPrice from "@/views/bidPrice/addBidPrice";

export default {
  name: "task-detail",
  components:{
    AddBidPrice,
    GoodsSelector,
  },
  props:{
    visible:{
      type:Boolean,
      default: false,
    },
    editable:{
      type:Boolean,
      default:false,
    },
    taskId:{
      type:Number,
    },
  },
  inject:['reload'],

  data(){
    return{
      selectedRowId:undefined,
      customVisible:this.visible,
      customEditable:this.editable,
      bidPriceVisible:false,
      taskDetailHidden:false,
      bidOrderTaskDetail:undefined,
      confirmLoading:false,
      searchLoading:false,
      okButtonStyle:{},
      existBidItem:[],
      userType:this.$store.getters.userInfo.staffType,
      userRoles:this.$store.getters.userInfo.roleIds,
      addPurchaseForm:this.$form.createForm(this, {name: 'add_purchase_form'}),
      purchaseDetail:undefined,
      taskCode:'暂无数据',
      resId:'暂无数据',
      resName:'暂无数据',
      companyName:'暂无数据',
      createDate:'暂无数据',
      deliveryWay:'暂无数据',
      deliveryTime:'暂无数据',
      createPerson:'暂无数据',
      bidStatus:0,
      goodsChooseOption:{key:'',label:''},
      dataParams:{
        productId:null,
        stockNum:'',
        unit:'',
      },
      dataSource: [],
      cacheData:[],
      editingKey:'',
      count: 1,
      delState:false,
      columns: [
        {
          title: '商品名称',
          dataIndex: 'productName',
          key:'productName',
          width: '20%',
          align: 'center',
          scopedSlots: { customRender: 'productName' },
        },
        {
          title: '计划采购数量',
          dataIndex: 'num',
          key:'num',
          width: '80px',
          align: 'center',
          scopedSlots: { customRender: 'num' },
        },
        {
          title: '剩余采购数量',
          dataIndex: 'stockNum',
          key:'stockNum',
          width: '80px',
          align: 'center',
          scopedSlots: { customRender: 'stockNum' },
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key:'unit',
          width: '80px',
          align: 'center',
        },
        {
          title: '报价状态',
          dataIndex: 'status',
          key:'status',
          width: '80px',
          align: 'center',
          scopedSlots: { customRender: 'status' },
        },
      ],
    }
  },
  created() {
    const userType =this.$store.getters.userInfo.staffType;
    if(userType===1||!this.isShowCompAction){
      this.okButtonStyle = {props: { disabled: true },};
    }
    if((userType===0&&(this.userRoles.indexOf("3")!==-1 ||
        this.userRoles.indexOf("4")!==-1))||
        (userType===0&&this.bidStatus===0)||
        this.userRoles.indexOf("6")!==-1){
      const item = {
        title: '操作',
        dataIndex: 'action',
        align: 'center',
        width: '100px',
        scopedSlots: {customRender: 'action'},
      }
      this.columns.push(item);
    }
    this.cacheData = this.dataSource.map(item => ({ ...item }));
  },
  methods:{
    customRow(record){
      return {
        on:{
          click:(event)=>{
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    getOrderDetailList(params){
      this.searchLoading = true;
      this.$api.purchaseOrderTask.detail(params).then(res=>{
        if(res.code===200&&res.success){
          const resultData = res.data;
          this.purchaseDetail = resultData;
          this.taskCode=resultData.code;
          this.resId=resultData.restaurantId;
          this.resName=resultData.restaurantName;
          this.companyName=resultData.companyName;
          this.createPerson=resultData.publisherName;
          this.bidStatus = resultData.bidStatus;
          this.createDate=dateFormat(resultData.createTime,'YYYY-MM-DD HH:mm:ss');
          this.deliveryTime=dateFormat(resultData.deliveryTime,"YYYY-MM-DD HH:mm:ss");
          this.deliveryWay = resultData.deliveryWay;
          this.dataSource = res.data.detailList;
          const countTemp = this.dataSource.length;
          this.dataSource.forEach(e=>{
            if(this.count<=countTemp){
              this.$set(e,'key',this.count);
            }
            this.count++;
          })
          this.cacheData = this.dataSource.map(item => ({ ...item }));
        }
      })
      this.searchLoading = false;
    },
    handleOk(){
      this.confirmLoading = true;
      const items = this.dataSource;
      if(items.length>0){
        let count = 0;
        items.forEach(item=>{
          if(item.productId===null||item.stockNum==''||item.unit==''){
            count++;
          }
        })
        if(count>0){
          this.$message.error("商品列表内容不能为空！！");
          return;
        }
        //TODO 请求后端创建采购任务单接口，并设置等待状态confirmLoading为真
        this.$api.purchaseOrderTaskDetail.saveOrUpdateList(items).then(res=>{
          if(res.code===200&&res.success){
            this.$message.success("操作成功！")
            this.reload();
          }else {
            if(res.msg){
              this.$message.error(res.msg);
            }
          }
        })
        this.confirmLoading = false;
      }
      // this.postAddFormProcessor();
      // this.confirmLoading = false;
    },
    handleCancel(){
      if(this.editingKey!==''){
        return
      }
      this.customVisible = false;
      this.customEditable = false;
      this.dataSource = [];
      this.cacheData = [];
      this.$emit("changeEditAble",this.customEditable);
      this.$emit("changeEditVisible",this.customVisible);
      this.$emit("changeTaskId",undefined);
    },

    goodsSelectChange(value){
      this.goodsChooseOption = value;
      this.dataParams.productId = value.key;
      let param = {
        id:value.key,
      }
      this.$api.goods.goodsDetail(param).then(res=>{
        if(res.code===200&&res.success){
          const goods = res.data;
          this.dataParams.unit = goods.unit;
          const newData = [...this.dataSource];
          const target = newData.filter(item=>this.editingKey === item.key)[0];
          if(target){
            target.productId = value.key;
            target.productName = value.label;
            target.unit = goods.unit;
            this.dataSource = newData;
          }
        }
      })
    },
    changeBidOrderTaskDetail(value){
      this.bidOrderTaskDetail = value;
    },
    edit(record) {
      this.goodsChooseOption={
        key:record.productId,
        label:record.productName,
      }
      const newData = [...this.dataSource];
      const target = newData.filter(item => record.key === item.key)[0];
      this.editingKey = record.key;
      if (target) {
        target.editable = true;
        this.delState = true;
        this.dataSource = newData;
      }
    },
    save(key) {
      const newData = [...this.dataSource];
      const newCacheData = [...this.cacheData];
      const target = newData.filter(item => key === item.key)[0];
      let targetCache = newCacheData.filter(item => key === item.key)[0];
      if (target&&targetCache) {
        delete target.editable;
        this.dataSource = newData;
        this.cacheData = JSON.parse(JSON.stringify(this.dataSource));
      }
      this.editingKey = '';
    },
    cancel(key) {
      const newData = [...this.dataSource];
      const target = newData.filter(item => key === item.key)[0];
      this.editingKey = '';
      this.goodsChooseOption = {key:'',label:''};
      if (target) {
        Object.assign(target, this.cacheData.filter(item => key === item.key)[0]);
        delete target.editable;
        this.dataSource = newData;
      }
    },
    handleChange(value,key,column){
      const newData = [...this.dataSource];
      const target = newData.filter(item => key === item.key)[0];
      if (target) {
        target[column] = value;
        target['stockNum'] = value;
        this.dataSource = newData;
      }
    },
    onDelete(record) {
      const dataSource = [...this.dataSource];
      const cacheData = [...this.cacheData];
      this.dataSource = dataSource.filter(item => item.key !== record.key);
      this.cacheData = cacheData.filter(item => item.key !== record.key);
      let queryParam = {
        ids:record.id,
      }
      if(queryParam.ids){
        this.$api.purchaseOrderTaskDetail.removeList(queryParam).then(res=>{
          if(res.code===200&&res.success){
            this.$message.success("操作成功");
          }else{
            if(res.msg){
              this.$message.error(res.msg);
            }
          }
        })
      }
    },
    handleAdd() {
      const { count, dataSource} = this;
      const newData = {
        companyId:this.purchaseDetail.companyId,
        restaurantId:this.purchaseDetail.restaurantId,
        publisherId:this.purchaseDetail.publisherId,
        purchaseOrderTaskId:this.purchaseDetail.id,
        status:0,
        key: count,
        productId:null,
        num:'',
        stockNum:this.num,
        productName:'',
        unit:'',
      };
      this.dataSource = [...dataSource, newData];
      this.cacheData.push(JSON.parse(JSON.stringify(newData)));
      this.count = count + 1;
    },
    changeBidPriceVisible(value){
      this.bidPriceVisible = value;
    },
    changeTaskDetailHidden(value){
      this.taskDetailHidden = value;
    },
    bidPrice(record){
      let bidPriceQueryParams = {
        size:-1,
        current:1,
        purchaseOrderTaskCode:record.purchaseOrderTaskCode,
        supplierId:this.$store.getters.userInfo.companyId,
        productId:record.productId,
      }
      this.$api.bidPriceOrder.pageList(bidPriceQueryParams).then(res=>{
        if(res.code===200){
          this.existBidItem = res.data.records;
          // console.log(this.existBidItem);
          if(this.existBidItem.length>0){
            let badBidItem = null;
            let bidItem = null;
            this.existBidItem.forEach(item=>{
              if(item.status === 0){
                bidItem = item;
              }
              if(item.status === 2){
                badBidItem = item;
              }
            })
            if(bidItem){
              this.$message.error("当前商品已经报价,获取报价结果后才能继续操作！");
              return;
            }
            if(badBidItem){
              this.$message.error("当前商品报价失败,请查看报价单重新报价");
              return;
            }
          }
          this.bidPriceVisible = true;
          this.taskDetailHidden = true;
          this.bidOrderTaskDetail = record;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    postAddFormProcessor(){
      this.dataSource = [];
      this.count = 1;
      this.cacheData = [];
    },
  },
  //
  watch:{
    taskId(newValue){
      if (newValue) {
        let queryParam = {
          id: this.taskId,
        }
        this.getOrderDetailList(queryParam);
      }
      this.cacheData = this.dataSource.map(item => ({...item}));
    },
    bidStatus(newValue){
      if(newValue>0&&this.userType===0){
        this.okButtonStyle = {props: { disabled: true },};
      }
    },
  },
  computed:{
    isShowCompAction(){
      return this.userRoles.indexOf('3') !== -1 ||
          this.userRoles.indexOf('4') !== -1;
    },
  }
}
</script>

<style lang="scss" scoped>
label:before{
  content: '*';
  color: red;
}
label{

}
.add-purchase{
  .add-purchase-form{
    padding: 10px 10px 0 0;
    border-top: 1px solid #eee !important;
    border-bottom: 1px solid #eee !important;
    .editable-row-operations a{
      margin-right: 8px;
    }
    ::v-deep .ant-table-body>tr>td{
      padding: 10px;
    }
    ::v-deep .ant-table-thead>tr>th{
      padding: 10px;
    }
    ::v-deep .clickRowStyle{
      background-color:#e6f7ff
    }
  }
}
</style>