<template>
  <div class="error-page">
    <div class="error-code">4<span>0</span>3 <span class="error-code-desc">Forbidden</span></div>
    <div class="error-desc">啊哦~ 你没有权限访问该页面哦</div>
    <div class="error-handle">
      <router-link to="/">
        <a-button type="primary" size="large">返回首页</a-button>
      </router-link>
      <a-button class="error-btn" type="primary" size="large" @click="goBack">返回上一页</a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page403',
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
  .error-page{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background: #f3f3f3;
      box-sizing: border-box;
  }
  .error-code{
      line-height: 1;
      font-size: 250px;
      font-weight: bolder;
      color: #bf777761;
      text-align: center;
  }
  .error-code span{
      color: #5c967961;
  }
  .error-code-desc {
    font-size: 100px;
    color: #5c967961;
    width: 100%;
  }
  .error-desc{
      font-size: 30px;
      color: #77777759;
  }
  .error-handle{
      margin-top: 30px;
      padding-bottom: 200px;
  }
  .error-btn{
      margin-left: 100px;
  }
</style>
