<template>
  <div class="add-purchase">
    <a-modal title="采购任务单"
             :visible="visible"
             :confirm-loading="confirmLoading"
             okText="确定"
             cancelText="取消"
             @ok="handleOk"
             @cancel="handleCancel"
             width="790px">
      <a-row>
        <a-col :span="12">
          <label>批次号：</label>
          <span style="color: #92989B">创建后自动生成</span>
        </a-col>
        <a-col :span="12">
          <label>采购方：</label>
          <span style="color: #92989B">{{ resName }}</span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="12">
          <label>发布时间：</label>
          <span style="color: #92989B">{{ createDate }}</span>

        </a-col>
        <a-col :span="12">
          <label>发布人：</label>
          <span style="color: #92989B">{{createPerson}}</span>
        </a-col>
      </a-row>
      <br>
      <a-form class="add-purchase-form"
              :form="addPurchaseForm"
              label-width="100px">
        <a-row>
          <a-col :span="14" :pull="2">
            <a-form-item label="交货方式" :labelCol="labelCol"
                         :wrapperCol="wrapperCol">
              <a-select
                  v-decorator="['deliveryWay',rules.deliveryWay]"
                  :getPopupContainer="trigger => trigger.parentNode"
                  placeholder="请选择"
                  type="text"
                  style="width: 110px"
                  allowClear>
                <a-select-option :key="0"
                                 value="0">
                  送货上门
                </a-select-option>
                <a-select-option :key="1"
                                 value="1">
                  自提
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8" :pull="2">
            <a-form-item label="交货时间" :labelCol="labelCol"
                         :wrapperCol="wrapperCol">
              <a-tooltip placement="top" title="确定后不能变更" autoAdjustOverflow>
              <a-date-picker v-decorator="['deliveryTime',rules.deliveryTime]"
                             :getPopupContainer="trigger => trigger.parentNode"
                             :locale="zh_CN"
                             formate="YYYY-MM-DD HH:mm:ss"
                             :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                             @change="onTimeChange" />
              </a-tooltip>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
           <a-col :span="24">
             <a-form-item>
               <span>商品列表</span>
               <div>
                 <a-button style="margin-bottom: 8px;"
                           type="primary"
                           @click="handleAdd">
                   添加
                 </a-button>
                 <a-table bordered
                          :data-source="dataSource"
                          :columns="columns"
                          :customRow="customRow"
                          :row-class-name="setRowClassName"
                          style="width: 100%;">
                   <template slot="goodsName" slot-scope="text,record">
                     <div v-if="record.editable"  style="margin: -5px 0">
                       <goods-selector ref="goodsSelector"
                                       :current-value="goodsChooseOption"
                                       @goodsSelectChange="goodsSelectChange"></goods-selector>
                     </div>
                     <template v-else>
                       {{text}}
                     </template>
                   </template>
                   <template
                       v-for="col in ['num']"
                       :slot="col"
                       slot-scope="text, record">
                     <div :key="col">
                       <a-input
                           v-if="record.editable"
                           style="margin: -5px 0"
                           :value="text"
                           @change="e => handleChange(e.target.value, record.key, col)"/>
                       <template v-else>
                         {{ text }}
                       </template>
                     </div>
                   </template>
                   <template  slot="unit" slot-scope="text,record">
                      <span v-if="record.editable">{{dataParams.unit}}</span>
                      <span v-else>{{text}}</span>
                   </template>
                   <template slot="action" slot-scope="text, record">
                     <span v-if="record.editable">
                        <a @click="() => save(record.key)">保存</a>
                         <a-divider type="vertical"/>
                        <a-popconfirm title="确定取消?"
                                      ok-text="确定"
                                      cancel-text="取消"
                                      @confirm="() => cancel(record.key)">
                        <a>取消</a>
                        </a-popconfirm>
                     </span>
                     <span v-else>
                        <a :disabled="editingKey !== ''" @click="() => edit(record.key)">修改</a>
                     </span>
                     <a-divider type="vertical"/>
                     <span v-if="record.editable&&delState">
                          <a :disabled="editingKey !== ''">删除</a>
                     </span>
                     <span v-else>
                         <a-popconfirm
                             v-if="dataSource.length"
                             title="确定删除?"
                             ok-text="确定"
                             cancel-text="取消"
                             @confirm="() => onDelete(record.key)">
                           <a-icon slot="icon" type="question-circle-o" style="color: red"/>
                           <a>删除</a>
                         </a-popconfirm>
                     </span>
                   </template>
                 </a-table>
               </div>
             </a-form-item>
           </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import {dateFormat} from "@/utils/common";
import GoodsSelector from "@/components/goodsSelector";
import moment from 'moment'
import zh_CN from 'ant-design-vue/es/date-picker/locale/zh_CN';
export default {
  name: "addPurchase",
  components:{
    GoodsSelector,
  },
  props:{
    visible:{
      type:Boolean,
    }
  },
  inject:['reload'],
  data(){
    return{
      zh_CN,
      moment,
      selectedRowId:undefined,
      customVisible:this.visible,
      confirmLoading:false,
      addPurchaseForm:this.$form.createForm(this, {name: 'add_purchase_form'}),
      resId:this.$store.getters.userInfo.restaurantId,
      resName:this.$store.getters.userInfo.restaurantName,
      companyName:this.$store.getters.userInfo.companyName,
      createDate:dateFormat(new Date(),'YYYY-MM-DD HH:mm:ss'),
      createPerson:this.$store.getters.userInfo.name,
      purchaseGoodsData:{
        deliveryTime:undefined,
        deliveryWay: undefined,
        purchaseOrderTaskDetails:[]
      },
      goodsChooseOption:{key:'',label:''},
      dataParams:{
        productId:null,
        num:'',
        unit:'',
      },
      dataSource: [],
      cacheData:[],
      editingKey:'',
      count: 1,
      delState:false,
      labelCol: {
        xs: {span: 24},
        sm: {span: 8} //控制label的框的长度
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 10}//控制文本框的长度
      },
      columns: [
        {
          title: '商品名称',
          dataIndex: 'goodsName',
          key:'goodsName',
          width: '20%',
          align: 'center',
          scopedSlots: { customRender: 'goodsName' },
        },
        {
          title: '采购数量',
          dataIndex: 'num',
          key:'num',
          width: '80px',
          align: 'center',
          scopedSlots: { customRender: 'num' },
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key:'unit',
          width: '80px',
          align: 'center',
          scopedSlots: { customRender: 'unit' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: '100px',
          scopedSlots: { customRender: 'action' },
        },
      ],
      rules:{
        deliveryTime: {
          rules: [
            {required: true, message: '请选择交货时间'},
          ]
        },
        deliveryWay: {
          rules: [
            {required: true, message: '请输入交货方式'},
          ]
        },
      }
    }
  },
  created() {
    this.cacheData = this.dataSource.map(item => ({ ...item }));
  },
  methods:{
    customRow(record){
      return {
        on:{
          click:(event)=>{
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    handleOk(){
      this.confirmLoading = true;
      const items = this.dataSource;
      if(items.length>0){
        let count = 0;
        items.forEach(item=>{
          if(item.productId===null||item.num==''||item.unit==''){
            count++;
          }
        })
        if(count>0){
          this.$message.error("商品列表内容不能为空！！");
          return;
        }
        this.purchaseGoodsData.purchaseOrderTaskDetails = items;
        this.addPurchaseForm.validateFields((err, values) => {
          if(!err){
            //console.log("表单数据：",values);
            this.purchaseGoodsData.payWay=values.payWay;
            this.purchaseGoodsData.deliveryWay=values.deliveryWay;
            this.$api.purchaseOrderTask.createPurchaseOrderTask(this.purchaseGoodsData).then(res=>{
              if(res.code===200&&res.success){
                this.$message.success("创建成功！！")
                this.reload();
              }else {
                if(res.msg){
                  this.$message.error(res.msg);
                }
              }
            })
          }
        });
      }
      // this.postAddFormProcessor();
      // this.confirmLoading = false;
    },
    handleCancel(){
      if(this.editingKey!==''){
        return
      }
      this.customVisible = false;
      this.dataSource = [];
      this.cacheData = [];
      this.purchaseGoodsData.purchaseOrderTaskDetails = [];
      this.goodsChooseOption={key:'',label:''};
      this.count = 1;
      this.dataParams = {
        productId: null,
        num: '',
        unit: '',
      };
      this.addPurchaseForm.resetFields();
      this.$emit("changeVisible",this.customVisible);
    },
    onTimeChange(date, dateString){
      //console.log(date, dateString);
      this.purchaseGoodsData.deliveryTime = dateString;
    },
    goodsSelectChange(value){
      this.goodsChooseOption = value;
      this.dataParams.productId = value.key;
      let param = {
        id:value.key,
      }
      this.$api.goods.goodsDetail(param).then(res=>{
        if(res.code===200&&res.success){
          const goods = res.data;
          this.dataParams.unit = goods.unit;

          const newData = [...this.dataSource];
          const target = newData.filter(item=>this.editingKey === item.key)[0];
          if(target){
            target.productId = value.key;
            target.goodsName = value.label;
            target.unit = goods.unit;
            this.dataSource = newData;
          }
          //console.log("单位：",target);
        }
      })

    },
    edit(key) {
      const newData = [...this.dataSource];
      const target = newData.filter(item => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.delState = true;
        this.dataSource = newData;

      }
    },
    save(key) {
      const newData = [...this.dataSource];
      const newCacheData = [...this.cacheData];
      const target = newData.filter(item => key === item.key)[0];
      let targetCache = newCacheData.filter(item => key === item.key)[0];
      if (target&&targetCache) {
        delete target.editable;
        this.dataSource = newData;
        this.cacheData = JSON.parse(JSON.stringify(this.dataSource));
        this.purchaseGoodsData.purchaseOrderTaskDetails =  JSON.parse(JSON.stringify(this.dataSource));
      }

      this.editingKey = '';
    },
    cancel(key) {
      const newData = [...this.dataSource];
      const target = newData.filter(item => key === item.key)[0];
      this.editingKey = '';
      this.goodsChooseOption = {key:'',label:''};
      this.dataParams = {
        productId: null,
        num: '',
        unit: '',
      };
      if (target) {
        Object.assign(target, this.cacheData.filter(item => key === item.key)[0]);
        delete target.editable;
        this.dataSource = newData;
      }
    },
    handleChange(value,key,column){
      const newData = [...this.dataSource];
      const target = newData.filter(item => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.dataSource = newData;
      }
    },
    onDelete(key) {
      const dataSource = [...this.dataSource];
      const cacheData = [...this.cacheData];
      this.dataSource = dataSource.filter(item => item.key !== key);
      this.purchaseGoodsData.purchaseOrderTaskDetails = JSON.parse(JSON.stringify(this.dataSource));
      this.cacheData = cacheData.filter(item => item.key !== key);
    },
    handleAdd() {
      const { count, dataSource} = this;
      const newData = {
        key: count,
        productId:null,
        num:'',
        goodsName:'',
        unit:'',
      };
      this.dataSource = [...dataSource, newData];
      this.cacheData.push(JSON.parse(JSON.stringify(newData)));
      this.count = count + 1;
    },
    postAddFormProcessor(){
      this.dataSource = [];
      this.count = 1;
      this.cacheData = [];
      this.purchaseGoodsData.purchaseOrderTaskDetails = [];
      this.addPurchaseForm.resetFields();
    }
  },
}
</script>

<style lang="scss" scoped>
label:before{
  content: '*';
  color: red;
}
label{

}
.add-purchase{
  .add-purchase-form{
    padding: 10px 10px 0 0;
    border-top: 1px solid #eee !important;
    border-bottom: 1px solid #eee !important;
    .editable-row-operations a{
      margin-right: 8px;
    }
    ::v-deep .ant-table-body>tr>td{
      padding: 10px;
    }
    ::v-deep .ant-table-thead>tr>th{
      padding: 10px;
    }
    ::v-deep .clickRowStyle{
      background-color:#e6f7ff
    }
  }
}
</style>