<template>
  <a-select
      show-search
      placeholder="请选择商品"
      option-filter-prop="children"
      style="width: 130px"
      size="large"
      :filter-option="false"
      :getPopupContainer="trigger => trigger.parentNode"
      @popupScroll="goodsScroll"
      @search="fetchProducts"
      @change="handleChange"
      :value="currentValue.label?currentValue:undefined"
      label-in-value>
    <a-select-option v-for="(item,index) in products" :key="index" :value="item.id">
      {{item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  name: "goodsSelector",
  props:{
    currentValue:{
      type:Object,
    },
    companyId:{
      type:Number,
    }
  },
  data(){
    return{
      products: [],
      chooseOption:{key:'',label:''},
      queryParam:{
        current:1,
        size:20,
        name:'',
      },
      value:undefined,
      text:'',
    }
  },
  created() {
    this.getGoodsDropDownList(this.queryParam,this.companyId);
  },
  methods:{
    handleChange(value){
      this.$emit('goodsSelectChange',value);
    },
    fetchProducts(value){
      const params = {
        current:1,
        size:this.queryParam.size,
        name:value,
      }
      this.getGoodsDropDownList(params);
    },
    goodsScroll(e){
      const { target } = e;
      if(target.scrollTop+target.offsetHeight===target.scrollHeight){
        const nextIndex = this.queryParam.current+1;
        this.setIndex(nextIndex);
        this.getGoodsDropDownList(this.queryParam)
      }
      //console.log(this.queryParam.current);
    },
    setIndex(index){
      this.queryParam.current = index;
    },
    getGoodsDropDownList(param,companyId){
      const userType = this.$store.getters.userInfo.staffType;
      let params = {...param};
      if(userType===1){
        if(!companyId){
          companyId = 0;
        }
        params = {...param,companyId};
      }
      //console.log("商品选择：",params);
      this.$api.goods.goodsDropDownList(params).then(res=>{
        if(res.code===200&&res.success){
          // console.log("加载的商品列表：",res.data)
          this.products = res.data.records;
        }
      });
    },
  },
  watch:{
    value(val){
      this.text = val.label;
    },
    companyId(newValue){
      this.getGoodsDropDownList(this.queryParam,newValue);
    }
  }
}
</script>

<style scoped>

</style>