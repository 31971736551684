<template>
  <div>员工详情</div>
</template>

<script>
export default {
  name: "detail"
}
</script>

<style scoped>

</style>