<template>
  <div clss="addReceive">
    <a-modal
        :visible="visible"
        :confirm-loading="confirmLoading"
        @cancel="handleCancel"
        :footer="null"
        width="1000px">
      <template slot="title">
        <span style="font-size: 20px;font-weight: bolder;">验收商品</span>
        <a-divider type="vertical" style="height: 40px;"/>
        <span style="font-size: 16px;font-weight: bolder">验收人:</span>
        <span style="color: red;font-weight: bolder;font-size: 16px;">
          {{receiveDetail.receiveStaffName}}</span>
      </template>
      <a-row>
        <a-col :span="24" style="font-weight: bold;">
          <label>所属订单号：</label>
          <span style="color: #92989B">{{orderCode}}</span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="12" style="font-weight: bold;">
          <label>验收单号：</label>
          <span style="color: #92989B">验收后自动生成</span>
        </a-col>
        <a-col :span="10" :offset="2" style="font-weight: bold;">
          <label>创建时间：</label>
          <span style="color: #92989B">{{getFormatDate(new Date())}}</span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="12" style="font-weight: bold;">
          <label>采购方：</label>
          <span style="color: #92989B">
            {{companyName}}({{restaurantName}})
          </span>
        </a-col>
        <a-col :span="10" :offset="2" style="font-weight: bold;">
          <label>供应商：</label>
          <span style="color: #92989B">{{supplierName}}</span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="12" style="font-weight: bold;">
          <label>商品名称：</label>
          <span style="color: #92989B">
            {{productName}}
          </span>
        </a-col>
        <a-col :span="10" :offset="2" style="font-weight: bold;">
          <label>单位：</label>
          <span style="color: #92989B">{{unit}}</span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <div>
          <a-col :span="12" style="font-weight: bold;">
            <div class="collapseClass">
              <a-collapse v-model="activeKey" style="width: 280px">
                <a-collapse-panel :key="0" :show-arrow="false">
                  <template slot="header">
                    <span>供货信息</span>
                  </template>
                  <div>
                    <div style="padding-bottom: 4px;padding-left: 10px;">
                    <span>供货数量：{{ num }}</span><br>
                    </div>
                    <div style="padding-bottom: 4px;padding-left: 10px;">
                      <span>供货单价：￥{{ unit ?
                          getFormatMoney(unitPrice,true) + "元/" + receiveInfo.unit
                          :getFormatMoney(unitPrice,true) + "元" }}</span><br>
                    </div>
                    <div style="padding-left: 10px;">
                      <span>供货总价：￥{{
                          getFormatMoney((totalPrice),true)+"元"
                        }}</span><br>
                    </div>
                  </div>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </a-col>
          <a-col :span="10" :offset="2" style="font-weight: bold;">
            <div>
              <a-collapse v-model="activeKey" style="width: 280px">
                <a-collapse-panel :key="0" header="验收信息" :show-arrow="false">
                  <div v-if="!editReceiveStatus">
                    <div style="padding-bottom: 4px;padding-left: 10px;">
                      <span>验收数量：{{ receiveDetail.receiveNum }}</span><br>
                    </div>
                    <div style="padding-bottom: 4px;padding-left: 10px;">
                      <span>验收单价：￥{{ resultUnitPrice+unitSuffix}}</span><br>
                    </div>
                    <div style="padding-left: 10px;">
                      <span>验收总价：￥{{resultTotalPrice+"元" }}</span><br>
                    </div>
                  </div>
                  <div v-if="editReceiveStatus">
                    <div style="padding-bottom: 4px;padding-left: 10px;">
                      <span>验收数量：</span>
                      <a-input style="width: 60px" size="small"
                               v-model="receiveDetail.receiveNum"/>
                      <br>
                    </div>
<!--                    <div style="padding-bottom: 4px;padding-left: 10px;">-->
<!--                      <span>验收单价：</span>-->
<!--                      <a-input style="width: 150px" size="small"-->
<!--                               v-model="receiveDetail.receiveUnitPrice">-->
<!--                        <template slot="prefix">-->
<!--                          <span>￥</span>-->
<!--                        </template>-->
<!--                        <template slot="suffix">-->
<!--                          <span>{{ unitSuffix }}</span>-->
<!--                        </template>-->
<!--                      </a-input>-->
<!--                      <br>-->
<!--                    </div>-->
                    <div style="padding-bottom: 4px;padding-left: 10px;">
                      <span>验收单价：￥{{ resultUnitPrice+unitSuffix}}</span><br>
                    </div>
                    <div style="padding-left: 10px;">
                      <span>验收总价：</span>
                      <span>￥{{resultTotalPrice+"元" }}</span><br>
                    </div>
                  </div>
                  <template slot="extra">
                    <a-tag :hidden="editReceiveStatus" color="#2db7f5" @click="editReceiveClick">编辑</a-tag>
                    <a-tag :hidden="!editReceiveStatus" color="#2db7f5" @click="editReceiveClick">返回</a-tag>
                  </template>
                </a-collapse-panel>
              </a-collapse>
            </div>
          </a-col>
        </div>
      </a-row>
      <br>
      <a-row>
        <a-col :span="2" style="font-weight: bold;">
          <label>验收图片：</label>
        </a-col>
        <a-col :span="10">
          <a-upload class="avatar-uploader"
                    action="/auth/upLoad"
                    :headers=headers
                    name="file"
                    :data=uploadParams
                    :fileList="receivePicList"
                    list-type="picture-card"
                    :disabled="receiveLoading"
                    :before-upload="handleBeforeUpload"
                    :remove="handleReceiveRemovePicture"
                    @preview="handleReceivePreview"
                    @change="handleReceiveChange">
            <div v-if="receivePicList.length<3">
              <a-icon type='plus'/>
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
            <a-modal :visible="receivePreviewVisible" :footer="null" @cancel="handleReceiveCancelDetailPic">
              <img alt="验收详情图片" style="width: 100%" :src="receiveDetail.receiveRemarkPic"/>
            </a-modal>
        </a-col>
        <a-col :span="8" :offset="2">
          <label style="font-weight: bold;">验收备注：</label>
          <a-textarea placeholder="备注信息"
                      rows="4"
                      v-model="receiveDetail.receiveRemark"
                      style="width: 280px"
                      allowClear/>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="24">
          <div>
            <span style="font-weight: bold;font-size: 18px">是否退货</span>
            <a-switch checked-children="是"
                      un-checked-children="否"
                      style="margin-bottom: 5px;margin-left: 5px;"
                      :checked="this.returnStatus"
                      @change="returnStatusChange"/>
          </div>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <div v-if="returnStatus">
          <a-row>
            <a-col :span="12" style="font-weight: bold;">
              <label>退货数量：</label>
              <a-input-number id="inputNumber" v-model="receiveDetail.returnNum"
                              style="width: 60px"
                              size="small"
                              :min="1" :max="num" @change="returnNumChange"/>
            </a-col>
            <a-col :span="10" :offset="2" style="font-weight: bold;">
              <label>单价：</label>
              <span style="color: #92989B">￥{{ returnUnitPrice+unitSuffix}}</span>
            </a-col>
          </a-row>
          <br>
          <a-row>
            <a-col :span="12" style="font-weight: bold;">
              <label>退货方式：</label>
              <span style="color: #92989B">
              待定
              </span>
            </a-col>
            <a-col :span="10" :offset="2" style="font-weight: bold;">
              <label>总价：</label>
              <span style="color: #92989B">￥{{returnTotalPrice+"元"}}</span>
            </a-col>
          </a-row>
          <br>
          <a-row>
            <a-col :span="12" style="font-weight: bold;">
              <label>处理人（采购方）：</label>
              <span style="color: #92989B">
            {{ this.$store.getters.userInfo.name }}
          </span>
            </a-col>
            <a-col :span="10" :offset="2" style="font-weight: bold;">
              <label>处理人（供应商）：</label>
              <a-input v-if="userType===1" style="width: 150px"/>
              <span v-if="userType===0" style="color: #92989B">暂未填写</span>
            </a-col>
          </a-row>
          <br>
          <a-row>
            <a-col :span="2" style="font-weight: bold;">
                <label>退货图片信息：</label>
            </a-col>
            <a-col :span="20">
                <a-upload class="avatar-uploader"
                          action="/auth/upLoad"
                          :headers=headers
                          name="file"
                          :data=uploadParams
                          :fileList="returnFileList"
                          list-type="picture-card"
                          :disabled="loading"
                          :before-upload="handleBeforeUpload"
                          :remove="handleReturnRemovePicture"
                          @preview="handleReturnPreview"
                          @change="handleReturnChange">
                  <div v-if="returnFileList.length<6">
                    <a-icon type='plus'/>
                    <div class="ant-upload-text">
                      上传图片
                    </div>
                  </div>
                </a-upload>
                  <a-modal :visible="previewVisible" :footer="null" @cancel="handleReturnCancelDetailPic">
                    <img alt="退货详情图片" style="width: 100%" :src="receiveDetail.reasonPic"/>
                  </a-modal>
            </a-col>
          </a-row>
          <br>
          <a-row>
            <a-col :span="10">
              <label style="font-weight: bold;">退货原因：</label>
              <a-textarea placeholder="退货原因"
                          rows="4"
                          v-model="receiveDetail.reason"
                          allowClear/>
            </a-col>
            <a-col :span="10" :offset="4">
              <label style="font-weight: bold;">备注：</label>
              <a-textarea placeholder="备注信息"
                          rows="4"
                          v-model="receiveDetail.remark"
                          allowClear/>
            </a-col>
          </a-row>
        </div>
      </a-row>
      <br>
      <br>
      <a-row>
        <a-col :span="4" :offset="10">
          <a-button type="primary" style="width: 150px" @click="doReceiveGoods">提交</a-button>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import {dateFormat, formatMoney} from "@/utils/common";
import Modal from "ant-design-vue/lib/modal/Modal";

export default {
  name: "addReceive",
  inject:["reload"],
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    receiveInfo:{
      type:Object,
    },
  },
  data(){
    return{
      customVisible:this.visible,
      confirmLoading:false,
      userType:this.$store.getters.userInfo.staffType,


      editReceiveStatus:false,
      returnStatus:false,

      taskCode:'暂无数据',
      orderCode:'暂无数据',
      companyName:'暂无数据',
      restaurantName:'暂无数据',
      supplierName:'暂无数据',
      unit:'暂无数据',
      num:undefined,
      unitPrice:undefined,
      totalPrice:undefined,
      sourceTotalPrice:undefined,
      productName:'暂无数据',
      activeKey:['0'],
      receiveDetail:{
        //receive
        receiveNum:undefined,
        receiveUnitPrice:undefined,
        receiveTotalPrice:undefined,
        receiveStaffId:this.$store.getters.userInfo.id,
        receiveStaffName:this.$store.getters.userInfo.name,
        receiveTime:undefined,
        receiveRemark:undefined,
        receiveRemarkPic:undefined,
        //return
        hasReturn:false,
        returnNum:undefined,
        currentUnitPrice:undefined,
        returnTotalPrice:undefined,
        returnsWay:undefined,
        remark:undefined,
        reasonPic:undefined,
        reason:undefined,
      },

      //上传图片相关
      headers:{
        contentType:'multipart/form-data'
      },
      receivePreviewVisible:false,
      receiveLoading:false,
      receivePicList:[],

      previewVisible:false,
      loading:false,
      returnFileList:[],


    }
  },
  methods:{
    doReceiveGoods(){
      this.confirmLoading = true;
      const receiveDetail = this.receiveDetail;
      const orderDetail = this.receiveInfo;
      const receivePics =this.receivePicList.map(pic=>{
        if(pic.response.data){
          return pic.response.data;
        }
      }).join(",");
      const params = {
        supplierId:orderDetail.supplierId,
        companyId:orderDetail.companyId,
        purchaseOrderTaskId:orderDetail.purchaseOrderTaskId,
        purchaseOrderId:orderDetail.purchaseOrderId,
        purchaseOrderDetailId:orderDetail.id,
        productId:orderDetail.productId,
        unit:this.unit,
        totalNum:this.num,
        unitPrice:this.unitPrice,
        totalPrice:this.sourceTotalPrice,
        receiveNum:receiveDetail.receiveNum,
        receiveUnitPrice:receiveDetail.receiveUnitPrice,
        receiveTotalPrice:receiveDetail.receiveNum*receiveDetail.receiveUnitPrice,
        receiveRemark:receiveDetail.receiveRemark,
        receiveRemarkPic:receivePics,
        receiveStaffName:receiveDetail.receiveStaffName,
      }
      if(this.returnStatus){
        const returnPics =this.returnFileList.map(pic=>{
          if(pic.response.data){
            return pic.response.data;
          }
        }).join(",");
        params.returnNum = receiveDetail.returnNum;
        params.currentUnitPrice = receiveDetail.currentUnitPrice;
        params.reason = receiveDetail.reason;
        params.reasonPic = returnPics;
        params.returnsWay = receiveDetail.returnsWay;
        params.remark = receiveDetail.remark;
        params.num = this.num;
        this.$api.returnGoods.addReturn(params).then(res=>{
          if(res.code===200&&res.success){
            this.$message.success("操作成功！");
            this.reload();
          }else{
            if(res.msg){
              this.$message.error(res.msg);
            }
          }
          this.confirmLoading = false;
        })
      }else{
        this.$api.purchaseOrder.checkOrder(params).then(res=>{
          if(res.code===200&&res.success){
            this.$message.success("操作成功！");
            this.reload();
          }else{
            if(res.msg){
              this.$message.error(res.msg);
            }
          }
          this.confirmLoading = false;
        })
      }
    },
    handleCancel(){
      this.customVisible = false;
      this.editReceiveStatus=false;
      this.returnStatus=false;
      this.previewVisible = false;
      this.receivePreviewVisible = false;
      this.receivePicList = [];
      this.returnFileList = [];
      this.receiveDetail = {
        //receive
        receiveNum: undefined,
        receiveUnitPrice: undefined,
        receiveTotalPrice: undefined,
        receiveStaffId: this.$store.getters.userInfo.id,
        receiveStaffName: this.$store.getters.userInfo.name,
        receiveTime: undefined,
        receiveRemark: undefined,
        receiveRemarkPic: undefined,
        //return
        hasReturn: false,
        returnNum: undefined,
        currentUnitPrice: undefined,
        returnTotalPrice: undefined,
        returnsWay: undefined,
        remark: undefined,
        reasonPic: undefined,
        reason: undefined,
      }
      this.$emit("changeReceiveDetail",{});
      this.$emit("changeReceiveVisible",this.customVisible);
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
    getFormatMoney(money,isCarryBit){
      const result = formatMoney(money,2,".",",",isCarryBit);
      return result;
    },
    editReceiveClick(event){
      event.stopPropagation();
      this.editReceiveStatus = !this.editReceiveStatus;
      if(!this.editReceiveStatus){
        this.receiveDetail.receiveNum = this.num;
        this.receiveDetail.receiveUnitPrice = this.unitPrice;
        this.receiveDetail.receiveTotalPrice = this.receiveNum*this.receiveUnitPrice;
      }
    },
    returnStatusChange(value){
      this.returnStatus = value;
      if(this.returnStatus){
        this.receiveDetail.hasReturn=1;
        this.receiveDetail.currentUnitPrice=this.receiveDetail.receiveUnitPrice;
        this.receiveDetail.returnNum = 1;
        this.receiveDetail.returnTotalPrice=this.receiveDetail.returnNum*this.receiveDetail.receiveUnitPrice;

        this.receiveDetail.receiveNum = this.num-this.receiveDetail.returnNum;
        this.receiveDetail.receiveTotalPrice = this.receiveDetail.receiveNum*this.receiveDetail.receiveUnitPrice;
      }
      if(!this.returnStatus){
        this.receiveDetail.hasReturn=0;
        this.receiveDetail.returnNum = undefined;
        this.receiveDetail.returnTotalPrice = undefined;
        this.receiveDetail.currentUnitPrice = undefined;
        this.receiveDetail.receiveNum = this.num;
        this.receiveDetail.receiveTotalPrice = this.receiveDetail.receiveNum*this.receiveDetail.receiveUnitPrice;
      }
    },
    returnNumChange(value){
      this.receiveDetail.returnNum = value;
      this.receiveDetail.receiveNum = this.num-value;
    },

    //上传图片相关
    uploadParams(){
      let d = {
        companyName:this.companyName,
        userType: this.$store.getters.userInfo.staffType,
      }
      return d;
    },
    handleBeforeUpload(file){
      return new Promise((resolve,reject)=>{
        const isJPG = file.type === 'image/jpg';
        const isJPEG = file.type=== 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isGIF = file.type === 'image/gif';
        const isLt10M = file.size/1024/1024 <10;
        if(!(isJPG||isJPEG||isPNG||isGIF)){
          Modal.error({
            title:'只能上传JPG、JPEG、GIF、PNG格式的图片',
          });
          return reject(false);
        }else if(!isLt10M){
          Modal.error({
            title:'图片大小不能超过10M',
          });
          return reject(false);
        }
        return resolve(true);
      })
    },
    //验收上传图片相关
    handleReceiveRemovePicture(){
      this.receiveDetail.receiveRemarkPic = null;
    },
    async handleReceivePreview(file) {
      if(file.response.data){
        this.receiveDetail.receiveRemarkPic = file.response.data;
        this.receivePreviewVisible = true;
      }else{
        this.$message.error("图片上传失败不能预览！")
      }
    },
    handleReceiveChange(info){
      this.receiveLoading = true;
      this.receivePicList = info.fileList;
      const status = info.file.status;
      if (status!== 'uploading') {
        this.receiveLoading = false;
        setTimeout(()=>{

        },10000);
      }
      if (status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
      if (status === 'done') {
        if (info.file.response.data) {
          this.$message.success(`${info.file.name} 上传成功`);
        } else {
          this.$message.error(`${info.file.name} ${info.file.response.msg}`);
        }
      }
      if(status ==='removed'){
        this.receiveDetail.receiveRemarkPic = null;
      }
    },

    handleReceiveCancelDetailPic(){
      this.receivePreviewVisible = false;
    },


    //退货上传图片相关
    handleReturnRemovePicture(){
      this.receiveDetail.reasonPic = null;
    },
    handleReturnChange(info){
      this.loading = true;
      this.returnFileList = info.fileList;
      const status = info.file.status;
      if (status!== 'uploading') {
        this.loading = false;
        setTimeout(()=>{

        },10000);
      }
      if (status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
      if (status === 'done') {
        if (info.file.response.data) {
          this.$message.success(`${info.file.name} 上传成功`);
        } else {
          this.$message.error(`${info.file.name} ${info.file.response.msg}`);
        }
      }
      if(status ==='removed'){
        this.receiveDetail.reasonPic = null;
      }
    },
    async handleReturnPreview(file) {
      if(file.response.data){
        this.receiveDetail.reasonPic = file.response.data;
        this.previewVisible = true;
      }else{
        this.$message.error("图片上传失败不能预览！")
      }
    },
    handleReturnCancelDetailPic(){
      this.previewVisible = false;
    },
  },
  watch:{
    receiveInfo(newValue){
      if(newValue){
        this.orderCode = newValue.orderCode;
        this.taskCode = newValue.taskCode;
        this.companyName = newValue.companyName;
        this.restaurantName = newValue.restaurantName;
        this.supplierName = newValue.supplierName;
        this.unit = newValue.unit;
        this.num = newValue.num;
        this.unitPrice = newValue.unitPrice;
        this.totalPrice = newValue.num*newValue.unitPrice;
        this.sourceTotalPrice = newValue.sourceTotalPrice;
        this.productName = newValue.productName;
        //receiveInfo
        this.receiveDetail.receiveNum = newValue.num;
        this.receiveDetail.receiveUnitPrice = newValue.unitPrice;
        this.receiveDetail.receiveTotalPrice = this.receiveNum*this.receiveUnitPrice;
      }
    },
    'receiveDetail.receiveUnitPrice'(newValue){
      if(newValue){
        if(this.returnStatus){
          this.receiveDetail.currentUnitPrice = newValue;
        }
      }
    },
    'receiveDetail.receiveNum'(newValue){
      if(newValue){
        if(newValue>this.num){
          newValue = this.num;
        }
        this.receiveDetail.receiveNum = newValue;
      }
    }

  },
  computed:{
    unitSuffix(){
      if(this.unit){
        return "元/"+this.unit;
      }else {
        return "元";
      }
    },
    resultUnitPrice(){
      const result = this.getFormatMoney(this.receiveDetail.receiveUnitPrice,true);
      return result;
    },
    resultTotalPrice(){
      const result = this.getFormatMoney((this.receiveDetail.receiveNum *this.receiveDetail.receiveUnitPrice),
          true);
      return result;
    },

    returnUnitPrice(){
      const result = this.getFormatMoney(this.receiveDetail.currentUnitPrice,true);
      return result;
    },
    returnTotalPrice(){
      const result = this.getFormatMoney((this.receiveDetail.returnNum *this.receiveDetail.currentUnitPrice),
          true);
      return result;
    },

  }
}
</script>

<style lang="scss" scoped>
.addReceive{
  .collapseContent{
  }
}
::v-deep .ant-collapse-header{
  padding: 10px 10px 10px 12px !important;
}

</style>