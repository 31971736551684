<template>
  <div class="bidPriceDetail">
    <a-modal :visible="visible"
             :confirm-loading="confirmLoading"
             okText="确定"
             cancelText="取消"
             @ok="handleOk"
             @cancel="handleCancel"
             :okButtonProps="this.okButtonStyle"
             width="700px">
      <div class="bidPriceContent">
        <a-descriptions bordered size="small" layout="vertical">
          <template v-slot:title>
            <span style="font-weight: bolder; font-size: 20px;">
              报价单
              <span v-if="bidPriceDetailItem.endStatus===0"
                    style="color: #108ee9;font-weight: bolder;font-size: 15px">
                报价中
              </span>
              <span v-if="bidPriceDetailItem.endStatus===1"
                    style="color: silver;font-weight: bolder;font-size: 15px">
                已结束
              </span>

            </span>
          </template>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px;text-align: center;">商品名称</span>
            </template>
            {{this.bidPriceDetailItem.productName}}
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px;text-align: center;">商品所属采购单批次号</span>
            </template>
            {{this.bidPriceDetailItem.taskCode}}
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px">报价状态</span>
            </template>
            <a-badge v-if="this.bidPriceDetailItem.status==0" status="processing" text="报价中"/>
            <a-badge v-if="this.bidPriceDetailItem.status==1" status="success" text="成功"/>
            <a-badge v-if="this.bidPriceDetailItem.status==2" status="error" text="失败"/>
            <a-badge v-if="this.bidPriceDetailItem.status==3" status="default" text="已结束"/>
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px">采购方所属公司</span>
            </template>
            {{this.bidPriceDetailItem.companyName}}
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px">采购方所属门店</span>
            </template>
            {{this.bidPriceDetailItem.restaurantName}}
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px">发布人</span>
            </template>
            {{this.bidPriceDetailItem.publisherName}}
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px">供应商</span>
            </template>
            {{this.bidPriceDetailItem.supplierName}}
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px;text-align: center;">报价次数</span>
            </template>
            <span style="color: red">{{this.bidPriceDetailItem.bidNum}}</span>
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px">报价人</span>
            </template>
            {{this.bidPriceDetailItem.bidStaffName}}
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px">供货数</span>
              <a-divider v-if="userType===0" type="vertical" style="height: 20px"/>
              <span v-if="userType===0" style="font-weight: bolder;font-size: 16px">采购数</span>
            </template>
            <div v-if="userType===0" style="display: flex;justify-content: center">
              <span style="color: #1890ff;font-weight: bold;padding-right: 33px">{{this.bidPriceDetailItem.num}}</span>
              <a-input-number v-if="userType===0"
                              :defaultValue = "this.bidPriceDetailItem.purchaseNum>0?
                                               this.bidPriceDetailItem.purchaseNum:this.bidPriceDetailItem.num"
                              v-model="currentBidNum"
                              size="small"
                              style="width: 80px;"
                              :disabled="purchaseNumInputStatus"
                              @change="bidNumChange"></a-input-number>
            </div>
            <span v-else style="color: #1890ff;font-weight: bold;padding-right: 33px">{{this.bidPriceDetailItem.num}}</span>
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px">单位</span>
            </template>
            {{this.bidPriceDetailItem.unit}}
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px">单价</span>
            </template>
            <span style="color:red;font-weight: bold;">
              ￥{{getFormatMoney(this.bidPriceDetailItem.unitPrice,true)+this.fullUnit}}
            </span>
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px">付款方式</span>
            </template>
            <span v-if="this.bidPriceDetailItem.payWay==0">现结</span>
            <span v-if="this.bidPriceDetailItem.payWay==1">签单（月付）</span>
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px">交货时间</span>
            </template>
            {{getFormatDate(this.bidPriceDetailItem.deliveryTime)}}
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px">总价</span>
            </template>
            <span style="color:red;font-weight: bold;">
              ￥{{getFormatMoney(this.bidPriceDetailItem.totalPrice,true)}}
            </span>
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px">交货方式</span>
            </template>
            <span v-if="this.bidPriceDetailItem.deliveryWay===0">送货上门</span>
            <span v-else-if="this.bidPriceDetailItem.deliveryWay===1">自提</span>
            <span v-else>暂无数据</span>
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px">图片信息</span>
            </template>
            <a-popover  title="商品图片" placement="top">
              <template slot="content">
                <img v-for=" (item,index) in this.bidPriceDetailItem.picturesInfo.split(',')" :key="index" :src="item" height="256px" width="256px"/>
              </template>
              <span style="color: #1890ff">商品图片</span>
            </a-popover>
          </a-descriptions-item>
          <a-descriptions-item>
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px">交货地点</span>
            </template>
            <span style="color: #92989B;font-weight: bold;">
              {{this.bidPriceDetailItem.deliveryAddress}}
            </span>
          </a-descriptions-item>
          <a-descriptions-item :span="3">
            <template v-slot:label>
              <span style="font-weight: bolder;font-size: 16px">备注</span>
            </template>
            <a-textarea :disabled="true"
                        :defaultValue="this.bidPriceDetailItem.remark"
                        rows="4"/>
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {dateFormat, formatMoney} from "@/utils/common";
export default {
  name: "bidPriceDetail",
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    bidPriceDetail:{
      type:Object,
    }
  },
  inject:['reload'],
  created() {
    if(0===this.userType){
      if(this.userRoles.indexOf("4")!==-1){
        this.purchaseNumInputStatus = false;
      }
    }
  },
  data(){
    return{
      customVisible:this.visible,
      okButtonStyle:{props: { hidden: true },},
      purchaseNumInputStatus:true,
      confirmLoading:false,
      userRoles:this.$store.getters.userInfo.roleIds,
      userType:this.$store.getters.userInfo.staffType,
      currentBidNum:0,
      purchaseNumOld:0,
      bidPriceDetailItem: {
        taskCode: '暂无数据',
        companyName: '暂无数据',
        restaurantName: '暂无数据',
        productName: '暂无数据',
        publisherName: '暂无数据',
        supplierName: '暂无数据',
        productMainPicture: '暂无数据',
        picturesInfo:'暂无数据',
        num: 0,
        purchaseNum:0,
        unit:undefined,
        unitPrice: 0,
        totalPrice: 0,
        payWay: '暂无数据',
        deliveryTime: '暂无数据',
        deliveryAddress: '暂无数据',
        deliveryWay: '暂无数据',
        bidStaffName: '暂无数据',
        remark: '暂无数据',
        bidNum: '暂无数据',
        status: '暂无数据',
        endStatus:'报价中',
      }
    }
  },
  methods:{
    handleOk(){

      if(!this.purchaseNumInputStatus){
        this.confirmLoading = true;
        const params = {
          bidPriceOrderId:this.bidPriceDetail.id,
          purchaseNum:this.bidPriceDetailItem.purchaseNum,
        }
        this.$api.bidPriceOrder.updatePurchaseNum(params).then(res=>{
          if (res.code === 200 && res.success) {
            this.$message.success("修改采购数量成功！");
            this.reload();
          } else {
            if (res.msg) {
              this.$message.error(res.msg);
            }
          }
          this.confirmLoading = false;
        })
      }else {
        this.handleCancel();
      }


    },
    handleCancel(){
      this.customVisible = false;
      this.$emit("changeBidPriceDetail",undefined);
      this.$emit("changeBidPriceDetailVisible",this.customVisible);
    },
    getFormatMoney(money,isCarryBit){
      const result = formatMoney(money,2,".",",",isCarryBit);
      return result;
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
    bidNumChange(value){
      if(undefined === value){
        value = 0;
      }
      if(value===0){
        this.$message.error("采购数量不能为0");
      }
      if(value>0){
        this.currentBidNum = value;
      }
      const maxNum = this.bidPriceDetailItem.num;
      if(value>maxNum){
        this.currentBidNum = maxNum;
      }
    }
  },
  computed:{
    fullUnit(){
      if(this.bidPriceDetailItem.unit){
        return "元/"+this.bidPriceDetailItem.unit;
      }else {
        return "元";
      }
    },
  },
  watch: {
    bidPriceDetail(newValue) {
      if (newValue) {
        this.bidPriceDetailItem.taskCode = newValue.purchaseOrderTaskCode;
        this.bidPriceDetailItem.companyName = newValue.companyName;
        this.bidPriceDetailItem.restaurantName = newValue.restaurantName;
        this.bidPriceDetailItem.productName = newValue.productName;
        this.bidPriceDetailItem.publisherName = newValue.publisherName;
        this.bidPriceDetailItem.supplierName = newValue.supplierName;
        this.bidPriceDetailItem.productMainPicture = newValue.productMainPicture;
        this.bidPriceDetailItem.picturesInfo = newValue.picturesInfo;
        this.bidPriceDetailItem.num = newValue.num;
        this.currentBidNum = newValue.purchaseNum?newValue.purchaseNum:newValue.num;
        this.purchaseNumOld = newValue.purchaseNum;
        this.bidPriceDetailItem.unit = newValue.unit;
        this.bidPriceDetailItem.unitPrice = newValue.unitPrice;
        this.bidPriceDetailItem.totalPrice = newValue.num*this.bidPriceDetailItem.unitPrice;
        this.bidPriceDetailItem.payWay = newValue.payWay;
        this.bidPriceDetailItem.deliveryTime = newValue.deliveryTime;
        this.bidPriceDetailItem.deliveryAddress = newValue.deliveryAddress;
        this.bidPriceDetailItem.deliveryWay = newValue.deliveryWay;
        this.bidPriceDetailItem.bidStaffName = newValue.bidStaffName;
        this.bidPriceDetailItem.remark = newValue.remark;
        this.bidPriceDetailItem.bidNum = newValue.bidNum;
        this.bidPriceDetailItem.status = newValue.status;
        this.bidPriceDetailItem.endStatus = newValue.endStatus;
        if(this.userType!==0||newValue.endStatus===1||newValue.status!==0){
          this.purchaseNumInputStatus = true;
        }else{
          this.purchaseNumInputStatus = false;
        }

      }
    },
    currentBidNum(newValue){
      if(newValue){
        this.bidPriceDetailItem.purchaseNum = newValue;
        this.bidPriceDetailItem.totalPrice = newValue*this.bidPriceDetailItem.unitPrice;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.bidPriceDetail{
  .bidPriceContent{
    ::v-deep .ant-descriptions-row > td {
      padding-bottom: 8px !important;
      width: 30% !important;
    }
  }
}
</style>