<template>
    <div class="error">
        <div class='number'>404</div>
        <div>
            <h1>抱歉，这个页面无法获取</h1>
            <h4>你点击的链接可能发生了故障，或者您无权限访问本页面</h4>
            <router-link to="/">返回上一级</router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: 'error'
}
</script>

<style lang="scss" scoped>
    .error{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .number{
            font-size: 180px;
            margin-right: 15px;
        }
    }
</style>
