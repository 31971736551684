<template>
  <div class="smallTaskDetailClass">
    <a-modal
        :visible="visible"
        :confirm-loading="confirmLoading"
        @cancel="handleCancel"
        :footer="null"
        width="1200px">
      <template slot="title">
        <span style="font-size: 30px;font-weight: bolder">采购单详细信息</span>
        <a-divider type="vertical" style="height: 30px"/>
        <span v-if="smallTaskDetailInfo.status==0"
              style="color: silver;font-weight: bolder;font-size: 18px">未开始</span>
        <span v-else-if="smallTaskDetailInfo.status==1"
              style="font-weight: bolder;font-size: 18px;color: #F29F2A">采购中</span>
        <span v-else-if="smallTaskDetailInfo.status==2"
              style="font-weight: bolder;font-size: 18px;color: limegreen">已完成</span>
      </template>
      <a-row>
        <a-col :span="12" style="font-weight: bold;">
          <label>订单号：</label>
          <span style="color: #92989B">{{ smallTaskDetailInfo.code }}</span>
        </a-col>
        <a-col :span="12" style="font-weight: bold;">
          <label>发布人：</label>
          <span style="color: #92989B">{{ smallTaskDetailInfo.publisherName }}</span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="12" style="font-weight: bold;">
          <label>采购方：</label>
          <span style="color: #92989B">
            {{ smallTaskDetailInfo.companyName }}({{ smallTaskDetailInfo.restaurantName }})
          </span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="12" style="font-weight: bold;vertical-align: middle">
          <label>创建时间：</label>
          <span style="color: #92989B">{{ getFormatDate(smallTaskDetailInfo.createTime) }}</span>
        </a-col>
        <a-col :span="12" style="font-weight: bold;">
          <label></label>
          <span style="color: red;font-size: 24px">
            采购总价：￥{{ getFormatMoney(smallTaskDetailInfo.totalPrice,true) }}元
          </span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="24" style="font-weight: bold;">

          <span>采购商品列表<a-icon type="down"/></span>
          <div>
            <a-collapse :activeKey="activeKey">
              <a-collapse-panel v-for="item in detailList" :key="item.productId.toString()">
                <template slot="header">
                  <div style="display: flex;justify-content: start">

                    <span style="font-size: 20px">{{ item.productName }}</span>
                    <a-divider type="vertical" style="height: 30px"/>
                    <span v-if="item.status==0"
                          style="color: silver;font-weight: bolder;">未开始</span>
                    <span v-else-if="item.status==1"
                          style="font-weight: bolder;color: #F29F2A">采购中</span>
                    <span v-else-if="item.status==2"
                          style="font-weight: bolder;color: limegreen;">已完成</span>

                    <span style="margin-left: 40px;color: red;font-size: 18px">
                      总价：￥{{getFormatMoney(item.totalPrice, true)}}
                    </span>

                    <span style="margin-left: 40px;">预购数量：{{ item.num }}</span>
                    <span style="margin-left: 40px;color: #1890ff">已购数量：{{ item.realNum }}</span>
                    <span
                        style="margin-left: 40px;color: #FF9502">待购数量：{{ item.realNum > item.num ? 0 : (item.num - item.realNum) }}</span>
                    <span v-if="item.realNum>item.num"
                          style="margin-left: 40px;color: red">超购数量：{{ item.realNum - item.num }}</span>
                  </div>
                </template>
                <span>采购记录列表<a-icon type="down"/></span>
                <a-table rowKey="id"
                         :pagination="false"
                         :columns="columns"
                         :data-source="item.smallPurchaseList"
                         :customRow="customRow"
                         :row-class-name="setRowClassName"
                         style="width: 100%;"
                         bordered
                >
                  <template slot="empty">
                    <a-empty description="暂无数据"></a-empty>
                  </template>
                  <template slot="productPic" slot-scope="text">
                    <a-popover title="商品图片" placement="top">
                      <template slot="content">
                        <img v-for=" (item,index) in text.split(',')" :key="index" :src="item" height="256px"
                             width="256px"/>
                      </template>
                      <span style="color: #1890ff">商品图片</span>
                    </a-popover>
                  </template>
                  <template slot="unitPrice" slot-scope="text">
                    <span style="color: red">
                      ￥{{ getFormatMoney(text, true) }}
                    </span>
                  </template>
                  <template slot="totalPrice" slot-scope="text,record">
                    <span style="color: red">
                      ￥{{
                        getFormatMoney((record.num * record.unitPrice), true)
                      }}
                    </span>
                  </template>
                  <template slot="payWay" slot-scope="text">
                    <span v-if="text===0">现结</span>
                    <span v-if="text===1">签单/月付</span>
                  </template>
                  <template slot="createTime" slot-scope="text">
                    {{ getFormatDate(text) }}
                  </template>
                  <template slot="payWay" slot-scope="text">
                    <span v-if="text==0">现结</span>
                    <span v-else-if="text==1">签单（月付）</span>
                    <span v-else>暂无数据</span>
                  </template>
                  <template slot="isDelete" slot-scope="text">
                    <span v-if="text==0" style="color:silver;">否</span>
                    <span v-if="text==1" style="color: red;">是</span>
                  </template>
                </a-table>
              </a-collapse-panel>
            </a-collapse>
          </div>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import {dateFormat, formatMoney} from "@/utils/common";

export default {
  name: "smallTaskDetail",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    smallTaskDetail: {
      type: Object,
    }
  },
  inject: ['reload'],
  data() {
    return {
      selectedRowId: undefined,
      confirmLoading: false,
      customVisible: this.visible,
      smallTaskDetailInfo: {
        companyName: '暂无数据',
        restaurantName: '暂无数据',
        resAddr: '暂无数据',
        companyAddr: '暂无数据',
        createTime: undefined,
        publisherName: '暂无数据',
        totalPrice: undefined,
        id: undefined,
        code: '暂无数据',
        companyId: undefined,
        status: undefined,
        remark: '暂无数据',
        isDelete: undefined,
      },
      detailList: [],
      activeKey: [],
      columns: [
        {
          title: '商品名称',
          dataIndex: 'productName',
          key: 'productName',
          width: '70px',
          align: 'center',
        },
        {
          title: '采购图片',
          dataIndex: 'productPic',
          key: 'productPic',
          width: '100px',
          align: 'center',
          scopedSlots: {customRender: 'productPic'},
        },
        {
          title: '数量',
          dataIndex: 'num',
          key: 'num',
          width: '50px',
          align: 'center',
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key: 'unit',
          width: '50px',
          align: 'center',
        },
        {
          title: '单价（元）',
          dataIndex: 'unitPrice',
          key: 'unitPrice',
          width: '70px',
          align: 'center',
          scopedSlots: {customRender: 'unitPrice'},
        },
        {
          title: '总价（元）',
          dataIndex: 'totalPrice',
          key: 'totalPrice',
          width: '80px',
          align: 'center',
          scopedSlots: {customRender: 'totalPrice'},
        },
        {
          title: '付款方式',
          dataIndex: 'payWay',
          key: 'payWay',
          width: '80px',
          align: 'center',
          scopedSlots: {customRender: 'payWay'},
        },
        {
          title: '采购人',
          dataIndex: 'purchaserName',
          key: 'purchaserName',
          width: '60px',
          align: 'center',
        },
        {
          title: '供应商',
          dataIndex: 'supplierName',
          key: 'supplierName',
          width: '80px',
          align: 'center',
        },
        {
          title: '采购时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: '120px',
          align: 'center',
          scopedSlots: {customRender: 'createTime'},
        },
        {
          title: '是否删除',
          dataIndex: 'isDelete',
          key: 'isDelete',
          width: '70px',
          align: 'center',
          scopedSlots: {customRender: 'isDelete'},
        },
      ],
    }
  },
  methods: {
    customRow(record) {
      return {
        on: {
          click: (event) => {
            console.log(event);
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record) {
      if (record.id === this.selectedRowId) {
        return 'clickRowStyle'
      }
    },
    handleOk() {
      this.handleCancel();
    },
    getFormatMoney(money, isCarryBit) {
      const result = formatMoney(money, 2, ".", ",", isCarryBit);
      return result;
    },
    handleCancel() {
      this.customVisible = false;
      this.$emit("changeSmallTaskDetail", undefined);
      this.$emit("changeSmallTaskDetailVisible", this.customVisible);
    },
    getFormatDate(text) {
      const result = dateFormat(text, 'YYYY-MM-DD');
      return result;
    },
    changeActiveKey(value) {
      console.log("选中的key：", value);
    },
  },
  watch: {
    smallTaskDetail(newValue) {
      if (newValue) {
        this.smallTaskDetailInfo = newValue;
        this.detailList = newValue.detailList;
        // this.activeKey = this.detailList.map(e => e.productId).map(String);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.smallTaskDetailClass {

}

::v-deep .ant-table-thead > tr > th {
  padding: 8px;
}

::v-deep .ant-table-tbody > tr > td {
  padding: 8px;
}

::v-deep .clickRowStyle {
  background-color: #e6f7ff
}
</style>