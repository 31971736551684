<template>
  <div class="staff">
    <div class="item dataList">
      <a-row>
        <a-col :span="24">
          <a-row class="select-form">
            <a-form layout="inline" :form="searchForm">
              <a-form-item>
                <a-input
                    size="large"
                    placeholder="用户名"
                    style="width: 100px"
                    v-model.trim="queryParam.name"
                    allowClear>
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-input
                    size="large"
                    placeholder="请输入用户手机号"
                    style="width: 180px"
                    v-model.trim="queryParam.phone"
                    :maxLength=11
                    allowClear>
                </a-input>
              </a-form-item>
              <a-form-item v-if="this.$store.getters.userInfo.staffType===0">
                <restaurant-selector ref="restaurantSelector" :current-value="chooseOption"
                                   @restaurantSelectChange="restaurantSelectChange"/>
              </a-form-item>
              <a-form-item>
                <a-select v-model="queryParam.status"
                          :getPopupContainer="trigger => trigger.parentNode"
                          allowClear
                          style="width: 110px"
                          size="large"
                          placeholder="登录状态"
                          @change="handleLoginStateSelectChange">
                  <a-select-option :key="0"
                                   value="0">
                    未登录
                  </a-select-option>
                  <a-select-option :key="1"
                                   value="1">
                    已登录
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item>
                <a-col :span="8">
                  <a-button
                      size="large"
                      type="primary"
                      @click="handleSearch">查询
                  </a-button>
                </a-col>
                <a-col :span="8" offset="8">
                  <a-button size="large" @click="resetSearchForm">重置</a-button>
                </a-col>
              </a-form-item>
            </a-form>
          </a-row>
        </a-col>
        <a-col :span="24">
          <div class="add-staff-btn">
            <a-button size="large"
                      type="primary"
                      @click="showAddModal">添加员工</a-button>
          </div>
        </a-col>
      </a-row>
      <a-table bordered
               rowKey="id"
               :pagination="false"
               :data-source="dataSource"
               :columns="columns"
               :customRow="customRow"
               :row-class-name="setRowClassName"
               style="width: 100%;">
        <template slot="sex" slot-scope="text">
          {{text==0?'女':'男'}}
        </template>
        <template slot="createTime" slot-scope="text">
          {{getFormatDate(text)}}
        </template>
        <template slot="status" slot-scope="text">
          <span v-if="text==0" style="color: silver">未登录</span>
          <span v-else style="color: limegreen">已登录</span>
        </template>
        <template slot="isQuit" slot-scope="text">
          <span v-if="text==0" style="color: #1890ff">在职</span>
          <span v-else style="color: red">离职</span>
        </template>
        <template slot="action" slot-scope="text,record">
          <a-tooltip placement="top" title="角色配置" autoAdjustOverflow>
            <a-button type="primary" size="small" icon="user" @click="selectOne(record)"/>
          </a-tooltip>
          <a-divider type="vertical"/>
          <a-popconfirm v-if="record.isQuit===0"
              title="确定离职?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="() => layoffStaff(record.id)">
            <a-tooltip placement="top" title="离职" autoAdjustOverflow>
              <a-button type="info" size="small" icon="user-delete"/>
            </a-tooltip>
          </a-popconfirm>
          <a-popconfirm v-if="record.isQuit===1"
                        title="确定恢复入职?"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="() => unLayOffStaff(record.id)">
            <a-tooltip placement="top" title="恢复入职" autoAdjustOverflow>
              <a-button type="info" size="small" icon="user-add"/>
            </a-tooltip>
          </a-popconfirm>
          <a-divider type="vertical"/>
           <a-popconfirm
               title="确定删除?"
               ok-text="确定"
               cancel-text="取消"
               @confirm="() => onDelete(record.id)">
             <a-icon slot="icon" type="question-circle-o" style="color: red"/>
             <a-button type="danger" size="small" icon="delete"/>
           </a-popconfirm>
        </template>
      </a-table>
      <pageination  style="float: right"
                    :paginationList="paginationList"
                    @paging="paging">
      </pageination>
      <a-modal title="设置角色"
               :visible="modalRoleVisible"
               :confirm-loading="confirmLoading"
               okText="确定"
               cancelText="取消"
               @ok="handleSetRoleOk"
               @cancel="handleSetRoleCancel"
               width="500px">
        <a-form class="set-role-form"
                label-width="80px">
          <a-form-item label="用户名">
            <a-row>
              <a-col :span="24">
                <a-input
                    disabled
                    :value="selectRecord.name"
                    type="text"
                    key="0"
                    allowClear>
                </a-input>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item label="角色列表">
            <a-checkbox :indeterminate="isIndeterminate" :checked="checkAll" @change="onCheckAllChange">
              全选
            </a-checkbox>
            <div style="margin: 15px 0"></div>
            <a-row>
              <a-col :span="24">
                <a-checkbox-group v-model="checkedRoleList" @change="handleCheckedChange">
                  <a-col :span="24">
                    <a-checkbox style="margin-left:20px;margin-top: 10px"
                                v-for="role in allRoles"
                                :key="role.id"
                                :disabled="role.roleName==='公司负责人'||role.roleName==='供应商负责人'"
                                :value="role.id">
                      {{ role.roleName }}
                    </a-checkbox>
                  </a-col>
                </a-checkbox-group>
              </a-col>
            </a-row>
          </a-form-item>
        </a-form>
      </a-modal>
      <div>
        <add-staff :add-visible="addVisible" @changeAddVisible="changeAddVisible"></add-staff>
      </div>
    </div>
  </div>
</template>

<script>
import {dateFormat, isMobile} from "@/utils/common";
import Pageination from "@/components/pageination";
import RestaurantSelector from "@/components/restaurantSelector";
import AddStaff from "@/views/staff/addStaff";

export default {
  name: "index",
  components: {AddStaff, RestaurantSelector, Pageination},
  inject:["reload"],
  data(){
    return{
      selectedRowId:undefined,
      searchForm: this.$form.createForm(this, {name: 'search_form'}),
      addVisible:false,
      chooseOption:{key:'',label:''},
      queryParam:{
        name:'',
        phone:'',
        restaurantId:undefined,
        status:undefined,
      },
      paginationList:{
        size:10,
        current:1,
        total:0,
        pageSizeOptions: ['10', '20', '30', '40', '50','100'],
      },
      restaurantName:undefined,
      dataSource:[],
      columns:[
        {
          title: '姓名',
          dataIndex: 'name',
          key:'name',
          width: '70px',
          align: 'center',
        },
        {
          title: '性别',
          dataIndex: 'sex',
          key:'sex',
          width: '10px',
          align: 'center',
          scopedSlots:{customRender:'sex'},
        },
        {
          title: '手机号',
          dataIndex: 'phone',
          key:'phone',
          width: '80px',
          align: 'center',
        },
        {
          title: '所属门店',
          dataIndex: 'restaurantName',
          key:'restaurantName',
          width: '140px',
          align: 'center',
        },
        {
          title: '角色',
          dataIndex: 'roleName',
          key:'roleName',
          width: '140px',
          align: 'center',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key:'createTime',
          width: '120px',
          align: 'center',
          scopedSlots: { customRender: 'createTime' },
        },
        {
          title: '登陆状态',
          dataIndex: 'status',
          key:'status',
          width: '60px',
          align: 'center',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '离职状态',
          dataIndex: 'isQuit',
          key:'isQuit',
          width: '60px',
          align: 'center',
          scopedSlots: { customRender: 'isQuit' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          key:'action',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],

      selectRecord: {},
      modalRoleVisible:false,

      isIndeterminate:false,
      checkAll:false,
      checkedRoleList:[], //用户的角色列表
      allRoles:[],
      confirmLoading: false,
    }
  },
  created() {
    let staffQuery = {
      current:this.paginationList.current,
      size:this.paginationList.size,
      restaurantId:this.queryParam.companyId,
      status:this.queryParam.status,
      phone:this.queryParam.phone,
      name:this.queryParam.name,
    };
    this.getCompanyStaffList(staffQuery);
  },
  methods:{
    customRow(record){
      return {
        on:{
          click:(event)=>{
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    showAddModal(){
      this.addVisible = true;
    },
    restaurantSelectChange(value){
      this.chooseOption = value;
      this.queryParam.restaurantId = value.key;
    },
    handleLoginStateSelectChange(value){
      this.queryParam.status = value;
    },
    async handleSearch() {
      if(this.queryParam.phone!==''){
        if (!isMobile(this.queryParam.phone)) {
          this.$message.error('请输入正确的手机号码')
          return false;
        }
      }
      let staffQuery = {
        current: this.paginationList.current,
        size: this.paginationList.size,
        restaurantId: this.queryParam.restaurantId,
        status: this.queryParam.status,
        phone: this.queryParam.phone,
        name: this.queryParam.name,
      };
      this.getCompanyStaffList(staffQuery);
    },
    paging(parameter) {
      let staffQuery = {
        current:parameter.current,
        size:parameter.size,
        restaurantId:this.queryParam.restaurantId,
        status:this.queryParam.status,
        phone:this.queryParam.phone,
        name:this.queryParam.name,
      };
      this.getCompanyStaffList(staffQuery);
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
    resetSearchForm(){
      this.restaurantName=undefined;
      this.queryParam = {
        name:'',
        phone:'',
        restaurantId:undefined,
        status:undefined,
      }
      this.chooseOption={key:'',label:''};
      let staffQuery = {
        current:1,
        size:10,
        restaurantId:this.queryParam.restaurantId,
        status:this.queryParam.status,
        phone:this.queryParam.phone,
        name:this.queryParam.name,
      };
      this.getCompanyStaffList(staffQuery);
    },
    getCompanyStaffList(queryParams){
      this.$api.staff.selectListPage(queryParams).then(res=>{
        if(res.code===200){
          this.dataSource = res.data.records;
          this.paginationList.total = res.data.total;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    onDelete(key) {
      let delIds = {
        ids:key,
      };
      this.$api.staff.removeById(delIds).then(res=>{
        if(res.code===200){
          this.$message.success("删除成功！")
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    unLayOffStaff(key){
      let delIds = {
        ids:key,
      };
      this.$api.staff.unLayOffById(delIds).then(res=>{
        if(res.code===200){
          this.$message.success("操作成功！")
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    layoffStaff(key){
      let delIds = {
        ids:key,
      };
      this.$api.staff.layoffById(delIds).then(res=>{
        if(res.code===200){
          this.$message.success("操作成功！")
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    selectOne(record){
      this.selectRecord = record;
      this.modalRoleVisible = true;
      this.getRoles(record.id);
    },
    async getRoles(staffId){
      const res = await this.$api.role.selectByStaffId(staffId);
      if(res.code===200){
        const roleList = res.data.allRoles;
        const currentStaffType = this.$store.getters.userInfo.staffType;
        if(currentStaffType===0){
          this.allRoles = roleList.filter(e=>e.roleName.indexOf("超级管理员")===-1)
              .filter(e=>e.roleName.indexOf("供应商")===-1);
        }
        if(currentStaffType===1){
          this.allRoles = roleList.filter(e=>e.roleName.indexOf("配送员")!==-1
              ||e.roleName.indexOf("供应商负责人")!==-1);
        }
        this.checkedRoleList = res.data.userRoleIds;
        this.checkAll = this.allRoles.length===this.checkedRoleList;
        this.isIndeterminate = this.checkedRoleList.length>0&&this.checkedRoleList.length<this.allRoles.length;
      }else{
        if(res.msg){
          this.$message.error(res.msg);
        }
      }
    },
    //是否全选
    onCheckAllChange(e){
      this.checkedRoleList = e.target.checked?this.allRoles.map(item=>item.id):[];
      this.isIndeterminate = false;
      this.checkAll = e.target.checked;
    },
    //角色列表项中发生改变的监听
    handleCheckedChange(checkedList){
      this.checkedRoleList = checkedList;
      this.checkAll = this.checkedRoleList.length === this.allRoles.length && this.allRoles.length>0;
      this.isIndeterminate = this.checkedRoleList.length > 0 && this.checkedRoleList.length<this.allRoles.length;
    },
    async handleSetRoleOk() {
      this.confirmLoading = true;
      const userId = this.selectRecord.id;
      const roleIds = this.checkedRoleList.join(",");
      const params = {
        userId: userId,
        roleIds: roleIds,
      }
      const res = await this.$api.role.addUserRoleList(params);
      if (res.code === 200 && res.success) {
        this.$message.success("分配角色成功！")
        this.reload();
      }else{
        if(res.msg){
          this.$message.error(res.msg);
        }
      }
      this.confirmLoading = false;
    },
    //重置角色分配信息
    handleSetRoleCancel(){
      this.modalRoleVisible = false;
      this.allRoles = [];
      this.checkedRoleList = [];
      this.isIndeterminate = false;
      this.checkAll = false;
    },
    changeAddVisible(value){
      this.addVisible = value;
    },
  }
}
</script>

<style lang="scss" scoped>

.staff{
  ::v-deep .ant-table-thead > tr > th{
    padding: 8px;
  }
  ::v-deep .ant-table-tbody > tr > td{
    padding: 8px;
  }
  .item {
  }
  .add-staff-btn{
    margin: 0 0 10px 0;
  }
  .dataList{
    .select-form{
      margin: 0px 0px 10px 0px;
    }
    ::v-deep .clickRowStyle{
      background-color:#e6f7ff
    }
  }
}
</style>