<template>
  <div class="bindSupplier">
    <div class="item dataList">
      <a-row>
        <a-col :span="24">
          <a-row class="select-form">
            <a-form layout="inline" :form="searchSupplierForm">
              <a-form-item>
                <restaurant-selector ref="restaurantSelector" :current-value="chooseOption"
                                     @restaurantSelectChange="restaurantSelectChange"/>
              </a-form-item>
              <a-form-item>
                <a-col :span="8">
                  <a-button
                      size="large"
                      type="primary"
                      @click="handleSearch">查询
                  </a-button>
                </a-col>
                <a-col :span="8" offset="8">
                  <a-button size="large" @click="resetSearchSupplierForm">重置</a-button>
                </a-col>
              </a-form-item>
            </a-form>
          </a-row>
        </a-col>
        <a-col :span="24">
          <div class="bind-supplier-btn">
            <a-button size="large"
                      type="primary"
                      @click="showBindModal">绑定供应商</a-button>
          </div>
        </a-col>
      </a-row>
      <a-table bordered
               :rowKey="(record,index)=>{return index}"
               :pagination="false"
               :data-source="dataSource"
               :columns="columns"
               :customRow="customRow"
               :row-class-name="setRowClassName"
               style="width: 100%;">
        <template slot="address" slot-scope="text,record">
          <span>{{record.provinceName}}-{{record.cityName}}
            -{{record.districtName}}-{{text}}
          </span>
        </template>
        <span slot="action" slot-scope="text,record">
            <a-popconfirm title="确定解除绑定?"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="() => unBind(record)">
              <a-icon slot="icon" type="question-circle-o" style="color: red"/>
              <a-button type="danger" size="small" icon="minus-circle"/>
            </a-popconfirm>
        </span>
      </a-table>
      <pageination  style="float: right"
                    :paginationList="paginationList"
                    @paging="paging">
      </pageination>
      <div>
        <add-bind :add-visible="addVisible" @changeAddVisible="changeAddVisible"></add-bind>
      </div>
    </div>
  </div>
</template>

<script>
import RestaurantSelector from "@/components/restaurantSelector";
import AddBind from "@/views/supplier/addBind";
import Pageination from "@/components/pageination";

export default {
  name: "bind",
  components: {Pageination, AddBind, RestaurantSelector},
  inject:["reload"],
  data(){
    return{
      selectedRowId:undefined,
      searchSupplierForm: this.$form.createForm(this, {name: 'search_supplier_form'}),
      addVisible:false,
      chooseOption:{key:'',label:''},
      queryParam:{
        restaurantId:undefined,
      },
      paginationList:{
        size:10,
        current:1,
        total:0,
        pageSizeOptions: ['10', '20', '30', '40', '50','100'],
      },
      dataSource:[],
      columns:[
        {
          title: '门店名称',
          dataIndex: 'restaurantName',
          key:'restaurantName',
          width: '120px',
          align: 'center',
        },
        {
          title: '供应商名称',
          dataIndex: 'name',
          key:'name',
          width: '140px',
          align: 'center',
        },
        {
          title: '供应商地址',
          dataIndex: 'address',
          key:'address',
          width: '80px',
          align: 'center',
          scopedSlots: { customRender: 'address' },
        },
        {
          title: '联系电话',
          dataIndex: 'phone',
          key:'phone',
          width: '80px',
          align: 'center',
        },
        {
          title: '负责人',
          dataIndex: 'leaderName',
          key:'leaderName',
          width: '140px',
          align: 'center',
        },
        {
          title: '负责人电话',
          dataIndex: 'leaderPhone',
          key:'leaderPhone',
          width: '140px',
          align: 'center',
        },
        {
          title: '介绍',
          dataIndex: 'introduce',
          key:'introduce',
          width: '140px',
          align: 'center',
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key:'remark',
          width: '100px',
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'action',
          key:'action',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],

    }
  },
  created() {
    let query = {
      current:this.paginationList.current,
      size:this.paginationList.size,
      restaurantId:this.queryParam.restaurantId,
    };
    this.getSupplierOfComp(query);
  },
  methods:{
    customRow(record){
      return {
        on:{
          click:(event)=>{
            this.selectedRowId = record.rsId;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.rsId===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    showBindModal(){
      this.addVisible = true;
    },
    changeAddVisible(value){
      this.addVisible = value;
    },
    async handleSearch() {
      let query = {
        current: this.paginationList.current,
        size: this.paginationList.size,
        restaurantId: this.queryParam.restaurantId,
      };
      this.getSupplierOfComp(query);
    },
    restaurantSelectChange(value){
      this.chooseOption = value;
      this.queryParam.restaurantId = value.key;
    },
    paging(parameter) {
      let query = {
        current:parameter.current,
        size:parameter.size,
        restaurantId:this.queryParam.restaurantId,
      };
      this.getSupplierOfComp(query);
    },
    resetSearchSupplierForm(){
      this.queryParam = {
        restaurantId:undefined,
      }
      this.chooseOption={key:'',label:''};
      let resQuery = {
        current:1,
        size:10,
        restaurantId:this.queryParam.restaurantId,
      };
      this.getSupplierOfComp(resQuery);
    },
    getSupplierOfComp(queryParams){
      this.$api.supplier.selectListOfComp(queryParams).then(res=>{
        if(res.code===200){
          this.dataSource = res.data.records;
          this.paginationList.total = res.data.total;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    unBind(record) {
      let params = {
        restaurantId:record.restaurantId,
        supplierIds:record.id,
      };
      this.$api.restaurant.unBindSupplier(params).then(res=>{
        if(res.code===200){
          this.$message.success("解绑成功！")
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.bindSupplier{
  ::v-deep .ant-table-thead > tr > th{
    padding: 8px;
  }
  ::v-deep .ant-table-tbody > tr > td{
    padding: 8px;
  }
  .item {
  }
  .bind-supplier-btn{
    margin: 0 0 10px 0;
  }
  .dataList{
    .select-form{
      margin: 0px 0px 10px 0px;
    }
    ::v-deep .clickRowStyle{
      background-color:#e6f7ff
    }
  }
}
</style>