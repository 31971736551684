<template>
  <div class="receiveDetail">
    <a-modal
        :visible="visible"
        :confirm-loading="confirmLoading"
        @cancel="handleCancel"
        :footer="null"
        width="1250px">
      <template slot="title">
        <span style="font-size: 20px;font-weight: bolder;">验收商品</span>
        <a-divider type="vertical" style="height: 40px;"/>
        <span style="font-size: 16px;font-weight: bolder">验收人:</span>
        <span style="color: red;font-weight: bolder;font-size: 16px;">
          {{receiveOrder.receiveStaffName}}</span>
      </template>
      <a-row>
        <a-col :span="24" style="font-weight: bold;">
          <label>所属订单号：</label>
          <span style="color: #92989B">{{receiveOrder.purchaseOrderCode}}</span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="12" style="font-weight: bold;">
          <label>验收单号：</label>
          <span style="color: #92989B">{{receiveOrder.code}}</span>
        </a-col>
        <a-col :span="8" :offset="4" style="font-weight: bold;">
          <label>验收时间：</label>
          <span style="color: #92989B">{{getFormatDate(receiveOrder.receiveTime)}}</span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="12" style="font-weight: bold;">
          <label>采购方：</label>
          <span style="color: #92989B">
            {{receiveOrder.companyName}}({{receiveOrder.restaurantName}})
          </span>
        </a-col>
        <a-col :span="8" :offset="4" style="font-weight: bold;">
          <label>供应商：</label>
          <span style="color: #92989B">{{receiveOrder.supplierName}}</span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="24" style="font-weight: bold;">
          <div class="collapseClass">
            <a-collapse v-model="activeKey" style="width: 800px">
              <a-collapse-panel :key="0" :show-arrow="false">
                <template slot="header">
                  <span style="font-size: 16px">订单商品信息</span>
                </template>
                <div v-if="orderGoodsInfo">
                  <div v-for="(item,index) in orderGoodsInfo.productList" :key="index">
                    <a-col :span="6">
                      <div style="padding: 0 0 10px 10px">
                        <span>商品名称：{{item.productName}}</span>
                      </div>
                    </a-col>
                    <a-col :span="6">
                      <div style="padding: 0 0 10px 10px">
                        <span>数量：{{item.num}}</span>
                      </div>
                    </a-col>
                    <a-col :span="6">
                      <div style="padding: 0 0 10px 10px">
                        <span>单价：￥{{getFormatMoney(item.unitPrice,true)}}{{unitSuffix(item.unit)}}</span>
                      </div>
                    </a-col>
                    <a-col :span="6">
                      <div style="padding: 0 0 10px 10px">
                        验收状态：
                        <span v-if="item.status===3" style="color: limegreen">已验收</span>
                        <span v-else style="color: silver">待验收</span>
                      </div>
                    </a-col>
                  </div>
                  <div>
                    <span style="font-size: 15px">订单总价：￥{{getFormatMoney(orderGoodsInfo.totalPrice,true)+"元"}}</span>
                  </div>
                </div>
                <div v-else>暂无数据</div>
              </a-collapse-panel>
            </a-collapse>
          </div>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <span style="font-weight: bold;font-size: 16px">验收详细信息<a-icon type="down"/></span>
        <div class="receive-detail-table-info">
          <a-table bordered
                   :scroll="{x:1200}"
                   :loading="receiveTableSearchLoading"
                   rowKey="id"
                   :data-source="receiveOrderDetailList"
                   :customRow="customRow"
                   :row-class-name="setRowClassName"
                   :columns="columns"
                   style="width: 100%;">
            <template slot="num" slot-scope="text">
              <span style="color:#1890ff;font-size: 20px">{{ text }}</span>
            </template>
            <template slot="receiveRemarkPic" slot-scope="text">
              <div v-if="text">
                <div>
                  <a-popover title="验收图片" placement="top">
                    <template slot="content">
                      <img v-for=" (item,index) in text.split(',')" :key="index" :src="item" height="256px" width="256px"/>
                    </template>
                    <span style="color: #1890ff">验收图片</span>
                  </a-popover>
                </div>
              </div>
            </template>
            <template slot="totalNum" slot-scope="text">
              <span style="color: silver">{{text}}</span>
            </template>
            <template slot="unitPrice" slot-scope="text,record">
                    <span style="color: silver">
                      ￥{{ record.unit ? getFormatMoney(text,true) + "元/" + record.unit
                        :getFormatMoney(text,true) + "元" }}
                    </span>
            </template>
            <template slot="totalPrice" slot-scope="text">
                    <span style="color: silver">
                      ￥{{
                        getFormatMoney(text,true)+"元"
                      }}
                    </span>
            </template>

            <template slot="receiveUnitPrice" slot-scope="text,record">
                    <span style="color: #1890ff">
                      ￥{{ record.unit ? getFormatMoney(text,true) + "元/" + record.unit
                        :getFormatMoney(text,true) + "元" }}
                    </span>
            </template>
            <template slot="receiveTotalPrice" slot-scope="text">
                    <span style="color: #1890ff">
                      ￥{{
                        getFormatMoney(text,true)+"元"
                      }}
                    </span>
            </template>

            <template slot="returnTotalPrice" slot-scope="text">
                    <span style="color: red">
                      ￥{{
                        getFormatMoney(text,true)+"元"
                      }}
                    </span>
            </template>
            <template slot="receiveNum" slot-scope="text,record">
              <span v-if="record.receiveNum<record.totalNum" style="color: red">{{text}}</span>
              <span v-if="record.receiveNum===record.totalNum" style="color: #1890ff">{{text}}</span>
            </template>
            <template slot="returnNum" slot-scope="text">
              <span v-if="text" style="color: red">{{text}}</span>
              <span v-else style="color: silver">无退货</span>
            </template>
            <template slot="receiveTime" slot-scope="text">
              <span>{{ getFormatDate(text) }}</span>
            </template>
            <template slot="hasRefund" slot-scope="text">
              <span v-if="text==0" style="color: #108ee9">没有</span>
              <span v-else-if="text==1" style="color: red">有</span>
            </template>
          </a-table>
        </div>
      </a-row>
      <br>
      <br>
    </a-modal>
  </div>
</template>

<script>
import {dateFormat, formatMoney} from "@/utils/common";

export default {
  name: "receiveDetail",
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    receiveDetail:{
      type:Object,
    },
  },
  data(){
    return{
      customVisible:this.visible,
      confirmLoading:false,
      userType:this.$store.getters.userInfo.staffType,

      receiveTableSearchLoading:false,
      selectedRowId:undefined,
      buttonWidth: 70,

      receiveOrderDetailList:undefined,
      returnsList:undefined,
      orderGoodsInfo:undefined,

      returnStatus:false,
      receiveOrder:{
        taskCode: '暂无数据',
        orderCode: '暂无数据',
        companyName: '暂无数据',
        restaurantName: '暂无数据',
        supplierName: '暂无数据',
        unit: '暂无数据',
        num: undefined,
        unitPrice: undefined,
        totalPrice: undefined,
        productName: '暂无数据',
        purchaseOrderId:undefined,
        receiveStaffName:'暂无数据'
      },
      columns:[
        {
          title: '商品名称',
          dataIndex: 'productName',
          key:'productName',
          width: '100px',
          align: 'center',
        },
        {
          title: '验收图片',
          dataIndex: 'receiveRemarkPic',
          key:'receiveRemarkPic',
          width: '80px',
          align: 'center',
          scopedSlots: {customRender: 'receiveRemarkPic'},
        },
        {
          title: '采购数量',
          dataIndex: 'totalNum',
          key:'totalNum',
          width: '50px',
          align: 'center',
          scopedSlots: {customRender: 'totalNum'},
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key:'unit',
          width: '50px',
          align: 'center',
        },
        {
          title: '采购单价',
          dataIndex: 'unitPrice',
          key:'unitPrice',
          width: '100px',
          align: 'center',
          scopedSlots: {customRender: 'unitPrice'},
        },
        {
          title: '采购总价',
          dataIndex: 'totalPrice',
          key:'totalPrice',
          width: '100px',
          align: 'center',
          scopedSlots: {customRender: 'totalPrice'},
        },
        {
          title: '验收数量',
          dataIndex: 'receiveNum',
          key:'receiveNum',
          width: '50px',
          align: 'center',
          scopedSlots: {customRender: 'receiveNum'},
        },
        {
          title: '验收单价',
          dataIndex: 'receiveUnitPrice',
          key:'receiveUnitPrice',
          width: '100px',
          align: 'center',
          scopedSlots: {customRender: 'receiveUnitPrice'},
        },
        {
          title: '验收总价',
          dataIndex: 'receiveTotalPrice',
          key:'receiveTotalPrice',
          width: '100px',
          align: 'center',
          scopedSlots: {customRender: 'receiveTotalPrice'},
        },
        {
          title: '是否退货',
          dataIndex: 'hasRefund',
          key:'hasRefund',
          width: '50px',
          align: 'center',
          scopedSlots: {customRender: 'hasRefund'},
        },
        {
          title: '退货数量',
          dataIndex: 'returnNum',
          key:'returnNum',
          width: '50px',
          align: 'center',
          scopedSlots: {customRender: 'returnNum'},
        },
        {
          title: '退货总价',
          dataIndex: 'returnTotalPrice',
          key:'returnTotalPrice',
          width: '100px',
          align: 'center',
          scopedSlots: {customRender: 'returnTotalPrice'},
        },
        {
          title: '验收员',
          dataIndex: 'receiveStaffName',
          key:'receiveStaffName',
          width: '100px',
          align: 'center',
        },
        {
          title: '验收时间',
          dataIndex: 'receiveTime',
          key:'receiveTime',
          width: '100px',
          align: 'center',
          scopedSlots: {customRender: 'receiveTime'},
        },
        {
          title: '备注',
          dataIndex: 'receiveRemark',
          key:'receiveRemark',
          width: '80px',
          align: 'center',
        },
      ],
      activeKey:['0','1'],
    }
  },
  methods:{
    customRow(record){
      return {
        on:{
          click:(event)=>{
            //console.log(event);
            //console.log("点击了：",record.id)
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    handleCancel(){
      this.customVisible = false;
      this.returnStatus=false;
      this.activeKey = ['0','1'];
      this.receiveOrder = {
        //receive
        receiveNum: undefined,
        receiveUnitPrice: undefined,
        receiveTotalPrice: undefined,
        receiveStaffId: undefined,
        receiveStaffName: '暂无数据',
        receiveTime: undefined,
        receiveRemark: undefined,
        receiveRemarkPic: undefined,
        //return
        hasReturn: false,
        returnNum: undefined,
        currentUnitPrice: undefined,
        returnTotalPrice: undefined,
        returnsWay: undefined,
        remark: undefined,
        reasonPic: undefined,
        reason: undefined,
      }
      this.$emit("changeReceiveOrderDetail",undefined);
      this.$emit("changeReceiveDetailVisible",this.customVisible);
    },
    unitSuffix(unit){
      if(unit){
        return "元/"+unit;
      }else {
        return "元";
      }
    },
    changeActiveKey(key){
      //console.log(key);
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
    getFormatMoney(money,isCarryBit){
      const result = formatMoney(money,2,".",",",isCarryBit);
      return result;
    },
    returnNumChange(value){
      this.receiveDetail.returnNum = value;
      this.receiveDetail.receiveNum = this.num-value;
    },
    handleReceiveCancelDetailPic(){
      this.receivePreviewVisible = false;
    },
  },
  watch:{
    receiveDetail(newValue){
      if(newValue){
        //console.log("newValue:",newValue);
        this.receiveOrder = newValue.receiveOrderVO;
        this.receiveOrderDetailList = newValue.receiveOrderDetailList;
        this.returnsList = newValue.returnsList;
        this.returnStatus = this.receiveDetail.hasReturn;
        const orderId = this.receiveOrder.purchaseOrderId;
        setTimeout(()=>{
          this.$api.purchaseOrder.orderGoodsInfo(orderId).then(res=>{
            if(res.code===200&&res.success){
              this.orderGoodsInfo = res.data;
              //console.log("goodsInfo",this.orderGoodsInfo)
            }
          })
        },0)
      }
    },
    returnStatus(newValue){
      if(newValue){
        this.activeKey = ['0','1','2']
      }
    },
  },
  computed:{
    resultUnitPrice(){
      const result = this.getFormatMoney(this.receiveDetail.receiveUnitPrice,true);
      return result;
    },
    resultTotalPrice(){
      const result = this.getFormatMoney((this.receiveDetail.receiveNum *this.receiveDetail.receiveUnitPrice),
          true);
      return result;
    },

    returnUnitPrice(){
      const result = this.getFormatMoney(this.receiveDetail.currentUnitPrice,true);
      return result;
    },
    returnTotalPrice(){
      const result = this.getFormatMoney((this.receiveDetail.returnNum *this.receiveDetail.currentUnitPrice),
          true);
      return result;
    },

  }
}
</script>

<style lang="scss" scoped>
.receiveDetail{
}
::v-deep .ant-table-thead > tr > th {
  padding: 8px;
}

::v-deep .ant-table-tbody > tr > td {
  padding: 8px;
}
::v-deep .clickRowStyle{
  background-color:#e6f7ff
}

</style>