<template>
  <div>
    <a-row class="companyBasicInfo">
      <span style="float: right;font-size: 16px;">注册时间：{{ getFormatDate(createdTime) }}</span>
      <div class="basic-info">
        <div style="display: flex;flex-direction: column;align-items: center;">
              <span style="font-size: 20px;color: #92989B;margin:6px">
                {{ companyName }}
                <!--                <span v-if="companyInfo.companyVO.status===0">(未审核)</span>-->
                <!--                <span v-if="companyInfo.companyVO.status===1" style="color: limegreen">(审核通过)</span>-->
                <!--                <span v-if="companyInfo.companyVO.status===2" style="color: red">(审核不通过)</span>-->
              </span>
          <a-avatar v-if="logoPicture"
                    shape="square"
                    :size="80"
                    :src="logoPicture"/>
          <a-avatar v-else
                    shape="square"
                    :size="80"
                    :src="require('../../assets/images/user/defaultHead.jpeg')"/>
        </div>
        <div style="display: flex;flex-direction: column;justify-content: space-around;margin: 0px 40px">
          <div>
            <span style="font-weight: bolder;font-size: 16px">公司地址：</span>
            <span>{{ provinceName }}-{{ cityName }}-{{ districtName }}-{{ address }}</span>
          </div>
          <div>
            <span style="font-weight: bolder;font-size: 16px">公司类型：</span>
            <span v-if="LoginStaffType===0">商户/餐厅</span>
            <span v-if="LoginStaffType===1">供应商</span>
          </div>
        </div>
        <div style="display: flex;flex-direction: column;justify-content: space-around;margin: 0px 40px">
          <div>
            <span style="font-weight: bolder;font-size: 16px">公司负责人：</span>
            <span>{{ leaderName }}</span>
          </div>
          <div>
            <span style="font-weight: bolder;font-size: 16px">公司负责人电话：</span>
            <span>{{ leaderPhone }}</span>
          </div>
        </div>
        <div style="display: flex;flex-direction: column;justify-content: space-around;margin: 0px 40px">
          <div>
            <span style="font-weight: bolder;font-size: 16px">公司法人：</span>
            <span>{{ legalPersonName }}</span>
          </div>
          <div>
            <span style="font-weight: bolder;font-size: 16px">公司总人数：</span>
            <span>{{ staffCount }}人</span>
          </div>
        </div>
      </div>
      <a-button style="float: right;margin: 8px" type="primary" @click="changeCompanyInfo">修改</a-button>
      <a-divider/>
    </a-row>
    <a-modal title="修改公司信息"
             :visible="compEditVisible"
             :confirm-loading="confirmLoading"
             okText="确定"
             cancelText="取消"
             @ok="handleOk"
             @cancel="handleCancel"
             width="800px">
      <div class="containerClass">
        <div class="updateLeftContainClass">
          <a-row>
            <div class="updateItemStyle">
              <a-col span="8">
                <span style="margin-left: 15px;font-weight: bolder"><span style="color: red">*</span>公司名称：</span>
              </a-col>
              <a-col span="16">
                <a-input style="margin-left: 10px;" size="small" placeholder="请输入公司名称" @change="updateCompName"/>
              </a-col>
            </div>
          </a-row>
          <a-row>
            <div class="updateItemStyle">
              <a-col span="8">
                <span style="margin-left: 15px;font-weight: bolder"><span style="color: red">*</span>公司法人：</span>
              </a-col>
              <a-col span="16">
                <a-input style="margin: 0px 10px;" size="small" placeholder="请输入法人名称" @change="updateCompLegalName"/>
              </a-col>
            </div>
          </a-row>
          <a-row>
            <div class="updateItemStyle">
              <a-col span="8">
                <span style="font-weight: bolder;"><span style="color: red">*</span>公司负责人：</span>
              </a-col>
              <a-col span="16">
                <a-input style="margin: 0px 10px;" size="small" placeholder="请输入负责人名称" @change="updateAdminName"/>
              </a-col>
            </div>
          </a-row>
          <a-row>
            <div class="updateItemStyle">
              <a-col span="8">
                <span style="font-weight: bolder"><span style="color: red">*</span>负责人电话：</span>
              </a-col>
              <a-col span="16">
                <a-input :disabled="true" :defaultValue="this.leaderPhone" style="margin: 0px 10px;"
                         size="small" @change="updateAdminPhone"/>
              </a-col>
            </div>
          </a-row>
          <a-row>
            <div class="updateItemStyle">
              <a-col span="8">
                <span style="margin-left: 15px;font-weight: bolder"><span style="color: red">*</span>公司地址：</span>
              </a-col>
              <a-col span="16">
                <a-cascader style="margin: 0px 10px;"
                            :field-names="{label:'name',value:'name',children:'children'}"
                            placeholder="请选择省市区"
                            :options="residences"
                            size="small"
                            @change="handleCompBasicAddrChange"
                />
              </a-col>
            </div>
          </a-row>
          <a-row>
            <div class="updateItemStyle">
              <a-col span="8">
                <span style="margin-left: 15px;font-weight: bolder"><span style="color: red">*</span>详细地址：</span>
              </a-col>
              <a-col span="16">
                <a-input style="margin: 0px 10px;" size="small" @change="updateCompAddress"/>
              </a-col>
            </div>
          </a-row>
        </div>

        <div class="updateRightContainClass">
          <a-row>
            <div class="uploadIdCardStyle">
              <a-col span="12">
                <span style="margin-left: 15px;font-weight: bolder"><span style="color: red">*</span>上传身份证：</span>
              </a-col>
              <a-col span="24">
                <div class="upload-items">
                  <a-upload
                      action="/auth/upLoad"
                      :headers=headers
                      name="file"
                      :data=uploadParams
                      list-type="picture-card"
                      :before-upload="handleBeforeUpload"
                      @preview="handlePreview"
                      @change="handleChange"
                      :remove="handleRemoveIdCarF"
                  >
                    <div v-if="uploadIdCardFState" @click="changeUploadIdCardFState">
                      <a-icon type="plus"/>
                      <div class="ant-upload-text">
                        国徽面
                      </div>
                    </div>
                  </a-upload>
                  <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                    <img alt="国徽面" style="width: 100%" :src="previewCardFImage"/>
                  </a-modal>
                  <a-upload
                      action="/auth/upLoad"
                      :headers=headers
                      name="file"
                      :data=this.uploadParams
                      list-type="picture-card"
                      :before-upload="handleBeforeUpload"
                      @preview="handlePreview"
                      @change="handleChange"
                      :remove="handleRemoveIdCarS"
                  >
                    <div v-if="uploadIdCardSState" @click="changeUploadIdCardSState">
                      <a-icon type="plus"/>
                      <div class="ant-upload-text">
                        肖像面
                      </div>
                    </div>
                  </a-upload>
                  <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                    <img alt="肖像面" style="width: 100%" :src="previewCardSImage"/>
                  </a-modal>
                  <a-upload
                      action="/auth/upLoad"
                      :headers=headers
                      name="file"
                      :data=this.uploadParams
                      list-type="picture-card"
                      :before-upload="handleBeforeUpload"
                      @preview="handlePreview"
                      @change="handleChange"
                      :remove="handleRemoveIdCarT">
                    <div v-if="uploadIdCardTState" @click="changeUploadIdCardTState">
                      <a-icon type="plus"/>
                      <div class="ant-upload-text">
                        手持人面照
                      </div>
                    </div>
                  </a-upload>
                  <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                    <img alt="手持人面照" style="width: 100%" :src="previewCardTImage"/>
                  </a-modal>
                </div>
              </a-col>
            </div>
          </a-row>
          <div style="display: flex;">
            <a-row>
              <div class="uploadIdCardStyle">
                <a-col span="24">
                  <span style="margin-left: 15px;font-weight: bolder"><span style="color: red">*</span>上传营业执照：</span>
                </a-col>
                <a-col span="12">
                  <div class="upload-items">
                    <a-upload action="/auth/upLoad"
                              :headers=headers
                              name="file"
                              :data=this.uploadParams
                              list-type="picture-card"
                              :before-upload="handleBeforeUpload"
                              :remove="handleRemoveLicense"
                              @preview="handlePreview"
                              @change="handleChange">
                      <div v-if="uploadLicenseState" @click="changeUploadLicenseState">
                        <a-icon type="plus"/>
                        <div class="ant-upload-text">
                          营业执照
                        </div>
                      </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                      <img alt="营业执照" style="width: 100%" :src="licenseImage"/>
                    </a-modal>
                  </div>
                </a-col>
              </div>
            </a-row>
            <a-row>
              <div class="uploadIdCardStyle">
                <a-col span="24">
                  <span style="margin-left: 15px;font-weight: bolder"><span style="color: red">*</span>上传公司LOGO：</span>
                </a-col>
                <a-col span="12">
                  <div class="upload-items">
                    <a-upload
                        action="/auth/upLoad"
                        :headers=headers
                        name="file"
                        :data=this.uploadParams
                        list-type="picture-card"
                        :before-upload="handleBeforeUpload"
                        @preview="handlePreview"
                        @change="handleChange"
                        :remove="handleRemoveHeadPic">
                      <div v-if="uploadHeadPicState" @click="changeUploadHeadPicState">
                        <a-icon type="plus"/>
                        <div class="ant-upload-text">
                          公司LOGO
                        </div>
                      </div>
                    </a-upload>
                    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                      <img alt="公司头像" style="width: 100%" :src="headPicImage"/>
                    </a-modal>
                  </div>
                </a-col>
              </div>
            </a-row>
          </div>
          <a-row>
            <div style="margin-left: 40px">
              <a-col span="8">
                <span style="margin-left: 15px;font-weight: bolder"><span style="color: red">*</span>营业执照号：</span>
              </a-col>
              <a-col span="16">
                <a-input size="small" placeholder="请输入营业执照号" @change="updateBno"/>
              </a-col>
            </div>
          </a-row>
        </div>
      </div>

      <div style="display: flex;justify-content: start">
        <span style="font-weight: bolder;margin-left: 5px">公司简介：</span>
        <textarea style="width: 90%;border: #dfe1e2 solid;outline-color:#59acff;border-radius: 3px"
                  placeholder="公司基本信息介绍" :rows="4" allowClear @change="updateCompIntroduce"></textarea>
      </div>
    </a-modal>
    <div style="display: flex;flex-direction: column">
      <div style="display:flex;flex-direction:column; align-items:center; margin: 30px 0px">
        <div style="display: flex;align-items: center;justify-content: start;margin: 10px 380px 10px 0px">
          <span>请选择日期：</span>
          <a-range-picker :getPopupContainer="trigger => trigger.parentNode"
                          formate="YYYY-MM-DD HH:mm:ss"
                          @change="onChartOneTimeChange"
                          :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                          style="align-self: flex-start;"/>
          <a-button size="small" @click="searchChartOne" style="width: 60px;margin-left: 100px;" type="primary">查询
          </a-button>
        </div>
        <div id="chartOne" style="height: 400px; width:1000px;margin: 0px auto; padding: 10px"></div>
      </div>
      <div style="display: flex;justify-content: space-between">
        <div style="display:flex;flex-direction:column; align-items:center;">
          <div style="display: flex;align-items: center;justify-content: space-between;">
            <span>请选择日期：</span>
            <a-range-picker :getPopupContainer="trigger => trigger.parentNode"
                            formate="YYYY-MM-DD HH:mm:ss"
                            @change="onChartYJGroupTimeChange"
                            :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                            style="align-self: flex-start;"/>
            <a-button size="small" @click="searchYJGroup" style="width: 60px;margin: 0px 50px"
                      type="primary">查询
            </a-button>
          </div>
          <div id="chartYJGroup" style="width: 600px; height: 400px"></div>
        </div>
        <div>
          <div style="display: flex;align-items: center;justify-content: start;margin: 0px 150px 10px 0px">
            <span>请选择日期：</span>
            <a-range-picker :getPopupContainer="trigger => trigger.parentNode"
                            formate="YYYY-MM-DD HH:mm:ss"
                            @change="onChartXJGroupTimeChange"
                            :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                            style="align-self: flex-start;"/>
            <a-button size="small" @click="searchXJGroup" style="width: 60px;margin-left:50px"
                      type="primary">查询
            </a-button>
          </div>
          <div id="chartXJGroup" style="width: 600px; height: 400px"></div>
        </div>
      </div>


      <div style="display: flex;justify-content: space-between">
        <div style="display:flex;flex-direction:column; align-items:center;">
          <div style="display: flex;align-items: center;justify-content: space-between;">
            <span>请选择日期：</span>
            <a-range-picker :getPopupContainer="trigger => trigger.parentNode"
                            formate="YYYY-MM-DD HH:mm:ss"
                            @change="onChartTaskCountAndPriceTimeChange"
                            :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                            style="align-self: flex-start;"/>
            <a-button size="small" @click="searchChartTaskCountAndPrice" style="width: 60px;margin: 0px 50px"
                      type="primary">查询
            </a-button>
          </div>
          <div id="chartTaskCountAndPrice" style="width: 600px; height: 400px"></div>
        </div>
        <div>
          <div style="display: flex;align-items: center;justify-content: start;margin: 0px 150px 10px 0px">
            <span>请选择日期：</span>
            <a-range-picker :getPopupContainer="trigger => trigger.parentNode"
                            formate="YYYY-MM-DD HH:mm:ss"
                            @change="onChartOrderCountAndPriceTimeChange"
                            :showTime="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
                            style="align-self: flex-start;"/>
            <a-button size="small" @click="searchChartOrderCountAndPrice" style="width: 60px;margin-left:50px"
                      type="primary">查询
            </a-button>
          </div>
          <div id="chartOrderCountAndPrice" style="width: 600px; height: 400px"></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {dateFormat} from "@/utils/common";
import {Modal} from "ant-design-vue";
import {provinceInfo} from "@/config/provinceInfo";
import moment from 'moment';

const residences = provinceInfo;
export default {
  name: "index",
  inject: ['reload'],
  created() {
    const params = {
      id: this.$store.getters.userInfo.companyId,
    };
    if (this.LoginStaffType === 0) {
      this.$api.company.detail(params).then(res => {
        if (res.code === 200 && res.success) {
          this.companyInfo = res.data;
          const {
            createTime,
            name,
            logoPicture,
            provinceName,
            cityName,
            districtName,
            address,
            leaderName,
            leaderPhone
          } = this.companyInfo.companyVO;
          const {legalPersonName} = this.companyInfo.companyQualification;
          this.compUpdateData.companyVO.id= this.companyInfo.companyVO.id;
          this.compUpdateData.companyQualification.id = this.companyInfo.companyQualification.id;
          this.createdTime = createTime;
          this.companyName = name;
          this.provinceName = provinceName;
          this.cityName = cityName;
          this.districtName = districtName;
          this.address = address;
          this.leaderName = leaderName;
          this.leaderPhone = leaderPhone;
          this.legalPersonName = legalPersonName;
          this.logoPicture = logoPicture;
        }
      })
      this.$api.company.selectStaffCount().then(res => {
        if (res.code === 200 && res.success) {
          this.staffCount = res.data;
        }
      })
    }
    if (this.LoginStaffType === 1) {
      this.$api.supplier.detail(params).then(res => {
        if (res.code === 200 && res.success) {
          this.companyInfo = res.data;
          const {createTime,
            name,
            logoPicture,
            provinceName,
            cityName,
            districtName,
            address,
            leaderName,
            leaderPhone
          } = this.companyInfo.supplierVO;
          const {legalPersonName} = this.companyInfo.supplierQualification;
          this.compUpdateData.companyVO.id= this.companyInfo.supplierVO.id;
          this.compUpdateData.companyQualification.id = this.companyInfo.supplierQualification.id;
          this.createdTime = createTime;
          this.companyName = name;
          this.logoPicture = logoPicture;
          this.provinceName = provinceName;
          this.cityName = cityName;
          this.districtName = districtName;
          this.address = address;
          this.leaderName = leaderName;
          this.leaderPhone = leaderPhone;
          this.legalPersonName = legalPersonName;

        }

      })
      this.$api.supplier.selectStaffCount().then(res => {
        if (res.code === 200 && res.success) {
          this.staffCount = res.data;
        }
      })
    }
  },
  data() {
    return {
      moment,

      residences,
      companyInfo: '',
      createdTime: '',
      companyName: '',
      provinceName: '',
      cityName: '',
      districtName: '',
      address: '',
      leaderName: '',
      leaderPhone: '',
      legalPersonName: '',
      logoPicture: '',
      bno: '',
      staffCount: 0,
      LoginStaffType: this.$store.getters.userInfo.staffType,
      compEditVisible: false,
      confirmLoading: false,

      headers: {
        contentType: 'multipart/form-data'
      },
      uploadIndex: 0,
      uploadIdCardFState: true,
      uploadIdCardSState: true,
      uploadIdCardTState: true,
      uploadLicenseState: true,
      uploadHeadPicState: true,
      previewVisible: false,
      previewCardFImage: null,
      previewCardSImage: null,
      previewCardTImage: null,
      licenseImage: null,
      headPicImage: null,
      compUpdateData: {
        companyQualification: {
          id:null,
          businessLicense: null,
          businessLicenseNo: null,
          legalPersonIdCardBack: null,
          legalPersonIdCardFront: null,
          legalPersonIdCardHandHold: null,
          legalPersonName: null
        },
        companyVO: {
          id:undefined,
          address: null,
          cityName: null,
          code: null,
          districtName: null,
          introduce: null,
          leaderName: null,
          leaderPhone: null,
          logoPicture: null,
          name: null,
          password: null,
          phone: null,
          provinceName: null,
          repeatPwd: null
        }
      },
      YJStartTime: '',
      YJEndTime: '',
      orderStartTime: '',
      orderEndTime: '',
      taskStartTime: '',
      taskEndTime: '',
      YJGroupStartTime: '',
      YJGroupEndTime: '',
      XJGroupStartTime: '',
      XJGroupEndTime: '',
    }
  },
  mounted() {
    this.drawChartOne();
    this.drawChartOrderCountAndPrice();
    this.drawChartTaskCountAndPrice();
    this.drawChartYJGroup();
    this.drawChartXJGroup();
  },
  methods: {
    onChartOneTimeChange(date, dateString) {
      this.YJStartTime = dateString[0];
      this.YJEndTime = dateString[1];
    },
    onChartOrderCountAndPriceTimeChange(date, dateString) {
      this.orderStartTime = dateString[0];
      this.orderEndTime = dateString[1];
    },
    onChartYJGroupTimeChange(date, dateString) {
      this.YJGroupStartTime = dateString[0];
      this.YJGroupEndTime = dateString[1];
    },
    searchYJGroup() {
      const startTime = this.YJGroupStartTime;
      const endTime = this.YJGroupEndTime;
      this.drawChartYJGroup(startTime,endTime);
    },
    onChartXJGroupTimeChange(date, dateString) {
      this.XJGroupStartTime = dateString[0];
      this.XJGroupEndTime = dateString[1];
    },
    searchXJGroup() {
      const startTime = this.XJGroupStartTime;
      const endTime = this.XJGroupEndTime;
      this.drawChartXJGroup(startTime,endTime);
    },
    searchChartOrderCountAndPrice() {
      const startTime = this.orderStartTime;
      const endTime = this.orderEndTime;
      this.drawChartOrderCountAndPrice(startTime, endTime);
    },

    onChartTaskCountAndPriceTimeChange(date, dateString) {
      this.taskStartTime = dateString[0];
      this.taskEndTime = dateString[1];
    },
    searchChartTaskCountAndPrice() {
      const startTime = this.taskStartTime;
      const endTime = this.taskEndTime;
      this.drawChartTaskCountAndPrice(startTime, endTime);
    },
    searchChartOne() {
      const startTime = this.YJStartTime;
      const endTime = this.YJEndTime;
      this.drawChartOne(startTime, endTime);
    },
    drawChartTaskCountAndPrice(startTime, endTime) {
      let myChart = this.$echarts.init(document.getElementById("chartTaskCountAndPrice"));
      let option = {
        title: {
          text: "采购单总数",
        },
        tooltip: {},
        legend: {
          data: ["采购单总数"],
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: []
        },
        yAxis: [
          {
            type: 'value',
            name: '采购单总数',
            min: 0,
            max: 200,
            interval: 20,
          },
        ],
        series: [
          {
            name: "采购单总数",
            type: "bar",
            data: [],
            barWidth: '50%',
            itemStyle: {
              color: '#5ab1ef',
              barBorderRadius: 2,
              borderWidth: 1,
              borderType: 'solid',
              borderColor: '#73c0de',
              shadowColor: '#5470c6',
            }
          },
        ],
      };

      const statisticParams = {
        startTime: startTime,
        endTime: endTime,
      }
      this.$api.statistics.purchaseOrderTaskCountTotal(statisticParams).then(res => {
        if (res.code === 200 && res.success) {
          const orderCount = res.data;
          orderCount.forEach(item => {
            const orderNum = item.taskNum;
            const xAxis = item.yearMonthStr;
            option.series[0].data.push(orderNum);
            option.xAxis.data.push(xAxis);
          })
          this.setEchartsOptions(myChart, option);
        }
      })

    },
    drawChartOrderCountAndPrice(startTime, endTime) {
      let myChart = this.$echarts.init(document.getElementById("chartOrderCountAndPrice"));
      let option = {
        title: {
          text: "采购订单总数及金额",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {
          data: ["订单总数", "订单总金额"],
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          },
          data: []
        },
        yAxis: [
          {
            type: 'value',
            name: '订单总数',
            min: 0,
            max: 200,
            interval: 50,
          },
          {
            type: 'value',
            name: '订单总金额',
            min: 0,
            max: 10000,
            interval: 2000,
            axisLabel: {
              formatter: '{value} ￥'
            }
          }
        ],
        series: [
          {
            name: "订单总数",
            type: "bar",
            data: [],
            barWidth: '50%',
            itemStyle: {
              color: '#5ab1ef',
              barBorderRadius: 2,
              borderWidth: 1,
              borderType: 'solid',
              borderColor: '#73c0de',
              shadowColor: '#5470c6',
            }
          },
          {
            name: '订单总金额',
            type: 'line',
            yAxisIndex: 1,
            data: []
          }
        ],
      };
      const statisticParams = {
        startTime: startTime,
        endTime: endTime,
      }
      this.$api.statistics.purchaseOrderCountListTotal(statisticParams).then(res => {
        if (res.code === 200 && res.success) {
          const orderCount = res.data;
          orderCount.forEach(item => {
            const orderNum = item.totalNum;
            const orderAmount = item.totalAmount;
            const xAxis = item.yearMonthStr;
            option.series[0].data.push(orderNum);
            option.series[1].data.push(orderAmount);
            option.xAxis.data.push(xAxis);
          })
          this.setEchartsOptions(myChart, option);
        }
      })
    },
    drawChartOne(startTime, endTime) {
      let myChart = this.$echarts.init(document.getElementById("chartOne"));
      let option = {
        title: {
          text: "月结数量及总金额",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
            dataView: {show: true, readOnly: false},
            magicType: {show: true, type: ['line', 'bar']},
            restore: {show: true},
            saveAsImage: {show: true}
          }
        },
        legend: {
          data: ['总金额', '月结数量']
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '总金额',
            min: 0,
            max: 10000,
            interval: 1000,
            axisLabel: {
              formatter: '{value} ￥'
            }
          },
          {
            type: 'value',
            name: '月结数量',
            min: 0,
            max: 50,
            interval: 5,
          },
        ],
        series: [
          {
            name: '总金额',
            type: 'bar',
            data: [],
            barWidth: '30%',
            yAxisIndex: 0,
          },
          {
            name: '月结数量',
            type: 'line',
            yAxisIndex: 1,
            data: [],
            itemStyle: {
              color: '#5ab1ef',
              barBorderRadius: 2,
              borderWidth: 1,
              borderType: 'solid',
              borderColor: '#73c0de',
              shadowColor: '#5470c6',
            },
          }
        ]
      };
      const statisticParams = {
        startTime: startTime,
        endTime: endTime,
      }
      this.$api.statistics.orderDetailPayWayCountTotal(statisticParams).then(res => {
        if (res.code === 200 && res.success) {
          const yjCount = res.data;
          yjCount.forEach(item => {
            const payNum = item.monthPayNum;
            const payAmount = item.monthPayAmount;
            const xAxis = item.monthDayStr;
            option.series[0].data.push(payAmount);
            option.series[1].data.push(payNum);
            option.xAxis[0].data.push(xAxis);
          })
          this.setEchartsOptions(myChart, option);
        }
      })
    },
    drawChartYJGroup(startTime, endTime) {
      let myChart = this.$echarts.init(document.getElementById("chartYJGroup"));
      let str = "供应商";
      if(this.LoginStaffType===0){
        str = "采购商"
      }
      let option = {
        title: {
          text: str+"月结次数--总金额",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
            dataView: {show: true, readOnly: false},
            magicType: {show: true, type: ['line', 'bar']},
            restore: {show: true},
            saveAsImage: {show: true}
          }
        },
        legend: {
          data: ['总金额', '月结数量']
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '总金额',
            min: 0,
            max: 10000,
            interval: 1000,
            axisLabel: {
              formatter: '{value} ￥'
            }
          },
          {
            type: 'value',
            name: '月结数量',
            min: 0,
            max: 50,
            interval: 5,
          },
        ],
        series: [
          {
            name: '总金额',
            type: 'bar',
            data: [],
            barWidth: '30%',
            yAxisIndex: 0,
          },
          {
            name: '月结数量',
            type: 'line',
            yAxisIndex: 1,
            data: [],
            itemStyle: {
              color: '#5ab1ef',
              barBorderRadius: 2,
              borderWidth: 1,
              borderType: 'solid',
              borderColor: '#73c0de',
              shadowColor: '#5470c6',
            },
          }
        ]
      };
      const statisticParams = {
        startTime: startTime,
        endTime: endTime,
        payWay: 1,
      }
      this.$api.statistics.orderDetailEachSupplierPayWayCountTotal(statisticParams).then(res => {
        const yjCount = res.data;
        yjCount.forEach(item => {
          const payNum = item.monthPayNum;
          const payAmount = item.monthPayAmount;
          let xAxis = item.supplierName;
          if (this.LoginStaffType &&this.LoginStaffType === 1) {
            xAxis = item.companyName;
          }
          option.series[0].data.push(payAmount);
          option.series[1].data.push(payNum);
          option.xAxis[0].data.push(xAxis);
        })
        this.setEchartsOptions(myChart, option);
      })
    },
    drawChartXJGroup(startTime, endTime) {
      let myChart = this.$echarts.init(document.getElementById("chartXJGroup"));
      let str = "供应商";
      if(this.LoginStaffType===0){
        str = "采购商"
      }
      let option = {
        title: {
          text: str+"现结次数--总金额",
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        toolbox: {
          feature: {
            dataView: {show: true, readOnly: false},
            magicType: {show: true, type: ['line', 'bar']},
            restore: {show: true},
            saveAsImage: {show: true}
          }
        },
        legend: {
          data: ['总金额', '现结数量']
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '总金额',
            min: 0,
            max: 10000,
            interval: 1000,
            axisLabel: {
              formatter: '{value} ￥'
            }
          },
          {
            type: 'value',
            name: '现结数量',
            min: 0,
            max: 50,
            interval: 5,
          },
        ],
        series: [
          {
            name: '总金额',
            type: 'bar',
            data: [],
            barWidth: '30%',
            yAxisIndex: 0,
          },
          {
            name: '现结数量',
            type: 'line',
            yAxisIndex: 1,
            data: [],
            itemStyle: {
              color: '#5ab1ef',
              barBorderRadius: 2,
              borderWidth: 1,
              borderType: 'solid',
              borderColor: '#73c0de',
              shadowColor: '#5470c6',
            },
          }
        ]
      };
      const statisticParams = {
        startTime: startTime,
        endTime: endTime,
        payWay: 0,
      }
      this.$api.statistics.orderDetailEachSupplierPayWayCountTotal(statisticParams).then(res => {
        const yjCount = res.data;
        yjCount.forEach(item => {
          const payNum = item.monthPayNum;
          const payAmount = item.monthPayAmount;
          let xAxis = item.supplierName;
          if (this.LoginStaffType && this.LoginStaffType === 1) {
            xAxis = item.companyName;
          }
          option.series[0].data.push(payAmount);
          option.series[1].data.push(payNum);
          option.xAxis[0].data.push(xAxis);
        })
        this.setEchartsOptions(myChart, option);
      })
    },
    setEchartsOptions(echarts, options) {
      echarts.setOption(options);
      echarts.resize();
    },
    changeCompanyInfo() {
      this.compEditVisible = true;
    },
    updateCompName(e) {
      const compName = e.target.value;
      this.compUpdateData.companyVO.name = compName;
    },
    handleUserTypeChange(value) {
    },
    updateCompLegalName(e) {
      const legalName = e.target.value;
      this.compUpdateData.companyQualification.legalPersonName = legalName;
    },
    updateAdminName(e) {
      const leaderName = e.target.value;
      this.compUpdateData.companyVO.leaderName = leaderName;
    },
    updateAdminPhone(e) {
      // const leaderPhone = e.target.value;
      // this.compUpdateData.companyVO.leaderPhone = leaderPhone;
    },
    handleCompBasicAddrChange(value) {
      if (value) {
        this.compUpdateData.companyVO.provinceName = value[0];
        this.compUpdateData.companyVO.cityName = value[1];
        this.compUpdateData.companyVO.districtName = value[2];
      }
    },
    updateCompAddress(e) {
      const address = e.target.value;
      this.compUpdateData.companyVO.address = address;
    },

    updateBno(e) {
      const bno = e.target.value;
      this.compUpdateData.companyQualification.businessLicenseNo = bno;
    },
    updateCompIntroduce(e) {
      const introduce = e.target.value;
      this.compUpdateData.companyVO.introduce = introduce;
    },
    handleOk() {
      let compUpdateFlag, compQuaUpdateFlag = false;
      this.compUpdateData.companyQualification.legalPersonIdCardFront = this.previewCardFImage;
      this.compUpdateData.companyQualification.legalPersonIdCardBack = this.previewCardSImage;
      this.compUpdateData.companyQualification.legalPersonIdCardHandHold = this.previewCardTImage;
      this.compUpdateData.companyQualification.businessLicense = this.licenseImage;
      this.compUpdateData.companyVO.logoPicture = this.headPicImage;
      const {
        legalPersonIdCardFront, legalPersonIdCardBack,
        legalPersonIdCardHandHold, businessLicense,
        businessLicenseNo, legalPersonName
      } = this.compUpdateData.companyQualification;
      if (legalPersonIdCardFront || legalPersonIdCardBack ||
          legalPersonIdCardHandHold || businessLicense || businessLicenseNo || legalPersonName) {
        compUpdateFlag = true;
      }
      const {name, leaderName, logoPicture, provinceName,
        cityName, districtName, address, introduce
      } = this.compUpdateData.companyVO;
      if (name || leaderName || logoPicture || provinceName ||
          cityName || districtName || address || introduce) {
        compQuaUpdateFlag = true;
      }
      const userType = this.LoginStaffType;
      if (compUpdateFlag || compQuaUpdateFlag) {
        if (userType === 0) {
          const params = {}
          if (compUpdateFlag) {
            params.companyVO = this.compUpdateData.companyVO;
          }
          if (compQuaUpdateFlag) {
            params.companyQualification = this.compUpdateData.companyQualification;
          }

          this.$api.company.update(params).then(res => {
            if (res.code === 200 && res.success) {
              this.$message.success("操作成功！");
              this.reload();
            }
          })
        }
        if (userType === 1) {
          const supUpdateData = {}
          if (compUpdateFlag) {
            supUpdateData.supplierVO = this.compUpdateData.companyVO;
          }
          if (compQuaUpdateFlag) {
            supUpdateData.supplierQualification = this.compUpdateData.companyQualification;
          }

          this.$api.supplier.update(supUpdateData).then(res => {
            if (res.code === 200 && res.success) {
              this.$message.success("操作成功！");
              this.reload();
            }
          })
        }
      } else {
        Modal.warning({
          title: "当前没有修改公司信息！",
          content: "请取消修改或至少修改一项公司信息。",
        });
      }
    },
    handleCancel() {
      this.compEditVisible = false;
    },

    //上传相关
    uploadParams() {
      let d = {
        companyName: this.companyName,
        userType: this.LoginStaffType,
      }
      return d;
    },
    handleRemoveIdCarF() {
      if (!this.uploadIdCardFState) {
        this.previewCardFImage = '';
        this.uploadIdCardFState = !this.uploadIdCardFState;
      }
      this.changeUploadIdCardFState();
    },
    handleRemoveIdCarS() {
      if (!this.uploadIdCardSState) {
        this.previewCardSImage = '';
        this.uploadIdCardSState = !this.uploadIdCardSState;
      }
      this.changeUploadIdCardSState();
    },
    handleRemoveIdCarT() {
      if (!this.uploadIdCardTState) {
        this.previewCardTImage = '';
        this.uploadIdCardTState = !this.uploadIdCardTState;
      }
      this.changeUploadIdCardTState();
    },
    handleRemoveLicense() {
      if (!this.uploadLicenseState) {
        this.licenseImage = '';
        this.uploadLicenseState = !this.uploadLicenseState;
      }
      this.changeUploadLicenseState();
    },
    handleRemoveHeadPic() {
      if (!this.uploadHeadPicState) {
        this.headPicImage = '';
        this.uploadHeadPicState = !this.uploadHeadPicState;
      }
      this.changeUploadHeadPicState();
    },
    async handlePreview(file) {
      if (file.response.data) {
        if (this.uploadIndex === 0) {
          this.previewCardFImage = file.response.data;
          this.previewVisible = true;
        }
        if (this.uploadIndex === 1) {
          this.previewCardSImage = file.response.data;
          this.previewVisible = true;
        }
        if (this.uploadIndex === 2) {
          this.previewCardTImage = file.response.data;
          this.previewVisible = true;
        }
        if (this.uploadIndex === 3) {
          this.licenseImage = file.response.data;
          this.previewVisible = true;
        }
        if (this.uploadIndex === 4) {
          this.headPicImage = file.response.data;
          this.previewVisible = true;
        }
      } else {
        this.$message.error("图片上传失败不能预览！")
      }

    },
    handleBeforeUpload(file) {
      return new Promise((resolve, reject) => {
        const companyName = this.companyName;
        if (companyName === '' || companyName === undefined) {
          this.$message.error("公司名称未填写");
          return reject(false);
        }
        const userType = this.LoginStaffType;
        if (userType === '' || userType === undefined) {
          this.$message.error("公司类型未选择");
          return reject(false);
        }
        const isJPG = file.type === 'image/jpg';
        const isJPEG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isGIF = file.type === 'image/gif';
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!(isJPG || isJPEG || isPNG || isGIF)) {
          Modal.error({
            title: '只能上传JPG、JPEG、GIF、PNG格式的图片',
          });
          return reject(false);
        } else if (!isLt10M) {
          Modal.error({
            title: '图片大小不能超过10M',
          });
          return reject(false);
        }
        return resolve(true);
      })

    },
    handleChange(info) {
      if (this.uploadIndex === 0) {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 上传失败`);
        }
        if (info.file.status === 'done') {
          if (info.file.response.success) {
            this.previewCardFImage = info.file.response.data;
            this.uploadIdCardFState = false;
            this.$message.success(`${info.file.name} 上传成功`);
          } else {
            this.$message.error(`${info.file.name} ${info.file.response.msg}`);
          }
        }
      }
      if (this.uploadIndex === 1) {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 上传失败`);
        }
        if (info.file.status === 'done') {
          if (info.file.response.success) {
            this.previewCardSImage = info.file.response.data;
            this.uploadIdCardSState = false;
            this.$message.success(`${info.file.name} 上传成功`);
          } else {
            this.$message.error(`${info.file.name} ${info.file.response.msg}`);
          }

        }

      }
      if (this.uploadIndex === 2) {
        if (info.file.status !== 'uploading') {

        }
        if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 上传失败`);
        }
        if (info.file.status === 'done') {
          if (info.file.response.success) {
            this.previewCardTImage = info.file.response.data;
            this.uploadIdCardTState = false;
            this.$message.success(`${info.file.name} 上传成功`);
          } else {
            this.$message.error(`${info.file.name} ${info.file.response.msg}`);
          }
        }
      }
      if (this.uploadIndex === 3) {
        if (info.file.status !== 'uploading') {
          //console.log(info)
        }
        if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 上传失败`);
        }
        if (info.file.status === 'done') {
          if (info.file.response.success) {
            this.licenseImage = info.file.response.data;
            this.uploadLicenseState = false;
            this.$message.success(`${info.file.name} 上传成功`);
          } else {
            this.$message.error(`${info.file.name} ${info.file.response.msg}`);
          }
        }
      }
      if (this.uploadIndex === 4) {
        if (info.file.status !== 'uploading') {
          //console.log(info)
        }
        if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 上传失败`);
        }
        if (info.file.status === 'done') {
          if (info.file.response.success) {
            this.headPicImage = info.file.response.data;
            this.uploadHeadPicState = false;
            this.$message.success(`${info.file.name} 上传成功`);
          } else {
            this.$message.error(`${info.file.name} ${info.file.response.msg}`);
          }
        }
      }
    },
    changeUploadIdCardFState() {
      this.uploadIndex = 0;
    },
    changeUploadIdCardSState() {
      this.uploadIndex = 1;
    },
    changeUploadIdCardTState() {
      this.uploadIndex = 2;
    },
    changeUploadLicenseState() {
      this.uploadIndex = 3;
    },
    changeUploadHeadPicState() {
      this.uploadIndex = 4;
    },
    getFormatDate(text) {
      const result = dateFormat(text, 'YYYY-MM-DD HH:mm:ss');
      return result;
    },
  }
}
</script>

<style lang="scss">
.basic-info {
  width: 80vw;
  display: flex;
  justify-content: start;
  margin-top: 20px;
}

.containerClass {
  display: flex;
  justify-content: start;
}

.updateLeftContainClass {
  display: flex;
  flex-direction: column;
}

.updateItemStyle {
  display: flex;
  margin: 10px 0px 40px 0px;
}

.updateRightContainClass {
  display: flex;
  flex-direction: column;
}

.uploadIdCardStyle {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}


.upload-items {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}


</style>