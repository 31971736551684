<template>
  <div class="edit-res">
    <a-modal title="编辑店铺信息"
             :visible="editVisible"
             :confirm-loading="confirmLoading"
             okText="确定" cancelText="取消"
             @ok="handleOk"
             @cancel="handleCancel"
             width="350px">
      <a-form class="edit-restaurant-form"
              :form="editResForm"
              label-width="100px">
        <a-row>
          <a-form-item label="门店名称" :label-col="{span:8}" :wrapper-col="{ span:16}">

            <a-input ref="resName"
                     :value="resInfo.name"
                     type="text"
                     @change="$emit('changeResName',$event.target.value)"
                     allowClear>
            </a-input>

          </a-form-item>
          <a-form-item label="公司地址" :label-col="{span:8}" :wrapper-col="{ span:16}">
            <a-cascader
                :field-names="{label:'name',value:'name',children:'children'}"
                :value="residence"
                @change=handleResidenceChange
                placeholder="请选择省市区"
                :options="residences"
            />
          </a-form-item>
          <a-form-item label="详细地址" :label-col="{span:8}" :wrapper-col="{ span:16}">
            <a-input
                :value="street"
                @change="$emit('changeResStreet',$event.target.value)"
                type="text"
                key="1"
                allowClear>
            </a-input>
          </a-form-item>
          <a-form-item label="联系方式" :label-col="{span:8}" :wrapper-col="{ span:16}">
            <a-input ref="phone"
                     placeholder="联系电话"
                     :value="resInfo.phone"
                     :maxLength="11"
                     addon-before="+86"
                     @change="$emit('changeResPhone',$event.target.value)"
                     allowClear/>
          </a-form-item>
          <a-form-item label="介绍" :label-col="{span:8}" :wrapper-col="{ span:16}">
            <a-input ref="resIntroduce"
                     :value="resInfo.introduce"
                     type="text"
                     @change="$emit('changeResIntroduce',$event.target.value)"
                     allowClear>
            </a-input>
          </a-form-item>
          <a-form-item class="upload-form-item" label="门店主图">
            <a-upload v-decorator="['headPicture',rules.headPicture]"
                      action="/auth/upLoad"
                      class="avatar-uploader"
                      :headers=headers
                      name="file"
                      :data=this.uploadParams
                      :fileList="fileList"
                      list-type="picture-card"
                      :before-upload="handleBeforeUpload"
                      :remove="handleRemovePicture"
                      @preview="handlePreview"
                      @change="handleChange">
              <div v-if="!this.editParam.headPicture">
                <a-icon :type="loading ? 'loading' : 'plus'"/>
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelHeadPic">
              <img alt="门店主图" style="width: 100%" :src="editParam.headPicture"/>
            </a-modal>
          </a-form-item>
          <a-form-item class="upload-form-item" label="门店详情图片">
            <a-upload v-decorator="['detailPictures',rules.detailPictures]"
                      class="avatar-uploader"
                      action="/auth/upLoad"
                      :headers=headers
                      name="file"
                      :data=this.uploadParams
                      :fileList="fileListSub"
                      list-type="picture-card"
                      :before-upload="handleBeforeUpload"
                      :remove="handleRemovePictureSub"
                      @preview="handlePreviewSub"
                      @change="handleChangeSub">
              <div v-if="this.fileListSub.length<3">
                <a-icon type='plus'/>
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisibleSub" :footer="null" @cancel="handleCancelDetailPic">
              <img alt="门店详情图片" style="width: 100%" :src="editParam.detailPictures"/>
            </a-modal>
          </a-form-item>
          <a-form-item label="备注" :label-col="{span:8}" :wrapper-col="{ span:16}">
            <a-textarea ref="remark"
                        :value="resInfo.remark"
                        placeholder="填写备注"
                        rows="4"
                        @change="$emit('changeRemark',$event.target.value)"
                        allowClear/>
          </a-form-item>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import Modal from "ant-design-vue/lib/modal/Modal";
import {provinceInfo} from "@/config/provinceInfo";
const residences = provinceInfo;
export default {
  name: "editRes",
  props:{
    editVisible:{
      type:Boolean,
    },
    resInfo:{
      type:Object,
    },
    residence:{
      type:Array,
    },
    street:{
      type:String,
    },
  },
  inject:["reload"],
  data(){
    return {
      residences,
      customVisible:this.editVisible,
      confirmLoading:false,
      selectResetFlag:false,
      editResForm:this.$form.createForm(this,{name: 'edit_restaurant_form'}),
      editParam:{
        id: undefined,
        name: undefined,
        address:undefined,
        phone:undefined,
        headPicture: undefined,
        detailPictures:undefined,
        introduce:undefined,
        remark: undefined,
      },
      rules:{
        headPicture:{
          rules: [
            {required: false, message: '上传门店主图'}
          ]
        },
        detailPictures:{
          rules: [
            {required: false, message: '上传门店详情图片'}
          ]
        },
      },

      headers:{
        contentType:'multipart/form-data'
      },
      previewVisible:false,
      previewVisibleSub:false,
      fileList:[],
      fileListSub:[],
      loading:false,
    }
  },
  methods:{
    handleResidenceChange(value){
      this.$emit('changeResResidence',value);
    },
    handleOk(){
      this.confirmLoading = true;
      const residenceInfo = this.residence;
      const detailAdd = residenceInfo[0].concat("-")
          .concat(residenceInfo[1]).concat("-")
          .concat(residenceInfo[2]).concat("-")
          .concat(this.street);
      if(this.fileListSub.length>0){
        const detailPics =this.fileListSub.map(pic=>{
          if(pic.response.data){
            return pic.response.data;
          }
        }).join(",");
        this.editParam.detailPictures = detailPics;
      }
      const {id,name,phone,introduce,remark} = this.resInfo;
      this.editParam.id = id;
      this.editParam.name = name;
      this.editParam.address = detailAdd;
      this.editParam.phone = phone;
      this.editParam.introduce = introduce;
      this.editParam.remark = remark;
      //console.log(this.resInfo,this.editParam);
      if(!this.editParam.name){
        this.$message.error("门店名称不能为空！");
        return;
      }
      //console.log(this.editParam);
      this.$api.restaurant.updateById(this.editParam).then(res=>{
        if(res.code===200&&res.success){
          this.$message.success("编辑成功!!");
          this.customVisible = false;
          this.$emit("changeEditVisible",this.customVisible);
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      });
      this.confirmLoading = false;
    },
    //上传文件相关
    uploadParams(){
      let d = {
        companyName:this.$store.getters.userInfo.companyName,
        userType: this.$store.getters.userInfo.staffType,
      }
      return d;
    },
    handleBeforeUpload(file){
      return new Promise((resolve,reject)=>{
        const isJPG = file.type === 'image/jpg';
        const isJPEG = file.type=== 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isGIF = file.type === 'image/gif';
        const isLt10M = file.size/1024/1024 <10;
        if(!(isJPG||isJPEG||isPNG||isGIF)){
          Modal.error({
            title:'只能上传JPG、JPEG、GIF、PNG格式的图片',
          });
          return reject(false);
        }else if(!isLt10M){
          Modal.error({
            title:'图片大小不能超过10M',
          });
          return reject(false);
        }
        return resolve(true);
      })
    },
    handleRemovePicture(){
      this.editParam.headPicture = null;
    },
    handleChange(info){
      this.loading = true;
      this.fileList = info.fileList;
      if (info.file.status !== 'uploading') {
        //console.log(info)
      }
      if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
      if (info.file.status === 'done') {
        if (info.file.response.data) {
          this.editParam.headPicture = info.file.response.data;
          this.$message.success(`${info.file.name} 上传成功`);
        } else {
          this.$message.error(`${info.file.name} ${info.file.response.msg}`);
        }
      }
      this.loading = false;
    },
    async handlePreview(file) {
      if(file.response.data){
        this.editParam.headPicture  =  file.response.data;
        this.previewVisible = true;
      }else{
        this.$message.error("图片上传失败不能预览！")
      }
    },
    handleCancelHeadPic(){
      this.previewVisible = false;
    },
    handleCancelDetailPic(){
      this.previewVisibleSub = false;
    },
    handleRemovePictureSub(){
      //console.log("remove2:",this.editResForm.getFieldValue('detailPictures'));
      this.editParam.detailPictures = null;
    },
    handleChangeSub(info){
      this.fileListSub = info.fileList;
      //console.log(info);
      const status = info.file.status;
      if (status!== 'uploading') {
        setTimeout(()=>{

        },10000);
        //console.log(info)
      }
      if (status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
      if (status === 'done') {
        if (info.file.response.data) {
          this.$message.success(`${info.file.name} 上传成功`);
        } else {
          this.$message.error(`${info.file.name} ${info.file.response.msg}`);
        }
      }
      if(status ==='removed'){
        this.editParam.detailPictures = null;
      }
    },
    async handlePreviewSub(file) {
      if(file.response.data){
        this.editParam.detailPictures  =  file.response.data;
        this.previewVisibleSub = true;
      }else{
        this.$message.error("图片上传失败不能预览！")
      }
    },

    handleCancel(){
      const item = this.$store.getters.restaurantInfo;
      this.editResForm.resetFields();
      this.fileList = [];
      this.fileListSub = [];
      this.editParam.headPicture = null;
      this.editParam.detailPictures = null;
      //console.log("item:",item);
      this.$emit('changeResName',item.name);
      this.$emit("changeResPhone",item.phone);
      this.$emit("changeResResidence",this.residence);
      this.$emit("changeResStreet",this.street);
      this.$emit('changeHeadPicture',item.headPicture);
      this.$emit('changeDetailPicture',item.detailPictures);
      this.$emit('changeResIntroduce',item.introduce);
      this.$emit('changeRemark',item.remark);
      this.customVisible = false;
      this.$emit("changeEditVisible",this.customVisible);
    },

  },
}
</script>

<style lang="scss" scoped>
.edit-res{

  .upload-form-item{
    .avatar-uploader > .ant-upload{
      width: 128px;
      height: 128px;
    }
    .ant-upload-select-picture-card i {
      font-size: 32px;
      color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
}
</style>