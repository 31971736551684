<template>
  <div class="add-role">
    <a-modal title="添加角色"
             :visible="addVisible"
             :confirm-loading="confirmLoading"
             okText="确定"
             cancelText="取消"
             @ok="handleOk"
             @cancel="handleCancel"
             width="400px">
      <a-form class="add-role-form"
              :form="addRoleForm"
              label-width="100px">
          <a-form-item label="角色名称">
            <a-row>
              <a-col :span="24">
                <a-input
                    v-decorator="['roleName',rules.roleName]"
                    type="text"
                    key="0"
                    allowClear>
                </a-input>
              </a-col>
            </a-row>
          </a-form-item>
        <a-form-item label="启用状态">
          <a-select v-decorator="['status',rules.status]"
                    :getPopupContainer="trigger => trigger.parentNode"
                    allowClear
                    style="width: 120px"
                    size="large"
                    placeholder="请选择">
            <a-select-option :key="0"
                             value="0">
              启用
            </a-select-option>
            <a-select-option :key="1"
                             value="1">
              禁用
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="描述">
          <a-textarea v-decorator="['describes',rules.describes]"
                      placeholder="角色描述"
                      rows="4"
                      allowClear/>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "addRole",
  props:{
    addVisible:{
      type:Boolean,
    },
  },
  inject:["reload"],
  data(){
    return {
      customVisible:this.addVisible,
      confirmLoading:false,
      addRoleForm:this.$form.createForm(this, {name: 'add_role_form'}),
      rules:{
        roleName: {
          rules: [
            {required: true, message: '请输入角色名称!'},
          ]
        },
        status:{
          rules: [
            {required: true, message: '请选择角色是否启用!'}
          ]
        },
        describes: {
          rules:[
            {required: false, message: '角色描述'},
          ]
        },
      }
    }
  },
  methods:{
     handleOk(){
       this.confirmLoading = true;
      this.addRoleForm.validateFields((err, values) => {
        if (!err) {
          this.$api.role.addOrUpdate(values).then(res=>{
            if(res.code===200&&res.success){
              this.$message.success("添加成功！");
              this.confirmLoading = false;
              this.reload();
            }
          });
        }
      });
    },
    handleCancel(){
       this.addRoleForm.resetFields();
      this.customVisible = false;
      this.$emit("changeAddVisible",this.customVisible);
    },
  }
}
</script>

<style lang="scss" scoped>
.add-role{
  .add-role-form{
    padding: 10px 10px 0 0;
    border-top: 1px solid #eee !important;
    border-bottom: 1px solid #eee !important;
    .editable-row-operations a{
      margin-right: 8px;
    }
    ::v-deep .ant-table-body>tr>td{
      padding: 10px;
    }
    ::v-deep .ant-table-thead>tr>th{
      padding: 10px;
    }
  }
}
</style>