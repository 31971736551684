<template>
  <div class="returnGoods">
    <div class="item dataList">
      <a-row>
        <a-col :span="24">
          <a-row class="select-form">
            <a-col :span="24">
              <a-form layout="inline" :form="searchReturnGoodsForm">
                <a-form-item>
                  <a-input
                      size="large"
                      placeholder="验收单号"
                      v-model.trim="queryParam.receiveOrderCode"
                      clearable
                  >
                  </a-input>
                </a-form-item>
                <a-form-item v-if="userType===0">
                  <supplier-selector ref="supplierSelector"
                                     :current-value="bindSupplierChooseOption"
                                     @supplierSelectChange="supplierSelectChange"/>
                </a-form-item>
                <a-form-item v-if="userRoles.indexOf('2')!==-1">
                  <restaurant-selector ref="restaurantSelector"
                                       :current-value="supplierBindResChooseOption"
                                       @restaurantSelectChange="restaurantSelectChange"/>
                </a-form-item>
                <a-form-item v-if="userType===1">
                  <company-selector ref="companySelector"
                                    :current-value="supplierBindCompanyChooseOption"
                                    @companySelectChange="companySelectChange"/>
                </a-form-item>
                <a-form-item>
                  <goods-selector ref="goodsSelector"
                                  :current-value="goodsChooseOption"
                                  :company-id="this.queryParam.companyId"
                                  @goodsSelectChange="goodsSelectChange"></goods-selector>
                </a-form-item>
                <a-form-item>
                  <a-select v-model="returnStatus"
                            :getPopupContainer="trigger => trigger.parentNode"
                            allowClear
                            style="width: 110px"
                            size="large"
                            placeholder="退货状态"
                            @change="returnStatusChange">
                    <a-select-option :key="0"
                                     :value="0">
                      待确认
                    </a-select-option>
                    <a-select-option :key="1"
                                     :value="1">
                      已确认
                    </a-select-option>
                    <a-select-option :key="2"
                                     :value="2">
                      已拒绝
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-row>
                  <a-form-item style=" margin: 10px 0 0 0;">
                    <a-col :span="8">
                      <a-button
                          size="large"
                          type="primary"
                          @click="searchReturnList">查询
                      </a-button>
                    </a-col>
                    <a-col :span="8" offset="8">
                      <a-button size="large" @click="resetReturnGoodsForm">重置</a-button>
                    </a-col>
                  </a-form-item>
                </a-row>
              </a-form>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-table rowKey="id"
               :pagination="false"
               :columns="columns"
               :data-source="dataSource"
               :customRow="customRow"
               :row-class-name="setRowClassName"
               style="width: 100%;"
               bordered>
        <template slot="empty">
          <a-empty description="暂无数据"></a-empty>
        </template>
        <template slot="receiveOrderCode" slot-scope="text">
          <router-link to="/receive/order/list" tag="a">{{text}}</router-link>
        </template>
        <template slot="productMainPicture" slot-scope="text">
          <a-popover v-if="text"  title="商品主图" placement="top">
            <template slot="content">
              <img v-for=" (item,index) in text.split(',')" :key="index" :src="item" height="256px" width="256px"/>
            </template>
            <span style="color: #1890ff">商品图片</span>
          </a-popover>
          <span v-else style="text-align: center;">暂未设置</span>
        </template>
        <template slot="num" slot-scope="text">
          <span  style="color: #2db7f5;font-weight: bolder">
            {{text}}
          </span >
        </template>
        <template slot="unitPrice" slot-scope="text">
          <span style="color: red">
            ￥{{getFormatMoney(text,true)}}
          </span>
        </template>
        <template slot="totalPrice" slot-scope="text">
          <span style="color: red">
            ￥{{getFormatMoney(text,true)}}
          </span>
        </template>
        <template slot="createTime" slot-scope="text">
          {{getFormatDate(text)}}
        </template>
        <template slot="status" slot-scope="text">
          <span v-if="text==0" style="color: silver">待确认</span>
          <span v-if="text==1" style="color: limegreen">已确认</span>
          <span v-if="text==2" style="color: red">已拒绝</span>
        </template>
        <template slot="reasonPic" slot-scope="text">
          <a-popover  title="退货相关图片" placement="top">
            <template slot="content">
              <img v-for=" (item,index) in text.split(',')" :key="index" :src="item" height="256px" width="256px"/>
            </template>
            <span style="color: #1890ff">退货相关图片</span>
          </a-popover>
        </template>
        <template slot="returnsHandlerName" slot-scope="text">
          <span v-if="text">{{text}}</span>
          <span v-else style="color: silver">暂无</span>
        </template>
        <template slot="action" slot-scope="text,record">
          <a-tooltip placement="top" title="退货处理" autoAdjustOverflow>
            <a-button type="primary" size="small" icon="check-circle"
                      :disabled="record.status!==0" @click="showReceiveReturnModal(record.id)"/>
          </a-tooltip>
        </template>
      </a-table>
      <pageination  style="float: right"
                    :paginationList="paginationList"
                    @paging="paging">
      </pageination>
      <div>
        <a-modal
            :visible="returnModalVisible"
            :confirm-loading="returnModalConfirmLoading"
            :footer="null"
            :dialog-style="{top:'20px'}"
            width="500px"
            @cancel="handleReturnModalCancel"
        >
          <template slot="title">
            <a-row>
              <a-col :span="8" :offset="8">
                <span style="font-size: 20px;font-weight: bolder;text-align: center">是否同意退货？</span>
              </a-col>
            </a-row>
          </template>
          <a-row>
            <a-col :span="8" :offset="6">
              <a-button type="primary" @click="receiveReturnGoods">同意</a-button>
            </a-col>
            <a-col :span="8">
              <a-button type="danger" @click="unReceiveReturnGoods">拒绝</a-button>
            </a-col>
          </a-row>

        </a-modal>
      </div>
    </div>
  </div>
</template>

<script>
import SupplierSelector from "@/components/supplierSelector";
import RestaurantSelector from "@/components/restaurantSelector";
import CompanySelector from "@/components/companySelector";
import GoodsSelector from "@/components/goodsSelector";
import Pageination from "@/components/pageination";
import {dateFormat, formatMoney} from "@/utils/common";

export default {
  name: "index",
  components: {
    SupplierSelector, RestaurantSelector, CompanySelector, GoodsSelector, Pageination},
  inject:['reload'],
  created() {
    const item1= {
      title: '采购门店所属公司',
      dataIndex: 'companyName',
      key:'companyName',
      width: '140px',
      align: 'center',
    }
    const item2 =  {
      title: '采购门店',
      dataIndex: 'restaurantName',
      key:'restaurantName',
      width: '100px',
      align: 'center',
    }
    const item4 = {
      title: '供应商',
      dataIndex: 'supplierName',
      key:'supplierName',
      width: '70px',
      align: 'center',
    }
    const action = {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: '80px',
      align: 'center',
      scopedSlots: {customRender: 'action'},
    };
    if(this.userType===1){
      this.columns.splice(2,0,item1,item2);
      this.columns.push(action);
    }
    if(0===this.userType){
      if(this.userRoles.indexOf("2")!==-1){
        this.columns.splice(2,0,item2,item4);
      }
      if(this.userRoles.indexOf("3")!==-1){
        this.columns.splice(2,0,item2,item4);
      }
      if(this.userRoles.indexOf("4")!==-1||this.userRoles.indexOf("5")!==-1){
        this.columns.splice(2,0,item4);
      }
    }

    let queryParam = {
      current:this.paginationList.current,
      size:this.paginationList.size,
      ...this.queryParam,
    }
    this.getReturnList(queryParam);
  },
  data(){
    return{
      selectedRowId:undefined,
      userType:this.$store.getters.userInfo.staffType,
      userRoles:this.$store.getters.userInfo.roleIds,
      searchReturnGoodsForm: this.$form.createForm(this, {name: 'return_goods_form'}),
      returnModalVisible:false,
      returnModalConfirmLoading:false,
      currentSelectRecordKey:undefined,

      queryParam:{
        receiveOrderCode:'',
        productId:undefined,
        restaurantId:undefined,
        companyId:undefined,
        supplierId:undefined,
        status:undefined,
      },
      goodsChooseOption:{key:'',label:''},
      supplierBindResChooseOption:{key:'',label:''},
      supplierBindCompanyChooseOption:{key:'',label:''},
      //公司和餐厅绑定供应商选择项
      bindSupplierChooseOption:{key:'',label:''},
      paginationList:{
        size:10,
        current:1,
        total:0,
        pageSizeOptions: ['10', '20', '30', '40', '50','100'],
      },
      returnStatus:undefined,
      dataSource:[],
      columns:[
        {
          title: '验收单号',
          dataIndex: 'receiveOrderCode',
          key:'receiveOrderCode',
          width: '120px',
          align: 'center',
          scopedSlots: {customRender: 'receiveOrderCode'},
        },
        {
          title: '商品名称',
          dataIndex: 'productName',
          key:'productName',
          width: '80px',
          align: 'center',
        },
        {
          title: '商品主图',
          dataIndex: 'productMainPicture',
          key:'productMainPicture',
          width: '70px',
          align: 'center',
          scopedSlots: { customRender: 'productMainPicture' },
        },
        {
          title: '退货数量',
          dataIndex: 'num',
          key:'num',
          width: '40px',
          align: 'center',
          scopedSlots: { customRender: 'num' },
        },
        {
          title: '退货单价',
          dataIndex: 'unitPrice',
          key:'unitPrice',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'unitPrice' },
        },
        {
          title: '退货总价',
          dataIndex: 'totalPrice',
          key:'totalPrice',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'totalPrice' },
        },
        {
          title: '退货状态',
          dataIndex: 'status',
          key:'status',
          width: '60px',
          align: 'center',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '退货原因',
          dataIndex: 'reason',
          key:'reason',
          width: '70px',
          align: 'center',
          scopedSlots: { customRender: 'reason' },
        },
        {
          title: '退货图片',
          dataIndex: 'reasonPic',
          key:'reasonPic',
          width: '70px',
          align: 'center',
          scopedSlots: { customRender: 'reasonPic' },
        },
        {
          title: '处理人(采购方)',
          dataIndex: 'returnsStaffName',
          key:'returnsStaffName',
          width: '55px',
          align: 'center',
        },
        {
          title: '处理人(供货方)',
          dataIndex: 'returnsHandlerName',
          key:'returnsHandlerName',
          width: '55px',
          align: 'center',
          scopedSlots: { customRender: 'returnsHandlerName' },
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key:'createTime',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'createTime' },
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key:'remark',
          width: '60px',
          align: 'center',
        },
      ],
    }
  },
  methods:{
    customRow(record){
      return {
        on:{
          click:()=>{
            //console.log("点击了：",record.id)
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    showReceiveReturnModal(key){
      this.currentSelectRecordKey = key;
      this.returnModalVisible = true;

    },
    handleReturnModalCancel(){
      this.returnModalVisible = false;
      this.currentSelectRecordKey = undefined;
    },
    goodsSelectChange(value){
      //获取下拉选择框中的数据
      this.goodsChooseOption = value;
      this.queryParam.productId = value.key;
    },
    returnStatusChange(value) {
      this.queryParam.status = value;
      this.orderStatus = value;
    },
    restaurantSelectChange(value){
      this.supplierBindResChooseOption = value;
      this.queryParam.restaurantId = value.key;
    },
    companySelectChange(value){
      this.supplierBindCompanyChooseOption = value;
      this.queryParam.companyId = value.key;
    },
    supplierSelectChange(value){
      this.bindSupplierChooseOption = value;
      this.queryParam.supplierId = value.key;
    },
    receiveReturnGoods(){
      const params = {
        keyId:this.currentSelectRecordKey,
        status:1,
        returnHandlerName:this.$store.getters.userInfo.name,
      };
      this.$api.returnGoods.updateReturn(params).then(res=>{
        if(res.code===200&&res.success){
          this.$message.success("操作成功！");
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    unReceiveReturnGoods(){
      const params = {
        keyId:this.currentSelectRecordKey,
        status:2,
        returnHandlerName:this.$store.getters.userInfo.name,
      };
      this.$api.returnGoods.updateReturn(params).then(res=>{
        if(res.code===200&&res.success){
          this.$message.success("操作成功！");
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    searchReturnList(){
      let queryParam = {
        current:this.paginationList.current,
        size:this.paginationList.size,
        ...this.queryParam,
      }
      //console.log(queryParam);
      this.getReturnList(queryParam);
    },
    //拼装分页参数
    paging(parameter) {
      //console.log("paging获取到的参数",parameter)
      let queryParam = {
        current:parameter.current,
        size:parameter.size,
        ...this.queryParam,
      };
      this.getReturnList(queryParam);
    },
    resetReturnGoodsForm(){
      this.$refs.goodsSelector.value = undefined;
      this.returnStatus = undefined;
      this.supplierBindResChooseOption = {key:'',label:''};
      this.goodsChooseOption = {key:'',label:''};
      this.supplierBindCompanyChooseOption={key:'',label:''};
      //公司和餐厅绑定供应商选择项
      this.bindSupplierChooseOption={key:'',label:''};
      this.queryParam={
        receiveOrderCode: '',
        productId: undefined,
        restaurantId: undefined,
        companyId: undefined,
        supplierId: undefined,
        status: undefined,
      }
      let params = {
        current:this.paginationList.current,
        size:this.paginationList.size,
        ...this.queryParam,
      }
      this.getReturnList(params);
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
    getFormatMoney(money,isCarryBit){
      const result = formatMoney(money,2,".",",",isCarryBit);
      return result;
    },
    getReturnList(params){
      //console.log("order:",params);
      this.$api.returnGoods.selectByCondition(params).then(res=>{
        if(res.code===200){
          //console.log("结果数据：",res.data);
          this.dataSource = res.data.records;
          this.paginationList.total = res.data.total;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.returnGoods{
  .item{
  }
  .dataList{
    .select-form{
      margin: 0 0 10px 0;
    }
    ::v-deep .ant-table-thead > tr > th{
      padding: 8px;
    }
    ::v-deep .ant-table-tbody > tr > td{
      padding: 8px;
    }
    ::v-deep .clickRowStyle{
      background-color:#e6f7ff
    }
  }
}
</style>