<template>
  <div class="orderDetail">
    <a-modal
        :visible="visible"
        :confirm-loading="confirmLoading"
        okText="提交"
        cancelText="取消"
        @ok="handleOk"
        @cancel="handleCancel"
        width="750px">
      <template slot="title">
        <span style="font-size: 20px;font-weight: bolder">配送员信息填写</span>
      </template>
      <a-row>
        <a-col :span="12" style="font-weight: bold;">
          <label>订单号：</label>
          <span style="color: #92989B">
            <span v-if="orderInfo">
             {{ orderInfo.code?orderInfo.code:'暂无数据'}}
            </span>
            <span v-else>暂无数据</span>
          </span>
        </a-col>
        <a-col :span="12" style="font-weight: bold;">
          <label>所属采购批次号：</label>
          <span style="color: #92989B">
             <span v-if="orderInfo">
             {{ orderInfo.purchaseOrderTaskCode?orderInfo.purchaseOrderTaskCode:'暂无数据'}}
            </span>
            <span v-else>暂无数据</span>
            </span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="12" style="font-weight: bold;">
          <label>供应商：</label>
          <span style="color: #92989B">
            <span v-if="orderInfo">
             {{ orderInfo.supplierName?orderInfo.supplierName:'暂无数据'}}
            </span>
            <span v-else>暂无数据</span>
          </span>
        </a-col>
        <a-col :span="12" style="font-weight: bold;">
          <label>创建时间：</label>
          <span style="color: #92989B">
             {{getFormatDate(new Date()) }}
          </span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="24" style="font-weight: bold;">
          <a-form layout="inline" :form="senderAddForm">
            <a-row>
              <a-col :span="12">
                <a-form-item label="配送员类型" :labelCol="{span:11}"
                             :wrapperCol=wrapperCol>
                  <a-select
                      v-decorator="['type',rules.type]"
                      :getPopupContainer="trigger => trigger.parentNode"
                      placeholder="请选择"
                      type="text"
                      style="width: 110px"
                      @change="showStaffSelector"
                      allowClear>
                    <a-select-option :key="0"
                                     :value="0">
                      员工
                    </a-select-option>
                    <a-select-option :key="1"
                                     :value="1">
                      第三方配送人员
                    </a-select-option>
                  </a-select>

                </a-form-item>
              </a-col>
              <a-col :span="12" v-if="staffSelectorEnable">
                <a-form-item v-if="staffSelectorEnable"
                             label="选择员工" :labelCol="labelCol"
                             :wrapperCol="wrapperCol">
                  <staff-selector ref="staffSelector"
                                  :current-value="supplierStaffChooseOption"
                                  @staffSelectChange="staffSelectChange"
                                  @staffCurrentSelect="staffCurrentSelect"/>
                </a-form-item>
              </a-col>
              <a-col :span="12" v-if="!staffSelectorEnable">
                <a-form-item v-if="!staffSelectorEnable"
                             label="姓名" :labelCol="labelCol"
                             :wrapperCol="wrapperCol">
                  <a-input
                      ref="name"
                      v-decorator="['name',rules.name]"
                      type="text"
                      style="width: 160px"/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-item label="性别" :labelCol="labelCol"
                             :wrapperCol="wrapperCol">
                  <a-select
                      v-decorator="['sex',rules.sex]"
                      :getPopupContainer="trigger => trigger.parentNode"
                      placeholder="请选择"
                      type="text"
                      :disabled="addStaffInfoDisabled"
                      style="width: 100px"
                      allowClear>
                    <a-select-option :key="0"
                                     :value="0">
                      女
                    </a-select-option>
                    <a-select-option :key="1"
                                     :value="1">
                      男
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="手机号" :labelCol="labelCol"
                             :wrapperCol="wrapperCol">
                  <a-input
                      ref="phone"
                      v-decorator="['phone',rules.phone]"
                      type="text"
                      :disabled="addStaffInfoDisabled"
                      style="width: 155px"/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
              <a-form-item label="备注">
                <a-textarea v-decorator="['remark',rules.remark]"
                            placeholder="备注信息"
                            rows="4"
                            style="width:700px"
                            allowClear/>
              </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import {dateFormat} from "@/utils/common";
import StaffSelector from "@/components/staffSelector";

export default {
  name: "addSender",
  components: {StaffSelector},
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    orderInfo:{
      type:Object,
    }
  },
  inject:['reload'],
  data(){
    return{
      labelCol: {
        xs: {span: 24},
        sm: {span: 8} //控制label的框的长度
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 10}//控制文本框的长度
      },
      confirmLoading:false,
      customVisible:this.visible,
      staffSelectorEnable:false,
      supplierStaffChooseOption:{key:'',label:''},
      addStaffInfoDisabled:false,
      senderAddForm: this.$form.createForm(this, {name: 'sender_add_form'}),
      addParams:{
        orderId:undefined,
        deliveryType:undefined,
        staffId:undefined,
        supplierId:undefined,
        name:undefined,
        phone:undefined,
        sex:undefined,
        remark:undefined,
      },
      rules: {
        type: {
          rules: [
            {required: true, message: '请选择'},
          ]
        },
        name:{
          rules: [
            {required: true, message: '请输入第三方配送员名称!'},
          ]
        },
        sex:{
          rules: [
            {required: true, message: '请选择'},
          ]
        },
        phone:{
          rules: [
            {required: true, message: '请输入配送员手机号!'},
          ]
        },
        remark:{
          rules:[
            {required: false, message: '请填写备注信息'},
          ],
        }
      },
    }
  },
  methods:{
    handleCancel(){
      this.customVisible = false;
      this.staffSelectorEnable = false;
      this.senderAddForm.resetFields();
      this.supplierStaffChooseOption = {key:'',label:''};
      this.addStaffInfoDisabled = false;
      this.$emit("changeAddSenderDetail",undefined);
      this.$emit("changeAddSenderVisible",this.customVisible);
    },
    showStaffSelector(value){
      if(value===0){
        this.staffSelectorEnable = true;
      }else{
        this.staffSelectorEnable = false;
        this.addStaffInfoDisabled = false;
        this.senderAddForm.setFieldsValue({
          sex:undefined,
          phone:undefined,
        })
        this.supplierStaffChooseOption = {key:'',label:''};
      }
    },
    handleOk(){
      this.senderAddForm.validateFields((err,values)=>{
        if(!err){
          this.addParams.deliveryType = values.type;
          if(this.addParams.deliveryType===1){
            this.addParams.name = values.name;
          }
          this.addParams.orderId = this.orderInfo.id;
          this.addParams.supplierId = this.orderInfo.supplierId;
          this.addParams.sex = values.sex;
          this.addParams.phone = values.phone;
          this.addParams.remark = values.remark;
          //调用修改或保存接口
          this.$api.sender.addSender(this.addParams).then(res=>{
            if(res.code===200&&res.success){
              this.$message.success("操作成功！");
              this.reload();
            }else{
              if(res.msg){
                this.$message.error(res.msg);
              }
            }
          })
        }
      })
    },
    staffSelectChange(value){
      this.supplierStaffChooseOption = value;
      this.addParams.staffId = value.key;
      this.addParams.name = value.label;
    },
    staffCurrentSelect(value){
      this.addStaffInfoDisabled = true;
      this.senderAddForm.setFieldsValue({
        sex: value.sex,
        phone: value.phone,
      })
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
  },
  computed:{
  },
}
</script>

<style scoped>

</style>