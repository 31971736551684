<template>
  <a-row :gutter="40" class="panel-group">
    <a-col :xs="12" :sm="12" :lg="6" class="card-panel-col">

    </a-col>
  </a-row>
</template>

<script>
export default {
  name: "panelGroup"
}
</script>

<style scoped>

</style>