<template>
  <div class="storage">
    <div class="item dataList">
      <a-row>
        <a-col :span="24">
          <a-row class="select-form">
            <a-col :span="24">
              <a-form layout="inline" :form="searchOrderForm">
                <a-form-item>
                  <a-input
                      size="large"
                      placeholder="请输入订单号"
                      v-model.trim="queryParam.purchaseOrderCode"
                      clearable
                  >
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-input
                      size="large"
                      placeholder="请输入验收单号"
                      v-model.trim="queryParam.receiveCode"
                      clearable
                  >
                  </a-input>
                </a-form-item>
                <a-form-item v-if="userType===0">
                  <supplier-selector ref="supplierSelector"
                                     :current-value="bindSupplierChooseOption"
                                     @supplierSelectChange="supplierSelectChange"/>
                </a-form-item>
                <a-form-item v-if="userRoles.indexOf('2')!==-1">
                  <restaurant-selector ref="restaurantSelector"
                                       :current-value="supplierBindResChooseOption"
                                       @restaurantSelectChange="restaurantSelectChange"/>
                </a-form-item>
                <a-form-item v-if="userType===1">
                  <company-selector ref="companySelector"
                                    :current-value="supplierBindCompanyChooseOption"
                                    @companySelectChange="companySelectChange"/>
                </a-form-item>
                <a-form-item>
                  <goods-selector ref="goodsSelector"
                                  :current-value="goodsChooseOption"
                                  :company-id="this.queryParam.companyId"
                                  @goodsSelectChange="goodsSelectChange"></goods-selector>
                </a-form-item>
                <a-form-item>
                  <a-select v-model="orderStatus"
                            :getPopupContainer="trigger => trigger.parentNode"
                            allowClear
                            style="width: 110px"
                            size="large"
                            placeholder="入库类型"
                            @change="orderStatusChange">
                    <a-select-option :key="0"
                                     value="0">
                      验收入库
                    </a-select-option>
                    <a-select-option :key="1"
                                     value="1">
                      手动入库
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-row type="flex">
                  <a-form-item style=" align-items:start; margin: 10px 0 0 0;">
                    <a-col :span="8">
                      <a-button
                          size="large"
                          type="primary"
                          @click="showAddModal">
                        <a-icon type="edit" class="PlusCircleOutlined"/>
                        入库
                      </a-button>
                    </a-col>
                    <a-col :span="8" :push="2">
                      <a-button
                          size="large"
                          type="primary"
                          style="margin-left: 5px"
                          @click="searchOrderTask">查询
                      </a-button>
                    </a-col>

                    <a-col :span="8" :push="4">
                      <a-button size="large" @click="resetStorageOrderForm">
                        重置
                      </a-button>
                    </a-col>
                  </a-form-item>
                </a-row>
              </a-form>
            </a-col>
          </a-row>

        </a-col>

      </a-row>
      <a-table rowKey="id"
               :pagination="false"
               :columns="columns"
               :data-source="dataSource"
               :customRow="customRow"
               :row-class-name="setRowClassName"
               style="width: 100%;"
               bordered
      >
        <template slot="empty">
          <a-empty description="暂无数据"></a-empty>
        </template>
        <template slot="purchaseOrderCode" slot-scope="text">
          <router-link v-if="text" to="/purchase/task/list">{{ text }}</router-link>
          <span v-else style="text-align: center;font-size: 24px">--</span>
        </template>
        <template slot="receiveOrderCode" slot-scope="text">
          <router-link v-if="text" to="/receive/order/list">{{ text }}</router-link>
          <span v-else style="text-align: center;font-size: 24px">--</span>
        </template>
        <template slot="restaurantName" slot-scope="text">
          <span v-if="text" style="text-align: center">{{ text }}</span>
          <span v-else style="text-align: center;font-size: 24px">--</span>
        </template>
        <template slot="supplierName" slot-scope="text">
          <span v-if="text" style="text-align: center">{{ text }}</span>
          <span v-else style="text-align: center;font-size: 24px">--</span>
        </template>
        <template slot="storageType" slot-scope="text">
          <span v-if="text===0" style="color: #108ee9">验收入库</span>
          <span v-if="text===1" style="color: #FF9502">手动入库</span>
        </template>
        <template slot="createTime" slot-scope="text">
          {{ getFormatDate(text) }}
        </template>
        <template slot="action" slot-scope="text,record">
          <div v-if="userType===0">
            <a-tooltip placement="top" title="编辑" autoAdjustOverflow>
              <a-button type="primary" size="small" icon="edit" @click="editStorageOrder(record)"/>
            </a-tooltip>
            <a-divider type="vertical"/>
            <a-popconfirm title="删除?"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="() => delStorageOrder(record.id)">
              <a-icon slot="icon" type="question-circle-o" style="color: red"/>
              <a-button type="danger" size="small" icon="close-circle"/>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
      <pageination style="float: right"
                   :paginationList="paginationList"
                   @paging="paging">
      </pageination>
    </div>
    <div class="add">
      <add-storage :add-visible="addVisible" @changeAddVisible="changeAddVisible"></add-storage>
    </div>
    <div class="edit">
      <edit-storage ref="editStorage"
                    :edit-visible="editVisible" :storage-info="editParam"
                    @changeStorageName="changeStorageName"
                    @changeStorageUnit="changeStorageUnit"
                    @changeStorageNum="changeStorageNum"
                    @changeStorageSupplier="changeStorageSupplier"
                    @changeRemark="changeRemark"
                    @changeEditVisible="changeEditVisible">

      </edit-storage>
    </div>
  </div>
</template>

<script>
import Pageination from "@/components/pageination";
import GoodsSelector from "@/components/goodsSelector";
import CompanySelector from "@/components/companySelector";
import RestaurantSelector from "@/components/restaurantSelector";
import SupplierSelector from "@/components/supplierSelector";
import {dateFormat} from "@/utils/common";
import AddStorage from "@/views/product/addStorage";
import EditStorage  from "@/views/product/editStorage"

export default {
  name: "index",
  components: {EditStorage,AddStorage, SupplierSelector, RestaurantSelector, CompanySelector, GoodsSelector, Pageination},
  inject: ['reload'],
  created() {
    const item1 = {
      title: '采购门店所属公司',
      dataIndex: 'companyName',
      key: 'companyName',
      width: '140px',
      align: 'center',
    }
    const item2 = {
      title: '采购门店',
      dataIndex: 'restaurantName',
      key: 'restaurantName',
      width: '100px',
      align: 'center',
      scopedSlots: {customRender: 'restaurantName'},
    }
    const item4 = {
      title: '供应商',
      dataIndex: 'supplierName',
      key: 'supplierName',
      width: '70px',
      align: 'center',
      scopedSlots: {customRender: 'supplierName'},
    }
    if (this.userType === 1) {
      this.columns.splice(2, 0, item1, item2);
      if (this.userRoles.indexOf("6") !== -1) {
        this.ensureBtnStatus = false;
        this.cancelBtnStatus = false;
      }
    }
    if (0 === this.userType) {
      if (this.userRoles.indexOf("2") !== -1) {
        this.columns.splice(2, 0, item2, item4);
      }
      if (this.userRoles.indexOf("3") !== -1) {
        this.columns.splice(2, 0, item2, item4);
      }
      if (this.userRoles.indexOf("4") !== -1) {
        this.columns.splice(2, 0, item4);
      }
    }


    let queryParam = {
      current: this.paginationList.current,
      size: this.paginationList.size,
      ...this.queryParam,
    }
    this.getStorageOrderList(queryParam);
    this.queryObj = this.$route.query.id;
  },
  data() {
    return {
      queryObj: undefined,
      cancelBtnStatus: true,
      ensureBtnStatus: true,
      addVisible: false,
      editVisible: false,

      userType: this.$store.getters.userInfo.staffType,
      userRoles: this.$store.getters.userInfo.roleIds,
      searchOrderForm: this.$form.createForm(this, {name: 'order_form'}),
      selectedRowId: undefined,
      queryParam: {
        purchaseOrderCode: '',
        receiveCode: '',
        productId: undefined,
        restaurantId: undefined,
        companyId: undefined,
        supplierId: undefined,
        status: undefined,
      },
      editParam: {
        id:undefined,
        productId: undefined,
        productName:undefined,
        unit:undefined,
        companyId:undefined,
        supplierId: undefined,
        supplierName:undefined,
        storageNum: 0,
        storageRemark: undefined,
      },
      goodsChooseOption: {key: '', label: ''},
      supplierBindResChooseOption: {key: '', label: ''},
      supplierBindCompanyChooseOption: {key: '', label: ''},
      //公司和餐厅绑定供应商选择项
      bindSupplierChooseOption: {key: '', label: ''},
      paginationList: {
        size: 10,
        current: 1,
        total: 0,
        pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
      },
      orderStatus: undefined,
      dataSource: [],
      columns: [
        {
          title: '订单号',
          dataIndex: 'purchaseOrderCode',
          key: 'purchaseOrderCode',
          width: '120px',
          align: 'center',
          scopedSlots: {customRender: 'purchaseOrderCode'},
        },
        {
          title: '验收单号',
          dataIndex: 'receiveOrderCode',
          key: 'receiveOrderCode',
          width: '120px',
          align: 'center',
          scopedSlots: {customRender: 'receiveOrderCode'},
        },
        {
          title: '商品名称',
          dataIndex: 'productName',
          key: 'productName',
          width: '70px',
          align: 'center',
        },
        {
          title: '入库数',
          dataIndex: 'storageNum',
          key: 'storageNum',
          width: '100px',
          align: 'center',
        },

        {
          title: '入库人',
          dataIndex: 'storageStaffName',
          key: 'storageStaffName',
          width: '100px',
          align: 'center',
        },
        {
          title: '入库类型',
          dataIndex: 'storageType',
          key: 'storageType',
          width: '100px',
          align: 'center',
          scopedSlots: {customRender: 'storageType'},
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: '100px',
          align: 'center',
          scopedSlots: {customRender: 'createTime'},
        },
        {
          title: '备注',
          dataIndex: 'storageRemark',
          key: 'storageRemark',
          width: '60px',
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: '100px',
          align: 'center',
          scopedSlots: {customRender: 'action'},
        },
      ],
    }
  },
  methods: {
    customRow(record) {
      return {
        on: {
          click: () => {
            // console.log(event);
            //console.log("点击了：",record.id)
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record) {
      if (record.id === this.selectedRowId) {
        return 'clickRowStyle'
      }
    },
    changeEditVisible(value) {
      this.editVisible = value;
    },
    changeAddVisible(value) {
      this.addVisible = value;
    },
    goodsSelectChange(value) {
      //获取下拉选择框中的数据
      this.goodsChooseOption = value;
      this.queryParam.productId = value.key;
    },
    orderStatusChange(value) {
      this.queryParam.status = value;
      this.orderStatus = value;
    },
    restaurantSelectChange(value) {
      this.supplierBindResChooseOption = value;
      this.queryParam.restaurantId = value.key;
    },
    companySelectChange(value) {
      this.supplierBindCompanyChooseOption = value;
      this.queryParam.companyId = value.key;
    },
    supplierSelectChange(value) {
      this.bindSupplierChooseOption = value;
      this.queryParam.supplierId = value.key;
    },
    searchOrderTask() {
      let queryParam = {
        current: this.paginationList.current,
        size: this.paginationList.size,
        ...this.queryParam,
      }
      //console.log(queryParam);
      this.getStorageOrderList(queryParam);
    },
    showAddModal() {
      this.addVisible = true;
    },
    //拼装分页参数
    paging(parameter) {
      //console.log("paging获取到的参数",parameter)
      let queryParam = {
        current: parameter.current,
        size: parameter.size,
        ...this.queryParam,
      };
      this.getStorageOrderList(queryParam);
    },
    resetStorageOrderForm() {
      this.$refs.goodsSelector.value = undefined;
      this.orderStatus = undefined;
      this.supplierBindResChooseOption = {key: '', label: ''};
      this.goodsChooseOption = {key: '', label: ''};
      this.supplierBindCompanyChooseOption = {key: '', label: ''};
      //公司和餐厅绑定供应商选择项
      this.bindSupplierChooseOption = {key: '', label: ''};
      this.queryParam = {
        purchaseOrderCode: '',
        receiveCode: '',
        productId: undefined,
        restaurantId: undefined,
        companyId: undefined,
        supplierId: undefined,
        status: undefined,
      }
      let params = {
        current: this.paginationList.current,
        size: this.paginationList.size,
        ...this.queryParam,
      }
      this.getStorageOrderList(params);
    },
    getFormatDate(text) {
      const result = dateFormat(text, 'YYYY-MM-DD HH:mm:ss');
      return result;
    },
    getStorageOrderList(params) {
      //console.log("order:",params);
      this.$api.storageOrder.selectByCondition(params).then(res => {
        if (res.code === 200) {
          this.dataSource = res.data.records;
          //console.log("dataSource",this.dataSource);
          this.paginationList.total = res.data.total;
        } else {
          if (res.msg) {
            this.$message.error(res.msg);
          }
        }
      })
    },
    //edit
    editStorageOrder(record) {
      this.$store.state.storageInfo = record;
      let item = JSON.parse(JSON.stringify(this.$store.getters.storageInfo));
      this.editParam = item;
      this.editVisible = true;
    },
    changeStorageName(value){
      this.editParam.productId = value.key;
    },
    changeStorageUnit(value){
      this.editParam.unit = value;
    },
    changeStorageNum(value){
      this.editParam.storageNum = value;
    },
    changeStorageSupplier(value){
      this.editParam.supplierId = value;
    },
    changeRemark(value){
      this.editParam.storageRemark = value;
    },
    //Del
    async delStorageOrder(id) {
      let delIds = {
        ids: id,
      };
      const res = await this.$api.storageOrder.removeById(delIds);
      if (res.code === 200) {
        this.$message.success("删除成功！")
        this.reload();
      } else {
        this.$message.error(res.msg);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.storage {
  .item {
  }

  .dataList {
    .select-form {
      margin: 0 0 10px 0;
    }

    ::v-deep .ant-table-thead > tr > th {
      padding: 8px;
    }

    ::v-deep .ant-table-tbody > tr > td {
      padding: 8px;
    }

    ::v-deep .clickRowStyle {
      background-color: #e6f7ff
    }
  }
}
</style>