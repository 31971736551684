<template>
  <div class="receiveInfo">
    <div class="item dataList">
      <a-row>
        <a-col :span="24">
          <a-row class="select-form">
            <a-col :span="24">
              <a-form layout="inline" :form="searchOrderForm">
                <a-form-item>
                  <a-input
                      size="large"
                      placeholder="请输入订单所属批次号"
                      v-model.trim="queryParam.receiveOrderCode"
                      clearable
                  >
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-input
                      size="large"
                      placeholder="请输入订单号"
                      v-model.trim="queryParam.purchaseOrderCode"
                      clearable
                  >
                  </a-input>
                </a-form-item>
                <a-form-item v-if="userType===0">
                  <supplier-selector ref="supplierSelector"
                                     :current-value="bindSupplierChooseOption"
                                     @supplierSelectChange="supplierSelectChange"/>
                </a-form-item>
                <a-form-item v-if="userRoles.indexOf('2')!==-1">
                  <restaurant-selector ref="restaurantSelector"
                                       :current-value="supplierBindResChooseOption"
                                       @restaurantSelectChange="restaurantSelectChange"/>
                </a-form-item>
                <a-form-item v-if="userType===1">
                  <company-selector ref="companySelector"
                                    :current-value="supplierBindCompanyChooseOption"
                                    @companySelectChange="companySelectChange"/>
                </a-form-item>
                <a-row>
                  <a-form-item style=" margin: 10px 0 0 0;">
                    <a-col :span="8">
                      <a-button
                          size="large"
                          type="primary"
                          @click="searchOrderTask">查询
                      </a-button>
                    </a-col>
                    <a-col :span="8" offset="8">
                      <a-button size="large" @click="resetReceiveOrderForm">重置</a-button>
                    </a-col>
                  </a-form-item>
                </a-row>
              </a-form>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-table rowKey="id"
               :pagination="false"
               :columns="columns"
               :data-source="dataSource"
               :customRow="customRow"
               :row-class-name="setRowClassName"
               style="width: 100%;"
               bordered
      >
        <template slot="empty">
          <a-empty description="暂无数据"></a-empty>
        </template>
        <template slot="purchaseOrderCode" slot-scope="text,record">
          <router-link :to="{path:'/order/list',query:{id:record.purchaseOrderId}}" tag="a">{{text}}</router-link>
        </template>
        <template slot="hasRefund" slot-scope="text">
          <span v-if="text==0" style="color: silver">无</span>
          <span v-if="text==1" style="color: red">有</span>
        </template>
        <template slot="totalPrice" slot-scope="text">
          <span style="color: red">
            ￥{{getFormatMoney(text,true)}}
          </span>
        </template>
        <template slot="receiveOrderDetail" slot-scope="text,record">
          <a-tag  style="color: #2db7f5;font-size: 12px" @click="getReceiveOrderDetail(record.id)">
            验收单详细信息，点击查看>>>
          </a-tag >
        </template>
        <template slot="receiveTotalPrice" slot-scope="text">
          <span style="color: red">
            ￥{{getFormatMoney(text,true)}}
          </span>
        </template>
        <template slot="returnTotalPrice" slot-scope="text">
          <span v-if="text" style="color: red">
            ￥{{getFormatMoney(text,true)}}
          </span>
          <span v-else style="color:silver;">
             ￥{{getFormatMoney(0,true)}}
          </span>
        </template>
        <template slot="receiveTime" slot-scope="text">
          {{getFormatDate(text)}}
        </template>
      </a-table>
      <pageination  style="float: right"
                    :paginationList="paginationList"
                    @paging="paging">
      </pageination>
    </div>
    <div>
      <receive-detail :visible="receiveOrderDetailVisible"
                    :receive-detail="receiveOrderDetail"
                    @changeReceiveOrderDetail="changeReceiveOrderDetail"
                    @changeReceiveDetailVisible="changeReceiveDetailVisible"/>
    </div>
  </div>
</template>

<script>

import SupplierSelector from "@/components/supplierSelector";
import RestaurantSelector from "@/components/restaurantSelector";
import CompanySelector from "@/components/companySelector";
import Pageination from "@/components/pageination";
import {dateFormat, formatMoney} from "@/utils/common";
import ReceiveDetail from "@/views/receive/receiveDetail";

export default {
  name: "index",
  components: {
    ReceiveDetail,
    SupplierSelector, RestaurantSelector, CompanySelector, Pageination},
  inject:['reload'],
  created() {
    const item1= {
      title: '采购门店所属公司',
      dataIndex: 'companyName',
      key:'companyName',
      width: '140px',
      align: 'center',
    }
    const item2 =  {
      title: '采购门店',
      dataIndex: 'restaurantName',
      key:'restaurantName',
      width: '100px',
      align: 'center',
    }
    const item4 = {
      title: '供应商',
      dataIndex: 'supplierName',
      key:'supplierName',
      width: '70px',
      align: 'center',
    }
    if(this.userType===1){
      this.columns.splice(2,0,item1,item2);
    }
    if(0===this.userType){
      if(this.userRoles.indexOf("2")!==-1){
        this.columns.splice(2,0,item2,item4);
      }
      if(this.userRoles.indexOf("3")!==-1){
        this.columns.splice(2,0,item2,item4);
      }
      if(this.userRoles.indexOf("4")!==-1||this.userRoles.indexOf("5")!==-1){
        this.columns.splice(2,0,item4);
      }
    }


    let queryParam = {
      current:this.paginationList.current,
      size:this.paginationList.size,
      ...this.queryParam,
    }
    this.getReceiveOrderList(queryParam);
  },
  data(){
    return{
      receiveOrderDetailVisible:false,
      receiveOrderDetail:undefined,

      userType:this.$store.getters.userInfo.staffType,
      userRoles:this.$store.getters.userInfo.roleIds,
      searchOrderForm: this.$form.createForm(this, {name: 'order_form'}),
      selectedRowId:undefined,
      queryParam:{
        receiveOrderCode:'',
        purchaseOrderCode:'',
        restaurantId:undefined,
        companyId:undefined,
        supplierId:undefined,
        purchaseOrderId:undefined,
      },
      supplierBindResChooseOption:{key:'',label:''},
      supplierBindCompanyChooseOption:{key:'',label:''},
      //公司和餐厅绑定供应商选择项
      bindSupplierChooseOption:{key:'',label:''},
      paginationList:{
        size:10,
        current:1,
        total:0,
        pageSizeOptions: ['10', '20', '30', '40', '50','100'],
      },
      dataSource:[],
      columns:[
        {
          title: '订单号',
          dataIndex: 'purchaseOrderCode',
          key:'purchaseOrderCode',
          width: '120px',
          align: 'center',
          scopedSlots: {customRender: 'purchaseOrderCode'},
        },
        {
          title: '验收单号',
          dataIndex: 'code',
          key:'code',
          width: '120px',
          align: 'center',
        },
        {
          title: '退货',
          dataIndex: 'hasRefund',
          key:'hasRefund',
          width: '40px',
          align: 'center',
          scopedSlots: { customRender: 'hasRefund' },
        },
        {
          title: '验收单明细',
          dataIndex: 'receiveOrderDetail',
          key:'receiveOrderDetail',
          width: '70px',
          align: 'center',
          scopedSlots: { customRender: 'receiveOrderDetail' },
        },
        {
          title: '订单总价',
          dataIndex: 'totalPrice',
          key:'totalPrice',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'totalPrice' },
        },
        {
          title: '验收总价',
          dataIndex: 'receiveTotalPrice',
          key:'receiveTotalPrice',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'receiveTotalPrice' },
        },
        {
          title: '退货总价',
          dataIndex: 'returnTotalPrice',
          key:'returnTotalPrice',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'returnTotalPrice' },
        },
        {
          title: '收货时间',
          dataIndex: 'receiveTime',
          key:'receiveTime',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'receiveTime' },
        },
        {
          title: '备注',
          dataIndex: 'receiveRemark',
          key:'receiveRemark',
          width: '60px',
          align: 'center',
        },
      ],
    }
  },
  methods:{
    customRow(record){
      return {
        on:{
          click:(event)=>{
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    restaurantSelectChange(value){
      this.supplierBindResChooseOption = value;
      this.queryParam.restaurantId = value.key;
    },
    companySelectChange(value){
      this.supplierBindCompanyChooseOption = value;
      this.queryParam.companyId = value.key;
    },
    supplierSelectChange(value){
      this.bindSupplierChooseOption = value;
      this.queryParam.supplierId = value.key;
    },

    getReceiveOrderDetail(key){
      this.$api.receiveOrder.detail(key).then(res=>{
        if(res.code===200&&res.success){
          this.receiveOrderDetail = res.data;
          this.receiveOrderDetailVisible = true;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      });
    },
    changeReceiveOrderDetail(value){
      this.receiveOrderDetail = value;
    },

    changeReceiveDetailVisible(value){
      this.receiveOrderDetailVisible = value;
    },
    searchOrderTask(){
      let queryParam = {
        current:this.paginationList.current,
        size:this.paginationList.size,
        ...this.queryParam,
      }
      this.getReceiveOrderList(queryParam);
    },
    //拼装分页参数
    paging(parameter) {
      let queryParam = {
        current:parameter.current,
        size:parameter.size,
        ...this.queryParam,
      };
      this.getReceiveOrderList(queryParam);
    },
    resetReceiveOrderForm(){
      this.supplierBindResChooseOption = {key:'',label:''};
      this.supplierBindCompanyChooseOption={key:'',label:''};
      //公司和餐厅绑定供应商选择项
      this.bindSupplierChooseOption={key:'',label:''};
      this.queryParam={
        receiveOrderCode: '',
        purchaseOrderCode: '',
        productId: undefined,
        restaurantId: undefined,
        companyId: undefined,
        supplierId: undefined,
      }
      let params = {
        current:this.paginationList.current,
        size:this.paginationList.size,
        ...this.queryParam,
      }
      this.getReceiveOrderList(params);
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
    getFormatMoney(money,isCarryBit){
      const result = formatMoney(money,2,".",",",isCarryBit);
      return result;
    },
    getReceiveOrderList(params){

      this.$api.receiveOrder.selectByCondition(params).then(res=>{
        if(res.code===200){
          this.dataSource = res.data.records;
          this.paginationList.total = res.data.total;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.receiveInfo{
  .item{
  }
  .dataList{
    .select-form{
      margin: 0 0 10px 0;
    }
    ::v-deep .ant-table-thead > tr > th{
      padding: 8px;
    }
    ::v-deep .ant-table-tbody > tr > td{
      padding: 8px;
    }
    ::v-deep .clickRowStyle{
      background-color:#e6f7ff
    }
  }
}
</style>