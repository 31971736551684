<template>
  <div>
    <a-tree
        v-model="checkedKeys"
        :expandedKeys.sync="defaultExpandedKeys"
        :tree-data="treeData"
        @check="selectedChange"
        checkable
    />
  </div>
</template>

<script>

export default {
  name: "permission",
  created() {
    this.$api.permission.permissionTree().then(res=>{
      if(res.code===200&&res.success){
        this.sourceData = res.data;
        //console.log(this.sourceData);
      }
    })
  },
  data(){
    return {
      sourceData:undefined,
      treeData:undefined,
      defaultExpandedKeys:[],
      checkedKeys:[],
      allCheckedKeys:[],
    }
  },
  methods:{
    getTreeNode(subItem){
      if(subItem) {
        return subItem.map(item => {
          const result = {
            key: item.id,
            pid:item.pid,
            level:item.level,
            title: item.name,
            value:item.permissionValue,
            children: item.subList,
          }
          if (result.children) {
            result.children = this.getTreeNode(result.children);
          }
          return result;
        });
      }else{
        return null;
      }
    },
    selectedChange(selectedKeys,info){
      const allSelectedKeys = selectedKeys.concat(info.halfCheckedKeys);
      this.allCheckedKeys = allSelectedKeys;
      //console.log("包含父节点的选中节点：",this.allCheckedKeys);
    },
  },
  watch:{
    sourceData(newValue){
      if(newValue){
        const sourceData = this.sourceData;
        this.treeData = [];
        if(sourceData) {
          this.treeData = sourceData.map(e => {
            return {
              key:  e.id,
              pid:e.pid,
              level:e.level,
              title: e.name,
              value:e.permissionValue,
              children: this.getTreeNode(e.subList),
            }
          });
        }
      }
    },
  }
}
</script>

<style scoped>

</style>