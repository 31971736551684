<template>
  <div>
    <a-modal title="添加员工"
             :visible="addVisible"
             :confirm-loading="confirmLoading"
             okText="确定"
             cancelText="取消"
             @ok="handleOk"
             @cancel="handleCancel"
             width="350px">
      <a-form class="add-staff-form"
              :form="addStaffForm"
              label-width="100px">
        <a-row>
          <a-form-item label="员工名称" :label-col="{span:8}" :wrapper-col="{ span:16}">
            <a-input
                v-decorator="['name',rules.name]"
                type="text"
                key="0"
                allowClear>
            </a-input>
          </a-form-item>
          <a-form-item label="员工性别" :label-col="{span:8}" :wrapper-col="{ span:16}">
                <a-select
                    v-decorator="['sex',rules.sex]"
                    placeholder="请选择"
                    type="text"
                    key="1"
                    allowClear>
                  <a-select-option :key="0"
                                   value="0">
                    女
                  </a-select-option>
                  <a-select-option :key="1"
                                   value="1">
                    男
                  </a-select-option>
                </a-select>
          </a-form-item>
          <a-form-item label="员工手机号" :label-col="{span:8}" :wrapper-col="{ span:16}">
            <a-input
                v-decorator="['phone',rules.phone]"
                style="width: 100%"
                :maxLength="11"
                addon-before="+86"
                allowClear
            >
            </a-input>
          </a-form-item>
          <a-form-item label="密码" :label-col="{span:8}" :wrapper-col="{ span:16}" has-feedback>
            <a-input
                v-decorator="['password',rules.password]"
                type="password"
                allowClear
            />
          </a-form-item>
          <a-form-item label="确认密码" :label-col="{span:8}" :wrapper-col="{ span:16}" has-feedback>
            <a-input v-decorator="['repeatPwd',rules.repeatPwd]"
                     type="password"
                     allowClear
                     @blur="handleConfirmBlur"
            />
          </a-form-item>
          <a-form-item v-if="userType===0" label="所属门店" :label-col="{span:8}" :wrapper-col="{ span:16}">
            <restaurant-selector ref="restaurantSelector" :current-value="chooseOption"
                                 @restaurantSelectChange="restaurantSelectChange"/>
          </a-form-item>
          <a-form-item label="所属公司" :label-col="{span:8}" :wrapper-col="{ span:16}">
            <span>{{ companyName }}</span>
          </a-form-item>
          <a-form-item label="选择角色" >
            <a-collapse :bordered="false" @change="changeActiveKey">
              <a-collapse-panel key="1" header="角色列表">
                <a-checkbox :indeterminate="isIndeterminate" :checked="checkAll" @change="onCheckAllChange">
                  全选
                </a-checkbox>
                <div style="margin: 15px 0"></div>
                <a-row>
                  <a-col :span="24">
                    <a-checkbox-group v-model="checkedRoleList" @change="handleCheckedChange">
                      <a-col :span="24">
                        <a-checkbox style="margin-left:20px;margin-top: 10px" v-for="role in allRoles" :key="role.id"
                                    :value="role.id">{{ role.roleName }}
                        </a-checkbox>
                      </a-col>
                    </a-checkbox-group>
                  </a-col>
                </a-row>
              </a-collapse-panel>
            </a-collapse>
          </a-form-item>
          <a-form-item label="备注">
            <a-textarea v-decorator="['remark',rules.remark]"
                        placeholder="备注信息"
                        rows="4"
                        allowClear/>
          </a-form-item>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import RestaurantSelector from "@/components/restaurantSelector";
export default {
  name: "addStaff",
  components: {RestaurantSelector},
  props:{
    addVisible:{
      type:Boolean,
    },
  },
  inject:["reload"],
  data(){
    return {
      customVisible:this.addVisible,
      confirmLoading:false,
      confirmDirty: false,
      addStaffForm:this.$form.createForm(this, {name: 'add_role_form'}),
      chooseOption:{key:'',label:''},
      companyName:this.$store.getters.userInfo.companyName,
      userType:this.$store.getters.userInfo.staffType,
      restaurantId:undefined,
      companyId:this.$store.getters.userInfo.companyId,
      rules:{
        name: {
          rules: [
            {required: true, message: '请输入员工名称!'},
          ]
        },
        sex: {
          rules: [
            {required: true, message: '请选择员工性别!'},
          ]
        },
        phone: {
          rules: [
            {required: true, message: '请输入员工电话!'},
          ]
        },
        password: {
          rules: [
            {required: true, message: '请输入密码!'},
            {validator: this.validateToNextPassword},
          ]
        },
        repeatPwd: {
          rules: [
            {required: true, message: '请确认你的密码!'},
            {validator: this.compareToFirstPassword},
          ]
        },
        remark: {
          rules:[
            {required: false, message: '填写备注信息'},
          ]
        },
      },
      isIndeterminate:false,
      checkAll:false,
      checkedRoleList:[], //用户的角色列表
      allRoles:[],
    }
  },
  methods:{
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.addStaffForm;
      if (value && value !== form.getFieldValue('password')) {
        callback('两次密码不一致！！');
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.addStaffForm;
      if (value && this.confirmDirty) {
        form.validateFields(['repeatPwd'], { force: true });
      }
      callback();
    },
    restaurantSelectChange(value){
      this.chooseOption = value;
      this.restaurantId = value.key;
    },
    handleOk(){
      this.confirmLoading = true;
      if(this.userType===0) {
        if (!this.restaurantId) {
          this.$message.error("门店未选择");
          return;
        }
      }
      this.addStaffForm.validateFields((err, values) => {
        if (!err) {
          const roles = this.checkedRoleList.join(",");
          const params = {
            companyId: this.companyId,
            roleIds:roles,
            ...values
          }
          if(this.userType===0){
            params.restaurantId = this.restaurantId;
          }
          this.$api.staff.addStaff(params).then(res => {
            if (res.code === 200 && res.success) {
              this.$message.success("添加成功！");
              this.confirmLoading = false;
              this.reload();
            } else {
              if (res.msg) {
                this.$message.error(res.msg);
              }
            }
          });
        }
      });
      this.confirmLoading = false;
    },
    handleCancel(){
      this.addStaffForm.resetFields();
      this.restaurantId=undefined;
      this.companyId = this.$store.getters.userInfo.companyId;
      this.customVisible = false;
      this.checkedRoleList = [];
      this.checkAll = false;
      this.chooseOption={key:'',label:''};
      this.$emit("changeAddVisible",this.customVisible);
    },
    changeActiveKey(key){
      if(key.length>0){
        this.getRoles();
      }
    },
    async getRoles(){
      const params = {
        current:1,
        size:-1,
      }
      const res = await this.$api.role.selectByCondition(params);
      if(res.code===200){
        const adminRoleList = res.data.records;
        if(this.userType===0){
          this.allRoles = adminRoleList.filter(e=>e.roleName.indexOf("超级管理员")===-1)
              .filter(e=>e.roleName.indexOf("公司负责人")===-1)
              .filter(e=>e.roleName.indexOf("供应商负责人")===-1)
              .filter(e=>e.roleName.indexOf("配送员")===-1);
        }
        if(this.userType===1){
          this.allRoles = adminRoleList.filter(e=>e.roleName.indexOf("超级管理员")===-1)
              .filter(e=>e.roleName.indexOf("公司负责人")===-1)
              .filter(e=>e.roleName.indexOf("供应商负责人")===-1)
              .filter(e=>e.roleName.indexOf("门店负责人")===-1)
              .filter(e=>e.roleName.indexOf("采购员")===-1)
              .filter(e=>e.roleName.indexOf("验收员")===-1)
              .filter(e=>e.roleName.indexOf("后厨")===-1);
        }
        this.checkAll = this.allRoles.length===this.checkedRoleList;
        this.isIndeterminate = this.checkedRoleList.length>0&&this.checkedRoleList.length<this.allRoles.length;
      }else{
        if(res.msg){
          this.$message.error(res.msg);
        }
      }
    },
    //是否全选
    onCheckAllChange(e){
      this.checkedRoleList = e.target.checked?this.allRoles.map(item=>item.id):[];
      this.isIndeterminate = false;
      this.checkAll = e.target.checked;
    },
    //角色列表项中发生改变的监听
    handleCheckedChange(checkedList){
      this.checkedRoleList = checkedList;
      this.checkAll = this.checkedRoleList.length === this.allRoles.length && this.allRoles.length>0;
      this.isIndeterminate = this.checkedRoleList.length > 0 && this.checkedRoleList.length<this.allRoles.length;
    },
  }
}
</script>

<style lang="scss" scoped>
.add-role {
  .add-role-form {
    padding: 10px 10px 0 0;
    border-top: 1px solid #eee !important;
    border-bottom: 1px solid #eee !important;

    .editable-row-operations a {
      margin-right: 8px;
    }

    ::v-deep .ant-table-body > tr > td {
      padding: 10px;
    }

    ::v-deep .ant-table-thead > tr > th {
      padding: 10px;
    }
  }
}
</style>