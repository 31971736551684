<template>
  <div class="add_storage">
    <a-drawer
        title="修改入库记录"
        :width="520"
        :visible="editVisible"
        :body-style="{ paddingBottom: '80px',fontFamily: '楷体, serif' }"
        :header-style="{fontFamily: '楷体, serif'}"
        @close="handleClose">
      <a-form class="edit-goods-form" :form="editStorageForm" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="商品名称" name="name">
              <goods-selector ref="goodsSelector"
                              :current-value="{key: storageInfo.productId, label: storageInfo.productName}"
                              @goodsSelectChange="goodsSelectChange"></goods-selector>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="入库数量" name="storageNum">
              <a-input-number
                  :value ="storageInfo.storageNum"
                  key="1"
                  :min="0" :max="99999"
                  :parser="value=>value.replace(/^[0]+[1-9]*/g,'')"
                  @change="$emit('changeStorageNum',$event)"
                  allowClear>
              </a-input-number>
              当前值：{{ storageInfo.storageNum }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="供应商" name="supplier">
              <supplier-selector ref="supplierSelector"
                                 :current-value="{key: storageInfo.supplierId, label: storageInfo.supplierName}"
                                 @supplierSelectChange="supplierSelectChange"/>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="单位" name="unit">
              <span v-if=storageInfo.unit>{{ storageInfo.unit }}</span>
              <span v-else-if=editParam.unit>{{ editParam.unit }}</span>
              <span v-else style="margin-left: 3px">--</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="入库备注" name="storageRemark">
              <a-textarea
                  :value ="storageInfo.storageRemark"
                  :rows="4"
                  placeholder="请输入备注信息"
                  @change="$emit('changeRemark',$event.target.value)"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div
          :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      }"
      >
        <a-button style="margin-right: 8px" type="primary" @click="handleOk">确定</a-button>
        <a-button @click="handleClose">取消</a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import GoodsSelector from "@/components/goodsSelector";
import SupplierSelector from "@/components/supplierSelector";

export default {
  name: "editStorage",
  components: {GoodsSelector, SupplierSelector},
  props: {
    editVisible: {
      type: Boolean,
    },
    storageInfo: {
      type: Object,
    },
  },
  inject: ["reload"],
  data() {
    return {
      customVisible: this.editVisible,
      editParam: {
        id: undefined,
        productId: undefined,
        productName: undefined,
        unit: undefined,
        supplierId: undefined,
        supplierName: undefined,
        storageNum: 0,
        storageRemark: undefined,
      },
      headers: {
        contentType: 'multipart/form-data'
      },
      editStorageForm: this.$form.createForm(this, {name: 'edit_storage_form'}),
      goodsChooseOption: {key: this.storageInfo.productId, label: this.storageInfo.productName},
      bindSupplierChooseOption: {key: this.storageInfo.supplierId, label: this.storageInfo.supplierName},
    }
  },
  methods: {
    handleOk() {
      const {id, productId, supplierId, storageNum, storageRemark} = this.storageInfo;
      this.editParam.id = id;
      this.editParam.productId = productId;
      this.editParam.storageNum = storageNum;
      this.editParam.supplierId = supplierId;
      this.editParam.storageRemark = storageRemark;

      if (!this.editParam.supplierId) {
        this.$message.error("供应商未选择！");
        return;
      }
      this.$api.storageOrder.update(this.editParam).then(res => {
        if (res.code === 200 && res.success) {
          this.$message.success("编辑成功！");
          this.customVisible = false;
          this.$emit("changeEditVisible", this.customVisible);
          this.reload();
        } else {
          if (res.msg) {
            this.$message.error(res.msg);
          }
        }
      });
    },
    handleClose() {
      // console.log("从父组件获取到的数据：",this.storageInfo)
      const item = this.$store.getters.storageInfo;
      this.editParam = {
        id: undefined,
        productId: undefined,
        productName: undefined,
        unit: undefined,
        supplierId: undefined,
        supplierName: undefined,
        storageNum: 0,
        storageRemark: undefined,
      };
      this.goodsChooseOption = {key: this.storageInfo.productId, label: this.storageInfo.productName};
      this.bindSupplierChooseOption={key: this.storageInfo.supplierId, label: this.storageInfo.supplierName};
      this.$emit('changeStorageNum',item.storageNum);
      this.$emit('changeRemark',item.storageRemark)
      this.customVisible = false;
      this.$emit("changeEditVisible", this.customVisible);
    },
    goodsSelectChange(value) {
      this.goodsChooseOption = value;
      this.editParam.productId = value.key;
      let param = {
        id: value.key,
      }
      this.$api.goods.goodsDetail(param).then(res => {
        if (res.code === 200 && res.success) {
          const goods = res.data;
          this.editParam.unit = goods.unit;
        }
        this.$emit("changeStorageName", value);
        this.$emit("changeStorageUnit", this.editParam.unit);
      })

    },
    supplierSelectChange(value) {
      this.bindSupplierChooseOption = value;
      this.editParam.supplierId = value.key;
      this.$emit("changeStorageSupplier", value);
    },
  }
}
</script>

<style lang="scss" scoped>
.add_storage {
  font-family: 楷体, serif;
}
</style>