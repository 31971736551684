<template>
  <div class="goods-basic-info">
    <div class="goods">
      <div class="item dataList">
        <a-row>
          <a-col :span="24">
            <a-row class="select-form">
              <a-col :span="24">
                <a-form layout="inline"  :form="this.goodsForm">
                  <a-form-item>
                    <a-input
                        size="large"
                        placeholder="商品名称"
                        style="width: 120px"
                        v-model.trim="queryParam.name"
                        allowClear>
                    </a-input>
                  </a-form-item>
                  <a-form-item>
                    <category-selector ref="categorySelector" :current-value="chooseOption"
                                       @categorySelectChange="categorySelectChange"/>
                  </a-form-item>
                  <a-form-item>
                    <a-col :span="8">
                      <a-button
                          size="large"
                          type="primary"
                          @click="handleSearch">查询
                      </a-button>
                    </a-col>
                    <a-col :span="8" offset="8">
                      <a-button size="large" @click="resetSearchForm">重置</a-button>
                    </a-col>
                  </a-form-item>
                </a-form>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="24">
            <div class="add-goods-btn">
              <a-button size="large"
                        type="primary"
                        @click="showAddModal">添加商品</a-button>
            </div>
          </a-col>
        </a-row>
      </div>
      <a-table bordered
               rowKey="id"
               :pagination="false"
               :columns="columns"
               :data-source="dataSource"
               :customRow="customRow"
               :row-class-name="setRowClassName"
               style="width: 100%;">
        <template slot="mainPicture" slot-scope="text">
          <div v-if="text">
            <div :style="{ marginLeft: `${buttonWidth}px`, whiteSpace: 'nowrap' }">
              <a-popover title="商品图片" placement="top">
                <template slot="content">
                  <img :src="text" width="256px" height="256px"/>
                </template>
                <span style="color: #1890ff">商品图片</span>
              </a-popover>
            </div>
          </div>
        </template>
        <span slot="action" slot-scope="text,record">
           <a-tooltip placement="top" title="编辑" autoAdjustOverflow>
            <a-button type="primary" size="small" icon="edit" @click="handleEdit(record)"/>
           </a-tooltip>
            <a-divider type="vertical"/>
            <a-popconfirm title="确定删除?"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="() => onDelete(record.id)">
              <a-icon slot="icon" type="question-circle-o" style="color: red"/>
              <a-button type="danger" size="small" icon="delete"/>
            </a-popconfirm>
        </span>
      </a-table>
      <pageination  style="float: right"
                    :paginationList="paginationList"
                    @paging="paging">
      </pageination>
      <div class="add">
        <add-goods :add-visible="addVisible" @changeAddVisible="changeAddVisible"></add-goods>
      </div>
      <div class="edit">
        <edit-goods ref="editGoods"
                    :edit-visible="editVisible" :goods-info="editParam"
                    @changeGoodsName="changeGoodsName"
                    @changeEditCategory = "changeEditCategory"
                    @changeRemark="changeRemark"
                    @changePic="changePicture"
                    @changeGoodsUnit="changeGoodsUnit"
                    @changeGoodsDesc="changeGoodsDesc"
                    @changeEditVisible="changeEditVisible">

        </edit-goods>
      </div>
    </div>
  </div>
</template>

<script>
import CategorySelector from "@/components/categorySelector";
import AddGoods from "@/views/product/addGoods";
import EditGoods from "@/views/product/editGoods";
import Pageination from "@/components/pageination";
export default {
  name: "index",
  components: {Pageination, EditGoods, AddGoods, CategorySelector},
  created() {
    let {size,current} = this.paginationList;
    let query = {size,current,...this.queryParam};
    this.getGoodsList(query);
  },
  data(){
    return{
      selectedRowId:undefined,
      goodsForm: this.$form.createForm(this, {name: 'goods_form'}),
      buttonWidth:70,
      dataSource:[],
      columns:[
        {
          title: '商品名称',
          dataIndex: 'name',
          key:'name',
          width: '70px',
          align: 'center',
        },
        {
          title: '商品分类',
          dataIndex: 'categoryName',
          key:'categoryName',
          width: '70px',
          align: 'center',
        },
        {
          title: '图片',
          dataIndex: 'mainPicture',
          key:'mainPicture',
          width: '70px',
          align: 'center',
          scopedSlots: { customRender: 'mainPicture' },
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key:'unit',
          width: '70px',
          align: 'center',
        },
        {
          title: '描述',
          dataIndex: 'description',
          key:'description',
          width: '70px',
          align: 'center',
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key:'remark',
          width: '70px',
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'action',
          key:'action',
          width: '70px',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      queryParam:{
        name:undefined,
        categoryId:undefined,
      },
      chooseOption:{key:'',label:undefined},
      paginationList:{
        size:10,
        current:1,
        total:0,
        pageSizeOptions: ['10', '20', '30', '40', '50','100'],
      },
      addVisible:false,
      editParam:{
        id: undefined,
        name: undefined,
        categoryId:undefined,
        categoryName:undefined,
        mainPicture: undefined,
        unit:undefined,
        description:undefined,
        remark: undefined,
      },
      editVisible:false,
    }
  },
  methods:{
    customRow(record){
      return {
        on:{
          click:(event)=>{
            //console.log(event);
            //console.log("点击了：",record.id)
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    changeAddVisible(value){
      this.addVisible = value;
    },
    async handleSearch() {
      let {size,current} = this.paginationList;
      let query = {size,current,...this.queryParam};
      this.getGoodsList(query);
    },
    resetSearchForm(){
      this.queryParam={
        name:undefined,
        categoryId:undefined,
      }
      this.chooseOption={key:'',label:undefined};
      const current = 1,size=10;
      const query = {current,size,...this.queryParam};
      this.getGoodsList(query);
    },
    showAddModal(){
      this.addVisible = true;
    },
    async onDelete(id){
      let delIds = {
        ids:id,
      };
      const res = await this.$api.goods.removeById(delIds);
      if(res.code===200){
        this.$message.success("删除成功！")
        this.reload();
      }else{
        this.$message.error(res.msg);
      }
    },
    handleEdit(record){
      this.$store.state.goodsInfo = record;
      //console.log(this.$store.state.goodsInfo);
      let item = JSON.parse(JSON.stringify(this.$store.getters.goodsInfo));
      this.editParam = item;
      //console.log(this.editParam,this.fileList);
      this.editVisible = true;
    },
    getGoodsList(params) {
      this.$api.goods.goodsList(params).then(res => {
        if (res.code === 200) {
          //console.log("结果数据：", res.data);
          this.dataSource = res.data.records;
          this.paginationList.total = res.data.total;
        }
      })
    },
    paging(parameter){
      let size = parameter.size;
      let current = parameter.current;
      let query = {size,current,...this.queryParam};
      this.getGoodsList(query);
    },
    categorySelectChange(value){
      this.chooseOption = value;
      this.queryParam.categoryId = value.key;
    },
    changeEditCategory(value){
      this.editParam.categoryId = value.key;
      this.editParam.categoryName = value.label;
    },
    changeGoodsName(value){
      this.editParam.name = value;
    },
    changeGoodsUnit(value){
      this.editParam.unit = value;
    },
    changeGoodsDesc(value){
      this.editParam.description = value;
    },
    changeRemark(value){
      this.editParam.remark = value;
    },
    changePicture(value){
      this.editParam.picture = value;
    },
    changeFileList(value){
      this.fileList = value;
    },
    changeEditVisible(value){
      this.editVisible = value;
    },
  }
}
</script>

<style lang="scss" scoped>
.goods-basic-info{
  .item{
  }
  .add-goods-btn{
    margin: 0 0 10px 0;
  }
  .dataList{
    .select-form{
      margin: 0 0 10px 0;
    }
    ::v-deep .clickRowStyle{
      background-color:#e6f7ff
    }
  }

}
.add{

}
</style>