<template>
  <div class="message">
    <div class="searchAction">
      <a-row type="flex" justify="start">
        <a-col :span="12">
          <a-col :span="4">
            <span  @click="isReadAction" :class="{'isClicked':queryParam.isRead===1}">已读</span>
          </a-col>
          <a-col :span="4">
            <a-badge :count="unReadCount" dot>
              <span @click="unReadAction" :class="{'isClicked':queryParam.isRead===0}" style="font-size: 16px">未读</span>
            </a-badge>
          </a-col>
          <a-col :span="4">
            <span @click="allAction" :class="{'isClicked':queryParam.isRead===undefined}">全部</span>
          </a-col>
        </a-col>
      </a-row>
    </div>
    <div>
      <a-list item-layout="horizontal" :data-source="resultSource" bordered :loading ="msgLoading">
        <a-list-item slot="renderItem" slot-scope="item">
          <a-button :disabled="item.isRead" slot="actions" type="primary"
                    size="small" icon="check" @click="handleCheck(item)"/>
          <a-button slot="actions" type="primary" size="small"
                    icon="delete" @click="handleDel(item)"/>
          <a-list-item-meta :description='item.content'>
            <a slot="title" :href="item.url">{{ item.title }}&nbsp;&nbsp;&nbsp;{{getFormatDate(item.createTime)}}</a>
            <a-avatar v-if="!item.isRead" size="small" slot="avatar" style="background-color:red" icon="info"/>
            <a-avatar v-else slot="avatar" style="background-color:#FFFFFF" size="small"/>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
    </div>
    <pageination  style="float: right"
                  :paginationList="paginationList"
                  @paging="paging">
    </pageination>
  </div>
</template>

<script>
import Pageination from "@/components/pageination";
import {dateFormat} from "@/utils/common";
export default {
  name: "message",
  components: {Pageination},
  inject:["reload"],
  created() {
    const {staffType,restaurantId} = this.$store.getters.userInfo;
    switch (staffType){
      case 0:
        this.queryParam.targetType = 1;
        break;
      case 1:
        this.queryParam.targetType = 2;
        break;
      case 2:
        this.queryParam.targetType = 0;
        break;
    }
    if(restaurantId>0){
      this.queryParam.targetType = 3;
    }
    let {size,current} = this.paginationList;
    let query = {size,current,...this.queryParam};
    this.getMsgList(query);
  },
  data() {
    return {
      dataSource:[],
      unReadSource:[],
      isReadSource:[],
      msgLoading:false,
      paginationList:{
        size:10,
        current:1,
        total:0,
        pageSizeOptions: ['10', '20', '30', '40', '50','100'],
      },
      queryParam:{
        isRead:undefined,
        targetType:undefined,
      },
      isClicked:{},
      unReadCount:0,
    };
  },
  methods:{
    isReadAction(){
      this.queryParam.isRead = 1;
      this.paginationList.total = this.isReadSource.length;
      let query = {size:10,current:1,...this.queryParam};
      this.getMsgList(query);
    },
    unReadAction(){
      this.queryParam.isRead = 0;
      this.paginationList.total = this.unReadSource.length;
      let query = {size:10,current:1,...this.queryParam};
      this.getMsgList(query);
    },
    allAction(){
      this.queryParam.isRead = undefined;
      this.paginationList.total = this.dataSource.length;
      let query = {size:10,current:1,...this.queryParam};
      this.getMsgList(query);
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
    getMsgList(params){
      this.$api.message.selectMsgList(params).then(res=>{
        if(res.code===200){
          //console.log("结果数据：",res.data);
          this.msgLoading = true;
          this.dataSource = res.data.records;
          this.paginationList.total = res.data.total;
          if(this.dataSource.length>0){
            this.unReadSource = this.dataSource.filter(e => e.isRead === false);
            this.isReadSource = this.dataSource.filter(e => e.isRead === true);
            this.unReadCount = this.unReadSource.length;
          }
          // console.log("未读消息：",this.unReadSource,"已读消息：",this.isReadSource,"全部消息：",this.unReadSource.length+this.isReadSource.length)
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
        this.msgLoading = false;
      })
    },
    handleCheck(item){
      let checkParam = {
        id:item.id,
      }
      this.$api.message.readMsg(checkParam).then(res=>{
        if(res.code===200){
          this.$message.success("操作成功！");
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    handleDel(item){
      //console.log(item);
      const params = {ids:item.id};
      this.$api.message.removeMsgByIds(params).then(res=>{
        if(res.code===200){
          this.$message.success("操作成功！");
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      });
    },
    paging(parameter) {
      // console.log("paging获取到的参数",parameter)
      let msgQuery = {
        current:parameter.current,
        size:parameter.size,
        isRead:this.queryParam.isRead,
        targetType:this.queryParam.targetType,
      };
      this.getMsgList(msgQuery);
    },
  },
  computed:{
    resultSource(){
      const isReadStatus = this.queryParam.isRead;
      let result = [];
      switch (isReadStatus){
        case 0:
          result = this.unReadSource;
          break;
        case 1:
          result = this.isReadSource;
          break;
        case undefined:
          result = this.dataSource;
          break;
      }
      return result;
    }
  }
}
</script>

<style lang="scss" scoped>
.message{
  .searchAction{
    margin: 0 0 10px 0;
    font-size: 16px;
    .isClicked{
      border-bottom: #FF9502  solid 2px;
      font-weight: bolder;
    }
  }
}
</style>