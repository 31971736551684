<template>
  <div class="order">
    <div class="item dataList">
      <a-row>
        <a-col :span="24">
          <a-row class="select-form">
            <a-col :span="24">
              <a-form layout="inline" :form="searchOrderForm">
                <a-form-item>
                  <a-input
                      size="large"
                      placeholder="请输入订单所属批次号"
                      v-model.trim="queryParam.purchaseOrderTaskCode"
                      clearable
                  >
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-input
                      size="large"
                      placeholder="请输入订单号"
                      v-model.trim="queryParam.code"
                      clearable
                  >
                  </a-input>
                </a-form-item>
                <a-form-item v-if="userType===0">
                  <supplier-selector ref="supplierSelector"
                                     :current-value="bindSupplierChooseOption"
                                     @supplierSelectChange="supplierSelectChange"/>
                </a-form-item>
                <a-form-item v-if="userRoles.indexOf('2')!==-1">
                  <restaurant-selector ref="restaurantSelector"
                                       :current-value="supplierBindResChooseOption"
                                       @restaurantSelectChange="restaurantSelectChange"/>
                </a-form-item>
                <a-form-item v-if="userType===1">
                  <company-selector ref="companySelector"
                                    :current-value="supplierBindCompanyChooseOption"
                                    @companySelectChange="companySelectChange"/>
                </a-form-item>
                <a-form-item>
                  <goods-selector ref="goodsSelector"
                                  :current-value="goodsChooseOption"
                                  :company-id="this.queryParam.companyId"
                                  @goodsSelectChange="goodsSelectChange"></goods-selector>
                </a-form-item>
                <a-form-item>
                  <a-select v-model="orderStatus"
                            :getPopupContainer="trigger => trigger.parentNode"
                            allowClear
                            style="width: 110px"
                            size="large"
                            placeholder="订单状态"
                            @change="orderStatusChange">
                    <a-select-option :key="0"
                                     value="0">
                      未接单
                    </a-select-option>
                    <a-select-option :key="1"
                                     value="1">
                      备货中
                    </a-select-option>
                    <a-select-option :key="2"
                                     value="2">
                      配送中
                    </a-select-option>
                    <a-select-option :key="3"
                                     value="3">
                      已验收
                    </a-select-option>
                    <a-select-option :key="5"
                                     value="5">
                      验收中
                    </a-select-option>
                    <a-select-option :key="4"
                                     value="4">
                      已取消
                    </a-select-option>

                  </a-select>
                </a-form-item>
                <a-row>
                  <a-form-item style=" margin: 10px 0 0 0;">
                    <a-col :span="8">
                      <a-button
                          size="large"
                          type="primary"
                          @click="searchOrderTask">查询
                      </a-button>
                    </a-col>
                    <a-col :span="8" offset="8">
                      <a-button size="large" @click="resetBidPriceForm">重置</a-button>
                    </a-col>
                  </a-form-item>
                </a-row>
              </a-form>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-table rowKey="id"
               :pagination="false"
               :columns="columns"
               :data-source="dataSource"
               :customRow="customRow"
               :row-class-name="setRowClassName"
               style="width: 100%;"
               bordered
      >
        <template slot="empty">
          <a-empty description="暂无数据"></a-empty>
        </template>
        <template slot="purchaseOrderTaskCode" slot-scope="text">
          <router-link to="/purchase/task/list" tag="a">{{text}}</router-link>
        </template>
        <template slot="orderDetail" slot-scope="text,record">
          <a-tag  style="color: #2db7f5;font-size: 12px" @click="getOrderDetail(record.id)">
            订单详细信息，点击查看>>>
          </a-tag >
        </template>
        <template slot="totalPrice" slot-scope="text">
          <span style="color: red">
            ￥{{getFormatMoney(text,true)}}
          </span>
        </template>
        <template slot="createTime" slot-scope="text">
          {{getFormatDate(text)}}
        </template>
        <template slot="status" slot-scope="text,record">
          <span v-if="text==0" style="color: red">未接单</span>
          <span v-if="text==1" style="color: #108ee9" @click="addSender(record)">备货中</span>
          <span v-if="text==2" style="color: #F29F2A">配送中</span>
          <span v-if="text==3" style="color: limegreen">已验收</span>
          <span v-if="text==4" style="color: silver">已取消</span>
          <span v-if="text==5" style="color: darkorange">验收中</span>

        </template>
        <template slot="senderDetail" slot-scope="text,record">
          <a-tag v-if="record.status>1&&record.status!==4" style="color: #2db7f5;font-size: 12px"
                 @click="getSenderDetail(record.id)">
            配送员详细信息，点击查看>>>
          </a-tag >
          <a-tag v-if="record.status===0||record.status===1||record.status===4" style="color: silver;font-size: 12px">
            暂未分配配送员
          </a-tag >
        </template>
        <template slot="action" slot-scope="text,record">
          <div v-if="userType===1&&record.status===0&&userRoles.indexOf('6')!==-1">
            <a-popconfirm :disabled="ensureBtnStatus||record.status!==0"
                          title="确认订单?"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="() => ensureOrder(record.id)">
              <a-button :disabled="ensureBtnStatus||record.status!==0"
                        type="primary" size="small" icon="check-circle"/>
            </a-popconfirm>
            <a-divider type="vertical"/>
            <a-popconfirm :disabled="cancelBtnStatus||record.status!==0"
                          title="取消订单?"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="() => cancelOrder(record.id)">
              <a-icon slot="icon" type="question-circle-o" style="color: red"/>
              <a-button  :disabled="cancelBtnStatus||record.status!==0" type="danger" size="small" icon="close-circle"/>
            </a-popconfirm>
          </div>
          <div v-if="userType===1&&record.status==1">
            <a-popconfirm :disabled="record.status!==1"
                          title="确定添加配送员?"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="() => addSender(record)">
              <a-icon slot="icon" type="question-circle-o" style="color: red"/>
              <a-button type="primary" size="small" icon="plus-circle"
                        :disabled="record.status!==1"></a-button>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
      <pageination  style="float: right"
                    :paginationList="paginationList"
                    @paging="paging">
      </pageination>
    </div>
    <div>
      <order-detail :visible="orderDetailVisible"
                        :order-detail="orderDetail"
                        @changeOrderDetail="changeOrderDetail"
                        @changeOrderDetailVisible="changeOrderDetailVisible"/>
    </div>
    <div>
      <sender-detail :visible="senderDetailVisible"
                     :sender-detail="senderDetail"
                     @changeSenderDetail="changeSenderDetail"
                     @changeSenderDetailVisible="changeSenderDetailVisible"/>
    </div>
    <div>
      <add-sender :visible="addSenderVisible"
                  :order-info="addSenderOrderDetail"
                  @changeAddSenderVisible="changeAddSenderVisible"
                  @changeAddSenderDetail="changeAddSenderDetail"/>
    </div>
  </div>
</template>

<script>
import OrderDetail from "@/views/order/orderDetail";
import Pageination from "@/components/pageination";
import GoodsSelector from "@/components/goodsSelector";
import CompanySelector from "@/components/companySelector";
import RestaurantSelector from "@/components/restaurantSelector";
import SupplierSelector from "@/components/supplierSelector";
import {dateFormat, formatMoney} from "@/utils/common";
import AddSender from "@/views/sender/addSender";
import SenderDetail from "@/views/sender/senderDetail";
export default {
  name: "index",
  components: {
    SenderDetail,
    AddSender, SupplierSelector, RestaurantSelector, CompanySelector, GoodsSelector, Pageination, OrderDetail},
  inject:['reload'],
  created() {
    const item1= {
      title: '采购门店所属公司',
      dataIndex: 'companyName',
      key:'companyName',
      width: '140px',
      align: 'center',
    }
    const item2 =  {
      title: '采购门店',
      dataIndex: 'restaurantName',
      key:'restaurantName',
      width: '100px',
      align: 'center',
    }
    const item4 = {
      title: '供应商',
      dataIndex: 'supplierName',
      key:'supplierName',
      width: '70px',
      align: 'center',
    }
    if(this.userType===1){
      this.columns.splice(2,0,item1,item2);
      if(this.userRoles.indexOf("6")!==-1){
        this.ensureBtnStatus = false;
        this.cancelBtnStatus = false;
      }
    }
    if(0===this.userType){
      if(this.userRoles.indexOf("2")!==-1){
        this.columns.splice(2,0,item2,item4);
      }
      if(this.userRoles.indexOf("3")!==-1){
        this.columns.splice(2,0,item2,item4);
      }
      if(this.userRoles.indexOf("4")!==-1){
        this.columns.splice(2,0,item4);
      }
    }


    let queryParam = {
      current:this.paginationList.current,
      size:this.paginationList.size,
      ...this.queryParam,
    }
    this.getOrderList(queryParam);
    this.queryObj = this.$route.query.id;
  },
  data(){
    return{
      queryObj:undefined,
      cancelBtnStatus:true,
      ensureBtnStatus:true,
      orderDetailVisible:false,
      orderDetail:undefined,

      senderDetailVisible:false,

      senderDetail:undefined,


      addSenderVisible:false,
      addSenderDetail:undefined,
      addSenderOrderDetail:undefined,

      userType:this.$store.getters.userInfo.staffType,
      userRoles:this.$store.getters.userInfo.roleIds,
      searchOrderForm: this.$form.createForm(this, {name: 'order_form'}),
      selectedRowId:undefined,
      queryParam:{
        purchaseOrderTaskCode:'',
        code:'',
        productId:undefined,
        restaurantId:undefined,
        companyId:undefined,
        supplierId:undefined,
        status:undefined,
      },
      goodsChooseOption:{key:'',label:''},
      supplierBindResChooseOption:{key:'',label:''},
      supplierBindCompanyChooseOption:{key:'',label:''},
      //公司和餐厅绑定供应商选择项
      bindSupplierChooseOption:{key:'',label:''},
      paginationList:{
        size:10,
        current:1,
        total:0,
        pageSizeOptions: ['10', '20', '30', '40', '50','100'],
      },
      orderStatus:undefined,
      dataSource:[],
      columns:[
        {
          title: '订单所属批次号',
          dataIndex: 'purchaseOrderTaskCode',
          key:'purchaseOrderTaskCode',
          width: '120px',
          align: 'center',
          scopedSlots: {customRender: 'purchaseOrderTaskCode'},
        },
        {
          title: '订单号',
          dataIndex: 'code',
          key:'code',
          width: '120px',
          align: 'center',
          scopedSlots: {customRender: 'code'},
        },
        {
          title: '订单明细',
          dataIndex: 'orderDetail',
          key:'orderDetail',
          width: '70px',
          align: 'center',
          scopedSlots: { customRender: 'orderDetail' },
        },
        {
          title: '总价',
          dataIndex: 'totalPrice',
          key:'totalPrice',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'totalPrice' },
        },

        {
          title: '订单状态',
          dataIndex: 'status',
          key:'status',
          width: '70px',
          align: 'center',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key:'createTime',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'createTime' },
        },
        {
          title: '配送员信息',
          dataIndex: 'senderDetail',
          key:'senderDetail',
          width: '70px',
          align: 'center',
          scopedSlots: {customRender: 'senderDetail'},
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key:'remark',
          width: '60px',
          align: 'center',
        },
        {
          title: '操作',
          dataIndex: 'action',
          key:'action',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  methods:{
    customRow(record){
      return {
        on:{
          click:(event)=>{
            //console.log(event);
            //console.log("点击了：",record.id)
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    goodsSelectChange(value){
      //获取下拉选择框中的数据
      this.goodsChooseOption = value;
      this.queryParam.productId = value.key;
    },
    orderStatusChange(value) {
      this.queryParam.status = value;
      this.orderStatus = value;
    },
    restaurantSelectChange(value){
      this.supplierBindResChooseOption = value;
      this.queryParam.restaurantId = value.key;
    },
    companySelectChange(value){
      this.supplierBindCompanyChooseOption = value;
      this.queryParam.companyId = value.key;
    },
    supplierSelectChange(value){
      this.bindSupplierChooseOption = value;
      this.queryParam.supplierId = value.key;
    },

    getOrderDetail(key){
      const param = {id:key};
      this.$api.purchaseOrder.detail(param).then(res=>{
        if(res.code===200&&res.success){
          this.orderDetail = res.data;
          this.orderDetailVisible = true;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      });
    },
    changeOrderDetail(value){
      this.orderDetail = value;
    },
    ensureOrder(key){
      this.$api.purchaseOrder.ensureOrder(key).then(res=>{
        if(res.code===200&&res.success){
          this.$message.success("操作成功！");
          this.ensureBtnStatus = true;
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },

    changeSenderDetail(value){
      this.senderDetail = value;
    },
    changeSenderDetailVisible(value){
      this.senderDetailVisible = value;
    },

    addSender(record){
      //console.log(record);
      this.addSenderVisible = true;
      this.addSenderOrderDetail = record;
    },
    changeAddSenderDetail(value){
      this.addSenderOrderDetail = value;
    },
    changeAddSenderVisible(value){
      this.addSenderVisible = value;
    },
    cancelOrder(key){
      this.$api.purchaseOrder.cancelOrder(key).then(res=>{
        if(res.code===200&&res.success){
          this.$message.success("操作成功！");
          this.ensureBtnStatus = true;
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    getSenderDetail(key){
      const param = {id:key};
      this.$api.purchaseOrder.detail(param).then(res=>{
        if(res.code===200&&res.success){
          this.senderDetail = res.data;
          this.senderDetailVisible = true;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      });
    },
    handleBidFailedReasonChange(event){
      this.senderDetailParam.checkRemark = event.target.value;
    },

    changeOrderDetailVisible(value){
      this.orderDetailVisible = value;
    },
    searchOrderTask(){
      let queryParam = {
        current:this.paginationList.current,
        size:this.paginationList.size,
        ...this.queryParam,
      }
      //console.log(queryParam);
      this.getOrderList(queryParam);
    },
    //拼装分页参数
    paging(parameter) {
      //console.log("paging获取到的参数",parameter)
      let queryParam = {
        current:parameter.current,
        size:parameter.size,
        ...this.queryParam,
      };
      this.getOrderList(queryParam);
    },
    resetBidPriceForm(){
      this.$refs.goodsSelector.value = undefined;
      this.orderStatus = undefined;
      this.supplierBindResChooseOption = {key:'',label:''};
      this.goodsChooseOption = {key:'',label:''};
      this.supplierBindCompanyChooseOption={key:'',label:''};
      //公司和餐厅绑定供应商选择项
      this.bindSupplierChooseOption={key:'',label:''};
      this.queryParam={
        purchaseOrderTaskCode: '',
        code: '',
        productId: undefined,
        restaurantId: undefined,
        companyId: undefined,
        supplierId: undefined,
        status: undefined,
      }
      let params = {
        current:this.paginationList.current,
        size:this.paginationList.size,
        ...this.queryParam,
      }
      this.getOrderList(params);
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
    getFormatMoney(money,isCarryBit){
      const result = formatMoney(money,2,".",",",isCarryBit);
      return result;
    },
    getOrderList(params){
      //console.log("order:",params);
      this.$api.purchaseOrder.purchaseOrderList(params).then(res=>{
        if(res.code===200){
          //console.log("结果数据：",res.data);
          this.dataSource = res.data.records;
          //console.log("dataSource",this.dataSource);
          this.paginationList.total = res.data.total;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.order{
  .item{
  }
  .dataList{
    .select-form{
      margin: 0 0 10px 0;
    }
    ::v-deep .ant-table-thead > tr > th{
      padding: 8px;
    }
    ::v-deep .ant-table-tbody > tr > td{
      padding: 8px;
    }
    ::v-deep .clickRowStyle{
      background-color:#e6f7ff
    }
  }
}
</style>