<template>
  <div class="staff">
    <div class="item dataList">
      <a-row class="select-form">
        <a-col :span="24">
          <a-form layout="inline" :form="searchForm">
            <a-form-item>
              <a-input
                  size="large"
                  placeholder="用户名"
                  style="width: 100px"
                  v-model.trim="queryParam.name"
                  allowClear>
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                  size="large"
                  placeholder="请输入公司全称"
                  v-model.trim="companyName"
                  allowClear>
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-input
                  size="large"
                  placeholder="请输入用户手机号"
                  style="width: 180px"
                  v-model.trim="queryParam.phone"
                  :maxLength=11
                  allowClear>
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-select v-model="queryParam.status"
                        :getPopupContainer="trigger => trigger.parentNode"
                        allowClear
                        style="width: 110px"
                        size="large"
                        placeholder="登录状态"
                        @change="handleLoginStateSelectChange">
                <a-select-option :key="0"
                                 value="0">
                  未登录
                </a-select-option>
                <a-select-option :key="1"
                                 value="1">
                  已登录
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select v-model="queryParam.staffType"
                        :getPopupContainer="trigger => trigger.parentNode"
                        allowClear
                        style="width: 120px"
                        size="large"
                        placeholder="公司类型"
                        @change="handleStaffTypeSelectChange">
                <a-select-option :key="0"
                                 value="0">
                  采购商
                </a-select-option>
                <a-select-option :key="1"
                                 value="1">
                  供应商
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-col :span="8">
                <a-button
                    size="large"
                    type="primary"
                    @click="handleSearch">查询
                </a-button>
              </a-col>
              <a-col :span="8" offset="8">
                <a-button size="large" @click="resetSearchForm">重置</a-button>
              </a-col>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
      <a-table bordered
               rowKey="id"
               :pagination="false"
               :data-source="dataSource"
               :columns="columns"
               :customRow="customRow"
               :row-class-name="setRowClassName"
               style="width: 100%;">
        <template slot="sex" slot-scope="text">
          {{text==0?'女':'男'}}
        </template>
        <template slot="staffType" slot-scope="text">
          <span v-if="text==0" style="color: #2db7f5">采购公司</span>
          <span v-if="text==2" style="color: red"></span>
          <span v-if="text==1" style="color: orange">供应商</span>
        </template>
        <template slot="createTime" slot-scope="text">
          {{getFormatDate(text)}}
        </template>
        <template slot="status" slot-scope="text">
          <span v-if="text==0" style="color: silver">未登录</span>
          <span v-else style="color: limegreen">已登录</span>
        </template>
        <template slot="isQuit" slot-scope="text">
          <span v-if="text==0" style="color: #1890ff">在职</span>
          <span v-else style="color: red">离职</span>
        </template>
        <span slot="action" slot-scope="text,record">
<!--          <a-tooltip placement="top" title="角色配置" autoAdjustOverflow>-->
<!--            <a-button type="primary" size="small" icon="user" @click="selectOne(record)"/>-->
<!--          </a-tooltip>-->
<!--          <a-divider type="vertical"/>-->
          <a-popconfirm v-if="record.isQuit===0"
                        title="确定离职?"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="() => layoffStaff(record.id)">
            <a-tooltip placement="top" title="离职" autoAdjustOverflow>
              <a-button type="info" size="small" icon="user-delete"/>
            </a-tooltip>
          </a-popconfirm>
          <a-popconfirm v-if="record.isQuit===1"
                        title="确定恢复入职?"
                        ok-text="确定"
                        cancel-text="取消"
                        @confirm="() => unLayOffStaff(record.id)">
            <a-tooltip placement="top" title="恢复入职" autoAdjustOverflow>
              <a-button type="info" size="small" icon="user-add"/>
            </a-tooltip>
          </a-popconfirm>
          <a-divider type="vertical"/>
           <a-popconfirm
               title="确定删除?"
               ok-text="确定"
               cancel-text="取消"
               @confirm="() => onDelete(record.id)">
                           <a-icon slot="icon" type="question-circle-o" style="color: red"/>
                           <a-button type="danger" size="small" icon="delete"/>
                         </a-popconfirm>
        </span>
      </a-table>
      <pageination  style="float: right"
                    :paginationList="paginationList"
                    @paging="paging">
      </pageination>
      <a-modal title="设置角色"
               :visible="modalRoleVisible"
               :confirm-loading="confirmLoading"
               okText="确定"
               cancelText="取消"
               @ok="handleSetRoleOk"
               @cancel="handleSetRoleCancel"
               width="500px">
        <a-form class="set-role-form"
                label-width="80px">
          <a-form-item label="用户名">
            <a-row>
              <a-col :span="24">
                <a-input
                    disabled
                    :value="selectRecord.name"
                    type="text"
                    key="0"
                    allowClear>
                </a-input>
              </a-col>
            </a-row>
          </a-form-item>
          <a-form-item label="角色列表">
            <a-checkbox :indeterminate="isIndeterminate" :checked="checkAll" @change="onCheckAllChange">
              全选
            </a-checkbox>
            <div style="margin: 15px 0"></div>
            <a-row>
              <a-col :span="24">
                <a-checkbox-group v-model="checkedRoleList" @change="handleCheckedChange">
                  <a-col :span="24">
                    <a-checkbox style="margin-left:20px;margin-top: 10px" v-for="role in allRoles" :key="role.id" :value="role.id">{{ role.roleName }}</a-checkbox>
                  </a-col>
                </a-checkbox-group>
              </a-col>
            </a-row>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {dateFormat, isMobile} from "@/utils/common";
import Pageination from "@/components/pageination";

export default {
  name: "userIndex",
  components: {Pageination},
  inject:["reload"],
  data(){
    return{
      selectedRowId:undefined,
      searchForm: this.$form.createForm(this, {name: 'search_form'}),
      queryParam:{
        name:'',
        phone:'',
        companyId:undefined,
        staffType:undefined,
        status:undefined,
      },
      paginationList:{
        size:10,
        current:1,
        total:0,
        pageSizeOptions: ['10', '20', '30', '40', '50','100'],
      },
      companyName:undefined,
      dataSource:[],
      columns:[
        {
          title: '姓名',
          dataIndex: 'name',
          key:'name',
          width: '70px',
          align: 'center',
        },
        {
          title: '性别',
          dataIndex: 'sex',
          key:'sex',
          width: '10px',
          align: 'center',
          scopedSlots:{customRender:'sex'},
        },
        {
          title: '手机号',
          dataIndex: 'phone',
          key:'phone',
          width: '80px',
          align: 'center',
        },
        {
          title: '所属公司',
          dataIndex: 'companyName',
          key:'companyName',
          width: '140px',
          align: 'center',
        },
        {
          title: '公司类型',
          dataIndex: 'staffType',
          key:'staffType',
          width: '60px',
          align: 'center',
          scopedSlots:{customRender:'staffType'},
        },
        {
          title: '角色',
          dataIndex: 'roleName',
          key:'roleName',
          width: '140px',
          align: 'center',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key:'createTime',
          width: '120px',
          align: 'center',
          scopedSlots: { customRender: 'createTime' },
        },
        {
          title: '登陆状态',
          dataIndex: 'status',
          key:'status',
          width: '60px',
          align: 'center',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: '离职状态',
          dataIndex: 'isQuit',
          key:'isQuit',
          width: '60px',
          align: 'center',
          scopedSlots: { customRender: 'isQuit' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          key:'action',
          width: '120px',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],

      selectRecord: {},
      modalRoleVisible:false,

      isIndeterminate:false,
      checkAll:false,
      checkedRoleList:[], //用户的角色列表
      allRoles:[],
      confirmLoading: false,
    }
  },
  created() {
    let staffQuery = {
      current:this.paginationList.current,
      size:this.paginationList.size,
      companyId:this.queryParam.companyId,
      staffType:this.queryParam.staffType,
      status:this.queryParam.status,
      phone:this.queryParam.phone,
      name:this.queryParam.name,
    };
    this.getStaffList(staffQuery);
  },
  methods:{
    customRow(record){
      return {
        on:{
          click:(event)=>{
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    handleLoginStateSelectChange(value){
      this.queryParam.status = value;
    },
    handleStaffTypeSelectChange(value){
      this.queryParam.staffType = value;
    },
    async handleSearch() {

      if(this.queryParam.phone!==''){
        if (!isMobile(this.queryParam.phone)) {
          this.$message.error('请输入正确的手机号码')
          return false;
        }
      }
      if(!this.companyName){
        this.queryParam.companyId = undefined;
      }
      if(this.companyName){
        if(!this.queryParam.staffType){
          this.$message.error("请选择公司类型",1);
          return false;
        }
        if(this.queryParam.staffType==0){
          const params = {
            companyName :this.companyName,
          }
          const res = await this.$api.company.selectByName(params);
          if(res.code===200){
            if(res.data){
              this.queryParam.companyId = res.data.id;
            }else{
              this.$message.error("该公司不存在！！请重新输入",2);
              return false;
            }
          }
        }
        if(this.queryParam.staffType==1){
          const params = {
            supplierName :this.companyName,
          }
          const res = await this.$api.supplier.selectByName(params);
          if(res.code===200){
            if(res.data){
              this.queryParam.companyId = res.data.id;
            }else{
              this.$message.error("该公司不存在！！请重新输入");
              return false;
            }
          }
        }
      }
      let staffQuery = {
        current: this.paginationList.current,
        size: this.paginationList.size,
        companyId: this.queryParam.companyId,
        staffType: this.queryParam.staffType,
        status: this.queryParam.status,
        phone: this.queryParam.phone,
        name: this.queryParam.name,
      };
      this.getStaffList(staffQuery);
    },
    paging(parameter) {
      let staffQuery = {
        current:parameter.current,
        size:parameter.size,
        companyId:this.queryParam.companyId,
        staffType:this.queryParam.staffType,
        status:this.queryParam.status,
        phone:this.queryParam.phone,
        name:this.queryParam.name,
      };
      this.getStaffList(staffQuery);
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
    resetSearchForm(){
      this.companyName=undefined;
      this.queryParam = {
        name:'',
        phone:'',
        companyId:undefined,
        staffType:undefined,
        status:undefined,
      }
      let staffQuery = {
        current:1,
        size:10,
        companyId:this.queryParam.companyId,
        staffType:this.queryParam.staffType,
        status:this.queryParam.status,
        phone:this.queryParam.phone,
        name:this.queryParam.name,
      };
      this.getStaffList(staffQuery);
    },
    getStaffList(queryParams){
      this.$api.staff.selectDirectors(queryParams).then(res=>{
        if(res.code===200){
          this.dataSource = res.data.records;
          this.paginationList.total = res.data.total;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    unLayOffStaff(key){
      let delIds = {
        ids:key,
      };
      this.$api.staff.unLayOffById(delIds).then(res=>{
        if(res.code===200){
          this.$message.success("操作成功！")
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    layoffStaff(key){
      let delIds = {
        ids:key,
      };
      this.$api.staff.layoffById(delIds).then(res=>{
        if(res.code===200){
          this.$message.success("操作成功！")
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    onDelete(key) {
      let delIds = {
        ids:key,
      };
      this.$api.staff.removeById(delIds).then(res=>{
        if(res.code===200){
          this.$message.success("删除成功！")
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    selectOne(record){
      this.selectRecord = record;
      this.modalRoleVisible = true;
      this.getRoles(record.id);
    },
    async getRoles(staffId){
      const res = await this.$api.role.selectByStaffId(staffId);
      if(res.code===200){
        const adminRoleList = res.data.allRoles;
        this.allRoles = adminRoleList.filter(e=>e.roleName.indexOf("公司负责人")!==-1
            ||e.roleName.indexOf("供应商负责人")!==-1);
        this.checkedRoleList = res.data.userRoleIds;

        this.checkAll = this.allRoles.length===this.checkedRoleList;
        this.isIndeterminate = this.checkedRoleList.length>0&&this.checkedRoleList.length<this.allRoles.length;
      }else{
        if(res.msg){
          this.$message.error(res.msg);
        }
      }
    },
    //是否全选
    onCheckAllChange(e){
      this.checkedRoleList = e.target.checked?this.allRoles.map(item=>item.id):[];
      this.isIndeterminate = false;
      this.checkAll = e.target.checked;
    },
    //角色列表项中发生改变的监听
    handleCheckedChange(checkedList){
      this.checkedRoleList = checkedList;
      this.checkAll = this.checkedRoleList.length === this.allRoles.length && this.allRoles.length>0;
      this.isIndeterminate = this.checkedRoleList.length > 0 && this.checkedRoleList.length<this.allRoles.length;
    },
    async handleSetRoleOk() {
      const userId = this.selectRecord.id;
      const roleIds = this.checkedRoleList.join(",");
      const params = {
        userId: userId,
        roleIds: roleIds,
      }
      this.confirmLoading = true;
      const res = await this.$api.role.addUserRoleList(params);
      if (res.code === 200 && res.success) {
        this.$message.success("分配角色成功！")
        this.confirmLoading = false;
        this.reload();
      }else{
        if(res.msg){
          this.$message.error(res.msg);
        }
      }
    },
    //重置角色分配信息
    handleSetRoleCancel(){
      this.modalRoleVisible = false;
      this.allRoles = [];
      this.checkedRoleList = [];
      this.isIndeterminate = false;
      this.checkAll = false;
    },
  }
}
</script>

<style lang="scss" scoped>

.staff{
  ::v-deep .ant-table-thead > tr > th{
    padding: 8px;
  }
  ::v-deep .ant-table-tbody > tr > td{
    padding: 8px;
  }
  .item {
  }
  .select-form{
    margin: 0px 0px 20px 0px;
  }
  ::v-deep .clickRowStyle{
    background-color:#e6f7ff
  }

}
</style>