<template>
  <div class="purchase">
    <div class="item dataList">
      <a-row>
        <a-col :span="24">
          <a-row class="select-form">
            <a-col :span="24">
              <a-form layout="inline" :form="searchForm">
                <a-form-item>
                  <a-input
                      size="large"
                      placeholder="请输入商品批次号"
                      v-model.trim="queryParam.purchaseOrderTaskCode"
                      clearable
                  >
                  </a-input>
                </a-form-item>
                <a-form-item v-if="userType===1">
                  <company-selector ref="companySelector"
                                    :current-value="supplierBindCompanyChooseOption"
                                    @companySelectChange="companySelectChange"/>
                </a-form-item>
                <a-form-item>
                  <goods-selector ref="goodsSelector"
                                  :current-value="goodsChooseOption"
                                  :company-id="queryParam.companyId"
                                  @goodsSelectChange="goodsSelectChange"></goods-selector>
                </a-form-item>
                <a-form-item>
                  <a-select v-model="bidState"
                            :getPopupContainer="trigger => trigger.parentNode"
                            allowClear
                            style="width: 120px"
                            size="large"
                            placeholder="报价状态"
                            @change="bidStateChange">
                    <a-select-option :key="0"
                                     value="0">
                      未开始
                    </a-select-option>
                    <a-select-option :key="1"
                                     value="1">
                      未报价
                    </a-select-option>
                    <a-select-option :key="2"
                                     value="2">
                      报价中
                    </a-select-option>
                    <a-select-option :key="3"
                                     value="3">
                      报价结束
                    </a-select-option>
                    <a-select-option :key="4"
                                     value="4">
                      已完成
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item v-if="this.staffRoles.indexOf('2')!==-1">
                  <restaurant-selector ref="restaurantSelector"
                                       :current-value="chooseOption"
                                       @restaurantSelectChange="restaurantSelectChange"/>
                </a-form-item>
                <a-form-item>
                  <a-col :span="8">
                    <a-button
                        size="large"
                        type="primary"
                        @click="searchOrderTask">查询
                    </a-button>
                  </a-col>
                  <a-col :span="8" offset="8">
                    <a-button size="large" @click="resetSearchForm">重置</a-button>
                  </a-col>
                </a-form-item>
              </a-form>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="24">
          <div v-if="staffRoles.indexOf('3')!==-1||
          staffRoles.indexOf('4')!==-1||staffRoles.indexOf('8')!==-1" class="add-purchase-btn">
            <a-button size="large"
                      type="primary"
                      @click="showModal">创建采购单</a-button>
          </div>
        </a-col>
      </a-row>
      <a-table rowKey="id"
               :pagination="false"
               :columns="columns"
               :data-source="dataSource"
               :customRow="customRow"
               :row-class-name="setRowClassName"
               style="width: 100%;"
               bordered
      >
        <template slot="empty">
          <a-empty description="暂无数据"></a-empty>
        </template>
        <template slot="createTime" slot-scope="text">
          {{getFormatDate(text)}}
        </template>
        <template slot="detail" slot-scope="text,record">
          <a-tag   style="color: #2db7f5;font-size: 12px" @click="handlePurchaseDetail(record.id)">
            采购详细信息，点击查看>>>
          </a-tag >
        </template>
        <template slot="bidStatus" slot-scope="text">
          <span v-if="text==0" style="color: silver">未开始</span>
          <span v-if="text==1" style="color: black">未报价</span>
          <span v-if="text==2" style="color: #108ee9">报价中</span>
          <span v-if="text==3" style="color: red">报价结束</span>
          <span v-if="text==4" style="color: limegreen">已完成</span>
        </template>
        <template slot="action" slot-scope="text,record">
          <div v-if="isShowCompAction">
            <a-tooltip placement="top" title="编辑" autoAdjustOverflow>
              <a-button type="primary" size="small" icon="edit" @click="handleEdit(record.id)"
                        :disabled="record.bidStatus>0"/>
            </a-tooltip>
            <a-divider type="vertical"/>
            <a-popconfirm title="确定开始报价?"
                          :disabled="record.bidStatus!==0"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="() => startBindPrice(record.id)">
              <a-button :disabled="record.bidStatus!==0" type="primary" size="small" icon="play-circle"/>
            </a-popconfirm>
            <a-divider type="vertical"/>
            <a-popconfirm title="确定结束报价?"
                          :disabled="record.bidStatus===0||record.bidStatus>2"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="() => stopBindPrice(record.id)">
              <a-icon slot="icon" type="question-circle-o" style="color: red"/>
              <a-button  :disabled="record.bidStatus===0||record.bidStatus>2" type="danger" size="small" icon="stop"/>
            </a-popconfirm>
          </div>
          <div v-if="isShowSupplierAction">
            <a-tooltip placement="top" title="报价" autoAdjustOverflow>
              <a-button :disabled="record.bidStatus!==1&&record.bidStatus!==2" type="primary" size="small" icon="play-circle" @click="showBidForm(record.id)"/>
            </a-tooltip>
          </div>
        </template>

      </a-table>
      <pageination  style="float: right"
                    :paginationList="paginationList"
                    @paging="paging">
      </pageination>
    </div>
    <div class="add">
      <add-purchase :visible="visible" @changeVisible="changeVisible"/>
    </div>
    <div class="purchase-detail">
      <task-detail :visible="editVisible"
                   :task-id="taskId"
                   :editable="editable"
                   @changeEditVisible="changeEditVisible"
                   @changeEditAble="changeEditAble"
                   @changeTaskId="changeTaskId"/>
    </div>
    <div class="bid-price">
      <add-bid-price :visible="bidPriceVisible"
                     :purchase-order-detail="bidOrderTaskDetail"
                     @changeBidOrderTaskDetail="changeBidOrderTaskDetail"
                     @changeBidVisible="changeBidVisible"/>
    </div>
  </div>
</template>

<script>
import GoodsSelector from "@/components/goodsSelector";
import AddPurchase from "@/views/purchase/addPurchase";
import RestaurantSelector from "@/components/restaurantSelector";
import Pageination from "@/components/pageination";
import {dateFormat} from "@/utils/common";
import TaskDetail from "@/views/purchase/task-detail";
import AddBidPrice from "@/views/bidPrice/addBidPrice";
import CompanySelector from "@/components/companySelector";
//TODO 根据条件查询采购单列表a-table需要完成，需要根据条件查询采购单列表
export default {
  name: "index",
  components: {
    CompanySelector,
    AddBidPrice,
    TaskDetail,
    Pageination,
    RestaurantSelector,
    AddPurchase,
    GoodsSelector,

  },
  inject:['reload'],
  created() {
    // let{size,current} = this.paginationList;
    //组装查询数据并调用查询接口进行查询
    const staffRoles = this.$store.getters.userInfo.roleIds;
    if(staffRoles.indexOf("3")!==-1||staffRoles.indexOf("4")!==-1){
      const item = {
        title: '操作',
        dataIndex: 'action',
        key:'action',
        width: '120px',
        align: 'center',
        scopedSlots: { customRender: 'action' },
      }
      this.columns.push(item);
    }
    if(staffRoles.indexOf("6")!==-1){
      const item = {
        title: '操作',
        dataIndex: 'action',
        key:'action',
        width: '120px',
        align: 'center',
        scopedSlots: { customRender: 'action' },
      }
      const item1 = {
        title: '所属公司',
        dataIndex: 'companyName',
        key:'companyName',
        width: '140px',
        align: 'center',
      };
      this.columns.splice(1,0,item1);
      this.columns.push(item);
    }
    let queryParam = {
      current:this.paginationList.current,
      size:this.paginationList.size,
      ...this.queryParam,
    }
    this.getPurchaseList(queryParam);
  },
  data(){
    return{
      selectedRowId:undefined,
      visible:false,
      editVisible:false,
      editable:false,
      bidPriceVisible:false,
      supplierBindCompanyChooseOption:{key:'',label:''},
      bidOrderTaskDetail: undefined,
      staffRoles:this.$store.getters.userInfo.roleIds,
      userType:this.$store.getters.userInfo.staffType,
      goodsChooseOption:{key:'',label:''},
      chooseOption:{key:'',label:''},
      searchForm: this.$form.createForm(this, {name: 'search_form'}),
      queryParam:{
        purchaseOrderTaskCode:'',
        productId:undefined,
        restaurantId:undefined,
        companyId:undefined,
        bidStatus:undefined,
      },
      taskId:undefined,
      paginationList:{
        size:10,
        current:1,
        total:0,
        pageSizeOptions: ['10', '20', '30', '40', '50','100'],
      },
      bidState:undefined,
      dataSource:[],
      columns:[
        {
          title: '批次号',
          dataIndex: 'code',
          key:'code',
          width: '140px',
          align: 'center',
        },
        {
          title: '采购门店',
          dataIndex: 'restaurantName',
          key:'restaurantName',
          width: '140px',
          align: 'center',
        },
        {
          title: '发布人',
          dataIndex: 'publisherName',
          key:'publisherName',
          width: '70px',
          align: 'center',
        },
        {
          title: '采购明细',
          dataIndex: 'detail',
          key:'detail',
          width: '80px',
          align: 'center',
          scopedSlots: { customRender: 'detail' },
        },
        {
          title: '发布时间',
          dataIndex: 'createTime',
          key:'createTime',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'createTime' },
        },
        {
          title: '报价状态',
          dataIndex: 'bidStatus',
          key:'bidStatus',
          width: '70px',
          align: 'center',
          scopedSlots: { customRender: 'bidStatus' },
        },
      ],
    }
  },
  computed:{
    isShowCompAction(){
      return this.staffRoles.indexOf('3') !== -1 ||
          this.staffRoles.indexOf('4') !== -1;
    },
    isShowSupplierAction(){
      return this.staffRoles.indexOf('6')!==-1;
    }
  },
  methods:{
    customRow(record){
      return {
        on:{
          click:()=>{
            //console.log(event);
            //console.log("点击了：",record.id)
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    showModal(){
      this.visible = true;
    },
    changeVisible(value){
      this.visible = value;
    },
    changeBidVisible(value){
      this.bidPriceVisible = value;
    },
    changeEditVisible(value){
      this.editVisible = value;
    },
    changeEditAble(value){
      this.editable = value;
    },
    changeTaskId(value){
      this.taskId = value;
    },
    changeBidOrderTaskDetail(value){
      this.bidOrderTaskDetail = value;
    },
    restaurantSelectChange(value){
      this.chooseOption = value;
      this.queryParam.restaurantId = value.key;
    },
    companySelectChange(value){
      this.supplierBindCompanyChooseOption = value;
      this.queryParam.companyId = value.key;
    },
    goodsSelectChange(value){
      //获取下拉选择框中的数据
      this.goodsChooseOption = value;
      this.queryParam.productId = value.key;
    },
    handlePurchaseDetail(key){
      //console.log("采购详细信息",this.userType);
      this.taskId = key;
      this.editVisible = true;
    },
    handleEdit(key){
      //console.log("编辑入参",key);
      this.taskId = key;
      this.editable = true;
      this.editVisible = true;
    },
    startBindPrice(key){
      //console.log("开始报价入参",key);
      this.$api.purchaseOrderTask.startPurchaseOrderTask(key).then(res=>{
        if(res.code===200&&res.success){
          this.$message.success("操作成功！！");
          this.reload();
        }else {
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    stopBindPrice(key){
      //console.log("停止报价入参",key);
      this.$api.purchaseOrderTask.endPurchaseOrderTask(key).then(res=>{
        if(res.code===200&&res.success){
          this.$message.success("操作成功！")
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    showBidForm(key){
      //console.log("供应商开始报价",key);
      this.taskId = key;
      this.editVisible = true;
    },
    searchOrderTask(){
      let queryParam = {
        current:this.paginationList.current,
        size:this.paginationList.size,
        ...this.queryParam,
      }
      //console.log(queryParam);
      this.getPurchaseList(queryParam);
    },
    resetSearchForm(){
      this.$refs.goodsSelector.value = undefined;
      this.supplierBindCompanyChooseOption={key:'',label:''},
      this.bidState = undefined;
      this.chooseOption = {key:'',label:''};
      this.goodsChooseOption = {key:'',label:''};
      this.queryParam = {
        purchaseOrderTaskCode: '',
        productId: undefined,
        restaurantId: undefined,
        companyId: undefined,
        bidStatus: undefined,
      }
      let params = {
        current:this.paginationList.current,
        size:this.paginationList.size,
        ...this.queryParam,
      }
      this.getPurchaseList(params);
    },

    bidStateChange(value) {
      this.queryParam.bidStatus = value;
    },
    getPurchaseList(params){
      //console.log(params);
      //console.log(this.userType);
      if(this.userType===0){
        this.$api.purchaseOrderTask.purchaseOrderTaskCompOrResList(params).then(res=>{
          if(res.code===200){
            //console.log("结果数据：",res.data);
            this.dataSource = res.data.records;
            this.paginationList.total = res.data.total;
          }else{
            if(res.msg){
              this.$message.error(res.msg);
            }
          }
        })
      }
      if(this.userType===1){
        this.$api.purchaseOrderTask.purchaseOrderTaskSupplierList(params).then(res=>{
          if(res.code===200){
            //console.log("结果数据：",res.data);
            this.dataSource = res.data.records;
            this.paginationList.total = res.data.total;
          }else{
            if(res.msg){
              this.$message.error(res.msg);
            }
          }
        })
      }
    },
    //拼装分页参数
    paging(parameter) {
      //console.log("paging获取到的参数",parameter)
      let queryParam = {
        current:parameter.current,
        size:parameter.size,
        ...this.queryParam,
      };
      this.getPurchaseList(queryParam);
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
  },
}
</script>

<style lang="scss" scoped>

.purchase{
  .item{
  }
  .add-purchase-btn{
    margin: 0 0 10px 0;
  }
  .dataList{
    .select-form{
      margin: 0 0 10px 0;
    }
    ::v-deep .clickRowStyle{
      background-color:#e6f7ff
    }
  }

}
.add{

}
</style>