<template>
  <div class="bidPrice">
    <a-modal title="报价单"
             :visible="visible"
             :confirm-loading="confirmLoading"
             okText="确定"
             cancelText="取消"
             @ok="handleOk"
             @cancel="handleCancel"
             :okButtonProps="this.okButtonStyle"
             width="800px">
      <a-row>
        <a-col :span="12">
          <label>商品所属采购单批次号：</label>
          <span style="color: #92989B;font-weight: bold;">{{ taskCode?taskCode:'暂无数据'}}</span>
        </a-col>
        <a-col :span="12">
          <label>采购方：</label>
          <span style="color: #92989B;font-weight: bold;">
            {{ companyName?companyName:'暂无数据'}}({{resName?resName:'暂无数据'}})
          </span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="6">
          <label>商品名称：</label>
          <span style="color: #92989B;font-weight: bold;">{{ goodsName?goodsName:'暂无数据'}}</span>
        </a-col>
          <a-col :span="6">

          <label>发布人：</label>
          <span style="color: #92989B;font-weight: bold;">{{ publisherName?publisherName:'暂无数据'}}</span>
          </a-col>

        <a-col :span="12">
          <label>供应商：</label>
          <span style="color: #92989B;font-weight: bold;">{{supplierName?supplierName:'暂无数据'}}</span>
        </a-col>
      </a-row>
      <br>
      <a-form class="bid-price-form"
              :form="bidPriceForm"
              label-width="40px">
        <a-row>
          <a-row>
            <a-col :span="8">
            <a-form-item label="供货数量"
                         :labelCol="labelCol"
                         :wrapperCol="wrapperCol">
              <a-input
                  ref="bidNum"
                  v-decorator="['num',rules.num]"
                  type="number"
                  style="width: 150px;"
                  @blur="unitPriceChange"
                  :suffix="unit?unit:''"/>
              <a-tooltip :title="purchaseOrderDetail?purchaseOrderDetail.num:'暂无数据'">
                <span style="color: #1890ff;font-size: 12px;text-decoration-line: underline;">当前采购数量</span>
              </a-tooltip>
            </a-form-item>
            </a-col>
            <a-col :span="10" :offset="4">
              <a-form-item label="报价（单价）" :labelCol="labelCol"
                           :wrapperCol="wrapperCol">
                <a-input
                    ref="unitPrice"
                    v-decorator="['unitPrice',rules.unitPrice]"
                    type="text"
                    :suffix="fullUnit"
                    style="width: 150px"
                    @blur="unitPriceChange"/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-form-item label="付款方式" :labelCol="labelCol"
                           :wrapperCol="wrapperCol">
                <a-select
                    v-decorator="['payWay',rules.payWay]"
                    :getPopupContainer="trigger => trigger.parentNode"
                    placeholder="请选择"
                    type="text"
                    style="width: 110px"
                    allowClear>
                  <a-select-option :key="0"
                                   value="0">
                    现结
                  </a-select-option>
                  <a-select-option :key="1"
                                   value="1">
                    签单（月付）
                  </a-select-option>
                </a-select>

              </a-form-item>
            </a-col>
            <a-col :span="10" :offset="4">
            <a-form-item label="报价（总价）" :labelCol="labelCol"
                         :wrapperCol="wrapperCol">
              <a-input
                  ref="totalPrice"
                  v-decorator="['totalPrice',rules.totalPrice]"
                  type="text"
                  :suffix="fullUnit"
                  :disabled="true"
                  style="width: 150px;color: red;"
                  allowClear/>
            </a-form-item>
            </a-col>
          </a-row>
          <a-row>
          </a-row>
          <a-row>
            <a-col :span="8">
              <a-form-item label="交货方式" :labelCol="labelCol"
                           :wrapperCol="wrapperCol">
                <span v-if="this.addParam.deliveryWay===0">送货上门</span>
                <span v-else-if="this.addParam.deliveryWay===1">自提</span>
                <span v-else>暂无数据</span>
                <a-checkbox @change="onDeliveryWayChange"
                            style="padding-left: 10px"
                            :default-checked="deliveryWayCheck">
                </a-checkbox>
              </a-form-item>
            </a-col>
            <a-col :span="14" :offset="2">
              <a-form-item label="交货时间" :labelCol="labelCol"
                           :wrapperCol="wrapperCol">
                  <span>{{this.addParam.deliveryTime}}</span>
                  <a-checkbox @change="onDeliveryTimeChange"
                              style="padding-left: 10px"
                              :default-checked="deliveryTimeCheck">
                  </a-checkbox>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="14" :pull="2" >
              <a-form-item label="交易地点" :labelCol="labelCol"
                           :wrapperCol="wrapperCol">
                <span  v-if="this.addParam.deliveryWay===0" style="color: #92989B;font-weight: bold;">
                  {{resAddress}}
                </span>
                <span  v-if="this.addParam.deliveryWay===1" style="color: #92989B;font-weight: bold;">
                  {{supplierAddress}}
                </span>
              </a-form-item>
            </a-col>
            <a-col :span="9" :pull="3">
              <a-form-item label="当前报价次数" :labelCol={span:12}
                           :wrapperCol="wrapperCol">
                <span v-if="isRepeat" style="color: red">{{purchaseOrderDetail.bidNum+1}}</span>
                <span v-else style="color: red">1</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-form-item label="图片信息" >
              <a-upload v-decorator="['picturesInfo',rules.picturesInfo]"
                        class="avatar-uploader"
                        action="/auth/upLoad"
                        :headers=headers
                        name="file"
                        :data=this.uploadParams
                        :fileList="fileList"
                        list-type="picture-card"
                        :disabled="loading"
                        :before-upload="handleBeforeUpload"
                        :remove="handleRemovePicture"
                        @preview="handlePreview"
                        @change="handleChange">
                <div v-if="this.fileList.length<3">
                  <a-icon type='plus' />
                  <div class="ant-upload-text">
                    上传图片
                  </div>
                </div>
              </a-upload>
              <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelDetailPic">
                <img alt="门店详情图片" style="width: 100%" :src="addParam.picturesInfo"/>
              </a-modal>
            </a-form-item>
          </a-row>
          <a-row>
          <a-form-item label="备注">
            <a-textarea v-decorator="['remark',rules.remark]"
                        placeholder="备注信息"
                        rows="4"
                        allowClear/>
          </a-form-item>
          </a-row>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import Modal from "ant-design-vue/lib/modal/Modal";
import {dateFormat} from "@/utils/common";
export default {
  name: "addBidPrice",
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    purchaseOrderDetail:{
      type:Object,
    },
    isRepeat:{
      type:Boolean,
      default: false,
    },
  },
  inject:['reload'],
  data(){
    return {
      labelCol: {
        xs: {span: 24},
        sm: {span: 8} //控制label的框的长度
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 10}//控制文本框的长度
      },
      confirmLoading:false,
      customVisible:false,
      deliveryWayCheck:true,
      deliveryTimeCheck:true,
      bidPriceForm:this.$form.createForm(this, {name: 'bid_price_form'}),
      okButtonStyle:{props: { disabled: false },},
      taskCode:'',
      companyName:'',
      resName:'',
      goodsName:'',
      unit:'',
      num:0,
      stockNum:0,
      supplierName:this.$store.getters.userInfo.companyName,
      supplierAddress:this.$store.getters.userInfo.companyAddress,
      publisherName:'',
      resAddress:'',

      addParam:{
        companyId:undefined,
        restaurantId:undefined,
        purchaseOrderTaskId:undefined,
        purchaseOrderTaskDetailId:undefined,
        productId:undefined,
        num:0,
        unit:undefined,
        unitPrice:undefined,
        payWay:undefined,
        deliveryTime:undefined,
        deliveryAddress:undefined,
        deliveryWay:undefined,
        picturesInfo:undefined,
        remark:undefined,
      },
      totalPrice:0,
      rules: {
        payWay: {
          rules: [
            {required: true, message: '请选择付款方式'},
          ]
        },
        num:{
          initialValue:0,
          rules: [
            {required: true, message: '请输入供货数量!'},
          ]
        },
        unitPrice:{
          initialValue:0,
          rules: [
            {required: true, message: '请输入采购单价!'},
          ]
        },
        totalPrice:{
          initialValue:0,
          rules: [
            {required: true, message: '请输入采购单价!'},
          ]
        },
        picturesInfo: {
          rules: [
            {required: false, message: '请上传相关商品图片!'},
          ]
        },
        remark:{
          rules:[
            {required: false, message: '请填写备注信息'},
          ],
        }
      },
      //上传图片相关
      headers:{
        contentType:'multipart/form-data'
      },
      previewVisible:false,
      loading:false,
      fileList:[],
    }
  },
  methods:{
    handleOk(){
      this.confirmLoading = true;
      this.bidPriceForm.validateFields((err, values) => {
        if (!err) {
          const detailPics =this.fileList.map(pic=>{
            if(pic.response.data){
              return pic.response.data;
            }
          }).join(",");
          this.addParam.picturesInfo = detailPics;
          if(this.fileList.length===0){
            this.$message.error("必须添加商品图片！");
            return;
          }
          this.addParam.unitPrice = values.unitPrice;
          this.addParam.payWay=values.payWay;
          this.addParam.remark=values.remark;
          this.addParam.num = values.num;
          if(this.isRepeat){
            const bidNum = this.purchaseOrderDetail.bidNum;
            const publisherId = this.purchaseOrderDetail.publisherId;
            const supplierId = this.purchaseOrderDetail.supplierId;
            const repeatParams = {...this.addParam,bidNum,publisherId,supplierId,};
            this.$api.bidPriceOrder.repeatBidPrice(this.purchaseOrderDetail.id,repeatParams).then(res => {
              if (res.code === 200 && res.success) {
                this.$message.success("添加成功！");
                this.confirmLoading = false;
                this.reload();
              } else {
                if (res.msg) {
                  this.$message.error(res.msg);
                }
              }
            });
          }else{
            this.$api.bidPriceOrder.createBidPriceOrder(this.addParam).then(res => {
              if (res.code === 200 && res.success) {
                this.$message.success("添加成功！");
                this.confirmLoading = false;
                this.reload();
              } else {
                if (res.msg) {
                  this.$message.error(res.msg);
                }
              }
            });
          }
        }
      });
      this.confirmLoading = false;
    },
    handleCancel(){
      this.previewVisible = false;
      this.fileList = [];
      this.addParam.picturesInfo = null;
      this.bidPriceForm.resetFields();
      this.$emit("changeBidOrderTaskDetail",undefined);
      this.$emit("changeBidPriceVisible",this.customVisible);
      this.$emit("changeTaskDetailHidden",false);
      this.$emit("changeRepeatStatus",false);
    },
    unitPriceChange(){
      const num = this.$refs.bidNum.value;
      const sourceNum = this.stockNum;
      // if(num>sourceNum){
      //   this.bidPriceForm.setFieldsValue({
      //     num: sourceNum,
      //   });
      // }
      this.bidPriceForm.setFieldsValue({
        totalPrice: this.calculatePrice,
      });
    },
    onDeliveryWayChange(e){
      this.deliveryWayCheck = e.target.checked;
      if(this.deliveryWayCheck&&this.deliveryTimeCheck){
        this.okButtonStyle = {props: { disabled: false },};
      }else {
        this.okButtonStyle = {props: { disabled: true },};
      }
    },
    onDeliveryTimeChange(e){
      this.deliveryTimeCheck = e.target.checked;
      if(this.deliveryWayCheck&&this.deliveryTimeCheck){
        this.okButtonStyle = {props: { disabled: false },};
      }else {
        this.okButtonStyle = {props: { disabled: true },};
      }
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
    //上传图片相关
    uploadParams(){
      let d = {
        companyName:this.companyName,
        userType: this.$store.getters.userInfo.staffType,
      }
      return d;
    },
    handleBeforeUpload(file){
      return new Promise((resolve,reject)=>{
        const isJPG = file.type === 'image/jpg';
        const isJPEG = file.type=== 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isGIF = file.type === 'image/gif';
        const isLt10M = file.size/1024/1024 <10;
        if(!(isJPG||isJPEG||isPNG||isGIF)){
          Modal.error({
            title:'只能上传JPG、JPEG、GIF、PNG格式的图片',
          });
          return reject(false);
        }else if(!isLt10M){
          Modal.error({
            title:'图片大小不能超过10M',
          });
          return reject(false);
        }
        return resolve(true);
      })
    },
    handleRemovePicture(){
      this.addParam.picturesInfo = null;
    },
    handleChange(info){
      this.loading = true;
      this.fileList = info.fileList;
      const status = info.file.status;
      if (status!== 'uploading') {
        this.loading = false;
        setTimeout(()=>{

        },10000);
      }
      if (status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
      if (status === 'done') {
        if (info.file.response.data) {
          this.$message.success(`${info.file.name} 上传成功`);
        } else {
          this.$message.error(`${info.file.name} ${info.file.response.msg}`);
        }
      }
      if(status ==='removed'){
        this.addParam.picturesInfo = null;
      }
    },
    async handlePreview(file) {
      if(file.response.data){
        this.addParam.picturesInfo = file.response.data;
        this.previewVisible = true;
      }else{
        this.$message.error("图片上传失败不能预览！")
      }
    },
    handleCancelDetailPic(){
      this.previewVisible = false;
    },
  },
  computed:{
    fullUnit(){
      if(this.unit){
        return "元/"+this.unit;
      }else {
        return "元";
      }
    },
    calculatePrice(){
      const count = this.$refs.bidNum.value;
      const p = this.$refs.unitPrice.value;
      return count*p;
    },
  },
  watch:{
    purchaseOrderDetail(newValue){
      if(newValue){
        this.taskCode=newValue.purchaseOrderTaskCode;
        this.companyName=newValue.companyName;
        this.resName=newValue.restaurantName;
        this.goodsName=newValue.productName;
        this.num=newValue.num;
        this.stockNum = newValue.stockNum;
        this.unit=newValue.unit;
        this.publisherName=newValue.publisherName;
        this.resAddress=newValue.resAddr;
        this.addParam.companyId=newValue.companyId;
        this.addParam.restaurantId=newValue.restaurantId;
        this.addParam.purchaseOrderTaskId=newValue.purchaseOrderTaskId;
        if(newValue.purchaseOrderTaskDetailId){
          this.addParam.purchaseOrderTaskDetailId=newValue.purchaseOrderTaskDetailId;
        }else{
          this.addParam.purchaseOrderTaskDetailId=newValue.id;
        }
        this.addParam.productId=newValue.productId;
        this.addParam.deliveryWay = newValue.deliveryWay;
        this.addParam.deliveryTime = this.getFormatDate(newValue.deliveryTime);
        this.addParam.unit=newValue.unit;
        this.addParam.bidNum = newValue.bidNum;
        this.addParam.deliveryAddress = newValue.resAddr;
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>