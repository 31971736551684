<template>
  <div>
    <a-modal title="关联供应商"
             :visible="addVisible"
             :confirm-loading="confirmLoading"
             okText="确定"
             cancelText="取消"
             @ok="handleOk"
             @cancel="handleCancel"
             width="350px">
      <a-form class="bind-supplier-form"
              :form="bindSupplierForm"
              label-width="100px">
        <a-row>
          <a-form-item label="门店选择">
            <restaurant-selector ref="restaurantSelector" :current-value="chooseOption"
                                 @restaurantSelectChange="restaurantSelectChange"/>
          </a-form-item>
          <a-form-item label="供应商选择">
            <a-input
                size="large"
                placeholder="请输入公司全称"
                v-model.trim="supplierName"
                allowClear>
            </a-input>
          </a-form-item>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import RestaurantSelector from "@/components/restaurantSelector";
export default {
  name: "addBind",
  components: {RestaurantSelector},
  props:{
    addVisible:{
      type:Boolean,
    },
  },
  inject:["reload"],
  data(){
    return {
      customVisible:this.addVisible,
      confirmLoading:false,
      bindSupplierForm:this.$form.createForm(this, {name: 'add_restaurant_form'}),
      chooseOption:{key:'',label:''},
      addParam:{
        supplierId:undefined,
        restaurantId:undefined,
      },
      supplierName:'',
    }
  },
  methods:{
    restaurantSelectChange(value){
      this.chooseOption = value;
      this.addParam.restaurantId = value.key;
    },
    async handleOk(){
      if(!this.addParam.restaurantId){
        this.$message.error("请选择门店！！");
        return;
      }
      if(!this.supplierName){
        this.$message.error("供应商名称不能为空");
        return;
      }
      const params = {
        supplierName :this.supplierName,
      }
      const res = await this.$api.supplier.selectByName(params);
      if(res.code===200){
        if(res.data){
          this.addParam.supplierId = res.data.id;
        }else{
          this.$message.error("该供应商不存在！！请重新输入");
          return false;
        }
      }
      this.confirmLoading = true;
      this.$api.restaurant.bindSupplier(this.addParam).then(res => {
        if (res.code === 200 && res.success) {
          this.$message.success("绑定成功！");
          this.confirmLoading = false;
          this.reload();
        } else {
          if (res.msg) {
            this.$message.error(res.msg);
          }
        }
      });
      this.confirmLoading = false;
    },
    handleCancel(){
      this.addParam.restaurantId = undefined;
      this.addParam.supplierId = undefined;
      this.chooseOption={key:'',label:''};
      this.supplierName = '';
      this.customVisible = false;
      this.$emit("changeAddVisible",this.customVisible);
    },
  },
}
</script>

<style scoped>

</style>