<template>
  <div class="orderDetail">
    <a-modal
             :visible="visible"
             :confirm-loading="confirmLoading"
             @cancel="handleCancel"
             :footer="null"
             width="1200px">
      <template slot="title">
        <span style="font-size: 20px;font-weight: bolder">订单详细信息</span>
        <a-divider type="vertical" style="height: 30px"/>
        <span v-if="orderDetailInfo.status==0"
              style="color: red;font-weight: bolder;font-size: 18px">未接单</span>
        <span v-else-if="orderDetailInfo.status==1"
              style="font-weight: bolder;font-size: 18px;color: #108ee9">备货中</span>
        <span v-else-if="orderDetailInfo.status==2"
              style="font-weight: bolder;font-size: 18px;color: #F29F2A">配送中</span>
        <span v-else-if="orderDetailInfo.status==3"
              style="font-weight: bolder;font-size: 18px;color: limegreen">已验收</span>
        <span v-else-if="orderDetailInfo.status==4"
              style="font-weight: bolder;font-size: 18px;color: silver">已取消</span>
        <span v-else-if="orderDetailInfo.status==5"
              style="font-weight: bolder;font-size: 18px;color: darkorange">验收中</span>
      </template>
      <a-row>
        <a-col :span="12" style="font-weight: bold;">
          <label>订单所属批次号：</label>
          <span style="color: #92989B">{{ orderDetailInfo.purchaseOrderTaskCode}}</span>
        </a-col>
        <a-col :span="12" style="font-weight: bold;">
          <label>订单号：</label>
          <span style="color: #92989B">{{ orderDetailInfo.code}}</span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="12" style="font-weight: bold;">
          <label>采购方：</label>
          <span style="color: #92989B">
            {{ orderDetailInfo.companyName}}{({{orderDetailInfo.restaurantName}})
          </span>
        </a-col>
        <a-col :span="12" style="font-weight: bold;">
          <label>供应商：</label>
          <span style="color: #92989B">{{orderDetailInfo.supplierName}}</span>
        </a-col>
      </a-row>
      <br>
      <a-row>
<!--        <a-col :span="12" style="font-weight: bold;">-->
<!--          <label>配送费：</label>-->
<!--          <span style="color: #92989B">{{ orderDetailInfo.deliveryFee?orderDetailInfo.deliveryFee:'暂无'}}</span>-->
<!--        </a-col>-->
        <a-col :span="12" style="font-weight: bold;">
          <label>创建时间：</label>
          <span style="color: #92989B">{{getFormatDate(orderDetailInfo.createTime)}}</span>
        </a-col>
      </a-row>
      <br>
        <a-row>
          <a-col :span="24" style="font-weight: bold;">

            <span>商品列表<a-icon type="down"/></span>

            <a-button v-if="orderDetailInfo.status>=2"
                      style="color: #FF9502; margin:0 10px 10px 10px; float: right"
                      @click="senderDetail">配送员信息
            </a-button>
<!--            <a-button v-if="orderDetailInfo.status===3||orderDetailInfo.status===5"-->
<!--                      style="color: #FF9502; margin:0 10px 10px 10px; float: right"-->
<!--                      @click="receiveOrderDetail">验收信息-->
<!--            </a-button>-->
            <a-button v-if="userType===1&&orderDetailInfo.status===0"
                      style="margin:0 10px 10px 10px; float: right"
                      type="danger" @click="cancelAllOrderDetails">
              取消订单
            </a-button>
            <a-button v-if="userType===1&&orderDetailInfo.status===0"
                      type="primary"
                      style="margin:0 10px 10px 10px; float: right"
                      @click="ensureAllOrderDetails">
              确认订单
            </a-button>
            <div class="order-detail-table-info">
              <a-table bordered
                       :scroll="{x:1000}"
                       :loading="searchLoading"
                       rowKey="id"
                       :data-source="orderDetailList"
                       :customRow="customRow"
                       :row-class-name="setRowClassName"
                       :columns="columns"
                       style="width: 100%;">
                <template slot="num" slot-scope="text">
                  <span style="color:#2db7f5;font-size: 20px">{{ text }}</span>
                </template>
                <template slot="unitPrice" slot-scope="text,record">
                    <span style="color: red">
                      ￥{{ record.unit ? getFormatMoney(text,true) + "元/" + record.unit
                        :getFormatMoney(text,true) + "元" }}
                    </span>
                </template>
                <template slot="totalPrice" slot-scope="text,record">
                    <span style="color: red">
                      ￥{{
                        getFormatMoney((record.num * record.unitPrice),true)+"元"
                      }}
                    </span>
                </template>
                <template slot="payWay" slot-scope="text">
                  <span v-if="text==0">现结</span>
                  <span v-else-if="text==1">签单（月付）</span>
                  <span v-else>暂无数据</span>
                </template>
                <template slot="deliveryWay" slot-scope="text">
                  <span v-if="text===0">送货上门</span>
                  <span v-else-if="text===1">自提</span>
                  <span v-else>暂无数据</span>
                </template>
                <template slot="deliveryTime" slot-scope="text">
                  <span>{{ getFormatDate(text) }}</span>
                </template>
                <template slot="status" slot-scope="text">
                  <span v-if="text==0" style="color: red">未接单</span>
                  <span v-else-if="text==1" style="color: #108ee9">备货中</span>
                  <span v-else-if="text==2" style="color: #F29F2A">配送中</span>
                  <span v-else-if="text==3" style="color: limegreen">已验收</span>
                  <span v-else-if="text==4" style="color: silver">已取消</span>
                </template>
                <template slot="action" slot-scope="text, record">
                  <div v-if="userType===0&&userRoles.indexOf('5')!==-1">
                    <a-popconfirm title="确认验收?"
                                  ok-text="确定"
                                  cancel-text="取消"
                                  :disabled="record.status!==2"
                                  @confirm="() => receiveOrder(record)">
                      <a-button type="primary" size="small" icon="check-circle"
                                :disabled="record.status!==2"/>
                    </a-popconfirm>
                  </div>
                </template>
              </a-table>
            </div>
            <div>
                <span style="font-weight: bolder;font-size: 20px; color: red">
                  订单总价：￥{{ getFormatMoney(orderDetailInfo.totalPrice, true) }}元
                </span>
            </div>
          </a-col>
        </a-row>
    </a-modal>
    <div>
      <sender-detail :visible="senderDetailVisible"
                     :sender-detail="senderDetailInfo"
                     @changeSenderDetail="changeSenderDetail"
                     @changeSenderDetailVisible="changeSenderDetailVisible"/>
    </div>
    <div>
      <add-receive :visible="receiveVisible"
                   :receive-info="receiveDetailInfo"
                   @changeReceiveVisible="changeReceiveVisible"
                   @changeReceiveDetail="changeReceiveDetail"/>
    </div>
  </div>
</template>

<script>
import {dateFormat, formatMoney} from "@/utils/common";
import SenderDetail from "@/views/sender/senderDetail";
import AddReceive from "@/views/receive/addReceive";

export default {
  name: "orderDetail",
  components: {AddReceive, SenderDetail},
  props:{
    visible:{
      type:Boolean,
      default:false,
    },
    orderDetail:{
      type:Object,
    }
  },
  inject:['reload'],
  data(){
    return{
      selectedRowId:undefined,
      confirmLoading:false,
      customVisible:this.visible,
      searchLoading:false,
      userType:this.$store.getters.userInfo.staffType,
      userRoles:this.$store.getters.userInfo.roleIds,
      senderDetailInfo:undefined,
      senderDetailVisible:false,
      //验收相关
      receiveVisible:false,
      receiveDetailInfo:{},

      orderDetailInfo:{
        code: '暂无数据',
        companyId: undefined,
        companyName: '暂无数据',
        createTime: undefined,
        deliveryFee: undefined,
        deliveryPersonIds: '暂无数据',
        id: undefined,
        purchaseOrderTaskCode: '暂无数据',
        purchaseOrderTaskId: undefined,
        remark: '暂无数据',
        restaurantName: '暂无数据',
        supplierName:'暂无数据',
        staffIds: '暂无数据',
        status: undefined,
        totalPrice: undefined,

      },
      orderDetailList:[],
      columns:[
        {
          title: '订单状态',
          dataIndex: 'status',
          key:'status',
          width: '60px',
          align: 'center',
          scopedSlots: {customRender: 'status'},
        },
        {
          title: '商品名称',
          dataIndex: 'productName',
          key:'productName',
          width: '80px',
          align: 'center',
        },
        {
          title: '数量',
          dataIndex: 'num',
          key:'num',
          width: '40px',
          align: 'center',
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key:'unit',
          width: '40px',
          align: 'center',
        },
        {
          title: '单价',
          dataIndex: 'unitPrice',
          key:'unitPrice',
          width: '80px',
          align: 'center',
          scopedSlots: {customRender: 'unitPrice'},
        },
        {
          title: '总价',
          dataIndex: 'totalPrice',
          key:'totalPrice',
          width: '100px',
          align: 'center',
          scopedSlots: {customRender: 'totalPrice'},
        },
        {
          title: '付款方式',
          dataIndex: 'payWay',
          key:'payWay',
          width: '80px',
          align: 'center',
          scopedSlots: {customRender: 'payWay'},
        },
        {
          title: '交货方式',
          dataIndex: 'deliveryWay',
          key:'deliveryWay',
          width: '60px',
          align: 'center',
          scopedSlots: {customRender: 'deliveryWay'},
        },
        {
          title: '交货时间',
          dataIndex: 'deliveryTime',
          key:'deliveryTime',
          width: '80px',
          align: 'center',
          scopedSlots: {customRender: 'deliveryTime'},
        },

        {
          title: '备注',
          dataIndex: 'remark',
          key:'remark',
          width: '80px',
          align: 'center',
        },
      ],
    }
  },
  methods:{
    customRow(record){
      return {
        on:{
          click:(event)=>{
            this.selectedRowId = record.id;
          },
        },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    handleOk(){
      this.handleCancel();
    },
    handleCancel(){
      this.customVisible = false;
      this.$emit("changeOrderDetail",undefined);
      this.$emit("changeOrderDetailVisible",this.customVisible);
    },
    //一键确认所有订单
    ensureAllOrderDetails(){
      const orderId = this.orderDetailInfo.id;
      this.$api.purchaseOrder.ensureOrder(orderId).then(res=>{
        if(res.code===200&&res.success){
          this.$message.success("操作成功！");
          this.ensureBtnStatus = true;
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    cancelAllOrderDetails(){
      const orderId = this.orderDetailInfo.id;
      this.$api.purchaseOrder.cancelOrder(orderId).then(res=>{
        if(res.code===200&&res.success){
          this.$message.success("操作成功！");
          this.ensureBtnStatus = true;
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
    //点击验收
    receiveOrder(record){
      this.receiveVisible = true;
      const taskCode = this.orderDetailInfo.purchaseOrderTaskCode;
      const orderCode = this.orderDetailInfo.code;
      const restaurantName = this.orderDetailInfo.restaurantName;
      const sourceTotalPrice = this.orderDetailInfo.totalPrice;
      this.receiveDetailInfo = {...record,taskCode,orderCode,restaurantName,sourceTotalPrice};
    },
    changeReceiveVisible(value){
      this.receiveVisible = value;
    },
    changeReceiveDetail(value){
      this.receiveDetailInfo = value;
    },

    changeSenderDetail(value){
      this.senderDetailInfo = value;
    },
    changeSenderDetailVisible(value){
      this.senderDetailVisible = value;
    },
    //配送员信息
    senderDetail(){
      //TODO 修改显示配送员信息的对话框状态控制字段，显示配送员信息,填写好后点击确认配送关闭对话框，修改订单状态
      this.senderDetailInfo = this.orderDetail;
      this.senderDetailVisible = true;
    },
    //验收信息
    receiveOrderDetail(){
    },

    getFormatMoney(money,isCarryBit){
      const result = formatMoney(money,2,".",",",isCarryBit);
      return result;
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
  },
  computed:{
    itemTotalPrice(){
      if(this.orderDetailInfo.unit){
        return "元/"+this.orderDetailInfo.unit;
      }else {
        return "元";
      }
    },
  },
  watch: {
    orderDetail(newValue) {
      if (newValue) {
        this.orderDetailInfo = newValue.purchaseOrder;
        this.orderDetailList = newValue.purchaseOrderDetailList;
        const state = newValue.purchaseOrder.status;
        if(this.userRoles.indexOf('5')!==-1){
          const item = {
            title: '操作',
            dataIndex: 'action',
            key:'action',
            width: '60px',
            align: 'center',
            scopedSlots: { customRender: 'action' },
          }
          const lastItem = this.columns[this.columns.length-1];
          if(lastItem.key!=='action'){
            this.columns.push(item);
          }
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.orderDetail {
  .order-detail-table-info {
  }
}
::v-deep .ant-table-thead > tr > th {
  padding: 8px;
}

::v-deep .ant-table-tbody > tr > td {
  padding: 8px;
}
::v-deep .clickRowStyle{
  background-color:#e6f7ff
}

</style>