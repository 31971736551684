<template>
  <div class="edit-role">
    <a-modal title="编辑角色"
             :visible="editVisible"
             :confirm-loading="confirmLoading"
             okText="确定" cancelText="取消"
             @ok="handleOk"
             @cancel="handleCancel"
             width="400px">
      <a-form class="edit-role-form"
              :form="editRoleForm"
              label-width="100px">
        <a-form-item label="角色名称">
          <a-row>
            <a-col :span="24">
              <a-input ref="roleName"
                       :value ="roleInfo.roleName"
                       type="text"
                       key="0"
                       @change="$emit('changeRoleName',$event.target.value)"
                       allowClear>
              </a-input>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item label="启用状态">
          <a-select ref="status"
                    :value ="roleInfo.status"
                    :getPopupContainer="trigger => trigger.parentNode"
                    allowClear
                    style="width: 120px"
                    size="large"
                    placeholder="请选择启用状态"
                    @change="changeStatus">
            <a-select-option v-for="(item,index) in [0,1]" :key="index" :value="item">
              {{item===0?"启用": "禁用"}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="描述">
          <a-textarea ref="desc"
                      :value="roleInfo.describes"
                      placeholder="角色描述"
                      rows="4"
                      @change="$emit('changeDes',$event.target.value)"
                      allowClear/>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "roleDetail",
  props:{
    editVisible:{
      type:Boolean,
    },
    roleInfo:{
      type:Object,
    },
  },
  inject:["reload"],
  data(){
    return {
      customVisible:this.editVisible,
      confirmLoading:false,
      editRoleForm:this.$form.createForm(this,{name: 'edit_role_form'}),
      editParam:{
        id:undefined,
        roleName:undefined,
        roleCode:undefined,
        status:undefined,
        describes:undefined,
      },
    }
  },
  methods:{
    handleOk(){
      this.confirmLoading = true;
      this.editParam = this.roleInfo;
      if(!this.editParam.roleName){
        this.$message.error("角色名称不能为空！");
        return;
      }
      if(this.editParam.status===undefined||this.editParam.status===null){
        this.$message.error("角色启用状态不能为空");
        return;
      }
      this.$api.role.addOrUpdate(this.editParam).then(res=>{
        if(res.code===200&&res.success){
          this.$message.success("编辑成功!!");
          this.confirmLoading = false;
          this.customVisible = false;
          this.$emit("changeEditVisible",this.customVisible);
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
            this.confirmLoading = false;
          }
        }
      });
    },
    changeStatus(value){
      this.$emit('changeStatus',value)
    },
    handleCancel(){
      const item = this.$store.getters.roleInfo;
      this.$emit('changeRoleName',item.roleName);
      this.$emit('changeStatus',item.status);
      this.$emit('changeDes',item.describes);
      this.customVisible = false;
      this.$emit("changeEditVisible",this.customVisible);
    },

  },
}
</script>

<style lang="scss" scoped>
.edit-role{
  .edit-role-form{
    padding: 10px 10px 0 0;
    border-top: 1px solid #eee !important;
    border-bottom: 1px solid #eee !important;
    .editable-row-operations a{
      margin-right: 8px;
    }
    ::v-deep .ant-table-body>tr>td{
      padding: 10px;
    }
    ::v-deep .ant-table-thead>tr>th{
      padding: 10px;
    }
  }
}
</style>