<template>
  <div>入库单详情</div>
</template>

<script>
export default {
  name: "detail"
}
</script>

<style scoped>

</style>