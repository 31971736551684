<template>
  <div class="edit-goods">
    <a-modal title="商品编辑"
             :visible="editVisible"
             :confirm-loading="confirmLoading"
             okText="确定" cancelText="取消"
             @ok="handleOk"
             @cancel="handleCancel"
             width="400px">
      <a-form class="edit-goods-form"
              :form="editGoodsForm"
              label-width="100px">
        <a-form-item label="分类名称">
          <a-row>
            <a-col :span="24">
              <a-input ref="goodsName"
                       :value ="goodsInfo.name"
                       type="text"
                       @change="$emit('changeGoodsName',$event.target.value)"
                       allowClear>
              </a-input>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item label="单位">
          <a-row>
            <a-col :span="24">
              <a-input ref="goodsUnit"
                       :value ="goodsInfo.unit"
                       type="text"
                       @change="$emit('changeGoodsUnit',$event.target.value)"
                       allowClear>
              </a-input>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item>
          <a-row>
            <a-col :span="24">
              <category-selector ref="categorySelector"
                                 :current-value="{key:this.goodsInfo.categoryId,label:this.goodsInfo.categoryName}"
                                 @categorySelectChange="categorySelectChange"/>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item class="upload-form-item" label="分类图片">
          <a-upload action="/auth/upLoad"
                    class="avatar-uploader"
                    :headers=headers
                    name="file"
                    :data=this.uploadParams
                    :fileList="fileList"
                    list-type="picture-card"
                    :before-upload="handleBeforeUpload"
                    :remove="handleRemovePicture"
                    @preview="handlePreview"
                    @change="handleChange">
            <div v-if="!this.editParam.mainPicture">
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="分类图片" style="width: 100%" :src="editParam.mainPicture"/>
          </a-modal>
        </a-form-item>
        <a-form-item label="描述">
          <a-textarea ref="desc"
                      :value="goodsInfo.description"
                      placeholder="填写商品描述"
                      rows="4"
                      @change="$emit('changeGoodsDesc',$event.target.value)"
                      allowClear/>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea ref="remark"
                      :value="goodsInfo.remark"
                      placeholder="填写备注"
                      rows="4"
                      @change="$emit('changeRemark',$event.target.value)"
                      allowClear/>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import Modal from "ant-design-vue/lib/modal/Modal";
import CategorySelector from "@/components/categorySelector";

export default {
  name: "editGoods",
  components: {CategorySelector},
  props:{
    editVisible:{
      type:Boolean,
    },
    goodsInfo:{
      type:Object,
    },
  },
  inject:["reload"],
  data(){
    return {
      customVisible:this.editVisible,
      confirmLoading:false,
      selectResetFlag:false,
      editGoodsForm:this.$form.createForm(this,{name: 'edit_goods_form'}),
      editParam:{
        id: undefined,
        name: undefined,
        mainPicture: undefined,
        categoryId:undefined,
        categoryName:undefined,
        unit:undefined,
        description:undefined,
        remark: undefined,
      },
      headers:{
        contentType:'multipart/form-data'
      },
      previewVisible:false,
      fileList:[],
      loading:false,
      selectOption:{key:this.goodsInfo.categoryId,label:this.goodsInfo.categoryName},
    }
  },
  methods:{
    handleOk(){
      this.confirmLoading = true;
      const {id,name,categoryId,unit,description,remark} = this.goodsInfo;
      this.editParam.id = id;
      this.editParam.name = name;
      this.editParam.remark = remark;
      this.editParam.categoryId = categoryId;
      this.editParam.unit = unit;
      this.editParam.description = description;
      if(!this.editParam.categoryId){
        this.$message.error("分类名称不能为空！");
        return;
      }
      this.$api.goods.editGoods(this.editParam).then(res=>{
        if(res.code===200&&res.success){
          this.$message.success("编辑成功!!");
          this.customVisible = false;
          this.$emit("changeEditVisible",this.customVisible);
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      });
      this.confirmLoading = false;
    },
    //上传文件相关
    uploadParams(){
      let d = {
        companyName:this.$store.getters.userInfo.companyName,
        userType: this.$store.getters.userInfo.staffType,
      }
      return d;
    },
    handleBeforeUpload(file){
      return new Promise((resolve,reject)=>{
        const isJPG = file.type === 'image/jpg';
        const isJPEG = file.type=== 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isGIF = file.type === 'image/gif';
        const isLt10M = file.size/1024/1024 <10;
        if(!(isJPG||isJPEG||isPNG||isGIF)){
          Modal.error({
            title:'只能上传JPG、JPEG、GIF、PNG格式的图片',
          });
          return reject(false);
        }else if(!isLt10M){
          Modal.error({
            title:'图片大小不能超过10M',
          });
          return reject(false);
        }
        return resolve(true);
      })
    },
    handleRemovePicture(){
      this.editParam.mainPicture = '';
    },
    handleChange(info){
      this.loading = true;
      this.fileList = info.fileList;
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
      if (info.file.status === 'done') {
        if (info.file.response.data) {
          this.editParam.mainPicture = info.file.response.data;
          this.$message.success(`${info.file.name} 上传成功`);
        } else {
          this.$message.error(`${info.file.name} ${info.file.response.msg}`);
        }
      }
      this.loading = false;
    },
    async handlePreview(file) {
      if(file.response.data){
        this.editParam.mainPicture  =  file.response.data;
        this.previewVisible = true;
      }else{
        this.$message.error("图片上传失败不能预览！")
      }
    },
    categorySelectChange(value){
      this.selectOption = value;
      this.editParam.categoryId = value.key;
      this.editParam.categoryName = value.label;
      this.$emit("changeEditCategory",value);
    },
    handleCancel(){
      const item = this.$store.getters.goodsInfo;
      this.fileList = [];
      this.editParam.mainPicture = null;
      this.$emit('changeGoodsName',item.name);
      this.$emit('changePicture',item.mainPicture);
      this.$emit('changeGoodsUnit',item.unit);
      this.$emit('changeRemark',item.remark);
      this.$emit('changeGoodsDesc',item.description);
      this.customVisible = false;
      this.$emit("changeEditVisible",this.customVisible);
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-goods{

  .upload-form-item{
    .avatar-uploader > .ant-upload{
      width: 128px;
      height: 128px;
    }
    .ant-upload-select-picture-card i {
      font-size: 32px;
      color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
}
</style>