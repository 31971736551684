<template>
  <div class="check-company-detail">
    <div class="check-modal">
      <a-modal :visible="checkVisible"
               :confirm-loading="confirmLoading"
               @ok="handleCheckOk"
               @cancel="handleCancel">
        <div class="company-detail">
          <a-descriptions title="公司详情" layout="vertical" bordered>
            <a-descriptions-item label="公司名称">
              <span v-if="!companyInfo||!companyInfo.companyVO.name" style="color: red">暂无数据</span>
              <span v-else >{{companyInfo.companyVO.name}}</span>
            </a-descriptions-item>
            <a-descriptions-item label="法人姓名">
              <span v-if="!companyInfo||!companyInfo.companyQualification.legalPersonName" style="color: red">暂无数据</span>
              <span v-else >{{companyInfo.companyQualification.legalPersonName}}</span>
            </a-descriptions-item>
            <a-descriptions-item label="公司类型">
              采购商
            </a-descriptions-item>
            <a-descriptions-item label="管理人">
              <span v-if="!companyInfo||!companyInfo.companyVO.leaderName" style="color: red">暂无数据</span>
              <span v-else >{{companyInfo.companyVO.leaderName}}</span>
            </a-descriptions-item>
            <a-descriptions-item label="管理人电话">
              <span v-if="!companyInfo||!companyInfo.companyVO.leaderPhone" style="color: red">暂无数据</span>
              <span v-else >{{companyInfo.companyVO.leaderPhone}}</span>
            </a-descriptions-item>
            <a-descriptions-item label="审核状态">
              <span v-if="!companyInfo">暂无数据</span>
              <span v-else-if="companyInfo.companyVO.status==0">未审核</span>
              <span v-else-if="companyInfo.companyVO.status==1" style="color: limegreen">审核通过</span>
              <a-popover v-else placement="top">
                <template slot="content">
                  <span>{{companyInfo.companyVO.checkRemark?companyInfo.companyVO.checkRemark:'暂无数据'}}</span>
                </template>
                <span style="color: red">审核驳回</span>
              </a-popover>

            </a-descriptions-item>
            <a-descriptions-item label="注册时间" >
              <span v-if="!companyInfo">暂无数据</span>
              <span v-else-if="companyInfo.companyVO.createTime">{{ getFormatDate(companyInfo.companyVO.createTime) }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="营业执照号" :span="2">
              <span v-if="!companyInfo||!companyInfo.companyQualification.businessLicenseNo" style="color: red">暂无数据</span>
              <span v-else >{{companyInfo.companyQualification.businessLicenseNo}}</span>
            </a-descriptions-item>
            <a-descriptions-item label="身份证照片" :span="3">
              <div v-if="companyInfo">
                <a-popover title="身份证国徽面" placement="top">
                  <template slot="content">
                    <span v-if="!companyInfo.companyQualification.legalPersonIdCardFront" style="color: red">暂无数据</span>
                    <img v-else :src="companyInfo.companyQualification.legalPersonIdCardFront"
                         width="256px" height="256px">
                  </template>
                  <span style="color: #1890ff">身份证国徽面</span>
                </a-popover>
                &nbsp;|&nbsp;
                <a-popover title="身份证人面像" placement="top">
                  <template slot="content">
                    <span v-if="!companyInfo.companyQualification.legalPersonIdCardBack" style="color: red">暂无数据</span>
                    <img v-else :src="companyInfo.companyQualification.legalPersonIdCardBack"
                         width="256px" height="256px">
                  </template>
                  <span style="color: #1890ff">身份证人面像</span>
                </a-popover>
                &nbsp;|&nbsp;
                <a-popover title="身份证手持人面像" placement="top">
                  <template slot="content">
                    <span v-if="!companyInfo.companyQualification.legalPersonIdCardHandHold" style="color: red">暂无数据</span>
                    <img v-else :src="companyInfo.companyQualification.legalPersonIdCardHandHold"
                         width="256px" height="256px">
                  </template>
                  <span style="color: #1890ff">身份证手持人面像</span>
                </a-popover>
              </div>
            </a-descriptions-item>
            <a-descriptions-item label="营业执照" :span="3">
              <a-popover title="营业执照" placement="top">
                <template slot="content">
                  <img v-if="!companyInfo||!companyInfo.companyQualification
                  ||!companyInfo.companyQualification.businessLicense"
                       :src="require('../../assets/images/register/status2.png')">
                  <img v-else :src="companyInfo.companyQualification.businessLicense"
                       width="256px" height="256px" alt="营业执照">
                </template>
                <span style="color: #1890ff">营业执照</span>
              </a-popover>
            </a-descriptions-item>
          </a-descriptions>
        </div>
        <template slot="footer">
          <div class="modal-footer">
            <a-button key="back" @click="handleCancel">
              返回
            </a-button>
            <a-button :disabled="companyInfo.companyVO.status!=0" key="success" type="primary" :loading="confirmLoading" @click="handleCheckOk">
              审核通过
            </a-button>
            <a-button :disabled="companyInfo.companyVO.status!=0" key="error" type="primary" :loading="confirmLoading" @click="handleCheckFailed">
              审核驳回
            </a-button>
          </div>
        </template>
      </a-modal>
    </div>
    <div class="check-fail-modal">
      <a-modal title="审核驳回详情"
               style="color: red"
               :visible="checkFailedVisible"
               okText="确定"
               cancelText="取消"
               @ok="checkFailedSubmit"
               @cancel="checkFailedReturn"
               width="400px">
        <a-textarea placeholder="审核驳回原因描述"
                    rows="4"
                    v-model="failedDetail"
                    allowClear/>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {dateFormat} from "@/utils/common";

export default {
  name: "companyDetail",
  inject:["reload"],
  props:{
    checkVisible:{
      type:Boolean,
    },
    companyInfo:{
      type:Object,
    }
  },
  data(){
    return {
      confirmLoading:false,
      checkFailedVisible:false,
      failedDetail:undefined,
    }
  },
  methods:{
    handleCheckOk(){
      this.confirmLoading = true;
      const checkParam = {
        id:this.companyInfo.companyVO.id,
        status:1,
      }
      this.$api.company.check(checkParam).then(res=>{
        if(res.code===200&&res.success){
          this.$message.success("操作成功！！");
          this.$emit("changeCheckVisible",false);
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
      this.confirmLoading = false;
    },
    handleCancel(){
      this.$emit("changeCheckVisible",false);
    },
    handleCheckFailed(){
      this.checkFailedVisible = true;
      this.$emit("changeCheckVisible",false);
    },
    // checkFailDetail(value){
    //   this.failedDetail = value;
    // },
    checkFailedSubmit(){
      this.confirmLoading = true;
      if(!this.failedDetail){
        this.$message.error("审核驳回需填写驳回原因！！");
        return;
      }
      const checkParam = {
        id:this.companyInfo.companyVO.id,
        status:2,
        checkRemark:this.failedDetail,
      }
      this.$api.company.check(checkParam).then(res=>{
        if(res.code===200&&res.success){
          this.$message.success("操作成功！！");
          this.$emit("changeCheckVisible",false);
          this.checkFailedVisible = false;
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
      this.confirmLoading = false;
    },
    checkFailedReturn(){
      this.checkFailedVisible = false;
      this.$emit("changeCheckVisible",true);
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
  }
}
</script>

<style lang="scss" scoped>
.check-company-detail{
  .check-modal{
    .company-detail{

    }
    .modal-footer{
      flex: content;
    }
  }
  .check-company-detail{

  }
}
</style>