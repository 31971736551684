<template>
  <div class="bg">
    <div class="content">
      <a-form class="register-form" :form="registerForm" @submit="handleSubmit">
        <a-form-item>
          <a-row>
            <a-col :span="24" :offset="1">
              <a-form-item class="register-form-header">
                <div>
                  <span>欢迎注册</span>
                </div>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="公司名称">
          <a-input
              v-decorator="['companyName',rules.companyName]"
              type="text"
              key="0"
              allowClear>
          </a-input>
        </a-form-item>

        <a-form-item v-bind="formItemLayout" label="公司类型">
          <a-radio-group name="userType" v-decorator="['userType',rules.userType]">
            <a-radio :value="0">
              商户/餐厅(采购商)
            </a-radio>
            <a-radio :value="1">
              供应商
            </a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item v-bind="formItemLayout" label="公司地址">
          <a-cascader
              :field-names="{label:'name',value:'name',children:'children'}"
              v-decorator="['residence',rules.residence]"
              placeholder="请选择省市区"
              :options="residences"
          />
        </a-form-item>

        <a-form-item v-bind="formItemLayout" label="详细地址">
          <a-input
              v-decorator="['address',rules.address]"
              type="text"
              key="1"
              allowClear>
          </a-input>
        </a-form-item>

        <a-form-item v-bind="formItemLayout" label="营业执照号">
          <a-input
              v-decorator="['business_license_no',rules.business_license_no]"
              type="text"
              key="2"
              allowClear>
          </a-input>
        </a-form-item>

        <a-form-item v-bind="formItemLayout" label="公司法人">
          <a-input
              v-decorator="['legal_person_name',rules.legal_person_name]"
              type="text"
              key="3"
              allowClear>
          </a-input>
        </a-form-item>

        <a-form-item v-bind="formItemLayout">
          <span slot="label">
            管理人姓名
            <a-tooltip title="该用户手机号用于登录公司账户，具有公司最高权限"
                       overlayClassName="toolTipClass">
              <a-icon type="question-circle-o"/>
            </a-tooltip>
          </span>
          <a-input
              v-decorator="['leader_name',rules.leader_name]"
              type="text"
              key="4"
              allowClear>
          </a-input>
        </a-form-item>

        <a-form-item v-bind="formItemLayout" label="管理人手机号">
          <a-input
              v-decorator="['leader_phone',rules.leader_phone]"
              style="width: 100%"
              addon-before="+86"
              allowClear
          >
<!--            <a-select-->
<!--                slot="addonBefore"-->
<!--                v-decorator="['prefix', { initialValue: '86' }]"-->
<!--                style="width: 70px"-->
<!--            >-->
<!--              <a-select-option value="86">-->
<!--                +86-->
<!--              </a-select-option>-->
<!--              <a-select-option value="87">-->
<!--                +87-->
<!--              </a-select-option>-->
<!--            </a-select>-->
          </a-input>
        </a-form-item>

        <a-form-item
            v-bind="formItemLayout"
            label="验证码"
        >
          <a-row :gutter="8">
            <a-col :span="12">
              <a-input
                  v-decorator="['smsCode',rules.smsCode]"
                  allowClear
              />
            </a-col>
            <a-col :span="12">
              <a-button class="getCode" :loding="this.loading" :disabled="this.disabled" icon="poweroff"
                        @click="getCode" type="primary" style="background-color: #1890ff;color:#f7f7f7">
                {{ loading === true ? this.delayCount + "秒后重发" : "获取验证码" }}
              </a-button>
            </a-col>
          </a-row>
        </a-form-item>

        <a-form-item v-bind="formItemLayout" label="密码" has-feedback>
          <a-input
              v-decorator="['password',rules.password]"
              type="password"
              allowClear
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="确认密码" has-feedback>
          <a-input v-decorator="['repeatPwd',rules.repeatPwd]"
                   type="password"
                   allowClear
                   @blur="handleConfirmBlur"
          />
        </a-form-item>

        <a-form-item class="upload-form-item" v-bind="formItemLayout" label="上传法人身份证">
          <div class="upload-items">
            <a-upload
                v-decorator="['uploadIdCardF',rules.uploadIdCardF]"
                action="/auth/upLoad"
                :headers=headers
                name="file"
                :data=this.uploadParams
                list-type="picture-card"
                :before-upload="handleBeforeUpload"
                @preview="handlePreview"
                @change="handleChange"
                :remove="handleRemoveIdCarF"
            >
              <div v-if="uploadIdCardFState" @click="changeUploadIdCardFState">
                <a-icon type="plus"/>
                <div class="ant-upload-text">
                  国徽面
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewCardFVisible" :footer="null" @cancel="handleCardFCancel">
              <img alt="国徽面" style="width: 100%" :src="previewCardFImage"/>
            </a-modal>
            <a-upload
                action="/auth/upLoad"
                :headers=headers
                name="file"
                :data=this.uploadParams
                list-type="picture-card"
                :before-upload="handleBeforeUpload"
                @preview="handlePreview"
                @change="handleChange"
                :remove="handleRemoveIdCarS"
            >
              <div v-if="uploadIdCardSState" @click="changeUploadIdCardSState">
                <a-icon type="plus"/>
                <div class="ant-upload-text">
                  肖像面
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewCardSVisible" :footer="null" @cancel="handleCardSCancel">
              <img alt="肖像面" style="width: 100%" :src="previewCardSImage"/>
            </a-modal>
            <a-upload
                action="/auth/upLoad"
                :headers=headers
                name="file"
                :data=this.uploadParams
                list-type="picture-card"
                :before-upload="handleBeforeUpload"
                @preview="handlePreview"
                @change="handleChange"
                :remove="handleRemoveIdCarT">
              <div v-if="uploadIdCardTState" @click="changeUploadIdCardTState">
                <a-icon type="plus"/>
                <div class="ant-upload-text">
                  手持人面照
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewCardTVisible" :footer="null" @cancel="handleCardTCancel">
              <img alt="手持人面照" style="width: 100%" :src="previewCardTImage" />
            </a-modal>
          </div>
        </a-form-item>

        <a-form-item class="upload-form-item" v-bind="formItemLayout" label="营业执照">
          <a-upload v-decorator="['uploadLicense',rules.upload]"
                    action="/auth/upLoad"
                    :headers=headers
                    name="file"
                    :data=this.uploadParams
                    list-type="picture-card"
                    :before-upload="handleBeforeUpload"
                    :remove="handleRemoveLicense"
                    @preview="handlePreview"
                    @change="handleChange">
            <div v-if="uploadLicenseState" @click="changeUploadLicenseState">
              <a-icon type="plus"/>
              <div class="ant-upload-text">
                营业执照
              </div>
            </div>
          </a-upload>
          <a-modal :visible="previewLicenseVisible" :footer="null" @cancel="handleLicenseCancel">
            <img alt="营业执照" style="width: 100%" :src="licenseImage"/>
          </a-modal>
        </a-form-item>
        <a-form-item class="upload-form-item" v-bind="formItemLayout" label="公司头像">
          <a-upload
              action="/auth/upLoad"
              :headers=headers
              name="file"
              :data=this.uploadParams
              list-type="picture-card"
              :before-upload="handleBeforeUpload"
              @preview="handlePreview"
              @change="handleChange"
              :remove="handleRemoveHeadPic">
            <div v-if="uploadHeadPicState" @click="changeUploadHeadPicState">
              <a-icon type="plus"/>
              <div class="ant-upload-text">
                公司LOGO
              </div>
            </div>
          </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="公司头像" style="width: 100%" :src="headPicImage"/>
            </a-modal>
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="公司简介">
          <a-textarea v-decorator="['introduce',rules.introduce]" placeholder="公司基本信息" :rows="4"  allowClear/>
        </a-form-item>
        <a-form-item v-bind="tailFormItemLayout">
          <a-checkbox v-decorator="['agreement', rules.agreement,]">
            我已阅读并同意
            <a @click="showPrivacyInfo">《隐私政策》</a>
          </a-checkbox>
        </a-form-item>
        <a-form-item v-bind="tailFormItemLayout">
          <a-button type="primary" html-type="submit"
                    style="justify-content: center;width: 50%;margin: 0px 15px; font-size: 20px">
            注册
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <div class="privacyDialog">
      <a-modal :visible="privacyVisible"
               @cancel="handlePrivacyCancel"
               :body-style="bodyStyle"
               width="790px">
        <template slot="title">
          <span style="font-weight: bolder;">隐私政策说明</span>
        </template>
        <div style=" line-height: 25px; background-color: white;padding-top: 10px;
             color: black; height: 178px;">
          <span style="color: black;padding-right: 5px;width: 700px">
            喜来缘采购信息管理系统隐私政策
            <br>
            版本更新时间2022年8月
            <br>
            云南亨泽科技发展有限责任公司(以下称为“本公司”或“我们”)非常重视您的个人信息保护，并会尽全力保护您的个人信息。
            本《隐私政策》适用于本公司向您提供的“喜来缘采购信息管理系统”相关产品及信息服务（以下简称“服务”），请您在使用服务前，仔细阅读并了解本《隐私政策》。
            <br>
            本政策包含以下内容：
            <br>
            一、我们如何收集和使用您的个人信息
            <br>
            二、我们如何使用 Cookie 和同类技术
            <br>
            三、我们如何共享、转让、公开披露您的个人信息
            <br>
            四、我们如何保存及保护您的个人信息
            <br>
            五、您的权利
            <br>
            六、您的个人信息如何在全球范围转移
            <br>
            七、本政策如何更新
            <br>
            八、如何联系我们
            <br>
            个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
            <br>
            本公司仅会出于本政策所述的以下目的，收集和使用您的个人信息：
            <br>
            （一）为更好地为您提供服务，本公司将遵循“合法、正当、必要”原则，按照法律法规的规定和您的同意收集您的个人信息，主要包括：
            <br>
            1.您提供的信息
            <br>
            您注册成为我们的用户或使用我们的服务时，向我们提供的相关个人信息，包括您的账号名称、性别、生日、电话号码、电子邮箱地址等。
            <br>
            您提供的上述信息，将在您使用服务期间持续授权我们使用。在您注销账号时，我们将删除您的个人信息或做匿名化处理。
            <br>
            2.您在使用服务过程中产生的信息
            <br>
            您使用我们提供的服务时，我们可能自动收集以下信息并记录日志
            <br>
            指您使用我们服务时，系统可能通过 cookies或其他方式自动采集的技术信息，包括：
            <br>
            （1）. 设备或软件信息，例如您的移动设备、网页浏览器或您用于接入我们的服务的其他程序所提供的配置信息、您的IP地址、操作系统版本、浏览器版本；
            <br>
            （2）. 您在使用我们服务时搜索和浏览的信息，例如您使用的搜索关键字、访问页面，以及您在使用我们服务时浏览或要求提供的其他信息；
            <br>
            （3）. 您在使用我们服务时，我们将记录您的关键业务操作；
            <br>
            （二）我们可能将收集到的您的个人信息用于以下目的：
            <br>
            1.为您提供服务
            <br>
            （1）在我们提供服务时，用于身份验证、客户服务、安全防范、存档和备份用途，确保我们向您提供的产品和服务的安全性；
            <br>
            （2）通过信息数据分析，帮助我们设计新服务，改善我们现有服务；
            <br>
            （3）使我们更加了解您如何使用我们的服务，从而针对性地满足您的个性化需求，例如语言设定、操作习惯、个性化的帮助服务和指示，或对您和其他使用我们服务的用户作出其他方面的回应；
            <br>
            （4）让您参与有关我们产品和服务的调查。
            <br>
            2.在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理。去标识化处理的信息将无法识别主体。
            请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在符合相关法律法规的前提下，我们有权对包括您的个人信息在内的用户数据库进行整体化分析和利用（包括商业性利用）。
            <br>
            3.当我们要将信息用于本政策未载明的其它用途时，会事先征求您的同意。
            <br>
            4.您充分知晓，以下情形中我们使用个人信息无需征得您的授权同意：
            <br>
            （1）与国家安全、国防安全直接相关的；
            <br>
            （2）与公共安全、公共卫生、重大公共利益直接相关的；
            <br>
            （3）与犯罪侦查、起诉、审判和判决执行等直接相关的；
            <br>
            （4）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
            <br>
            （5）所收集的个人信息是个人信息主体自行向社会公众公开的；
            <br>
            （6）从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
            <br>
            （7）根据您的要求签订合同所必需的；
            <br>
            （8）用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；
            <br>
            （9）为合法的新闻报道所必需的；
            <br>
            （10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
            <br>
            （11）法律法规规定的其他情形。
            <br>
            二、我们如何使用 Cookie 和同类技术
            <br>
            （一）Cookie
            <br>
            为确保服务能够正常运转，我们会在您的计算机或移动设备上存储名为 Cookie 的小数据文件。
            Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于 Cookie，我们能够了解您的兴趣偏好及使用习惯。
            <br>
            我们不会将 Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除 Cookie。有关详情，请参见 AboutCookies.org。
            <br>
            您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止 Cookie 的功能。但如果您这么做，则需要在每一次访问我们产品时亲自更改用户设置。
            <br>
            （二）网站信标和像素标签
            <br>
            除 Cookie 外，我们还可能会使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的点击 URL。
            如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
            <br>
            （三）Do Not Track（请勿追踪）
            <br>
            很多网络浏览器均设有 Do Not Track 功能，该功能可向网站发布 Do Not Track 请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了 Do Not Track，那么我们的所有网站都会尊重您的选择。
            <br>
            三、我们如何共享、转让、公开披露您的个人信息
            <br>
            （一）共享
            <br>
            我们不会与本公司以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：
            <br>
            1.在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
            <br>
            2.我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
            <br>
            对我们与之共享个人信息的公司、组织和个人，我们会与其签署相应的保密协定，要求他们按照我们的说明、本个人信息保护政策以及其他任何相关的保密和安全措施来处理个人信息。
            <br>
            （二）转让
            <br>
            我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
            <br>
            1.在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
            <br>
            2.在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受此个人信息保护政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
            <br>
            （三）公开披露
            <br>
            我们仅会在以下情况下，公开披露您的个人信息：
            <br>
            1.获得您明确同意后；
            <br>
            2.基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
            <br>
            四、我们如何保存及保护您的个人信息
            <br>
            （一）保存期限。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非经过您的同意延长保留期或受到法律的允许。
            如果您注销账号或主动删除上述信息，我们将按照法律、法规规定的最短期限保留您的现有个人信息，在法律法规要求的最短保存期限内，
            我们将不再对您的个人信息进行商业化使用。当您的个人信息超出上述保存期限，我们将会对其进行匿名化处理。
            <br>
            （二）存放地域。您的个人信息均储存于中华人民共和国境内；我们仅会在本政策第三条第（一）款第4项的A中所述情形下将您的个人信息传输至中华人民共和国境外，除此之外如需跨境传输，我们将会单独征得您的授权同意。
            <br>
            （三）安全保护措施。
            <br>
            1. 我们会以“最小化”原则收集、使用、存储和传输用户信息，并通过用户协议和隐私政策告知您相关信息的使用目的和范围。
            我们会严格按照《网络安全法》《全国人民代表大会常务委员会关于加强网络信息保护的决定》《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等法律法规的相关要求，建立信息安全保障制度，采取技术措施和其他必要措施保护您的个人信息安全。
            <br>
            2.本公司将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。如：使用加密技术确保数据的保密性；使用受信赖的保护机制防止数据遭到恶意攻击；对相关访问进行授权；
            定期开展数据安全专项检查等安全防护与检测措施；对员工进行数据安全的意识培养和安全能力的培训，加强员工对于保护个人信息重要性的认识。
            <br>
            3.请您理解：互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性，但由于技术的限制及可能存在的各种恶意手段，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百安全。
            <br>
            4.在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。
            我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
            <br>
            五、您的权利
            <br>
            1.查询和更正您的个人信息
            <br>
            （1）您可以随时登录个人账户，访问或编辑账户中的个人资料信息、更改密码等。您可以在使用服务过程中查阅个人的邀请记录、发出记录、订单信息等。
            <br>
            （2）在您通过用户身份验证，且更正不影响信息的客观性和准确性的情况下，您有权对错误或不完整的信息做出更正或更新。您可以自行进行更正，或通过意见反馈或联系客服等将更正申请提交给我们。
            <br>
            2.删除个人信息
            <br>
            您在我们的产品与/或服务页面中可以直接清除或删除的信息，包括浏览信息、收藏信息等；
            <br>
            在以下情形中，您可以向我们提出删除个人信息的请求：
            <br>
            （1）如果我们处理个人信息的行为违反法律法规；
            <br>
            （2）如果我们收集、使用您的个人信息，却未征得您的同意；
            <br>
            （3）如果我们处理个人信息的行为违反了与您的约定；
            <br>
            （4）如果您不再使用我们的产品或服务，或您注销了账号；
            <br>
            （5）如果我们不再为您提供产品或服务。
            <br>
            当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
            <br>
            3.撤回同意
            <br>
            每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。
            <br>
            （1）您可以通过删除信息、关闭设备功能、进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权 (具体设置以服务呈现的页面为准)。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。
            <br>
            （2）当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也将不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的同意或授权而开展的个人信息处理。
            <br>
            4.注销账号
            <br>
            您注销账号之后，我们将停止为您提供产品或服务，并依据您的要求，删除您的个人信息，法律法规另有规定的除外。
            <br>
            六、您的个人信息如何在全球范围转移
            <br>
            原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。我们仅会在本政策第三条第（一）款第4项的A中所述情形下将您的个人信息传输至中华人民共和国境外，除此之外如果需要将您的个人信息转移到境外，我们将另行获得您的授权同意。
            在此情况下我们会依据本政策以使您的个人信息得到足够的保护。
            <br>
            七、本政策如何更新
            <br>
            1.我们的个人信息保护政策可能变更。原则上，我们不会削减您按照本个人信息保护政策所应享有的权利。
            <br>
            2.我们会以通告的形式发布对本政策所做的任何变更。在该种情况下，若您继续使用我们的服务，即表示您同意接受修订后的隐私政策约束。
            <br>
            3.如无特殊说明，修订后的隐私政策自公布之日起生效。
            <br>
            八、如何联系我们
            <br>
            如果您对本个人信息保护政策有任何疑问、意见或建议，可以随时通过拨打客服电话、登录平台等方式与我们联系，我们会积极进行查证、处理并尽快答复。
            <br>
            本公司联系方式如下：
            <br>
            公司名称：云南亨泽科技发展有限责任公司
            <br>
            办公地址：云南省玉溪市新平彝族傣族自治县扬武镇龙泉路46号附1号
            <br>
            邮编：650031
            <br>
            电话：13887710218
            <br>
            感谢您花时间了解我们的隐私政策！
            <br>
          </span>
        </div>
        <template slot="footer">
          <a-button @click="handlePrivacyCancel">退出</a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>

import {isMobile} from "@/utils/common";
import {provinceInfo} from "@/config/provinceInfo";
import Modal from "ant-design-vue/lib/modal/Modal";
const residences = provinceInfo;
export default {
  name: "register",
  data() {
    return {
      privacyVisible:false,
      okButtonStyle:{props: { hidden: true },},
      bodyStyle:{
        height: '480px',
        overflow: 'hidden',
        overflowY: 'scroll',
      },
      registerForm:this.$form.createForm(this,{name:'register-form'}),
      supplierRegisterData:{
        supplierQualification: {
          businessLicense: "",
          businessLicenseNo: "",
          legalPersonIdCardBack: "",
          legalPersonIdCardFront: "",
          legalPersonIdCardHandHold: "",
          legalPersonName: ""
        },
        supplierVO: {
          address: "",
          cityName: "",
          code: "",
          districtName: "",
          introduce: "",
          leaderName: "",
          leaderPhone: "",
          logoPicture: "",
          name: "",
          password: "",
          phone: "",
          provinceName: "",
          repeatPwd: ""
        }
      },
      companyRegisterData:{
        companyQualification: {
          businessLicense: "",
          businessLicenseNo: "",
          legalPersonIdCardBack: "",
          legalPersonIdCardFront: "",
          legalPersonIdCardHandHold: "",
          legalPersonName: ""
        },
        companyVO: {
          address: "",
          cityName: "",
          code: "",
          districtName: "",
          introduce: "",
          leaderName: "",
          leaderPhone: "",
          logoPicture: "",
          name: "",
          password: "",
          phone: "",
          provinceName: "",
          repeatPwd: ""
        }
      },
      rules: {
        companyName: {
          rules: [
            {required: true, message: '请输入公司名称!'},
          ]
        },

        userType:{
          rules: [
            {required: true, message: '请选择公司类型!'},
          ]
        },
        residence:{
          rules:[
            { type: 'array', required: true, message: '请选择省市区' }
          ]
        },
        address: {
          rules: [
            {required: true, message: '请输入具体街道地址!'},
          ]
        },

        business_license_no: {
          rules: [
            {required: true, message: '请输入公司营业执照号!'},
          ]
        },
        legal_person_name: {
          rules: [
            {required: true, message: '请输入公司法人!'},
          ]
        },
        leader_name: {
          rules: [
            {required: true, message: '请输入管理人姓名!'},
          ]
        },

        leader_phone: {
          rules: [
            {required: true, message: '请输入管理人电话号码!'},
          ]
        },
        password: {
          rules: [
            {required: true, message: '请输入密码!'},
            {validator: this.validateToNextPassword},
          ]
        },
        repeatPwd: {
          rules: [
            {required: true, message: '请确认你的密码!'},
            {validator: this.compareToFirstPassword},
          ]
        },
        smsCode: {
          rules: [
            {required: true, message: '请输入验证码!'},
          ]
        },
        uploadIdCardF:{
          rules:[
            {required: true, message: '请上传图片!'},

          ],
        },
        upload:{
          rules:[
            {required: true, message: '请上传图片!'},

          ],
        },
        introduce: {
          rules:[
            {required: false, message: '请输入公司简介!'},
          ]
        },
        agreement:{
          rules:[
            {required: true, message: '注册需同意我司相关协议!'},
          ],
          valuePropName: 'checked'
        }
      },
      confirmDirty: false,
      residences,
      formItemLayout: {
        labelCol: {
          xs: {span: 24},
          sm: {span: 4, offset:4},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 14},
        },
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      },
      headers:{
        contentType:'multipart/form-data'
      },
      imgLoading:false,
      uploadIndex:0,
      uploadIdCardFState:true,
      uploadIdCardSState:true,
      uploadIdCardTState:true,
      uploadLicenseState:true,
      uploadHeadPicState:true,
      previewCardFVisible: false,
      previewCardSVisible: false,
      previewCardTVisible: false,
      previewLicenseVisible:false,
      previewVisible:false,
      previewCardFImage: '',
      previewCardSImage: '',
      previewCardTImage: '',
      licenseImage:'',
      headPicImage:'',
      loading: false,
      delayCount: 60,
      disabled: false,
      provinceArray: {},
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.registerForm.validateFieldsAndScroll((err, values) => {
        if (!err) {
          const userType = values.userType;
          if(userType<0||userType>1||userType===undefined){
            this.$message.error("请选择公司类型！")
            return;
          }
          if(!this.previewCardFImage){
            this.$message.error("请上传身份证国徽面！")
            return;
          }
          if(!this.previewCardSImage){
            this.$message.error("请上传身份证肖像面！")
            return;
          }
          if(!this.previewCardTImage){
            this.$message.error("请上传身份证手持人面照！")
            return;
          }
          if(!this.licenseImage){
            this.$message.error("请上传营业执照！")
            return;
          }
          if(userType===0){
            const residenceInfo = values.residence;
            this.companyRegisterData.companyVO.provinceName = residenceInfo[0];
            this.companyRegisterData.companyVO.cityName = residenceInfo[1];
            this.companyRegisterData.companyVO.districtName = residenceInfo[2];
            this.companyRegisterData.companyVO.name = values.companyName;
            this.companyRegisterData.companyVO.address = values.address;
            this.companyRegisterData.companyQualification.businessLicenseNo = values.business_license_no;
            this.companyRegisterData.companyQualification.legalPersonName = values.legal_person_name;
            this.companyRegisterData.companyVO.leaderName = values.leader_name;
            this.companyRegisterData.companyVO.leaderPhone = values.leader_phone;
            this.companyRegisterData.companyVO.code = values.smsCode;
            this.companyRegisterData.companyVO.password = values.password;
            this.companyRegisterData.companyVO.repeatPwd = values.repeatPwd;
            this.companyRegisterData.companyQualification.legalPersonIdCardFront = this.previewCardFImage;
            this.companyRegisterData.companyQualification.legalPersonIdCardBack = this.previewCardSImage;
            this.companyRegisterData.companyQualification.legalPersonIdCardHandHold = this.previewCardTImage;
            this.companyRegisterData.companyQualification.businessLicense = this.licenseImage;
            this.companyRegisterData.companyVO.logoPicture = this.headPicImage;
            this.companyRegisterData.companyVO.introduce =values.introduce;
            this.$api.company.register(this.companyRegisterData).then(res=>{
             if(res.code===200){
               this.$message.success("注册成功！！")
               this.$router.replace("/login")
             }else{
               if(res.msg){
                 this.$message.error(res.msg);
               }
             }
            });
          }
          if(userType===1){
            const residenceInfo = values.residence;
            this.supplierRegisterData.supplierVO.provinceName = residenceInfo[0];
            this.supplierRegisterData.supplierVO.cityName = residenceInfo[1];
            this.supplierRegisterData.supplierVO.districtName = residenceInfo[2];
            this.supplierRegisterData.supplierVO.name = values.companyName;
            this.supplierRegisterData.supplierVO.address = values.address;
            this.supplierRegisterData.supplierQualification.businessLicenseNo = values.business_license_no;
            this.supplierRegisterData.supplierQualification.legalPersonName = values.legal_person_name;
            this.supplierRegisterData.supplierVO.leaderName = values.leader_name;
            this.supplierRegisterData.supplierVO.leaderPhone = values.leader_phone;
            this.supplierRegisterData.supplierVO.code = values.smsCode;
            this.supplierRegisterData.supplierVO.password = values.password;
            this.supplierRegisterData.supplierVO.repeatPwd = values.repeatPwd;
            this.supplierRegisterData.supplierQualification.legalPersonIdCardFront = this.previewCardFImage;
            this.supplierRegisterData.supplierQualification.legalPersonIdCardBack = this.previewCardSImage;
            this.supplierRegisterData.supplierQualification.legalPersonIdCardHandHold = this.previewCardTImage;
            this.supplierRegisterData.supplierQualification.businessLicense = this.licenseImage;
            this.supplierRegisterData.supplierVO.logoPicture = this.headPicImage;
            this.supplierRegisterData.supplierVO.introduce =values.introduce;
            this.$api.supplier.register(this.supplierRegisterData).then(res=>{
              if(res.code===200){
                this.$message.success("注册成功，跳转到登录界面")
                setTimeout(()=>{
                  this.$router.replace("/login");
                },1000)
              }else{
                if(res.msg){
                  this.$message.error(res.msg);
                }
              }
            })
          }

          //TODO 根据userType调用相关的接口进行注册，成功后然后进行跳转
        }
      });
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.registerForm;
      if (value && value !== form.getFieldValue('password')) {
        callback('两次密码不一致！！');
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.registerForm;
      if (value && this.confirmDirty) {
        form.validateFields(['repeatPwd'], { force: true });
      }
      callback();
    },
    //上传文件相关
    uploadParams(){
      let d = {
        companyName:this.registerForm.getFieldValue('companyName'),
        userType: this.registerForm.getFieldValue('userType'),
      }
      return d;
    },
    handleCardFCancel() {
      this.previewCardFVisible = false;
    },
    handleCardSCancel() {
      this.previewCardSVisible = false;
    },
    handleCardTCancel() {
      this.previewCardTVisible = false;
    },
    handleLicenseCancel() {
      this.previewLicenseVisible = false;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    handleBeforeUpload(file){
      return new Promise((resolve,reject)=>{
        const companyName = this.registerForm.getFieldValue('companyName');
        if(companyName===''||companyName===undefined){
          this.$message.error("公司名称未填写");
          return reject(false);
        }
        const userType = this.registerForm.getFieldValue('userType');
        if(userType===''||userType===undefined){
          this.$message.error("公司类型未选择");
          return reject(false);
        }
        const isJPG = file.type === 'image/jpg';
        const isJPEG = file.type=== 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isGIF = file.type === 'image/gif';
        const isLt10M = file.size/1024/1024 <10;
        if(!(isJPG||isJPEG||isPNG||isGIF)){
          Modal.error({
            title:'只能上传JPG、JPEG、GIF、PNG格式的图片',
          });
          return reject(false);
        }else if(!isLt10M){
          Modal.error({
            title:'图片大小不能超过10M',
          });
          return reject(false);
        }
        return resolve(true);
      })

    },
    handleRemoveIdCarF(){
      if(!this.uploadIdCardFState){
        this.previewCardFImage = '';
        this.uploadIdCardFState = !this.uploadIdCardFState;
      }
      this.changeUploadIdCardFState();
    },
    handleRemoveIdCarS(){
      if(!this.uploadIdCardSState){
        this.previewCardSImage = '';
        this.uploadIdCardSState = !this.uploadIdCardSState;
      }
      this.changeUploadIdCardSState();
    },
    handleRemoveIdCarT(){
      if(!this.uploadIdCardTState){
        this.previewCardTImage = '';
        this.uploadIdCardTState = !this.uploadIdCardTState;
      }
      this.changeUploadIdCardTState();
    },
    handleRemoveLicense(){
      if(!this.uploadLicenseState){
        this.licenseImage = '';
        this.uploadLicenseState = !this.uploadLicenseState;
      }
      this.changeUploadLicenseState();
    },
    handleRemoveHeadPic(){
      if(!this.uploadHeadPicState){
        this.headPicImage = '';
        this.uploadHeadPicState = !this.uploadHeadPicState;
      }
      this.changeUploadHeadPicState();
    },
    async handlePreview(file) {
      if(file.response.data){
        if(this.uploadIndex===0){
          this.previewCardFImage = file.response.data;
          this.previewCardFVisible = true;
        }
        if(this.uploadIndex===1){
          this.previewCardSImage =  file.response.data;
          this.previewCardSVisible = true;
        }
        if(this.uploadIndex===2){
          this.previewCardTImage =  file.response.data;
          this.previewCardTVisible = true;
        }
        if(this.uploadIndex===3){
          this.licenseImage =  file.response.data;
          this.previewLicenseVisible = true;
        }
        if(this.uploadIndex===4){
          this.headPicImage =  file.response.data;
          this.previewVisible = true;
        }
      }else{
        this.$message.error("图片上传失败不能预览！")
      }

    },
    handleChange(info) {
      if (this.uploadIndex === 0) {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 上传失败`);
        }
        if (info.file.status === 'done') {
          if (info.file.response.success) {
            this.previewCardFImage = info.file.response.data;
            this.uploadIdCardFState = false;
            this.$message.success(`${info.file.name} 上传成功`);
          } else {
            this.$message.error(`${info.file.name} ${info.file.response.msg}`);
          }
        }
      }
      if (this.uploadIndex === 1) {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 上传失败`);
        }
        if (info.file.status === 'done') {
          if (info.file.response.success) {
            this.previewCardSImage = info.file.response.data;
            this.uploadIdCardSState = false;
            this.$message.success(`${info.file.name} 上传成功`);
          } else {
            this.$message.error(`${info.file.name} ${info.file.response.msg}`);
          }

        }
      }
      if (this.uploadIndex === 2) {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 上传失败`);
        }
        if (info.file.status === 'done') {
          if (info.file.response.success) {
            this.previewCardTImage = info.file.response.data;
            this.uploadIdCardTState = false;
            this.$message.success(`${info.file.name} 上传成功`);
          } else {
            this.$message.error(`${info.file.name} ${info.file.response.msg}`);
          }
        }
      }
      if (this.uploadIndex === 3) {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 上传失败`);
        }
        if (info.file.status === 'done') {
          if (info.file.response.success) {
            this.licenseImage = info.file.response.data;
            this.uploadLicenseState = false;
            this.$message.success(`${info.file.name} 上传成功`);
          } else {
            this.$message.error(`${info.file.name} ${info.file.response.msg}`);
          }
        }
      }
      if (this.uploadIndex === 4) {
        if (info.file.status !== 'uploading') {
        }
        if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 上传失败`);
        }
        if (info.file.status === 'done') {
          if (info.file.response.success) {
            this.headPicImage = info.file.response.data;
            this.uploadHeadPicState = false;
            this.$message.success(`${info.file.name} 上传成功`);
          } else {
            this.$message.error(`${info.file.name} ${info.file.response.msg}`);
          }
        }
      }
    },
    customLicenseRequest(data){

    },
    changeUploadIdCardFState(){
      this.uploadIndex=0;
    },
    changeUploadIdCardSState(){
      this.uploadIndex=1;
    },
    changeUploadIdCardTState(){
      this.uploadIndex=2;
    },
    changeUploadLicenseState(){
      this.uploadIndex=3;
    },
    changeUploadHeadPicState(){
      this.uploadIndex=4;
    },
    getCode() {
      const params = {
        phone: this.registerForm.getFieldValue('leader_phone'),
        userType: this.registerForm.getFieldValue('userType'),
      }
      if(params.userType===''||params.userType===undefined){
        this.$message.error("请选择公司类型！")
        return;
      }
      if (!isMobile(params.phone)) {
        this.$message.error('请输入正确的手机号码')
        return
      }

      this.$api.common.sendCode(params).then(res=>{
            if(res.code===200||res.success){
              this.$message.success(res.msg);
            }else{
              if(res.msg){
                this.$message.error(res.msg);
              }
            }
      })
      //等待重发验证码
      this.smsLoading();
    },
    smsLoading() {
      this.doLoading();
    },
    doLoading() {
      let siv = setInterval(() => {
        this.delayCount--;
        if (this.delayCount < 1) {
          this.loading = false;
          this.disabled = false;
          this.delayCount = 60;
          clearInterval(siv)
        } else {
          this.loading = true;
          this.disabled = true;
        }
      }, 1000)
    },
    showPrivacyInfo(){
      this.privacyVisible = true;
    },
    handlePrivacyCancel(){
      this.privacyVisible = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.bg {
  height: 100vh;
  background: linear-gradient(to bottom, #31324c, #FFFFFF);
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  font-family: 楷体;
}
.content{
  border-radius: 10px;
  background: #f7f7f7;
  padding: 40px 40px 40px 10px;
  position: absolute;
  top: 15%;
  width: 800px;
  height: 1600px;
  box-shadow: 1px 5px 3px #ddd;
  text-align: left;
  opacity: 0.75;
  .register-form{

  }
  .upload-form-item{
    zoom: 1;
    display: inline-block;
    width: 100%;
    .upload-items{
      display: flex;
      justify-content: center;
    }
    .ant-upload-select-picture-card i {
      font-size: 32px;
      color: #999;
    }
    .ant-upload-select-picture-card .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
  .register-form-header{
    display: flex;
    justify-content: center;
    padding:0px;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
  }

}

</style>