<template>
  <a-select
      show-search
      allowClear
      placeholder="请选择分类"
      option-filter-prop="children"
      style="width: 155px"
      size="large"
      :filter-option="false"
      :getPopupContainer="trigger => trigger.parentNode"
      @popupScroll="goodsScroll"
      @search="fetchCategory"
      @change="chooseOption"
      :value="currentValue.label?currentValue:undefined"
      label-in-value>
    <a-select-option v-for="(item,index) in categoryList" :key="index" :value="item.id">
      {{item.name}}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  name: "categorySelector",
  props:{
   currentValue:{
      type:Object,
    }
  },
  data(){
    return{
      categoryList: [],
      queryParam:{
        current:1,
        size:20,
        name:'',
      },
      selectOption:'',
      text:'',
    }
  },
  created() {
    this.getCategoryDropDownList(this.queryParam);
  },
  methods:{
    chooseOption(value){
      //console.log(value);
      this.$emit('categorySelectChange',value);
    },
    fetchCategory(value){
      const params = {
        current:1,
        size:this.queryParam.size,
        name:value,
      }
      this.getCategoryDropDownList(params);
    },
    goodsScroll(e){
      const { target } = e;
      if(target.scrollTop+target.offsetHeight===target.scrollHeight){
        const nextIndex = this.queryParam.current+1;
        this.setIndex(nextIndex);
        this.getCategoryDropDownList(this.queryParam)
      }
      //console.log(this.queryParam.current);
    },
    setIndex(index){
      this.queryParam.current = index;
    },
    getCategoryDropDownList(params){
      this.$api.category.categoryDropDownList(params).then(res=>{
        if(res.code===200&&res.success){
          //console.log(res.data)
          this.categoryList = res.data.records;
        }
      });
    },
  },
  watch:{
    currentValue(val){
      this.text = val.label;
    }
  }
}
</script>

<style scoped>

</style>