<template>
  <div>
    <a-row class="personInfo">
      <div>
        <a-descriptions layout="vertical">
          <template slot="title">
            <span style="font-weight: bolder;font-size: 18px">基本信息</span>
          </template>
          <a-descriptions-item>
            <template slot="label">
              <span style="font-weight: bolder;font-size: 16px">用户名</span>
            </template>
            <span style="font-size: 16px;color: #92989B;">{{ userInfo.name }}</span>
          </a-descriptions-item>
          <a-descriptions-item>
            <template slot="label">
              <span style="font-weight: bolder;font-size: 16px">性别</span>
            </template>
            <span style="font-size: 16px;color: #92989B;">{{ userInfo.sex === 0 ? '女' : '男' }}</span>
          </a-descriptions-item>
          <a-descriptions-item>
            <template slot="label">
              <span style="font-weight: bolder;font-size: 16px">头像</span>
            </template>
            <a-avatar v-if="userInfo.headPicture"
                      size="large" style="margin-right: 30px"
                      :src="userInfo.headPicture"/>
            <span v-else style="font-size: 16px;color: #92989B;padding-right: 30px">暂未设置</span>
            <a-upload :customRequest="upLoadHeadPic"
                      name="file"
                      :disabled="upLoading"
                      :before-upload="handleBeforeUpload"
                      :show-upload-list="false"
                      @change="handleHeadPicChange">
              <a-button :loading="upLoading" :disabled="upLoading">
                <a-icon type="upload"/>
                上传头像
              </a-button>
            </a-upload>
          </a-descriptions-item>
          <a-descriptions-item :span="spanNum">
            <template slot="label">
              <span style="font-weight: bolder;font-size: 16px">所属公司</span>
            </template>
            <span style="font-size: 16px;color: #92989B">名称：{{ userInfo.companyName }}</span>
            <br/>
            <span style="font-size: 16px;color: #92989B">地址：{{ userInfo.companyAddress }}</span>
          </a-descriptions-item>
          <a-descriptions-item v-if="userInfo.staffType===0&&userInfo.restaurantId">
            <template slot="label">
              <span style="font-weight: bolder;font-size: 16px">所属门店</span>
            </template>
            <span style="font-size: 16px;color: #92989B">名称：{{ userInfo.restaurantName }}</span>
            <br/>
            <span style="font-size: 16px;color: #92989B">地址：{{ userInfo.restaurantAddress }}</span>
          </a-descriptions-item>
          <a-descriptions-item>
            <template slot="label">
              <span style="font-weight: bolder;font-size: 16px">用户角色</span>
            </template>
            <span style="font-size: 16px;color: #92989B">{{ userInfo.roleName }}</span>
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <a-divider/>
      <div class="safe-info">
        <a-descriptions :column="2">
          <template slot="title">
            <span style="font-weight: bolder;font-size: 18px">安全信息</span>
          </template>
          <a-descriptions-item>
            <template slot="label">
              <span style="font-weight: bolder;font-size: 16px">手机号</span>
            </template>
            <span style="font-size: 16px;color: #92989B;padding: 5px 30px 5px 2px;">
              {{ userInfo.phone }}
            </span>
            <a-button @click="updatePhone">修改</a-button>
          </a-descriptions-item>
          <a-descriptions-item>
            <template slot="label">
              <span style="font-weight: bolder;font-size: 16px">密码</span>
            </template>
            <span style="font-size: 16px;padding: 5px 30px 5px 2px;color: #92989B">********</span>
            <a-button @click="updatePwd">修改</a-button>
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </a-row>
    <div class="update-phone-div">
      <a-modal :visible="updatePhoneVisible"
               okText="绑定手机号"
               cancelText="取消"
               :ok-button-props="{props:{size:'large'}}"
               :cancel-button-props="{props:{size:'large'}}"
               @ok="doUpdatePhone"
               @cancel="updatePhoneCancel" width="400px">
        <template slot="title">
          <span style="font-size: 20px;font-weight: bold">更换手机号</span>
        </template>
        <a-form class="update-phone"
                :form="updatePhoneForm"
                layout="vertical"
                label-width="100px" >
          <a-row>
            <a-col :span="12">
              <a-form-item>
                <template slot="label">
                  <span style="font-size: 20px;">验证密码</span>
                </template>
                <a-input-password v-decorator="['password',rules.password]"
                         placeHolder="请输入密码"
                         type="password"
                         key="0"
                         style="width: 320px;"
                         size="large"
                         allowClear>
                </a-input-password>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item>
                <template slot="label">
                  <span style="font-size: 20px;">当前手机号</span>
                </template>
                <a-input :disabled="true"
                         :value="this.userInfo.phone"
                         type="text"
                         key="1"
                         style="width: 320px;"
                         size="large"
                         allowClear>
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item>
                <template slot="label">
                  <span style="font-size: 20px;">想要绑定的手机号</span>
                </template>
                <a-input v-decorator="['newPhone',rules.newPhone]"
                         placeHolder="请输入手机号"
                         type="text"
                         key="2"
                         style="width: 320px;"
                         :maxLength="11"
                         size="large"
                         allowClear>
                </a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-item>
                <template slot="label">
                  <span style="font-size: 20px;">验证码</span>
                </template>
                <a-col :span="12">
                  <a-input v-decorator="['code',rules.code]"
                           placeHolder="请输入验证码"
                           type="text"
                           key="3"
                           style="width: 140px;"
                           size="large"
                           allowClear>
                  </a-input>
                </a-col>
                <a-col :span="6">
                  <a-button class="getCode"
                            :loding="this.codeLoading"
                            :disabled="this.codeDisabled"
                            icon="poweroff"
                            @click="getCode" type="primary"
                            size="large"
                            style="width:140px;background-color: #1890ff;color:#f7f7f7">
                    {{ codeLoading === true ? this.delayCount + "秒后重发" : "获取验证码" }}
                  </a-button>
                </a-col>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-modal>
    </div>
    <div class="update-pwd-div">
      <a-modal :visible="updatePwdVisible"
               okText="修改密码"
               cancelText="取消"
               :ok-button-props="{props:{size:'large'}}"
               :cancel-button-props="{props:{size:'large'}}"
               @ok="doUpdatePwd"
               @cancel="updatePwdCancel"
               width="400px">
      <template slot="title">
        <span style="font-size: 20px;font-weight: bold">修改密码</span>
      </template>
        <a-form class="update-phone"
                :form="updatePwdForm"
                layout="vertical"
                label-width="100px">
          <a-row>
            <a-col :span="12">
              <a-form-item>
                <template slot="label">
                  <span style="font-size: 20px;">旧密码</span>
                </template>
                <a-input-password v-decorator="['oldPwd',changePwdRules.oldPwd]"
                                  placeHolder="请输入当前登录密码"
                                  type="password"
                                  key="0"
                                  style="width: 320px;"
                                  size="large"
                                  allowClear>
                </a-input-password>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item>
                <template slot="label">
                  <span style="font-size: 20px;">新密码</span>
                </template>
                <a-input-password v-decorator="['newPwd',changePwdRules.newPwd]"
                                  placeHolder="请输入密码"
                                  type="password"
                                  key="1"
                                  style="width: 320px;"
                                  size="large"
                                  allowClear>
                </a-input-password>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item>
                <template slot="label">
                  <span style="font-size: 20px;">重复密码</span>
                </template>
                <a-input-password v-decorator="['repeatNewPwd',changePwdRules.repeatNewPwd]"
                                  placeHolder="请再次输入密码"
                                  type="password"
                                  key="2"
                                  style="width: 320px;"
                                  :maxLength="11"
                                  size="large"
                                  allowClear
                                  @blur="handleConfirmBlur">
                </a-input-password>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script>
import Modal from "ant-design-vue/lib/modal/Modal";
import {isMobile} from "@/utils/common";

export default {
  name: "personal-info",
  inject:['reload'],
  created() {
    const userId = this.userInfo.id;
    this.$api.staff.getDetail(userId).then(res=>{
      if(res.code===200&&res.success){
        this.userInfo = res.data;
      }
    })
  },
  data(){
    return{

      updatePwdVisible:false,
      updatePhoneVisible:false,
      upLoading:false,
      codeLoading:false,
      codeDisabled:false,
      delayCount:60,
      userInfo:this.$store.getters.userInfo,
      userType:this.$store.getters.staffType,
      confirmDirty:false,
      updatePhoneForm:this.$form.createForm(this, {name: 'update_phone_form'}),
      updatePwdForm:this.$form.createForm(this, {name: 'update_pwd_form'}),
      labelCol: {
        xs: {span: 24},
        sm: {span: 12} //控制label的框的长度
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 12}//控制文本框的长度
      },
      rules:{
        password: {
          rules: [
            {required: true, message: '请输入密码'},
          ]
        },
        newPhone: {
          rules: [
            {required: true, message: '请输入要绑定的手机号'},
          ]
        },
        code: {
          rules: [
            {required: true, message: '请输入验证码'},
          ]
        },
      },
      changePwdRules:{
        oldPwd: {
          rules: [
            {required: true, message: '请输入当前密码'},
          ]
        },
        newPwd: {
          rules: [
            {required: true, message: '请输入新密码'},
            {validator: this.validateToNextPassword},
          ]
        },
        repeatNewPwd: {
          rules: [
            {required: true, message: '请再次输入密码'},
            {validator: this.compareToFirstPassword},
          ]
        },
      },
    }
  },
  methods:{
    upLoadHeadPic(file){
      this.upLoading = true;
      //console.log("上传的文件：",file);
      const form = new FormData();
      form.append('file',file.file);
      this.$api.staff.upLoadHeadPic(form).then(res=>{
        if(res.code===200&&res.success){
          //console.log(res)
          file.onSuccess(res);
          file.status = 'done';
        }else{
          file.onError()
          file.status = 'error';
        }
      })
    },
    handleBeforeUpload(file){
      return new Promise((resolve,reject)=>{
        const isJPG = file.type === 'image/jpg';
        const isJPEG = file.type=== 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isGIF = file.type === 'image/gif';
        const isLt10M = file.size/1024/1024 <10;
        if(!(isJPG||isJPEG||isPNG||isGIF)){
          Modal.error({
            title:'只能上传JPG、JPEG、GIF、PNG格式的图片',
          });
          return reject(false);
        }else if(!isLt10M){
          Modal.error({
            title:'图片大小不能超过10M',
          });
          return reject(false);
        }
        return resolve(true);
      })
    },
    handleHeadPicChange(info){
      this.upLoading = true;
      //console.log("change方法调用：",info);
      const status = info.file.status;
      if (status!== 'uploading') {
        this.upLoading = false;
        setTimeout(()=>{

        },10000);
        //console.log(info)
      }
      if (status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`);
      }
      if (status === 'done') {
        if (info.file.response.data) {
          this.userInfo.headPicture = info.file.response.data;
          this.$message.success(`${info.file.name} 上传成功`);
        } else {
          this.$message.error(`${info.file.name} ${info.file.response.msg}`);
        }
      }
      if(status ==='removed'){
        this.userInfo.headPicture = null;
      }
    },
    updatePhone(){
      this.updatePhoneVisible = true;
    },
    doUpdatePhone(){
       this.updatePhoneForm.validateFields((err,values)=>{
         if(!err){
           const params = {
             oldPhone:this.userInfo.phone,
             newPhone:values.newPhone,
             code:values.code,
             password:values.password,
             userType:this.userType
           }
           this.$api.staff.updatePhone(params).then(res=>{
             if(res.code===200&&res.success){
               this.$message.success("操作成功!!");
               this.reload();
             }else{
               if(res.msg){
                 this.$message.error(res.msg);
               }
             }
           })
         }
       })
    },
    updatePhoneCancel(){
      this.updatePhoneVisible = false;
      this.updatePhoneForm.resetFields();
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    updatePwd(){
      this.updatePwdVisible = true;
      //console.log("updatePwd")
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.updatePwdForm;
      if (value && this.confirmDirty) {
        form.validateFields(['repeatNewPwd'], { force: true });
      }
      callback();
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.updatePwdForm;
      if (value && value !== form.getFieldValue('newPwd')) {
        callback('两次密码不一致！！');
      } else {
        callback();
      }
    },
    updatePwdCancel(){
      this.updatePwdVisible = false;
      this.updatePwdForm.resetFields();
    },
    doUpdatePwd(){

    },
    getCode() {
      const params = {
        phone: this.updatePhoneForm.getFieldValue('newPhone'),
        userType:this.userType,
      }
      //console.log(params);
      if (!isMobile(params.phone)) {
        this.$message.error('请输入正确的手机号码')
        return
      }
      this.$api.common.sendCode(params).then(res=>{
        if(res.code===200||res.success){
          this.$message.success(res.msg);
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
      //等待重发验证码
      this.smsLoading();
    },
    smsLoading() {
      this.doLoading();
    },
    doLoading() {
      let siv = setInterval(() => {
        this.delayCount--;
        if (this.delayCount < 1) {
          this.codeLoading = false;
          this.codeDisabled = false;
          this.delayCount = 5;
          clearInterval(siv)
        } else {
          this.codeLoading = true;
          this.codeDisabled = true;
        }
      }, 1000)
      //console.log(this.codeLoading);
    },
  },
  computed:{
    spanNum(){
      if(this.userInfo.restaurantId){
        return 1;
      }else{
        return 2;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.personInfo{

}

</style>