<template>
  <div class="bidPrice">
    <div class="item dataList">
      <a-row>
        <a-col :span="24">
          <a-row class="select-form">
            <a-col :span="24">
              <a-form layout="inline" :form="searchBidPriceForm">
                <a-form-item>
                  <a-input
                      size="large"
                      placeholder="请输入商品批次号"
                      v-model.trim="queryParam.purchaseOrderTaskCode"
                      clearable
                  >
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-input
                      ref="bidNum"
                      v-model="bidNum"
                      type="number"
                      placeholder="请输入报价次数"
                      style="width: 150px;"
                      size="large"
                      @change="bidNumChange"/>
                </a-form-item>
                <a-form-item v-if="userType===0">
                  <supplier-selector ref="supplierSelector"
                                     :current-value="bindSupplierChooseOption"
                                     @supplierSelectChange="supplierSelectChange"/>
                </a-form-item>
                <a-form-item v-if="userRoles.indexOf('2')!==-1">
                  <restaurant-selector ref="restaurantSelector"
                                       :current-value="supplierBindResChooseOption"
                                       @restaurantSelectChange="restaurantSelectChange"/>
                </a-form-item>
                <a-form-item v-if="userType===1">
                  <company-selector ref="companySelector"
                                    :current-value="supplierBindCompanyChooseOption"
                                    @companySelectChange="companySelectChange"/>
                </a-form-item>
                <a-form-item>
                  <goods-selector ref="goodsSelector"
                                  :current-value="goodsChooseOption"
                                  :company-id="this.queryParam.companyId"
                                  @goodsSelectChange="goodsSelectChange"></goods-selector>
                </a-form-item>
                <a-form-item>
                  <a-select v-model="bidState"
                            :getPopupContainer="trigger => trigger.parentNode"
                            allowClear
                            style="width: 110px"
                            size="large"
                            placeholder="报价结果"
                            @change="bidStateChange">
                    <a-select-option :key="0"
                                     value="0">
                      报价中
                    </a-select-option>
                    <a-select-option :key="1"
                                     value="1">
                      成功
                    </a-select-option>
                    <a-select-option :key="2"
                                     value="2">
                      失败
                    </a-select-option>
                    <a-select-option :key="3"
                                     value="3">
                      已结束
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item>
                  <a-select v-model="endStatus"
                            :getPopupContainer="trigger => trigger.parentNode"
                            allowClear
                            style="width: 110px"
                            size="large"
                            placeholder="报价状态"
                            @change="endStateChange">
                    <a-select-option :key="0"
                                     value="0">
                      报价中
                    </a-select-option>
                    <a-select-option :key="1"
                                     value="1">
                      已结束
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <a-row>
                  <a-form-item style=" margin: 10px 0 0 0;">
                    <a-col :span="8">
                      <a-button
                          size="large"
                          type="primary"
                          @click="searchOrderTask">查询
                      </a-button>
                    </a-col>
                    <a-col :span="8" offset="8">
                      <a-button size="large" @click="resetBidPriceForm">重置</a-button>
                    </a-col>
                  </a-form-item>
                </a-row>
              </a-form>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-table rowKey="id"
               :pagination="false"
               :columns="columns"
               :data-source="dataSource"
               :customRow="customRow"
               :row-class-name="setRowClassName"
               style="width: 100%;"
               bordered
      >
        <template slot="empty">
          <a-empty description="暂无数据"></a-empty>
        </template>
        <template slot="purchaseOrderTaskCode" slot-scope="text">
          <router-link to="/purchase/task/list" tag="a">{{text}}</router-link>
        </template>
        <template slot="bidDetail" slot-scope="text,record">
          <a-tag   style="color: #2db7f5;font-size: 12px" @click="changeBidDetailVisible(record)">
            报价详细信息，点击查看>>>
          </a-tag >
        </template>
        <template slot="num" slot-scope="text">
          <span style="color:#2db7f5">
           {{text}}
          </span>
        </template>
        <template slot="totalPrice" slot-scope="text">
          <span style="color: red">
            ￥{{getFormatMoney(text,true)}}
          </span>
        </template>
        <template slot="createTime" slot-scope="text">
          {{getFormatDate(text)}}
        </template>
        <template slot="status" slot-scope="text,record">
          <span v-if="text==0" style="color: #108ee9">报价中</span>
          <span v-if="text==1" style="color: limegreen">成功</span>
          <span v-if="text==2" style="color: red" @click="bidPriceFailed(record)">失败</span>
          <span v-if="text==3" style="color: silver">已结束</span>
        </template>
        <template slot="endStatus" slot-scope="text">
          <span v-if="text==0" style="color: #108ee9">报价中</span>
          <span v-if="text==1" style="color: silver">已结束</span>
        </template>
        <template slot="action" slot-scope="text,record">
          <div v-if="userType===0&&userRoles.indexOf('4')!==-1">
            <a-popconfirm :disabled="successBtnStatus||record.status!==0"
                          title="确定竞价成功?"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="() => bidPriceSuccess(record)">
              <a-button :disabled="successBtnStatus||record.status!==0"
                        type="primary" size="small" icon="check-circle"/>
            </a-popconfirm>
            <a-divider type="vertical"/>
            <a-popconfirm :disabled="failedBtnStatus
                                     ||record.repeatStatus===1
                                     ||record.status!==0"
                          title="确定竞价失败?"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="() => bidPriceFailed(record)">
              <a-icon slot="icon" type="question-circle-o" style="color: red"/>
              <a-button  :disabled="failedBtnStatus
                                    ||record.repeatStatus===1
                                    ||record.status!==0"
                         type="danger" size="small" icon="close-circle"/>
            </a-popconfirm>
          </div>
          <div v-else-if="userType===1">
            <a-popconfirm :disabled="record.status!==2
                                     ||record.repeatStatus===1"
                          title="确定重新报价?"
                          ok-text="确定"
                          cancel-text="取消"
                          @confirm="() => bidPriceRepeat(record)">
              <a-icon slot="icon" type="question-circle-o" style="color: red"/>
              <a-button type="primary" size="small" icon="file-add"
                        :disabled="record.status!==2
                                   ||record.repeatStatus===1"></a-button>
            </a-popconfirm>
          </div>
        </template>
      </a-table>
      <pageination  style="float: right"
                    :paginationList="paginationList"
                    @paging="paging">
      </pageination>
    </div>
    <div>
      <bid-price-detail :visible="bidPriceDetailVisible"
                        :bid-price-detail="bidPriceDetail"
                        @changeBidOrderTaskDetail="changeBidPriceDetail"
                        @changeBidPriceDetailVisible="changeBidPriceDetailVisible">

      </bid-price-detail>
    </div>
    <div class="bidFailedDetail">
      <a-modal :visible="bidFailedVisible"
               okText="确定"
               cancelText="取消"
               @ok="handleBidFailedOkBtn"
               @cancel="handleBidFailedCancelBtn"
               width="700px">
        <a-row type="flex" justify="center">
          <span style="color:red;font-size: 25px;font-weight: bolder;text-align: center">失败详情</span>
        </a-row>
        <a-row>
          <a-tag v-if="userRoles.indexOf('4')!==-1"
                 :hidden="this.bidFailedEditStatus||this.bidFailedParam.repeatStatus===1"
                 color="#2db7f5" style="float: right;" @click="bidFailedEditStatusChange">编辑</a-tag>
        </a-row>
        <br>
        <a-row>
          <a-col :span="12">
            <label>采购单批次号：</label>
            <span style="color: #92989B;font-weight: bold;">
              {{ this.bidFailedParam.bidTaskCode?this.bidFailedParam.bidTaskCode:'暂无数据' }}
            </span>
          </a-col>
          <a-col :span="12">
            <label>竞价时间：</label>
            <span style="color: #92989B;font-weight: bold;">
            {{ this.bidFailedParam.bidTime?getFormatDate(this.bidFailedParam.bidTime):'暂无数据' }}
          </span>
          </a-col>
        </a-row>
        <br>
        <a-row>
          <a-col :span="12">
            <label>审核人：</label>
            <span style="color: #92989B;font-weight: bold;">
              {{ this.bidFailedParam.checkStaffName?this.bidFailedParam.checkStaffName:'暂无数据' }}
            </span>
          </a-col>
          <a-col :span="12">

            <label>审核时间：</label>
            <span style="color: #92989B;font-weight: bold;">
              {{ this.bidFailedParam.checkTime?getFormatDate(this.bidFailedParam.checkTime):'暂无数据' }}
            </span>
          </a-col>
        </a-row>
        <br>
        <a-row>
          <a-col :span="24">
            <label>失败原因：</label>
            <a-textarea :disabled="!bidFailedEditStatus"
                        :value="this.bidFailedParam.checkRemark"
                        @change="handleBidFailedReasonChange"
                        rows="4"/>
          </a-col>
        </a-row>
      </a-modal>
    </div>
    <div>
      <add-bid-price :visible="bidPriceRepeatVisible"
                     :purchase-order-detail="bidPriceRepeatOrderTaskDetail"
                     :is-repeat="repeatStatus"
                     @changeBidOrderTaskDetail="changeBidPriceRepeatDetail"
                     @changeBidPriceVisible="changeBidPriceRepeatVisible"
                     @changeRepeatStatus="changeRepeatStatus"/>
    </div>
  </div>
</template>

<script>
import SupplierSelector from "@/components/supplierSelector";
import RestaurantSelector from "@/components/restaurantSelector";
import CompanySelector from "@/components/companySelector";
import GoodsSelector from "@/components/goodsSelector";
import Pageination from "@/components/pageination";
import {dateFormat, formatMoney} from "@/utils/common";
import BidPriceDetail from "@/views/bidPrice/bidPriceDetail";
import AddBidPrice from "@/views/bidPrice/addBidPrice";

export default {
  name: "index",
  components: {
    AddBidPrice,
    BidPriceDetail, Pageination, GoodsSelector, CompanySelector, RestaurantSelector, SupplierSelector},
  inject:['reload'],
  created() {
    const item1= {
      title: '采购门店所属公司',
      dataIndex: 'companyName',
      key:'companyName',
      width: '140px',
      align: 'center',
    }
    const item2 =  {
      title: '采购门店',
      dataIndex: 'restaurantName',
      key:'restaurantName',
      width: '140px',
      align: 'center',
    }
    const item3 = {
          title: '发布人',
          dataIndex: 'publisherName',
          key:'publisherName',
          width: '60px',
          align: 'center',
    }
    const item4 = {
      title: '供应商',
      dataIndex: 'supplierName',
      key:'supplierName',
      width: '70px',
      align: 'center',
    }
    if(this.userType===1){
      this.columns.splice(2,0,item1,item2,item3);
    }
    if(0===this.userType){
      if(this.userRoles.indexOf("2")!==-1){
        this.columns.splice(2,0,item2,item4);
      }
      if(this.userRoles.indexOf("3")!==-1){
        this.columns.splice(2,0,item2,item4);
      }
      if(this.userRoles.indexOf("4")!==-1){
        this.columns.splice(2,0,item4);
        this.successBtnStatus = false;
        this.failedBtnStatus = false;
      }
    }


    let queryParam = {
      current:this.paginationList.current,
      size:this.paginationList.size,
      ...this.queryParam,
    }
    this.getBidPriceList(queryParam);
  },
  data(){
    return{
      selectedRowId:undefined,
      failedBtnStatus:true,
      successBtnStatus:true,
      bidPriceDetailVisible:false,
      bidPriceDetail:undefined,
      bidFailedVisible:false,
      bidFailedEditStatus:false,

      bidPriceRepeatVisible:false,
      bidPriceRepeatDetail:undefined,
      repeatStatus:true,
      bidPriceRepeatOrderTaskDetail:undefined,

      userType:this.$store.getters.userInfo.staffType,
      userRoles:this.$store.getters.userInfo.roleIds,
      searchBidPriceForm: this.$form.createForm(this, {name: 'bid_price_form'}),

      queryParam:{
        purchaseOrderTaskCode:'',
        productId:undefined,
        restaurantId:undefined,
        companyId:undefined,
        supplierId:undefined,
        status:undefined,
        endStatus:undefined,
        bidNum:undefined,
      },
      bidFailedParam:{
        bidTaskCode:"暂无数据",
        bidTime:undefined,
        id:undefined,
        checkStaffId:undefined,
        checkStaffName:undefined,
        checkTime:undefined,
        checkRemark:undefined,
        checkRemarkPic:undefined,
        repeatStatus:0,
      },
      goodsChooseOption:{key:'',label:''},
      supplierBindResChooseOption:{key:'',label:''},
      supplierBindCompanyChooseOption:{key:'',label:''},
      //公司和餐厅绑定供应商选择项
      bindSupplierChooseOption:{key:'',label:''},
      paginationList:{
        size:10,
        current:1,
        total:0,
        pageSizeOptions: ['10', '20', '30', '40', '50','100'],
      },
      bidState:undefined,
      endStatus:undefined,
      bidNum:undefined,
      dataSource:[],
      columns:[
        {
          title: '批次号',
          dataIndex: 'purchaseOrderTaskCode',
          key:'purchaseOrderTaskCode',
          width: '140px',
          align: 'center',
          scopedSlots: {customRender: 'purchaseOrderTaskCode'},
        },
        {
          title: '商品名称',
          dataIndex: 'productName',
          key:'productName',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'productName' },
        },
        {
          title: '报价明细',
          dataIndex: 'bidDetail',
          key:'bidDetail',
          width: '140px',
          align: 'center',
          scopedSlots: { customRender: 'bidDetail' },
        },
        {
          title: '供货数量',
          dataIndex: 'num',
          key:'num',
          width: '50px',
          align: 'center',
          scopedSlots: { customRender: 'num' },
        },
        {
          title: '总价',
          dataIndex: 'totalPrice',
          key:'totalPrice',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'totalPrice' },
        },
        {
          title: '报价人',
          dataIndex: 'bidStaffName',
          key:'bidStaffName',
          width: '60px',
          align: 'center',
        },

        {
          title: '报价次数',
          dataIndex: 'bidNum',
          key:'bidNum',
          width: '50px',
          align: 'center',
          scopedSlots: { customRender: 'bidNum' },
        },
        {
          title: '报价结果',
          dataIndex: 'status',
          key:'status',
          width: '70px',
          align: 'center',
          scopedSlots: { customRender: 'status' },
        },{
          title: '是否结束',
          dataIndex: 'endStatus',
          key:'endStatus',
          width: '70px',
          align: 'center',
          scopedSlots: { customRender: 'endStatus' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          key:'action',
          width: '100px',
          align: 'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  methods:{
    customRow(record){
      return {
       on:{
         click:(event)=>{
           this.selectedRowId = record.id;
         },
       },
      };
    },
    setRowClassName(record){
      if(record.id===this.selectedRowId){
        return 'clickRowStyle'
      }
    },
    goodsSelectChange(value){
      //获取下拉选择框中的数据
      this.goodsChooseOption = value;
      this.queryParam.productId = value.key;
    },
    bidStateChange(value) {
      this.queryParam.status = value;
    },
    endStateChange(value) {
      this.queryParam.endStatus = value;
    },
    bidNumChange(event){
      const value = event.target.value;
      this.queryParam.bidNum = value;
    },
    restaurantSelectChange(value){
      this.supplierBindResChooseOption = value;
      this.queryParam.restaurantId = value.key;
    },
    companySelectChange(value){
      this.supplierBindCompanyChooseOption = value;
      this.queryParam.companyId = value.key;
    },
    supplierSelectChange(value){
      this.bindSupplierChooseOption = value;
      this.queryParam.supplierId = value.key;
    },
    changeBidPriceDetailVisible(value){
      this.bidPriceDetailVisible = value;
    },
    changeBidPriceDetail(value){
      this.bidPriceDetail = value;
    },
    bidPriceSuccess(record){
      const params = {
        bidPriceOrderId:record.id,
        taskId:record.purchaseOrderTaskId,
        productId:record.productId,
        productNum:record.purchaseNum,
      }
      this.$api.bidPriceOrder.bidSuccess(params).then(res=>{
        if(res.code===200&&res.success){
          this.$message.success("操作成功！");
          this.successBtnStatus = true;
          this.reload();
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
      //TODO 如果成功，按钮状态设置为不可点击
    },
    bidPriceRepeat(record){
      this.bidPriceRepeatVisible = true;
      let params = {
        id:record.purchaseOrderTaskDetailId,
      }
      this.$api.purchaseOrderTaskDetail.getDetail(params).then(res=>{
        if(res.code===200&&res.success){
          const resData = res.data;
          const stockNum = resData.stockNum;
          this.bidPriceRepeatOrderTaskDetail ={...record,stockNum,};
        }
      })
    },
    changeBidPriceRepeatDetail(value){
      this.bidPriceRepeatOrderTaskDetail = value;
    },
    changeBidPriceRepeatVisible(value){
      this.bidPriceRepeatVisible = value;
    },
    changeRepeatStatus(value){
      this.repeatStatus = value;
    },


    bidPriceFailed(record){
      this.bidFailedVisible = true;
      this.bidFailedParam.bidTaskCode = record.purchaseOrderTaskCode;
      this.bidFailedParam.bidTime = record.createTime;
      this.bidFailedParam.id = record.id;
      this.bidFailedParam.checkStaffId = record.checkStaffId;
      this.bidFailedParam.checkStaffName = record.checkStaffName;
      this.bidFailedParam.checkTime = record.checkTime;
      this.bidFailedParam.checkRemark = record.checkRemark;
      this.bidFailedParam.checkRemarkPic = record.checkRemarkPic;
      this.bidFailedParam.repeatStatus = record.repeatStatus;
    },
    bidFailedEditStatusChange(){
      this.bidFailedEditStatus = true;
      if((!this.bidFailedParam.checkStaffId)||(!this.bidFailedParam.checkStaffName)){
        this.bidFailedParam.checkStaffId = this.$store.getters.userInfo.id;
        this.bidFailedParam.checkStaffName = this.$store.getters.userInfo.name;
      }
      this.bidFailedParam.checkTime = new Date();
    },
    handleBidFailedReasonChange(event){
      this.bidFailedParam.checkRemark = event.target.value;
    },
    handleBidFailedOkBtn(){
      if(!this.bidFailedParam.checkRemark){
        this.$message.error("失败原因不能为空！");
        return;
      }
      if(this.bidFailedEditStatus){
        //调用修改或保存接口
        this.$api.bidPriceOrder.bidFailed(this.bidFailedParam).then(res=>{
          if(res.code===200&&res.success){
            this.$message.success("操作成功！");
            this.reload();
          }else{
            if(res.msg){
              this.$message.error(res.msg);
            }
          }
        })
        this.bidFailedEditStatus = false;
      }else{
        this.handleBidFailedCancelBtn();
      }
    },
    handleBidFailedCancelBtn(){
      this.bidFailedVisible = false;
      this.bidFailedEditStatus = false;
      this.bidFailedParam={
        id: undefined,
        checkStaffId: undefined,
        checkStaffName: undefined,
        checkRemark: undefined,
        checkRemarkPic:undefined,
      };
    },

    changeBidDetailVisible(record){
      this.bidPriceDetail = record;
      this.bidPriceDetailVisible = true;
    },
    searchOrderTask(){
      let queryParam = {
        current:this.paginationList.current,
        size:this.paginationList.size,
        ...this.queryParam,
      }
      this.getBidPriceList(queryParam);
    },
    //拼装分页参数
    paging(parameter) {
      let queryParam = {
        current:parameter.current,
        size:parameter.size,
        ...this.queryParam,
      };
      this.getBidPriceList(queryParam);
    },
    resetBidPriceForm(){
      this.$refs.goodsSelector.value = undefined;
      this.bidState = undefined;
      this.endStatus = undefined;
      this.bidNum = undefined;
      this.supplierBindResChooseOption = {key:'',label:''};
      this.goodsChooseOption = {key:'',label:''};
      this.supplierBindCompanyChooseOption={key:'',label:''};
      //公司和餐厅绑定供应商选择项
      this.bindSupplierChooseOption={key:'',label:''};
      this.queryParam={
        purchaseOrderTaskCode: '',
        productId: undefined,
        restaurantId: undefined,
        companyId: undefined,
        supplierId: undefined,
        status: undefined,
        bidNum: undefined,
      }
      let params = {
        current:this.paginationList.current,
        size:this.paginationList.size,
        ...this.queryParam,
      }
      this.getBidPriceList(params);
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
    getFormatMoney(money,isCarryBit){
      const result = formatMoney(money,2,".",",",isCarryBit);
      return result;
    },
    getBidPriceList(params){
      this.$api.bidPriceOrder.pageList(params).then(res=>{
        if(res.code===200){
          this.dataSource = res.data.records;
          this.paginationList.total = res.data.total;
        }else{
          if(res.msg){
            this.$message.error(res.msg);
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.bidPrice{
  .item{
  }
  .dataList{
    .select-form{
      margin: 0 0 10px 0;
    }
    ::v-deep .ant-table-thead > tr > th{
      padding: 8px;
    }
    ::v-deep .ant-table-tbody > tr > td{
      padding: 8px;
    }
    ::v-deep .clickRowStyle{
      background-color:#e6f7ff
    }
  }
}
</style>