<template>
  <div class="senderDetail">
    <a-modal :visible="visible"
             okText="确定"
             cancelText="取消"
             @ok="handleSenderDetailOkBtn"
             @cancel="handleCancel"
             width="800px">
      <a-row type="flex" justify="center">
        <span style="font-size: 25px;font-weight: bolder;text-align: center">配送员详情
          <a-divider type="vertical"/>
          <span v-if="this.senderInfo.status===0"
                style="color: silver;font-size: 20px;font-weight: bolder;">未配送</span>
          <span v-if="this.senderInfo.status===1"
                style="color: #F29F2A;font-size: 20px;font-weight: bolder;">配送中</span>
        </span>
      </a-row>
      <a-row>
        <a-tag v-if="userRoles.indexOf('6')!==-1"
               :hidden="senderDetailEditStatus||senderInfo.status===3||senderInfo.status===4"
               color="#2db7f5" style="float: right;" @click="senderDetailEditStatusChange">更换配送员</a-tag>
        <a-tag v-if="userRoles.indexOf('6')!==-1&&senderDetailEditStatus"
               :hidden="!senderDetailEditStatus"
               color="#2db7f5" style="float: right;"
               @click="senderDetailEditStatusReBack">
          返回
        </a-tag>
      </a-row>
      <br>
      <a-row>
        <a-col :span="12" style="font-weight: bold;">
          <label>订单号：</label>
          <span style="color: #92989B;font-weight: bold;">
           <span>{{code}}</span>
            </span>
        </a-col>
        <a-col :span="12" style="font-weight: bold;">
          <label>所属采购批次号：</label>
          <span style="color: #92989B;font-weight: bold;">
            <span>{{taskCode}}</span>
            </span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <a-col :span="12" style="font-weight: bold;">
          <label>供应商：</label>
          <span style="color: #92989B;font-weight: bold;">
              {{supplierName}}
            </span>
        </a-col>
        <a-col :span="12" style="font-weight: bold;">
          <label>创建时间：</label>
          <span v-if="!senderDetailEditStatus" style="color: #92989B;font-weight: bold;">
              {{getFormatDate(senderInfo.createTime) }}
          </span>
          <span v-if="senderDetailEditStatus" style="color: #92989B;font-weight: bold;">
              {{getFormatDate(new Date())}}
          </span>
        </a-col>
      </a-row>
      <br>
      <a-row>
        <div v-if="senderDetailEditStatus">
          <a-form layout="inline" :form="senderEditForm">
            <a-row>
              <a-col :span="12" style="font-weight: bold;">
                <a-form-item label="配送员类型" :labelCol="{span:11}"
                             :wrapperCol=wrapperCol>
                  <a-select
                      v-decorator="['type',rules.type]"
                      :getPopupContainer="trigger => trigger.parentNode"
                      placeholder="请选择"
                      type="text"
                      style="width: 110px"
                      @change="showStaffSelector"
                      allowClear>
                    <a-select-option :key="0"
                                     :value="0">
                      员工
                    </a-select-option>
                    <a-select-option :key="1"
                                     :value="1">
                      第三方配送人员
                    </a-select-option>
                  </a-select>

                </a-form-item>
              </a-col>
              <a-col :span="12" style="font-weight: bold;">
                <a-form-item v-if="senderEditForm.getFieldValue('type')===0"
                             label="选择员工" :labelCol="labelCol"
                             :wrapperCol="wrapperCol">
                  <staff-selector ref="staffSelector"
                                    :current-value="supplierStaffChooseOption"
                                    @staffSelectChange="staffSelectChange"
                                  @staffCurrentSelect="staffCurrentSelect"/>
                </a-form-item>
              </a-col>
              <a-col :span="12" style="font-weight: bold;">
                <a-form-item v-if="senderEditForm.getFieldValue('type')===1"
                             label="姓名" :labelCol="labelCol"
                             :wrapperCol="wrapperCol">
                  <a-input
                      ref="name"
                      v-decorator="['name',rules.name]"
                      type="text"
                      style="width: 160px"/>
                </a-form-item>
              </a-col>

            </a-row>
            <a-row>
              <a-col :span="12" style="font-weight: bold;">
                <a-form-item label="性别" :labelCol="labelCol"
                             :wrapperCol="wrapperCol">
                  <a-select
                      v-decorator="['sex',rules.sex]"
                      :getPopupContainer="trigger => trigger.parentNode"
                      placeholder="请选择"
                      type="text"
                      :disabled="editDisabledStatus"
                      style="width: 100px"
                      allowClear>
                    <a-select-option :key="0"
                                     :value="0">
                      女
                    </a-select-option>
                    <a-select-option :key="1"
                                     :value="1">
                      男
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12" style="font-weight: bold;">
                <a-form-item label="手机号" :labelCol="labelCol"
                             :wrapperCol="wrapperCol">
                  <a-input
                      ref="phone"
                      v-decorator="['phone',rules.phone]"
                      type="text"
                      :disabled="editDisabledStatus"
                      :maxLength="11"
                      style="width: 155px"/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24" style="font-weight: bold;">
                <a-form-item label="备注">
                  <a-textarea v-decorator="['remark',rules.remark]"
                              placeholder="备注信息"
                              rows="4"
                              style="width:700px"
                              allowClear/>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
        <div v-else disabled="true">
          <br>
          <a-row>
            <a-col :span="12" style="font-weight: bold;">
              <label>配送员类型：</label>
              <span style="color: #92989B;font-weight: bold;">
                <span v-if="this.senderInfo.type===0">员工</span>
                <span v-else-if="this.senderInfo.type===1">第三方配送人员</span>
                <span v-else>'暂无数据'</span>
              </span>
            </a-col>
            <a-col :span="12" style="font-weight: bold;">
              <label>姓名：</label>
              <span style="color: #92989B;font-weight: bold;">
              {{ this.senderInfo.name
                  ?this.senderInfo.name:'暂无数据' }}
            </span>
            </a-col>
          </a-row>
          <br>
          <a-row>
            <a-col :span="12" style="font-weight: bold;">
              <label>手机号：</label>
              <span style="color: #92989B;font-weight: bold;">
             {{ this.senderInfo.phone
                  ?this.senderInfo.phone:'暂无数据' }}
            </span>
            </a-col>
            <a-col :span="12" style="font-weight: bold;">
              <label>性别：</label>
              <span style="color: #92989B;font-weight: bold;">
              <span v-if="this.senderInfo.sex===0">女</span>
                <span v-else-if="this.senderInfo.sex===1">男</span>
                <span v-else>'暂无数据'</span>
              </span>
            </a-col>
          </a-row>
          <br>
          <a-row>
            <a-col :span="24" style="font-weight: bold;">
              <label>备注：</label>
              <span style="color: #92989B;font-weight: bold;">
               {{ this.senderInfo.remark
                  ?this.senderInfo.remark:'暂无数据' }}
            </span>
            </a-col>
          </a-row>
        </div>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import StaffSelector from "@/components/staffSelector";
import {dateFormat, isMobile} from "@/utils/common";
export default {
  name: "senderDetail",
  components: {StaffSelector},
  props:{
    visible:{
     type:Boolean,
      default:false,
    },
    senderDetail:{
      type:Object,
    }
  },
  data(){
    return{
      labelCol: {
        xs: {span: 24},
        sm: {span: 8} //控制label的框的长度
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 10}//控制文本框的长度
      },
      customVisible:this.visible,
      senderDetailEditStatus:false,
      supplierStaffChooseOption:{key:'',label:''},
      userType:this.$store.getters.userInfo.staffType,
      userRoles:this.$store.getters.userInfo.roleIds,
      editDisabledStatus:false,
      updateSenderInfo:{},
      senderEditForm: this.$form.createForm(this, {name: 'sender_edit_form'}),
      code:'暂无数据',
      taskCode:'暂无数据',
      supplierName:'暂无数据',
      senderStaffId:undefined,
      orderDetailInfo:undefined,
      senderInfo: {
        id:undefined,
        orderId:undefined,
        deliveryType:undefined,
        staffId:undefined,
        supplierId:undefined,
        name:undefined,
        phone:undefined,
        sex:undefined,
        status:undefined,
        remark:undefined,
        createTime:undefined,
      },
      rules: {
        type: {
          initialValue:this.initDeliveryType,
          rules: [
            {required: true, message: '请选择'},
          ]
        },
        name:{
          initialValue:this.initName,
          rules: [
            {required: true, message: '请输入第三方配送员名称!'},
          ]
        },
        sex:{
          initialValue:this.initSex,
          rules: [
            {required: true, message: '请选择'},
          ]
        },
        phone:{
          initialValue:this.initPhone,
          rules: [
            {required: true, message: '请输入配送员手机号!'},
          ]
        },
        remark:{
          initialValue:this.initRemark,
          rules:[
            {required: false, message: '请填写备注信息'},
          ],
        }
      },
    }
  },
  methods:{
    handleSenderDetailOkBtn(){
      if(this.senderDetailEditStatus){
        this.senderEditForm.validateFields((err,values)=>{
          if(!err){
            const type = values.type;
            if(type===1){
              this.updateSenderInfo.name = values.name;
            }
            this.updateSenderInfo.deliveryType = type;
            this.updateSenderInfo.sex = values.sex;
            this.updateSenderInfo.remark = values.remark;
            this.updateSenderInfo.phone = values.phone;

            if (!isMobile(this.updateSenderInfo.phone)) {
              this.$message.error('请输入正确的手机号码')
              return
            }
            this.$api.sender.addSender(this.updateSenderInfo).then(res => {
              if (res.code === 200 && res.success) {
                this.$message.success("操作成功！");
                this.senderDetailEditStatus = false;
                this.reload();
              } else {
                if (res.msg) {
                  this.$message.error(res.msg);
                }
              }
            })

          }
        })
      }else{
        this.handleCancel();
      }
    },
    handleCancel(){
      this.customVisible = false;
      this.senderDetailEditStatus = false;
      this.editDisabledStatus = false;
      this.$emit("changeSenderDetail",undefined);
      this.$emit("changeSenderDetailVisible",this.customVisible);
    },
    showStaffSelector(value){
      if(0===value){
        this.editDisabledStatus = true;
      }else {
        this.editDisabledStatus = false;
      }
    },
    staffCurrentSelect(value){
      this.senderEditForm.setFieldsValue({
        sex: value.sex,
        phone: value.phone,
      })
    },
    senderDetailEditStatusChange(){
      this.senderDetailEditStatus = true;
      this.updateSenderInfo = JSON.parse(JSON.stringify(this.senderInfo));
      if(this.updateSenderInfo.type===0){
        this.editDisabledStatus = true;
      }
      setTimeout(()=>{
        this.senderEditForm.setFieldsValue({
          type:this.updateSenderInfo.deliveryType,
          sex:this.updateSenderInfo.sex,
          phone:this.updateSenderInfo.phone,
          remark:this.updateSenderInfo.remark,
        });
        if(this.senderEditForm.getFieldValue('type')===1){
          this.senderEditForm.setFieldsValue({
            name:this.updateSenderInfo.name
          });
        }
        if(this.senderEditForm.getFieldValue('type')===0){
          this.supplierStaffChooseOption.label = this.updateSenderInfo.name;
        }
      },0)
    },
    senderDetailEditStatusReBack(){
      this.senderDetailEditStatus = false;
      this.supplierStaffChooseOption = {key:'',label:''};
    },
    staffSelectChange(value){
      this.supplierStaffChooseOption = value;
      this.updateSenderInfo.staffId = value.key;
      this.updateSenderInfo.name = value.label;
    },
    getFormatDate(text){
      const result = dateFormat(text,'YYYY-MM-DD HH:mm:ss');
      return result;
    },
  },
  watch:{
    senderDetail(newValue){
      if(newValue){
        this.orderDetailInfo = newValue.purchaseOrder;
        this.code = this.orderDetailInfo.code;
        this.taskCode = this.orderDetailInfo.purchaseOrderTaskCode;
        this.supplierName = this.orderDetailInfo.supplierName;
        const senderList = newValue.deliveryPersonInfoList;
        const supplierId = this.orderDetailInfo.supplierId;
        const orderId = this.orderDetailInfo.id;
        if(senderList&&senderList.length>0){
          const deliveryType = senderList[0].type;
          this.senderInfo = {deliveryType,supplierId,orderId,...senderList[senderList.length-1]};
        }
      }
    },
  },
  computed:{
    initDeliveryType(){
      return this.senderInfo.deliveryType?this.senderInfo.deliveryType:0;
    },
    initName(){
      return this.senderInfo.name?this.senderInfo.name:"xxx";
    },
    initSex(){
      return this.senderInfo.sex?this.senderInfo.sex:0;
    },
    initPhone(){
      return this.senderInfo.phone?this.senderInfo.phone:undefined;
    },
    initRemark(){
      return this.senderInfo.remark?this.senderInfo.remark:undefined;
    },

  }
}
</script>

<style scoped>

</style>